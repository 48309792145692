import { useTheme } from '@mui/material';
import React from 'react';

interface CheckedDarkIconAttributes {
  size?: number; // Optional size prop to control the icon size
  color?: string; // Optional color prop to control the icon color
}

export const CheckedDarkIcon: React.FC<CheckedDarkIconAttributes> = ({ size = 24, color = '#000000' }: CheckedDarkIconAttributes) => {
  const theme = useTheme();

  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Icon / Check">
        <path id="Vector" d="M20 6L9 17L4 12" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </g>
    </svg>
  );
};
