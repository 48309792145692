import { forwardRef, Fragment, MouseEvent, useId, useState } from 'react';
import { RowCenterStack, StyledListItemButton, StyledListItemButtonNested, StyledListItemButtonNestedWithIndicator, VisuallyHidden } from '@/shared';
import { Box, Collapse, List, ListItemText, IconButton, Typography, Chip } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'src/app/hooks';
import { selectErbFieldCategoriesWithActiveSections, selectErTemplateSections } from '../erb.slice';

interface EmployeeRecordBuilderMenuProps {
  scrollToSection: (e: React.MouseEvent<HTMLButtonElement>, sectionScrollLabelID: string) => void;
  categoryButtonRefs: React.MutableRefObject<Record<string, React.RefObject<HTMLButtonElement>>>;
}

export const EmployeeRecordBuilderMenu = forwardRef<HTMLDivElement, EmployeeRecordBuilderMenuProps>(({ scrollToSection, categoryButtonRefs }, ref) => {
  const { t } = useTranslation(['common']);
  const subHeaderId = useId();
  const sections = useAppSelector(selectErTemplateSections);
  const [primaryItemOpen, setPrimaryItemOpen] = useState('');
  const [isGrouped, setIsGrouped] = useState(false);
  const categoriesWithActiveSections = useAppSelector(selectErbFieldCategoriesWithActiveSections);

  const handlePrimaryItemClick = (e: MouseEvent<HTMLButtonElement>, name: string) => {
    e.preventDefault();
    setPrimaryItemOpen(name === primaryItemOpen ? '' : name);
  };

  const handleGroupedButtonClicked = () => {
    setIsGrouped(!isGrouped);
    setPrimaryItemOpen(categoriesWithActiveSections[0].categoryId);
  };

  return (
    <Box component="nav" sx={{ backgroundColor: 'inherit', borderRadius: 'inherit', height: '100%', overflowY: 'scroll' }} ref={ref}>
      <RowCenterStack
        sx={{
          justifyContent: 'space-between',
          padding: '8px 0'
        }}
      >
        <Typography
          sx={{
            color: (theme) => theme.palette.customTheme.contColor,
            padding: '8px 24px',
            textTransform: 'uppercase',
            fontSize: '14px',
            fontWeight: 700
          }}
        >
          {isGrouped ? t('categories', { ns: 'common' }) : t('list_sections', { ns: 'common' })}
        </Typography>
        <Chip
          label={!isGrouped ? 'Group by Category' : 'Show Sections'}
          sx={{
            background: ' rgba(151, 71, 255, 0.10)',
            fontWeight: 700
          }}
          onClick={handleGroupedButtonClicked}
        />
      </RowCenterStack>
      {!isGrouped && (
        <List
          sx={{
            width: '100%',
            px: 1
          }}
          aria-labelledby={subHeaderId}
        >
          {sections.map((s) => (
            <StyledListItemButtonNested key={s.id}>
              <ListItemText
                primary={s.name}
                primaryTypographyProps={{
                  fontSize: 14
                }}
              />
            </StyledListItemButtonNested>
          ))}
        </List>
      )}
      {isGrouped && (
        <List
          sx={{
            width: '100%',
            px: 1
          }}
          aria-labelledby={subHeaderId}
        >
          {categoriesWithActiveSections.map((c) => (
            <Fragment key={c.categoryId}>
              <StyledListItemButton>
                <ListItemText
                  primary={c.categoryName}
                  primaryTypographyProps={{
                    fontSize: 14
                  }}
                />
                {c.sections.length > 0 && (
                  <IconButton
                    disableFocusRipple
                    onClick={(e) => handlePrimaryItemClick(e, c.categoryId)}
                    sx={{
                      p: 0,
                      m: 0
                    }}
                  >
                    {primaryItemOpen === c.categoryId ? (
                      <>
                        <ExpandLess />
                        <VisuallyHidden>Expand {c.categoryName}</VisuallyHidden>
                      </>
                    ) : (
                      <>
                        <ExpandMore />
                        <VisuallyHidden>Expand {c.categoryName}</VisuallyHidden>
                      </>
                    )}
                  </IconButton>
                )}
              </StyledListItemButton>
              {c.sections.length > 0 && (
                <Collapse in={primaryItemOpen === c.categoryId} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {c.sections.map((s) => (
                      <Fragment key={s.id}>
                        <StyledListItemButtonNestedWithIndicator>
                          <RowCenterStack
                            sx={{
                              gap: 1
                            }}
                          >
                            <ListItemText
                              primary={s.name}
                              primaryTypographyProps={{
                                fontSize: 12
                              }}
                            />
                          </RowCenterStack>
                        </StyledListItemButtonNestedWithIndicator>
                      </Fragment>
                    ))}
                  </List>
                </Collapse>
              )}
            </Fragment>
          ))}
        </List>
      )}
    </Box>
  );
});
