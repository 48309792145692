import { RetrieveEmployeesByQueryResponse } from '@thrivea/organization-client';
import { OptionType } from '@features/admin-settings';

export const flattenData = (data: RetrieveEmployeesByQueryResponse): OptionType[] => {
  const options: OptionType[] = [];

  if (data.siteResults && data.siteResults.sites) {
    options.push(...data.siteResults.sites);
  }

  if (data.jobTitleResults && data.jobTitleResults.jobTitles) {
    options.push(...data.jobTitleResults.jobTitles);
  }

  if (data.employeeResults && data.employeeResults.employees) {
    options.push(...data.employeeResults.employees);
  }

  return options;
};
