import React from 'react';

interface StackedBarsIconProps {
  size?: number; // Optional size prop to control the icon size
  color?: string; // Optional color prop to control the icon color
}

export const StackedBars: React.FC<StackedBarsIconProps> = ({ size = 24, color = '#251D38' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size / 2} fill="none">
      <path d="M19.3698 0H5.42773V3.01449H19.3698V0Z" fill={color} />
      <path d="M15.4132 5.08691H5.42773V8.10141H15.4132V5.08691Z" fill={color} />
      <path d="M10.3263 9.98535H5.42773V12.9998H10.3263V9.98535Z" fill={color} />
      <path
        d="M2.11851 2.99093C2.94012 2.99093 3.60616 2.32488 3.60616 1.50328C3.60616 0.68167 2.94012 0.015625 2.11851 0.015625C1.2969 0.015625 0.630859 0.68167 0.630859 1.50328C0.630859 2.32488 1.2969 2.99093 2.11851 2.99093Z"
        fill={color}
      />
      <path
        d="M2.11851 8.09933C2.94012 8.09933 3.60616 7.43328 3.60616 6.61168C3.60616 5.79007 2.94012 5.12402 2.11851 5.12402C1.2969 5.12402 0.630859 5.79007 0.630859 6.61168C0.630859 7.43328 1.2969 8.09933 2.11851 8.09933Z"
        fill={color}
      />
      <path
        d="M2.11851 12.9133C2.94012 12.9133 3.60616 12.2472 3.60616 11.4256C3.60616 10.604 2.94012 9.93799 2.11851 9.93799C1.2969 9.93799 0.630859 10.604 0.630859 11.4256C0.630859 12.2472 1.2969 12.9133 2.11851 12.9133Z"
        fill={color}
      />
    </svg>
  );
};
