import { alertClasses, Box, Collapse, collapseClasses, IconButton, paperClasses, Snackbar, snackbarClasses, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { RowCenterStack, StyledAlert } from '@/shared';
import { closeSnackbar, selectIsDeploymentSnackbarOpen } from '@features/snackbar/snackbar.slice';
import { useTranslation } from 'react-i18next';
import { ReplayOutlined } from '@mui/icons-material';

export const DeploymentSnackbar = () => {
  const { t } = useTranslation(['common']);
  const dispatch = useAppDispatch();
  const isDeploymentSnackbarOpen = useAppSelector(selectIsDeploymentSnackbarOpen);

  const handleSnackbarClose = () => {
    dispatch(closeSnackbar());
  };

  return (
    <Snackbar
      open={isDeploymentSnackbarOpen}
      onClose={handleSnackbarClose}
      TransitionComponent={Collapse}
      sx={{
        [`&.${snackbarClasses.root}`]: {
          position: 'sticky',
          top: 16
        },
        [`& .${collapseClasses.root}`]: {
          width: '100%',
          mr: 2,
          mb: 2
        },
        [`& .${paperClasses.root}.${alertClasses.root}`]: {
          pr: 2
        }
      }}
    >
      <Box
        sx={{
          width: '100%'
        }}
      >
        <StyledAlert severity="info" onClose={handleSnackbarClose}>
          <RowCenterStack gap={1}>
            <Typography>{t('update_app', { ns: 'common' })}</Typography>
            <IconButton aria-label="Reload" onClick={() => window.location.reload()}>
              <ReplayOutlined sx={{ fontSize: 18, color: (theme) => theme.palette.common.white }} />
            </IconButton>
          </RowCenterStack>
        </StyledAlert>
      </Box>
    </Snackbar>
  );
};
