import { Stack, Box, Typography, Chip } from '@mui/material';
import { PrimaryTypographyBodyBold, RowCenterStack, StyledTransparentButton } from '@/shared';
import { AssetsAction, AssetsDrawerTabs } from '@features/assets';
import { useAppDispatch } from '@app/hooks';
import { retrieveEmployeeBasicInfoRequested } from '@features/admin-settings';
import { RetrieveEmployeeBasicInfoRequest } from '@thrivea/organization-client';

export const AssetsDrawerContent = () => {
  const dispatch = useAppDispatch();

  const handleRetrieveSingleEmployeeInfo = (employeeId: string) => {
    dispatch(
      retrieveEmployeeBasicInfoRequested(
        new RetrieveEmployeeBasicInfoRequest({
          employeeId
        })
      )
    );
  };

  return (
    <Stack
      gap={2}
      sx={{
        position: 'relative'
      }}
    >
      <Stack
        gap={1}
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          padding: 2,
          minHeight: 220,
          backgroundColor: (theme) => theme.palette.grey[600],
          borderRadius: (theme) => theme.spacing(2)
        }}
      >
        <Box
          component="img"
          loading="lazy"
          sx={{ height: 150, width: 150, objectFit: 'cover', borderRadius: (theme) => theme.spacing(1) }}
          src={'http://localhost:5173/images/word-docl-placeholder.png'}
          alt={`cover image`}
        />
        <Typography variant="h4">Test pdf doc</Typography>
        <Typography variant="body2">PDF Document</Typography>
        <AssetsAction />
      </Stack>
      <Stack
        gap={1}
        sx={{
          justifyContent: 'center',
          width: '100%',
          padding: 2,
          backgroundColor: (theme) => theme.palette.common.white,
          borderRadius: (theme) => theme.spacing(2)
        }}
      >
        <PrimaryTypographyBodyBold variant="h5">File details</PrimaryTypographyBodyBold>
        <RowCenterStack gap={'4px'}>
          <PrimaryTypographyBodyBold>Type:</PrimaryTypographyBodyBold>
          <Typography>PDF Document</Typography>
        </RowCenterStack>
        <RowCenterStack gap={'4px'}>
          <PrimaryTypographyBodyBold>Size:</PrimaryTypographyBodyBold>
          <Typography>2MB</Typography>
        </RowCenterStack>
        <RowCenterStack gap={'4px'}>
          <PrimaryTypographyBodyBold>Owner:</PrimaryTypographyBodyBold>
          <StyledTransparentButton
            onClick={() => handleRetrieveSingleEmployeeInfo('019006af-dbb6-4614-ac4b-fbc200efebef')}
            variant="text"
            sx={{ color: (theme) => theme.palette.customTheme.focusItem, textDecoration: 'underline' }}
          >
            Stevo Cvijetic
          </StyledTransparentButton>
        </RowCenterStack>
        <RowCenterStack gap={'4px'}>
          <PrimaryTypographyBodyBold>Created:</PrimaryTypographyBodyBold>
          <Typography>Aug 18, 2024</Typography>
        </RowCenterStack>
        <RowCenterStack gap={'4px'}>
          <PrimaryTypographyBodyBold>Modified:</PrimaryTypographyBodyBold>
          <RowCenterStack gap={'4px'}>
            <Typography>Aug 18, 2024, by </Typography>
            <StyledTransparentButton
              onClick={() => handleRetrieveSingleEmployeeInfo('019006af-dbb6-4614-ac4b-fbc200efebef')}
              variant="text"
              sx={{ color: (theme) => theme.palette.customTheme.focusItem, textDecoration: 'underline' }}
            >
              Stevo Cvijetic
            </StyledTransparentButton>
          </RowCenterStack>
        </RowCenterStack>
        <RowCenterStack gap={'4px'}>
          <PrimaryTypographyBodyBold>Permissions:</PrimaryTypographyBodyBold>
          <Chip label={'Viewers can download'} size="small" sx={{ fontWeight: 700, backgroundColor: (theme) => theme.palette.customTheme.primaryA40 }} />
        </RowCenterStack>
        <RowCenterStack gap={'4px'}>
          <PrimaryTypographyBodyBold>Description:</PrimaryTypographyBodyBold>
          <Typography>None</Typography>
        </RowCenterStack>
      </Stack>
      <AssetsDrawerTabs />
    </Stack>
  );
};
