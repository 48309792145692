import React, { memo, useMemo } from 'react';
import { Box } from '@mui/material';

interface HighlightedTextProps {
  text: string;
  highlight: string;
}

const areEqual = (prevProps: HighlightedTextProps, nextProps: HighlightedTextProps) => {
  return prevProps.text === nextProps.text && prevProps.highlight === nextProps.highlight;
};

export const HighlightedText: React.FC<HighlightedTextProps> = memo(({ text, highlight }) => {
  const lowerHighlight = highlight.toLowerCase();
  const parts = useMemo(() => text.split(new RegExp(`(${highlight})`, 'gi')), [text, highlight]);

  return (
    <Box component="div">
      {parts.map((part, index) =>
        part.toLowerCase() === lowerHighlight ? (
          <Box
            component="span"
            key={`${part}-${index}`}
            sx={{
              backgroundColor: (theme) => theme.palette.divider,
              fontWeight: 600
            }}
          >
            {part}
          </Box>
        ) : (
          part
        )
      )}
    </Box>
  );
}, areEqual);
