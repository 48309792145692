import { Skeleton, Stack, Typography, alpha } from '@mui/material';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { ActionStatus, Analytics, CircleIcon, RowCenterStack, StyledEmployeeRecordBuilderAudienceButton } from '@/shared';
import { DrawerType, openDrawer, PostAnalyticsTab, PostAnalyticsViewsAndReadsChart, PostAnalyticsViewsChart } from '@features/drawer';
import { retrieveAudienceByIdRequested } from '@features/admin-settings';
import { useTranslation } from 'react-i18next';
import {
  retrieveCurrentPostAnalyticAudienceLengthRequested,
  retrievePostReadAnalyticsRequested,
  selectAudiencesById,
  selectCurrentPostAnalytic,
  selectCurrentPostAnalyticAudienceLength,
  selectPostAnalyticsStatus
} from '@features/homepage';
import { RetrieveAudienceByIdRequest, RetrieveEmployeeIdsByAudienceIdRequest, RetrievePostReadAnalyticsRequest } from '@thrivea/networking-client';
import { useEffect } from 'react';

export const PostAnalyticsDrawerContent = () => {
  const { t } = useTranslation(['drawer']);
  const dispatch = useAppDispatch();
  const currentPostAnalytic = useAppSelector(selectCurrentPostAnalytic);
  const currentPostAnalyticRecipients = useAppSelector(selectCurrentPostAnalyticAudienceLength);
  const postAudiencesById = useAppSelector(selectAudiencesById);
  const postAnalyticsStatus = useAppSelector(selectPostAnalyticsStatus);

  const handleRetrieveAudienceById = (audienceId: string) => {
    dispatch(retrieveAudienceByIdRequested(new RetrieveAudienceByIdRequest({ audienceId })));
    dispatch(openDrawer(DrawerType.Audience));
  };

  useEffect(() => {
    if (currentPostAnalytic && currentPostAnalytic.case === 'shoutout' && currentPostAnalytic.hasReadConfirmation) {
      dispatch(
        retrievePostReadAnalyticsRequested(
          new RetrievePostReadAnalyticsRequest({
            postId: currentPostAnalytic.id
          })
        )
      );
    }
  }, [currentPostAnalytic]);

  useEffect(() => {
    if (currentPostAnalytic) {
      dispatch(
        retrieveCurrentPostAnalyticAudienceLengthRequested(
          new RetrieveEmployeeIdsByAudienceIdRequest({
            audienceId: currentPostAnalytic.audienceIds[0]
          })
        )
      );
    }
  }, [currentPostAnalytic]);

  return (
    <Stack
      gap={2}
      sx={{
        padding: 1
      }}
    >
      <Analytics width={66} height={63} color="#000000" />
      <RowCenterStack>
        <Typography variant="h4">Post analytics</Typography>
      </RowCenterStack>
      <Stack
        gap={2}
        sx={{
          backgroundColor: (theme) => theme.palette.common.white,
          margin: '0  -12px',
          padding: '24px 16px',
          borderRadius: '16px'
        }}
      >
        {!currentPostAnalytic && (
          <>
            <Skeleton sx={{ width: '100%', height: 40 }} />
            <Skeleton sx={{ width: '100%', height: 40 }} />
            <Skeleton sx={{ width: '100%', height: 40 }} />
            <Skeleton sx={{ width: '100%', height: 40 }} />
            <Skeleton sx={{ width: '100%', height: 40 }} />
          </>
        )}
        {currentPostAnalytic && (
          <>
            <RowCenterStack gap={1}>
              <Typography>Time & Date: </Typography>
              {postAnalyticsStatus === ActionStatus.Pending && <Skeleton width={176} height={20} />}
              {postAnalyticsStatus === ActionStatus.Idle && (
                <Typography fontWeight={700}>
                  {currentPostAnalytic.case === 'shoutout' &&
                    currentPostAnalytic.editedTime &&
                    currentPostAnalytic.editedTime.toFormat("ccc HH:mm; dd.LL. yyyy.'")}
                  {currentPostAnalytic.case === 'shoutout' &&
                    !currentPostAnalytic.editedTime &&
                    currentPostAnalytic.publishTime.toFormat("ccc HH:mm; dd.LL. yyyy.'")}
                </Typography>
              )}
              {currentPostAnalytic.case === 'shoutout' && currentPostAnalytic.editedTime && (
                <>
                  <CircleIcon size={4} color={'#251D38'} />
                  <Typography
                    component="span"
                    variant="caption"
                    sx={{ lineHeight: 'normal', color: (theme) => alpha(theme.palette.customTheme.contColor, 0.45) }}
                  >
                    {t('edited', { ns: 'homepage' })}
                  </Typography>
                </>
              )}
            </RowCenterStack>
            <RowCenterStack gap={1}>
              <Typography> Audience:</Typography>
              {postAnalyticsStatus === ActionStatus.Pending && <Skeleton width={176} height={20} />}
              {postAnalyticsStatus === ActionStatus.Idle && (
                <StyledEmployeeRecordBuilderAudienceButton
                  variant="text"
                  onClick={() => handleRetrieveAudienceById(postAudiencesById[currentPostAnalytic.audienceIds[0]].audienceId)}
                >
                  <Typography component="span" variant="body2">
                    {postAudiencesById[currentPostAnalytic.audienceIds[0]].audienceName}
                  </Typography>
                </StyledEmployeeRecordBuilderAudienceButton>
              )}
            </RowCenterStack>
            <RowCenterStack gap={1}>
              <Typography> Recipients:</Typography>
              {postAnalyticsStatus === ActionStatus.Pending && <Skeleton width={30} height={20} />}
              {postAnalyticsStatus === ActionStatus.Idle && <Typography fontWeight={700}> {currentPostAnalyticRecipients}</Typography>}
            </RowCenterStack>
            <RowCenterStack gap={1}>
              <Typography>Imporant post:</Typography>
              {postAnalyticsStatus === ActionStatus.Pending && <Skeleton width={30} height={20} />}
              {postAnalyticsStatus === ActionStatus.Idle && (
                <Typography fontWeight={700}>{currentPostAnalytic.case === 'shoutout' && currentPostAnalytic.isImporant ? 'Yes' : 'No'}</Typography>
              )}
            </RowCenterStack>
            <RowCenterStack gap={1}>
              <Typography>Read confirmation:</Typography>
              {postAnalyticsStatus === ActionStatus.Pending && <Skeleton width={30} height={20} />}
              {postAnalyticsStatus === ActionStatus.Idle && (
                <Typography fontWeight={700}>{currentPostAnalytic.case === 'shoutout' && currentPostAnalytic.hasReadConfirmation ? 'Yes' : 'No'}</Typography>
              )}
            </RowCenterStack>
          </>
        )}
      </Stack>
      <Stack
        sx={{
          backgroundColor: (theme) => theme.palette.common.white,
          padding: '24px 16px',
          margin: '0 -12px',
          borderRadius: '16px'
        }}
      >
        <Typography variant="h4">{t('views', { ns: 'drawer' })}</Typography>
        {currentPostAnalytic && currentPostAnalytic.case === 'shoutout' && currentPostAnalytic.hasReadConfirmation && <PostAnalyticsViewsAndReadsChart />}
        {currentPostAnalytic && currentPostAnalytic.case === 'shoutout' && !currentPostAnalytic.hasReadConfirmation && <PostAnalyticsViewsChart />}
      </Stack>
      {currentPostAnalytic && currentPostAnalytic.case === 'shoutout' && currentPostAnalytic.hasReadConfirmation && (
        <Stack
          sx={{
            margin: '0 -12px',
            borderRadius: '16px'
          }}
        >
          <PostAnalyticsTab />
        </Stack>
      )}
    </Stack>
  );
};
