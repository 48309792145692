import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActionStatus } from '@/shared';
import { FileWithStatus, UploadedFile, UploadProgress } from '@api/blob-storage.api';
import { RootState } from '@app/store';
import { trimQueryParams } from '@utils/trimQueryParams';
import { SasUri, RetrieveSiteDeleteSasTokenRequest, SasToken } from '@thrivea/auth-client';

interface SiteState {
  ui: {
    siteMediaFileWithStatus: FileWithStatus;
    uploadSiteMediaStatus: ActionStatus;
    fileUploadProgress: {
      fileProgress: number;
      totalProgress: number;
    };
  };
  siteWriteSasUri: string;
  siteDeleteSasToken: string;
  siteReadSasToken: string;
}

const initialState: SiteState = {
  ui: {
    siteMediaFileWithStatus: {} as FileWithStatus,
    uploadSiteMediaStatus: ActionStatus.Idle,
    fileUploadProgress: {
      fileProgress: 0,
      totalProgress: 0
    }
  },
  siteDeleteSasToken: '',
  siteReadSasToken: '',
  siteWriteSasUri: ''
};

export const siteSlice = createSlice({
  name: 'site',
  initialState,
  reducers: {
    uploadSiteMediaRequested: (state, action: PayloadAction<File>) => {
      state.ui.siteMediaFileWithStatus = {
        isUploaded: false,
        isUploading: false,
        name: action.payload.name,
        file: action.payload,
        url: ''
      } as FileWithStatus;

      state.ui.uploadSiteMediaStatus = ActionStatus.Pending;
    },
    uploadSiteMediaSucceeded: (state) => {
      state.ui.uploadSiteMediaStatus = ActionStatus.Idle;
    },
    uploadSiteMediaFailed: (state) => {
      state.ui.uploadSiteMediaStatus = ActionStatus.Failed;
      state.ui.siteMediaFileWithStatus = {} as FileWithStatus;
    },
    updateSiteFileUploadProgress: (state, action: PayloadAction<UploadProgress>) => {
      state.ui.fileUploadProgress = {
        fileProgress: action.payload.fileProgress,
        totalProgress: action.payload.totalProgress
      };
    },
    deleteSiteMediaRequested: (state, action: PayloadAction<string>) => {},
    deleteSiteMediaSucceeded: (state) => {
      state.ui.siteMediaFileWithStatus = {} as FileWithStatus;
    },
    deleteSiteMediaFailed: (state) => {},
    uploadSiteFile: (state, action: PayloadAction<FileWithStatus>) => {
      state.ui.siteMediaFileWithStatus.isUploading = true;
      state.ui.siteMediaFileWithStatus.isUploaded = false;
    },
    siteFileUploaded: (state, action: PayloadAction<UploadedFile>) => {
      state.ui.siteMediaFileWithStatus.isUploading = false;
      state.ui.siteMediaFileWithStatus.isUploaded = true;
      state.ui.siteMediaFileWithStatus.url = trimQueryParams(action.payload.url);
    },
    retrieveSiteWriteSasUriRequested: (state) => {},
    retrieveSiteWriteSasUriSucceeded: (state, action: PayloadAction<SasUri>) => {
      state.siteWriteSasUri = action.payload.uri;
    },
    retrieveSiteWriteSasUriFailed: (state) => {},
    retrieveSiteDeleteSasTokenRequested: (state, action: PayloadAction<RetrieveSiteDeleteSasTokenRequest>) => {},
    retrieveSiteDeleteSasTokenSucceeded: (state, action: PayloadAction<SasToken>) => {
      state.siteDeleteSasToken = action.payload.token;
    },
    retrieveSiteDeleteSasTokenFailed: (state) => {},
    retrieveSiteReadSasTokenRequested: (state) => {},
    retrieveSiteReadSasTokenSucceeded: (state, action: PayloadAction<SasToken>) => {
      state.siteReadSasToken = action.payload.token;
    },
    retrieveSiteReadSasTokenFailed: (state) => {}
  }
});

export const selectSiteMediaFileWithStatus = (state: RootState) => state.site.ui.siteMediaFileWithStatus;
export const selectUploadSiteMediaStatus = (state: RootState) => state.site.ui.uploadSiteMediaStatus;
export const selectSiteFileUploadProgress = (state: RootState) => state.site.ui.fileUploadProgress.fileProgress;

export const selectIsSiteMediaFileUploading = (state: RootState) => state.site.ui.siteMediaFileWithStatus.isUploading;
export const selectIsSiteMediaFileUploaded = (state: RootState) => state.site.ui.siteMediaFileWithStatus.isUploaded;
export const selectSiteMediaFileUrl = (state: RootState) => state.site.ui.siteMediaFileWithStatus.url;

export const selectSiteWriteSasUri = (state: RootState) => state.site.siteWriteSasUri;
export const selectSiteDeleteSasToken = (state: RootState) => state.site.siteDeleteSasToken;
export const selectSiteReadSasToken = (state: RootState) => state.site.siteReadSasToken;

export const {
  deleteSiteMediaFailed,
  deleteSiteMediaRequested,
  deleteSiteMediaSucceeded,
  siteFileUploaded,
  updateSiteFileUploadProgress,
  uploadSiteFile,
  uploadSiteMediaFailed,
  uploadSiteMediaRequested,
  uploadSiteMediaSucceeded,
  retrieveSiteDeleteSasTokenFailed,
  retrieveSiteDeleteSasTokenRequested,
  retrieveSiteDeleteSasTokenSucceeded,
  retrieveSiteReadSasTokenFailed,
  retrieveSiteReadSasTokenRequested,
  retrieveSiteReadSasTokenSucceeded,
  retrieveSiteWriteSasUriFailed,
  retrieveSiteWriteSasUriRequested,
  retrieveSiteWriteSasUriSucceeded
} = siteSlice.actions;
export default siteSlice.reducer;
