import React, { useState } from 'react';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { StyledFilledInput, StyledFormHeader, StyledFormWrapper } from '@/shared';
import {
  ProfileTimelineModal,
  selectBasicInfoCategory,
  selectEmergencyCategory,
  selectEmployeeItems,
  selectEmploymentCategoryTableData,
  selectEmploymentTypes,
  selectPersonalContactDetailsCategory,
  selectWorkCategoryTableData,
  selectWorkContactCategory
} from '@features/employee-profile';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@app/hooks';
import { EmploymentContractType } from '@thrivea/organization-client';

const employmentContractTypes = Object.keys(EmploymentContractType)
  .filter((key) => !isNaN(Number(key)))
  .slice(1) //exclude first option
  .map((key) => ({
    id: parseInt(key, 10) - 1, //deduct by 1 as we are removing first option
    // removes underscores and sets only the first letter to uppercase
    name: EmploymentContractType[Number(key)].toLowerCase()
  }));

export const ProfileOverview: React.FC = () => {
  const { t } = useTranslation(['common', 'employee_profile', 'employment_contract_type']);

  const employmentTypes = useAppSelector(selectEmploymentTypes);
  const employeeItems = useAppSelector(selectEmployeeItems);
  const basicInfoCategory = useAppSelector(selectBasicInfoCategory);
  const workContactCategory = useAppSelector(selectWorkContactCategory);
  const personalContactCategory = useAppSelector(selectPersonalContactDetailsCategory);
  const emergencyCategory = useAppSelector(selectEmergencyCategory);
  const employmentCategoryTable = useAppSelector(selectEmploymentCategoryTableData);
  const workCategoryTable = useAppSelector(selectWorkCategoryTableData);
  const lastEmploymentEvent = employmentCategoryTable[employmentCategoryTable.length - 1];
  const lastWorkEvent = workCategoryTable[workCategoryTable.length - 1];
  const [isTimelineModalOpen, setIsTimelineModalOpen] = useState(false);

  const handleTimelineModalOpen = () => {
    setIsTimelineModalOpen(true);
  };

  const handleTimelineModalClose = () => {
    setIsTimelineModalOpen(false);
  };

  return (
    <StyledFormWrapper id="overview">
      <StyledFormHeader className="Mui-ProfileFiledHeader">
        <Typography component="h3" variant="h5" fontWeight={700}>
          Overview
        </Typography>
        {/* Add these buttons after org chart and timeline implementation */}
        {/* <RowCenterStack gap={1}>
          <Box>
            <StyledIconButton>
              <AccountTreeIcon />
              <Typography variant="body1">Org chart</Typography>
            </StyledIconButton>
          </Box>
          <Box>
            <StyledIconButton onClick={handleTimelineModalOpen}>
              <HistoryIcon />
              <Typography variant="body1">View timeline</Typography>
            </StyledIconButton>
          </Box>
        </RowCenterStack> */}
      </StyledFormHeader>
      <Grid
        container
        spacing={2}
        sx={{
          padding: 2
        }}
      >
        <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
          <StyledFilledInput
            disabled
            id="reports_to"
            label={t('reports_to', { ns: 'employee_profile' })}
            value={
              lastWorkEvent !== undefined && workCategoryTable[workCategoryTable.length - 1].reportsToId !== ''
                ? employeeItems.byId[workCategoryTable[workCategoryTable.length - 1].reportsToId].displayName
                : ''
            }
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
          <StyledFilledInput
            disabled
            id="employment_contract"
            label={t('employment_contract', { ns: 'employee_profile' })}
            value={
              lastEmploymentEvent !== undefined ? t(employmentContractTypes[employmentCategoryTable.length - 1].name, { ns: 'employment_contract_type' }) : ''
            }
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
          <StyledFilledInput
            disabled
            id="employment_type"
            label={t('employment_type_id', { ns: 'employee_profile' })}
            value={
              lastEmploymentEvent !== undefined
                ? employmentTypes.find((employmentType) => employmentType.id === lastEmploymentEvent.employmentTypeId)!.name
                : ''
            }
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
          <StyledFilledInput disabled id="time_in_position" label={t('time_in_position', { ns: 'employee_profile' })} />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
          <StyledFilledInput disabled id="start_date" label={t('start_date', { ns: 'employee_profile' })} />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
          <StyledFilledInput disabled id="accumulated_tenure" label={t('accumulated_tenure', { ns: 'employee_profile' })} />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
          <StyledFilledInput disabled id="overview_email" value={basicInfoCategory.emailAddress} label={t('email', { ns: 'common' })} />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
          <StyledFilledInput
            disabled
            id="work_landline"
            value={workContactCategory.workLandlinePhoneNumber}
            label={t('work_landline', { ns: 'employee_profile' })}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
          <StyledFilledInput
            disabled
            id="personal_mobile"
            value={personalContactCategory.personalMobilePhoneNumber}
            label={t('personal_mobile', { ns: 'employee_profile' })}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
          <StyledFilledInput
            disabled
            id="personal_phone"
            value={personalContactCategory.personalLandlinePhoneNumber}
            label={t('personal_phone', { ns: 'employee_profile' })}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
          <StyledFilledInput
            disabled
            id="emergency_contact"
            value={emergencyCategory.emergencyContactMobilePhoneNumber}
            label={t('emergency_contact', { ns: 'employee_profile' })}
          />
        </Grid>
      </Grid>
      <ProfileTimelineModal isModalOpen={isTimelineModalOpen} handleModalClose={handleTimelineModalClose} handleModalOpen={handleTimelineModalOpen} />
    </StyledFormWrapper>
  );
};
