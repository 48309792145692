import { useTheme } from '@mui/material';
import React from 'react';

interface CommentIconAttributes {
  size?: number;
  color?: string;
}

export const CommentIcon: React.FC<CommentIconAttributes> = ({ size = 24, color = 'black' }: CommentIconAttributes) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
      <path
        fill={color}
        d="M12.253 3.633c-6.202 0-11.25 3.368-11.25 7.5 0 2.025 1.208 3.953 3.345 5.355a6.968 6.968 0 0 1-2.25 3.15l-.817.66a.75.75 0 0 0 .472 1.335h.083l1.357-.15a8.527 8.527 0 0 0 5.745-3.172c1.092.217 2.202.325 3.315.322 6.203 0 11.25-3.367 11.25-7.5 0-4.132-5.047-7.5-11.25-7.5Zm0 13.5c-1.164.001-2.324-.132-3.457-.397a.75.75 0 0 0-.788.3 7.02 7.02 0 0 1-3.885 2.737 8.602 8.602 0 0 0 1.838-3.39.75.75 0 0 0-.353-.847c-1.972-1.178-3.105-2.768-3.105-4.403 0-3.307 4.373-6 9.75-6 5.378 0 9.75 2.693 9.75 6 0 3.308-4.372 6-9.75 6Z"
      />
    </svg>
  );
};
