import { ForwardedRef, useMemo, forwardRef, useEffect, useId, useState } from 'react';
import { Typography, IconButton, CircularProgress, Stack, Box } from '@mui/material';
import Grid from '@mui/material/Grid2';
import {
  RowCenterStack,
  VisuallyHidden,
  EditIcon,
  CancelEditIcon,
  StyledFilledInput,
  StyledFormHeader,
  StyledFormWrapper,
  StyledCancelButton,
  StyledFormSubmitButton,
  ActionStatus
} from '@/shared';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { BasicInfo, UpdateBasicInfoRequest } from '@thrivea/organization-client';
import { createBasicInfoSchema, selectBasicInfoCategory, selectProfileNotification, updateBasicInfoRequested } from '@features/employee-profile';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { snakeCase } from 'lodash';
import { AllowedTo } from 'react-abac';
import { GroupPermissions, ProfileCategoryInfo } from '@features/abac';

interface BasicInfoCategoryProps {
  ref: ForwardedRef<HTMLDivElement>;
  employeeId: string;
}

interface BasicInfoModel extends BasicInfo {
  fullName: string;
}

export const BasicInfoCategory = forwardRef<HTMLDivElement, BasicInfoCategoryProps>(({ employeeId }, ref) => {
  const id = useId();
  const { t } = useTranslation(['common', 'employee_profile']);
  const name = t('basic_info', { ns: 'employee_profile' });
  const dispatch = useAppDispatch();
  const [isEditable, setIsEditable] = useState(false);
  const basicInfoCategory = useAppSelector(selectBasicInfoCategory);
  const { status, category } = useAppSelector(selectProfileNotification);
  const isPendingAndMatchingName = status === ActionStatus.Pending && category === 'basicInfo';
  const basicInfoSchema = useMemo(() => createBasicInfoSchema(t), [t]);

  const {
    formState: { dirtyFields, errors },
    control,
    handleSubmit,
    reset
  } = useForm<BasicInfoModel>({
    mode: 'all',
    resolver: zodResolver(basicInfoSchema),
    defaultValues: {
      emailAddress: basicInfoCategory.emailAddress,
      firstName: basicInfoCategory.firstName,
      lastName: basicInfoCategory.lastName,
      displayName: basicInfoCategory.displayName,
      middleName: basicInfoCategory.middleName
    }
  });
  const watchFirstName = useWatch({ control, name: 'firstName' });
  const watchLastName = useWatch({ control, name: 'lastName' });
  const watchMiddleName = useWatch({ control, name: 'middleName' });

  const onSubmit = (data: BasicInfoModel) => {
    setIsEditable(false);
    dispatch(
      updateBasicInfoRequested({
        employeeId: employeeId,
        firstName: data.firstName,
        lastName: data.lastName,
        displayName: data.displayName,
        middleName: data.middleName
      } as UpdateBasicInfoRequest)
    );
  };

  const handleToggleEditable = () => {
    setIsEditable(!isEditable);
  };

  const handleCloseEditable = () => {
    setIsEditable(false);
    reset({
      firstName: basicInfoCategory.firstName,
      lastName: basicInfoCategory.lastName,
      displayName: basicInfoCategory.displayName,
      middleName: basicInfoCategory.middleName
    });
  };

  //  TODO - add summary view

  useEffect(() => {
    reset({
      firstName: basicInfoCategory.firstName,
      lastName: basicInfoCategory.lastName,
      displayName: basicInfoCategory.displayName,
      middleName: basicInfoCategory.middleName
    });
  }, [basicInfoCategory]);

  return (
    <StyledFormWrapper isEditable={isEditable} id={snakeCase(name)} ref={ref}>
      <Box
        component="form"
        name={name}
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          backgroundColor: 'transparent'
        }}
      >
        <Stack>
          <StyledFormHeader className="Mui-ProfileFiledHeader" isEditable={isEditable}>
            <RowCenterStack gap={1}>
              {isPendingAndMatchingName && (
                <CircularProgress
                  size={24}
                  thickness={4}
                  sx={{
                    color: (theme) => theme.palette.primary.dark
                  }}
                />
              )}
              <Typography component="h3" variant="h5" fontWeight={700}>
                {name}
              </Typography>
            </RowCenterStack>
            <AllowedTo perform={GroupPermissions.EDIT_PROFILE} data={{ employeeId, categoryName: 'basic_info' } as ProfileCategoryInfo}>
              <IconButton
                onClick={handleToggleEditable}
                sx={{
                  opacity: '0',
                  display: isEditable ? 'none' : 'inline-flex'
                }}
              >
                <VisuallyHidden>Edit {name}</VisuallyHidden>
                <EditIcon />
              </IconButton>
            </AllowedTo>
            <RowCenterStack
              gap={2}
              sx={{
                display: isEditable ? 'flex' : 'none'
              }}
            >
              <StyledCancelButton variant="contained" onClick={handleCloseEditable} startIcon={<CancelEditIcon />}>
                {t('cancel', { ns: 'common' })}
              </StyledCancelButton>
              <StyledFormSubmitButton
                type="submit"
                disabled={Object.values(dirtyFields).length === 0 || Object.values(errors).length !== 0}
                variant="contained"
              >
                {t('done', { ns: 'common' })}
              </StyledFormSubmitButton>
            </RowCenterStack>
          </StyledFormHeader>

          <Grid
            container
            spacing={2}
            sx={{
              padding: 2
            }}
          >
            <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
              <Controller
                name="emailAddress"
                control={control}
                render={({ field, fieldState }) => (
                  <StyledFilledInput
                    {...field}
                    disabled
                    id={id + field.name}
                    label={t(snakeCase(field.name), { ns: 'employee_profile' })}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    InputProps={{
                      readOnly: true
                    }}
                  />
                )}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
              <Controller
                name="firstName"
                control={control}
                render={({ field, fieldState }) => (
                  <StyledFilledInput
                    {...field}
                    id={id + field.name}
                    label={t(snakeCase(field.name), { ns: 'employee_profile' })}
                    value={field.value ?? ''}
                    disabled={!isEditable}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    required
                  />
                )}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
              <Controller
                name="lastName"
                control={control}
                render={({ field, fieldState }) => (
                  <StyledFilledInput
                    {...field}
                    id={id + field.name}
                    label={t(snakeCase(field.name), { ns: 'employee_profile' })}
                    value={field.value ?? ''}
                    disabled={!isEditable}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    required
                  />
                )}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
              <Controller
                name="fullName"
                control={control}
                render={({ field, fieldState }) => (
                  <StyledFilledInput
                    {...field}
                    disabled
                    id={id + field.name}
                    label={t(snakeCase(field.name), { ns: 'employee_profile' })}
                    value={`${watchFirstName !== undefined ? watchFirstName : ''} ${watchMiddleName !== undefined ? watchMiddleName : ''} ${watchLastName !== undefined ? watchLastName : ''}`}
                    InputProps={{
                      readOnly: true
                    }}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
              <Controller
                name="displayName"
                control={control}
                render={({ field, fieldState }) => (
                  <StyledFilledInput
                    {...field}
                    id={id + field.name}
                    label={t(snakeCase(field.name), { ns: 'employee_profile' })}
                    value={field.value ?? ''}
                    disabled={!isEditable}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    required
                  />
                )}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
              <Controller
                name="middleName"
                control={control}
                render={({ field, fieldState }) => (
                  <StyledFilledInput
                    {...field}
                    id={id + field.name}
                    label={t(snakeCase(field.name), { ns: 'employee_profile' })}
                    value={field.value ?? ''}
                    disabled={!isEditable}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Stack>
      </Box>
    </StyledFormWrapper>
  );
});
