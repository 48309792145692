import React from 'react';
import { useTheme } from '@mui/material';

interface EditIconAttributes {
  className?: string;
}

export const EditIcon: React.FC<EditIconAttributes> = ({ className = '' }: EditIconAttributes) => {
  const theme = useTheme();

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.82766 2.86924H16.5161L14.6976 4.73115H4.82766C4.82265 4.73115 4.81855 4.73535 4.81855 4.74048V19.1288C4.81855 19.1339 4.82265 19.1381 4.82766 19.1381H18.8809C18.8859 19.1381 18.89 19.1339 18.89 19.1288V10.0254L20.7085 8.16346V19.1288C20.7085 20.1579 19.8861 21 18.8809 21H4.82766C3.82249 21 3 20.1579 3 19.1288V4.74048C3.00023 3.71134 3.82249 2.86924 4.82766 2.86924ZM12.2695 12.0104C11.6511 11.3773 10.9229 10.9719 10.2339 10.8215L10.1637 10.8934L9.50364 14.8422L13.3605 14.1664L13.4307 14.0946C13.2838 13.3891 12.8877 12.6436 12.2695 12.0104ZM21.2486 0.118585C21.0942 -0.0395283 20.842 -0.0395283 20.6876 0.118585L19.3763 1.46115L22.5729 4.73395L23.8842 3.39138C24.0386 3.23327 24.0386 2.97511 23.8842 2.817L21.2486 0.118585ZM21.8306 5.49419L18.634 2.2214L11.0782 9.95704C11.8044 10.2329 12.4686 10.694 13.0118 11.2502C13.5551 11.8064 14.0054 12.4866 14.2748 13.2298L21.8306 5.49419Z"
        fill="#251D38"
      />
    </svg>
  );
};
