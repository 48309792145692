import { Stack, Skeleton } from '@mui/material';
import { RowCenterStack } from '@/shared';

export const DrawerItemSkeleton = () => {
  return (
    <RowCenterStack
      gap={1}
      sx={{
        p: 1,
        borderRadius: 2
      }}
    >
      <Skeleton width={38} height={38} variant="rounded" />
      <Stack gap={1}>
        <Skeleton width={150} height={20} />
        <Skeleton width={50} height={10} />
      </Stack>
    </RowCenterStack>
  );
};
