import { Box, Stack, Tabs, Typography, alpha } from '@mui/material';
import {
  CustomTabPanel,
  FieldsIcon,
  PrimaryTypographyBodyBold,
  RoundedAutocomplete,
  RowCenterStack,
  StyledEmployeeRecordTab,
  StyledEmplyoeeRecordCategoryPicker
} from '@/shared';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { a11yProps } from '@/utils';
import { EmployeeRecordSortableList } from './EmployeeRecordSortableList';
import { EmployeeRecordDraggableField } from './EmployeeRecordDraggableField';
import { ErbFieldOptions, ErbFieldType, ErbFieldTypeCardinality, ErbFieldWidth, ErTemplateField, TextErbFieldOptions } from '@thrivea/organization-client';
import { EmployeeRecordBuilderDrawerField } from './EmployeeRecordBuilderDrawerField';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { addFieldToSection, selectErTemplateFieldIds } from '@features/employee-record-builder';

const tempEmployeeRecordPageCategories = [
  {
    id: '3c945333-ad81-43e4-9dc9-bc1e54585488',
    name: 'Personal information',
    sections: [
      {
        id: '015bc9b8-a06a-4a21-90da-aa36659c6fef',
        name: 'Section 01'
      },
      {
        id: '0f260a58-84ab-41a9-85a1-2c55f59c850e',
        name: 'Section 02'
      }
    ]
  },
  {
    id: '695a39fb-55e5-4c83-be7e-a2799665df22',
    name: 'Work information',
    sections: [
      {
        id: '015bc9b8-a06a-4a21-90da-aa36659c6fef',
        name: 'Section 01'
      }
    ]
  },
  {
    id: 'd702f344-34e2-4764-acb1-e40430c9fa14',
    name: 'Organizational information',
    sections: [
      {
        id: '015bc9b8-a06a-4a21-90da-aa36659c6fef',
        name: 'Section 01'
      }
    ]
  },
  {
    id: 'b99f71c4-d28c-4cc2-81be-2ca225e9bbfc',
    name: 'Financial information',
    sections: [
      {
        id: '015bc9b8-a06a-4a21-90da-aa36659c6fef',
        name: 'Section 01'
      }
    ]
  },
  {
    id: '229ddca9-1b41-4f4f-baa6-aca1354340f5',
    name: 'Legal & Compliance',
    sections: [
      {
        id: '015bc9b8-a06a-4a21-90da-aa36659c6fef',
        name: 'Section 01'
      }
    ]
  },
  {
    id: '48fcce5d-e592-470f-8c94-f9df743ff890',
    name: 'Custom',
    sections: [
      {
        id: '015bc9b8-a06a-4a21-90da-aa36659c6fef',
        name: 'Section 01'
      }
    ]
  }
];

const tempEmployeeFields = [
  {
    kind: {
      value: {
        id: 'e39913e1-3b46-4e8a-8f4b-239eaa16cd1d',
        name: 'First name',
        position: 1,
        width: ErbFieldWidth.HALF,
        erbScalarField: {
          id: '4da6ca2a-b1eb-4548-adaf-1fcac62638cd',
          name: 'First name',
          type: {
            id: 'c0ea8f8d-4fa6-4522-8d8e-5f4c180c1a03',
            name: 'Text',
            options: undefined,
            cardinality: ErbFieldTypeCardinality.ONE
          } as ErbFieldType,
          options: {
            kind: {
              value: {
                placeholder: 'Placeholder'
              } as TextErbFieldOptions,
              case: 'text'
            }
          } as ErbFieldOptions,
          isDefaultInSection: false,
          isWellKnown: false,
          isCalculated: false
        }
      },
      case: 'scalarField'
    }
  },
  {
    kind: {
      value: {
        id: '57099d73-826c-4a11-b36e-c8cfc6ebd954',
        name: 'Last name',
        position: 2,
        width: ErbFieldWidth.HALF,
        erbScalarField: {
          id: '2b5bbfc0-5412-42f9-897e-9ba8b50c8911',
          name: 'Last name',
          type: {
            id: '05386ea9-5e12-45a2-a2cb-2723d99f3eef',
            name: 'Text',
            options: undefined,
            cardinality: ErbFieldTypeCardinality.ONE
          } as ErbFieldType,
          options: {
            kind: {
              value: {
                placeholder: 'Placeholder'
              } as TextErbFieldOptions,
              case: 'text'
            }
          } as ErbFieldOptions,
          isDefaultInSection: false,
          isWellKnown: false,
          isCalculated: false
        }
      },
      case: 'scalarField'
    }
  },
  {
    kind: {
      value: {
        id: '1891827c-fbf5-447e-9328-7d5595680790',
        name: 'Date of birth',
        position: 3,
        width: ErbFieldWidth.HALF,
        erbScalarField: {
          id: '58493f99-1ae0-4ddc-bd07-9df62288e395',
          name: 'Date of birth',
          type: {
            id: '61ed3af1-11bf-4ba8-9e47-acb77839f108',
            name: 'DateTime',
            options: undefined,
            cardinality: ErbFieldTypeCardinality.ONE
          } as ErbFieldType,
          options: {
            kind: {
              value: {
                placeholder: 'Placeholder'
              } as TextErbFieldOptions,
              case: 'text'
            }
          } as ErbFieldOptions,
          isDefaultInSection: false,
          isWellKnown: false,
          isCalculated: false
        }
      },
      case: 'scalarField'
    }
  }
  // {
  //   kind: {
  //     value: {
  //       id: '761dd449-19cb-47e3-9970-082584733eb3',
  //       name: 'Pronouns',
  //       position: 3,
  //       width: ErbFieldWidth.HALF,
  //       erbScalarField: {
  //         id: '93263f21-43d0-4d74-8af3-6a1b01ec4088',
  //         name: 'Pronouns',
  //         type: {
  //           id: 'fc9675f5-a50c-4920-b70a-315d7392129d',
  //           name: 'List',
  //           options: {
  //             kind: {
  //               value: {
  //                 options: [{ value01: 'He/Him/His' }, { value02: 'She/Her/Hers' }, { value03: 'They/Their/Theirs' }]
  //               }
  //             }
  //           },
  //           cardinality: ErbFieldTypeCardinality.ONE
  //         } as ErbFieldType,
  //         options: {
  //           kind: {
  //             value: {
  //               placeholder: 'Placeholder'
  //             } as TextInputErbFieldOptions,
  //             case: 'textInput'
  //           }
  //         } as ErbFieldOptions,
  //         isDefaultInSection: false,
  //         isWellKnown: false,
  //         isCalculated: false
  //       }
  //     },
  //     case: 'scalarField'
  //   }
  // }
] as ErTemplateField[];

export const EmployeeRecordBuilderDrawerContent = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['common', 'employee_profile']);
  const [activeTab, setActiveTab] = useState(0);
  const erTemplateFieldIds = useAppSelector(selectErTemplateFieldIds);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const handleFieldChipClick = (field: ErTemplateField) => {
    console.log('chip clicked', field);
    dispatch(addFieldToSection(field));
  };
  const handleSelect = (selectedOption: { id: string; name: string }) => {
    console.log(selectedOption);
  };
  return (
    <Stack
      spacing={2}
      sx={{
        backgroundColor: 'transparent',
        height: '100%',
        borderRadius: (theme) => theme.spacing(2)
      }}
    >
      <RowCenterStack
        sx={{
          justifyContent: 'space-between'
        }}
      >
        <FieldsIcon />
      </RowCenterStack>
      <Stack gap={2}>
        <Typography variant="h5" fontWeight={700}>
          Fields
        </Typography>
        <PrimaryTypographyBodyBold>
          Irure nostrud laboris dolore ipsum fugiat non dolor non aliquip enim esse exercitation magna. Excepteur officia id nisi ea pariatur dolor aliqua
          fugiat exercitation do do sunt laboris deserunt.
        </PrimaryTypographyBodyBold>
        <StyledEmplyoeeRecordCategoryPicker gap={1}>
          <Typography variant="h4">Categories</Typography>
          <Typography>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Quisque volutpat mattis eros.</Typography>
          <RoundedAutocomplete options={tempEmployeeRecordPageCategories} name={t('category', { ns: 'employee_profile' })} onOptionSelect={handleSelect} />
          <EmployeeRecordSortableList renderItem={(item) => <EmployeeRecordDraggableField field={item} />} />
        </StyledEmplyoeeRecordCategoryPicker>
        <RoundedAutocomplete options={tempEmployeeRecordPageCategories} name={t('section', { ns: 'employee_profile' })} onOptionSelect={handleSelect} />
        <Box
          sx={{
            width: '100%'
          }}
        >
          <Tabs
            value={activeTab}
            onChange={handleChange}
            aria-label="Employee Panel"
            sx={{
              minHeight: 'auto',
              '& .MuiTabs-flexContainer': {
                direction: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start'
              },
              '& .MuiTab-root': {
                fontWeight: 700
              }
            }}
          >
            <StyledEmployeeRecordTab
              label="All"
              {...a11yProps('employee_record_all_fields', 0)}
              sx={{
                backgroundColor: (theme) => (activeTab === 0 ? alpha(theme.palette.customTheme.focusItem, 0.4) : 'transparent')
              }}
            />
            <StyledEmployeeRecordTab
              label="Unassigned"
              {...a11yProps('employee_record_unassigned_fields', 1)}
              sx={{
                backgroundColor: (theme) => (activeTab === 1 ? alpha(theme.palette.customTheme.focusItem, 0.4) : 'transparent')
              }}
            />
            <StyledEmployeeRecordTab
              label="Assigned"
              {...a11yProps('employee_record_assigned_fields', 2)}
              sx={{
                backgroundColor: (theme) => (activeTab === 2 ? alpha(theme.palette.customTheme.focusItem, 0.4) : 'transparent')
              }}
            />
          </Tabs>
          <CustomTabPanel value={activeTab} index={0}>
            <RowCenterStack
              gap={1}
              sx={{
                padding: '16px 0',
                flexWrap: 'wrap'
              }}
            >
              {tempEmployeeFields.map((field) => (
                <EmployeeRecordBuilderDrawerField
                  selected={erTemplateFieldIds.includes(field.kind.value!.id)}
                  field={field}
                  onClick={() => handleFieldChipClick(field)}
                />
              ))}
            </RowCenterStack>
          </CustomTabPanel>
        </Box>
      </Stack>
    </Stack>
  );
};
