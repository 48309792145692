import { Stack, Avatar, Typography } from '@mui/material';
import { EmployeeDrawerListItem, EmployeeListItem, RetrieveEmployeeBasicInfoRequest, SiteDrawerItem } from '@thrivea/organization-client';
import { t } from 'i18next';
import { pictureUrl } from 'src/utils';
import { PictureSizeSuffix } from '../shared.model';
import { StyledProfileLink } from '@/shared';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { retrieveEmployeeBasicInfoRequested } from '@features/admin-settings';
import { selectEmployeeProfileAndCoverReadSasToken } from '@features/employee-profile';

interface EmployeeListProps {
  employees: EmployeeDrawerListItem[];
}

export const EmployeeList: React.FC<EmployeeListProps> = ({ employees }) => {
  const dispatch = useAppDispatch();
  const employeeProfileSasToken = useAppSelector(selectEmployeeProfileAndCoverReadSasToken);

  const handleRetrieveSingleEmployeeInfo = (employeeId: string) => {
    dispatch(
      retrieveEmployeeBasicInfoRequested(
        new RetrieveEmployeeBasicInfoRequest({
          employeeId
        })
      )
    );
  };
  return (
    <Stack>
      {employees.map((employee) => (
        <Stack
          key={crypto.randomUUID()}
          direction="row"
          spacing={2}
          sx={{
            borderRadius: '8px',
            padding: '8px',
            transition: 'background-color 0.3s ease-in-out',
            '&:hover': {
              backgroundColor: (theme) => theme.palette.common.white,
              '& .Mui-ProfileLink': {
                opacity: 1
              }
            }
          }}
        >
          <Avatar
            src={pictureUrl(employee.profilePicture, employeeProfileSasToken, PictureSizeSuffix.sm)}
            alt={employee.name}
            variant="rounded"
            sx={{
              border: (theme) => `1px solid ${theme.palette.customTheme.contColor}`
            }}
          />
          <Stack
            direction="row"
            sx={{
              width: '100%',
              justifyContent: 'space-between'
            }}
          >
            <Stack>
              <Typography fontWeight={700}>{employee.name}</Typography>
              <Typography>{employee.jobTitle}</Typography>
            </Stack>
            <StyledProfileLink onClick={() => handleRetrieveSingleEmployeeInfo(employee.id)} className="Mui-ProfileLink" variant="text">
              {t('view_profile', { ns: 'drawer' })}
            </StyledProfileLink>
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};
