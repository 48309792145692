import React from 'react';
import { PreviewModalDocument, PreviewModalImage, PreviewModalVideo } from '@/shared';
import { isDocumentMimeType, isImage, isVideo } from '@/utils';
import { useAppSelector } from '@app/hooks';
import { deletePostSingleFileRequested, selectPostFileUploadProgress, selectPostReadSasToken } from '@features/homepage';
import { FileWithStatus } from '@api/blob-storage.api';

interface PreviewFileModalProps {
  fileWithStatus: FileWithStatus;
}

export const PreviewPostFileModal: React.FC<PreviewFileModalProps> = ({ fileWithStatus }) => {
  const uploadProgress = useAppSelector(selectPostFileUploadProgress);
  const postReadSasToken = useAppSelector(selectPostReadSasToken);

  if (isVideo(fileWithStatus.file.type))
    return (
      <PreviewModalVideo
        fileWithStatus={fileWithStatus}
        fileUploadProgress={uploadProgress}
        deleteFileAction={deletePostSingleFileRequested}
        sasToken={postReadSasToken}
      />
    );

  if (isImage(fileWithStatus.file.type))
    return (
      <PreviewModalImage
        fileWithStatus={fileWithStatus}
        fileUploadProgress={uploadProgress}
        deleteFileAction={deletePostSingleFileRequested}
        sasToken={postReadSasToken}
      />
    );

  if (isDocumentMimeType(fileWithStatus.file.type))
    return <PreviewModalDocument fileWithStatus={fileWithStatus} fileUploadProgress={uploadProgress} deleteFileAction={deletePostSingleFileRequested} />;
};
