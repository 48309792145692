import { Empty } from '@bufbuild/protobuf';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import {
  retrieveInitialAppStateAndUserFailed,
  retrieveInitialAppStateAndUserRequested,
  retrieveInitialAppStateAndUserSucceeded
} from '@features/protected-routes';
import { retrieveAdminOnboardingFlowState, retrieveUser } from '@api/organization.api';
import { RetrieveAdminOnboardingFlowStateResponse, RetrieveUserResponse } from '@thrivea/organization-client';
import { retrieveUserSucceeded } from '@app/user';
import { setCurrentAuthor } from '@features/homepage';
import * as Sentry from '@sentry/react';
import { retrieveGroupsRequested, retrieveAudiencesByEmployeeIdRequested, setAbacUserId } from '@features/abac';
import { RetrieveAudiencesByEmployeeIdRequest } from '@thrivea/networking-client';
import { RetrieveGroupsRequest } from '@thrivea/auth-client';
import { hasUnreadNotificationsRequested, retrieveLastNotificationDateTimeRequested, retrieveNotificationsRequested } from '@features/notifications';
import { CheckNewNotificationRequest, HasUnreadNotificationsRequest, RetrieveNotificationsRequest } from '@thrivea/notification-client';
import { Code, ConnectError } from '@connectrpc/connect';
import { redirectToErrorPage } from '@app/error';

interface InitialAppStateAndUser {
  adminOnboardingFlowState?: RetrieveAdminOnboardingFlowStateResponse;
  user?: RetrieveUserResponse;
}

function* retrieveInitialAppStateAndUserGenerator() {
  let initialData: InitialAppStateAndUser = { user: undefined, adminOnboardingFlowState: undefined };

  try {
    initialData = yield all({
      adminOnboardingFlowState: call(retrieveAdminOnboardingFlowState, new Empty()),
      user: call(retrieveUser, new Empty())
    });
  } catch (error) {
    Sentry.captureException(error);
    if (error instanceof ConnectError && error.code !== Code.InvalidArgument) {
      yield put(redirectToErrorPage(error.code));
      return;
    }

    yield put(retrieveInitialAppStateAndUserFailed());
  }

  yield put(retrieveInitialAppStateAndUserSucceeded(initialData.adminOnboardingFlowState!));
  yield put(retrieveUserSucceeded(initialData.user!));
  yield put(setCurrentAuthor(initialData.user!.id));
  yield put(setAbacUserId(initialData.user!.id));
  yield put(retrieveAudiencesByEmployeeIdRequested(new RetrieveAudiencesByEmployeeIdRequest({ employeeId: initialData.user!.id })));
  yield put(retrieveGroupsRequested(new RetrieveGroupsRequest({ employeeId: initialData.user!.id })));
  yield put(retrieveLastNotificationDateTimeRequested(new CheckNewNotificationRequest({ employeeId: initialData.user!.id })));
  yield put(retrieveNotificationsRequested(new RetrieveNotificationsRequest({ employeeId: initialData.user!.id, pageNumber: 1, pageSize: 10 })));
  yield put(hasUnreadNotificationsRequested(new HasUnreadNotificationsRequest({ employeeId: initialData.user!.id })));
}

function* retrieveInitialAppStateAndUserRequestedWatcher() {
  yield takeLatest(retrieveInitialAppStateAndUserRequested.type, retrieveInitialAppStateAndUserGenerator);
}

export function* protectedRoutesSagas() {
  yield fork(retrieveInitialAppStateAndUserRequestedWatcher);
}
