import React, { useId, useMemo, useState } from 'react';
import {
  Autocomplete,
  Box,
  IconButton,
  ListItem,
  Modal,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import {
  AddIcon,
  AlertIcon,
  ChevronDownIcon,
  AddNewRow,
  CancelButton,
  UpdateButton,
  RowCenterStack,
  StyledModalContent,
  StyledTransparentInput,
  StyledTransparentInputDatePicker,
  CloseIcon
} from '@/shared';
import { useTranslation } from 'react-i18next';
import { AddressEvent } from '@thrivea/organization-client';
import { Controller, useForm } from 'react-hook-form';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { addNewAddressEvent, createAddressEventSchema, selectAddressCategoryTableData } from '@features/employee-profile';
import { zodResolver } from '@hookform/resolvers/zod';
import { i18nCountries } from '@utils/getCountries';
import { snakeCase } from 'lodash';
import { AllowedTo } from 'react-abac';
import { GroupPermissions, ProfileCategoryInfo } from '@features/abac';

interface AddressCategoryTableProps {
  handleSetEditable: () => void;
  employeeId: string;
}

export const AddressCategoryTable: React.FC<AddressCategoryTableProps> = ({ handleSetEditable, employeeId }) => {
  const id = useId();
  const { t } = useTranslation(['common', 'settings_working_patterns', 'employee_profile']);
  const dispatch = useAppDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const addressCategoryTableData = useAppSelector(selectAddressCategoryTableData);
  const addressEventsSchema = useMemo(() => createAddressEventSchema(t), [t]);
  const { formState, control, reset, handleSubmit, setValue } = useForm<AddressEvent>({
    mode: 'all',
    resolver: zodResolver(addressEventsSchema),
    defaultValues: {
      effectiveDate: '',
      location: {
        addressLine1: '',
        addressLine2: '',
        city: '',
        postalCode: '',
        country: '',
        state: '',
        province: '',
        region: ''
      },
      reason: ''
    }
  });

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // const handleRowEdit = (event: React.MouseEvent<HTMLElement>, id: string) => {
  //   event.stopPropagation();
  //   setActiveEditWorkingPatternModal(id);
  // };

  const handleModalOpen = () => {
    handleSetEditable();
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const onSubmit = (data: AddressEvent) => {
    dispatch(addNewAddressEvent(data));
    reset();
    handleModalClose();
  };

  return (
    <Stack
      gap={2}
      sx={{
        height: '100%',
        justifyContent: 'stretch',
        padding: 0
      }}
    >
      <Paper
        elevation={0}
        sx={{
          width: '100%',
          overflow: 'hidden',
          '&.MuiPaper-root': {
            backgroundColor: (theme) => (addressCategoryTableData.length === 0 ? theme.palette.customTheme.drawerBackground : 'transparent')
          }
        }}
      >
        <Stack
          gap={2}
          sx={{
            flex: 1,
            justifyContent: 'stretch'
          }}
        >
          <TableContainer sx={{ maxHeight: 440 }} tabIndex={0}>
            {addressCategoryTableData.length === 0 && (
              <Stack
                sx={{
                  width: '100%',
                  height: 250,
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '16px',
                  '& svg': {
                    width: 24,
                    height: 24
                  }
                }}
              >
                <AlertIcon />
                <Typography>{t('empty_table', { ns: 'employee_profile' })}</Typography>
                <AllowedTo perform={GroupPermissions.EDIT_PROFILE} data={{ employeeId, categoryName: 'address' } as ProfileCategoryInfo}>
                  <AddNewRow variant="outlined" endIcon={<AddIcon />} onClick={handleModalOpen}>
                    {t('add_new_row', { ns: 'common' })}
                  </AddNewRow>
                </AllowedTo>
              </Stack>
            )}
            {addressCategoryTableData.length !== 0 && (
              <>
                <Table stickyHeader>
                  <TableHead
                    sx={{
                      position: 'relative'
                    }}
                  >
                    <TableRow
                      sx={{
                        '& > .MuiTableCell-root': {
                          border: 0
                        }
                      }}
                    >
                      <TableCell
                        sx={{
                          minWidth: 170
                        }}
                      >
                        {t('effective_date', { ns: 'employee_profile' })}
                      </TableCell>
                      <TableCell
                        sx={{
                          minWidth: 170
                        }}
                      >
                        {t('address_one', { ns: 'employee_profile' })}
                      </TableCell>
                      <TableCell
                        sx={{
                          minWidth: 170
                        }}
                      >
                        {t('address_two', { ns: 'employee_profile' })}
                      </TableCell>
                      <TableCell
                        sx={{
                          minWidth: 170
                        }}
                      >
                        {t('city', { ns: 'employee_profile' })}
                      </TableCell>
                      <TableCell
                        sx={{
                          minWidth: 170
                        }}
                      >
                        {t('postal', { ns: 'employee_profile' })}
                      </TableCell>
                      <TableCell
                        sx={{
                          minWidth: 170
                        }}
                      >
                        {t('country', { ns: 'employee_profile' })}
                      </TableCell>
                      <TableCell
                        sx={{
                          minWidth: 170
                        }}
                      >
                        {t('state', { ns: 'employee_profile' })}
                      </TableCell>
                      <TableCell
                        sx={{
                          minWidth: 170
                        }}
                      >
                        {t('reason', { ns: 'employee_profile' })}
                      </TableCell>
                      {/* <TableCell
                        sx={{
                          position: 'sticky',
                          right: 0,
                          backgroundColor: (theme) => theme.palette.common.white,
                          minWidth: '90px',
                          height: '100%',
                          boxShadow: '-5px 0px 3px 0px rgba(0,0,0,0.12)'
                        }}
                      /> */}
                    </TableRow>
                  </TableHead>
                  <TableBody
                    sx={{
                      position: 'relative'
                    }}
                  >
                    {addressCategoryTableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, rowIndex) => (
                      <TableRow hover key={rowIndex}>
                        <TableCell>{row.effectiveDate}</TableCell>
                        <TableCell>{row.location!.addressLine1}</TableCell>
                        <TableCell>{row.location!.addressLine2}</TableCell>
                        <TableCell>{row.location!.city}</TableCell>
                        <TableCell>{row.location!.postalCode}</TableCell>
                        <TableCell>{i18nCountries.find((i18nc) => i18nc.id === row.location!.country)!.label}</TableCell>
                        <TableCell>{row.location!.state}</TableCell>
                        <TableCell>{row.reason}</TableCell>
                        {/* TODO - Implement after adding edit/delete actions */}
                        {/* <TableCell
                          sx={{
                            position: 'sticky',
                            right: 0,
                            backgroundColor: (theme) => theme.palette.common.white,
                            minWidth: '90px',
                            height: '100%',
                            boxShadow: '-5px 0px 3px 0px rgba(0,0,0,0.12)',
                            textAlign: 'center'
                          }}
                        >
                          <IsolatedMenu id={crypto.randomUUID()} handleRowEdit={(e) => handleRowEdit(e, crypto.randomUUID())} />
                        </TableCell> */}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 20]}
                  component="div"
                  count={addressCategoryTableData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
                <AllowedTo perform={GroupPermissions.EDIT_PROFILE} data={{ employeeId, categoryName: 'address' } as ProfileCategoryInfo}>
                  <AddNewRow
                    variant="outlined"
                    endIcon={<AddIcon />}
                    onClick={handleModalOpen}
                    sx={{
                      ml: 2,
                      mb: 2
                    }}
                  >
                    {t('add_new_row', { ns: 'common' })}
                  </AddNewRow>
                </AllowedTo>
              </>
            )}
          </TableContainer>
        </Stack>
        <Modal
          open={isModalOpen}
          onClose={handleModalClose}
          sx={{
            background: 'rgba(217, 217, 217, 0.60)',
            backdropFilter: 'blur(10px)'
          }}
        >
          <StyledModalContent
            sx={{
              position: 'relative',
              justifyContent: 'center',
              alignItems: 'center',
              minWidth: {
                xs: 'auto',
                lg: '1316px'
              },
              height: {
                xs: '80%',
                lg: 'auto'
              },
              padding: '120px',
              display: 'flex',
              borderRadius: '20px',
              boxShadow: '0px 5px 12px 0px rgba(0, 0, 0, 0.10)'
            }}
          >
            <Typography
              variant="h3"
              sx={{
                mb: 3
              }}
            >
              {t('add_new_row', { ns: 'common' })}
            </Typography>
            <Grid
              container
              columnSpacing={2}
              rowGap={2}
              sx={{
                mt: 2,
                mb: 4
              }}
            >
              <Box
                component="form"
                onSubmit={handleSubmit(onSubmit)}
                sx={{
                  width: '100%'
                }}
              >
                <Grid
                  container
                  spacing={2}
                  sx={{
                    padding: 2
                  }}
                >
                  <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
                    <Controller
                      name="effectiveDate"
                      control={control}
                      render={({ field, fieldState }) => (
                        <LocalizationProvider dateAdapter={AdapterLuxon}>
                          <DatePicker
                            {...field}
                            className="MuiDate-root"
                            format="yyyy-MM-dd"
                            value={field.value ? DateTime.fromISO(field.value) : null}
                            disableFuture
                            slots={{ textField: StyledTransparentInputDatePicker }}
                            slotProps={{
                              textField: {
                                id: id + field.name,
                                placeholder: 'YYYY-MM-DD',
                                variant: 'filled',
                                label: t(snakeCase(field.name), { ns: 'employee_profile' }),
                                error: !!fieldState.error,
                                helperText: fieldState.error?.message,
                                required: true,
                                fullWidth: true,
                                InputProps: {
                                  disableUnderline: true,
                                  required: true,
                                  onBlur: () => {
                                    field.onBlur();
                                  }
                                },
                                InputLabelProps: {
                                  shrink: true
                                }
                              }
                            }}
                            onChange={(date: DateTime | null) => {
                              if (date) {
                                const jsDate = date.toJSDate();
                                const stringDate = DateTime.fromJSDate(jsDate).toFormat('yyyy-MM-dd');
                                field.onChange(stringDate);
                              }
                            }}
                          />
                        </LocalizationProvider>
                      )}
                    />
                  </Grid>
                  <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
                    <Controller
                      name="location.addressLine1"
                      control={control}
                      render={({ field, fieldState }) => (
                        <StyledTransparentInput
                          {...field}
                          required
                          id={id + field.name}
                          label={t(snakeCase(field.name), { ns: 'employee_profile' })}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          onChange={(e) => field.onChange(e)}
                        />
                      )}
                    />
                  </Grid>
                  <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
                    <Controller
                      name="location.addressLine2"
                      control={control}
                      render={({ field, fieldState }) => (
                        <StyledTransparentInput
                          {...field}
                          id={id + field.name}
                          label={t(snakeCase(field.name), { ns: 'employee_profile' })}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          onChange={(e) => field.onChange(e)}
                        />
                      )}
                    />
                  </Grid>
                  <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
                    <Controller
                      name="location.city"
                      control={control}
                      render={({ field, fieldState }) => (
                        <StyledTransparentInput
                          {...field}
                          required
                          id={id + field.name}
                          label={t(snakeCase(field.name), { ns: 'employee_profile' })}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          onChange={(e) => field.onChange(e)}
                        />
                      )}
                    />
                  </Grid>
                  <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
                    <Controller
                      name="location.postalCode"
                      control={control}
                      render={({ field, fieldState }) => (
                        <StyledTransparentInput
                          {...field}
                          required
                          id={id + field.name}
                          label={t(snakeCase(field.name), { ns: 'employee_profile' })}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          onChange={(e) => field.onChange(e)}
                        />
                      )}
                    />
                  </Grid>
                  <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
                    <Controller
                      name="location.country"
                      control={control}
                      render={({ field, fieldState }) => (
                        <Autocomplete
                          popupIcon={<ChevronDownIcon />}
                          disableClearable
                          options={i18nCountries}
                          getOptionLabel={(option) => option.label}
                          onChange={(event, value, reason) => {
                            // check on blur if the value is empty
                            if (reason === 'blur' && !value) {
                              setValue('location.country', '');
                            } else {
                              field.onChange(value.id);
                            }
                          }}
                          renderInput={(params) => (
                            <StyledTransparentInput
                              {...params}
                              id={id + field.name}
                              label={t(snakeCase(field.name), { ns: 'employee_profile' })}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                              required
                              onBlur={() => field.onBlur()}
                            />
                          )}
                          renderOption={(props, option) => (
                            <ListItem {...props} key={crypto.randomUUID()}>
                              {option.label}
                            </ListItem>
                          )}
                        />
                      )}
                    />
                  </Grid>
                  <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
                    <Controller
                      name="location.state"
                      control={control}
                      render={({ field, fieldState }) => (
                        <StyledTransparentInput
                          {...field}
                          id={id + field.name}
                          label={t(snakeCase(field.name), { ns: 'employee_profile' })}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          onChange={(e) => field.onChange(e)}
                        />
                      )}
                    />
                  </Grid>
                  <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
                    <Controller
                      name="location.province"
                      control={control}
                      render={({ field, fieldState }) => (
                        <StyledTransparentInput
                          {...field}
                          id={id + field.name}
                          label={t(snakeCase(field.name), { ns: 'employee_profile' })}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          onChange={(e) => field.onChange(e)}
                        />
                      )}
                    />
                  </Grid>
                  <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
                    <Controller
                      name="location.region"
                      control={control}
                      render={({ field, fieldState }) => (
                        <StyledTransparentInput
                          {...field}
                          id={id + field.name}
                          label={t(snakeCase(field.name), { ns: 'employee_profile' })}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          onChange={(e) => field.onChange(e)}
                        />
                      )}
                    />
                  </Grid>
                  <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
                    <Controller
                      name="reason"
                      control={control}
                      render={({ field, fieldState }) => (
                        <StyledTransparentInput
                          {...field}
                          id={id + field.name}
                          label={t(snakeCase(field.name), { ns: 'employee_profile' })}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          onChange={(e) => field.onChange(e)}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <RowCenterStack
                  gap={2}
                  sx={{
                    justifyContent: 'center'
                  }}
                >
                  <CancelButton variant="outlined" onClick={handleModalClose}>
                    Cancel
                  </CancelButton>
                  <UpdateButton type="submit" variant="contained" disabled={!formState.isValid}>
                    Update
                  </UpdateButton>
                </RowCenterStack>
              </Box>
            </Grid>
            <IconButton
              sx={{
                position: 'absolute',
                top: 10,
                right: 10,
                zIndex: 1
              }}
              onClick={handleModalClose}
            >
              <CloseIcon />
            </IconButton>
          </StyledModalContent>
        </Modal>
      </Paper>
    </Stack>
  );
};
