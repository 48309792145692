import { Autocomplete, Box, Stack, Typography } from '@mui/material';
import {
  ActionsIcon,
  ChevronDownIcon,
  PrimaryTypographyBodyBold,
  RowCenterStack,
  StyledEmployeeRecordAutocompleteInput,
  StyledEmplyoeeRecordCategoryListItem,
  StyledPeopleDirectoryDrawerChip
} from '@/shared';
import Grid from '@mui/material/Grid2';
import { t } from 'i18next';

const tempEmployeeRecordPageCategories = [
  {
    id: '3c945333-ad81-43e4-9dc9-bc1e54585488',
    name: 'Personal information'
  },
  {
    id: '695a39fb-55e5-4c83-be7e-a2799665df22',
    name: 'Work information'
  },
  {
    id: 'd702f344-34e2-4764-acb1-e40430c9fa14',
    name: 'Organizational information'
  },
  {
    id: 'b99f71c4-d28c-4cc2-81be-2ca225e9bbfc',
    name: 'Financial information'
  },
  {
    id: '229ddca9-1b41-4f4f-baa6-aca1354340f5',
    name: 'Legal & Compliance'
  },
  {
    id: '48fcce5d-e592-470f-8c94-f9df743ff890',
    name: 'Custom'
  }
];

export const PeopleDirectoryDrawerContent = () => {
  return (
    <Stack
      spacing={2}
      sx={{
        backgroundColor: (theme) => theme.palette.customTheme.drawerBackground,
        borderRadius: (theme) => theme.spacing(2)
      }}
    >
      <Stack spacing={2}>
        <ActionsIcon width={72} height={42} color="#000000" />
        <Typography variant="h4">Actions</Typography>
        <PrimaryTypographyBodyBold>
          Reprehenderit est ad qui velit voluptate ipsum id dolor sint commodo. Nulla aliquip labore nostrud reprehenderit culpa do ea cillum aliqua et anim.
        </PrimaryTypographyBodyBold>
      </Stack>
      <Stack
        spacing={'30px'}
        sx={{
          backgroundColor: (theme) => theme.palette.grey[600],
          padding: '24px 16px',
          borderRadius: 4
        }}
      >
        <Stack spacing={1}>
          <Typography variant="h3">Manage Documents</Typography>
          <Typography variant="body1">
            Duis nisi occaecat commodo cupidatat sunt ad laborum incididunt est laboris ea sit. Labore ad consectetur sit enim. Nulla laboris elit minim
            excepteur. Cupidatat aute laborum incididunt nisi laboris.
          </Typography>
        </Stack>
        <Stack spacing={1}>
          <RowCenterStack gap={1}>
            <StyledPeopleDirectoryDrawerChip label="Request Read Approval" />
            <Typography variant="body1">Lorem ipsum dolor sit amet.</Typography>
          </RowCenterStack>
          <RowCenterStack gap={1}>
            <StyledPeopleDirectoryDrawerChip label="Personal info" />
            <Typography variant="body1">Lorem ipsum dolor sit amet.</Typography>
          </RowCenterStack>
        </Stack>
      </Stack>
      <Stack
        spacing={'30px'}
        sx={{
          backgroundColor: (theme) => theme.palette.grey[600],
          padding: '24px 16px',
          borderRadius: 4
        }}
      >
        <Stack spacing={1}>
          <Typography variant="h3">Trigger Task List</Typography>
          <Typography variant="body1">
            Duis nisi occaecat commodo cupidatat sunt ad laborum incididunt est laboris ea sit. Labore ad consectetur sit enim. Nulla laboris elit.
          </Typography>
        </Stack>
        <Stack spacing={1}>
          <Box
            sx={{
              position: 'relative',
              minWidth: 226
            }}
          >
            <Autocomplete
              popupIcon={<ChevronDownIcon />}
              disableClearable
              disablePortal
              options={tempEmployeeRecordPageCategories}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <StyledEmployeeRecordAutocompleteInput {...params} id="employee_record_category_input" label={t('category', { ns: 'employee_profile' })} />
              )}
              renderOption={(props, option) => (
                <StyledEmplyoeeRecordCategoryListItem {...props} key={option.id}>
                  {option.name}
                </StyledEmplyoeeRecordCategoryListItem>
              )}
              slotProps={{
                paper: {
                  elevation: 0,
                  sx: {
                    borderBottomLeftRadius: 'inherit',
                    borderBottomRightRadius: 'inherit'
                  }
                },
                popper: {
                  placement: 'bottom-end',
                  sx: {
                    border: '1px solid',
                    borderColor: (theme) => theme.palette.customTheme.contColor,
                    width: '100% !important'
                  }
                }
              }}
            />
          </Box>
        </Stack>
      </Stack>
      <Stack
        spacing={'30px'}
        sx={{
          backgroundColor: (theme) => theme.palette.grey[600],
          padding: '24px 16px',
          borderRadius: 4
        }}
      >
        <Stack spacing={1}>
          <Typography variant="h3">Personal Contact Details</Typography>
          <Typography variant="body1">
            Duis nisi occaecat commodo cupidatat sunt ad laborum incididunt est laboris ea sit. Labore ad consectetur sit enim. Nulla laboris elit minim.
          </Typography>
        </Stack>
        <Stack spacing={1}>
          <RowCenterStack gap={1}>
            <StyledPeopleDirectoryDrawerChip label="Identification" />
            <Typography variant="body1">Lorem ipsum dolor sit amet.</Typography>
          </RowCenterStack>
          <RowCenterStack gap={1}>
            <StyledPeopleDirectoryDrawerChip label="Work" />
            <Typography variant="body1">Lorem ipsum dolor sit amet.</Typography>
          </RowCenterStack>
          <RowCenterStack gap={1}>
            <StyledPeopleDirectoryDrawerChip label="Work Contact Details" />
            <Typography variant="body1">Lorem ipsum dolor sit amet.</Typography>
          </RowCenterStack>
          <RowCenterStack gap={1}>
            <StyledPeopleDirectoryDrawerChip label="Address" />
            <Typography variant="body1">Lorem ipsum dolor sit amet.</Typography>
          </RowCenterStack>
          <RowCenterStack gap={1}>
            <StyledPeopleDirectoryDrawerChip label="Home" />
            <Typography variant="body1">Lorem ipsum dolor sit amet.</Typography>
          </RowCenterStack>
          <RowCenterStack gap={1}>
            <StyledPeopleDirectoryDrawerChip label="About" />
            <Typography variant="body1">Lorem ipsum dolor sit amet.</Typography>
          </RowCenterStack>
        </Stack>
      </Stack>
      <Stack
        spacing={'30px'}
        sx={{
          backgroundColor: (theme) => theme.palette.grey[600],
          padding: '24px 16px',
          borderRadius: 4
        }}
      >
        <Stack spacing={1}>
          <Typography variant="h3">Financial </Typography>
          <Typography variant="body1">
            Duis nisi occaecat commodo cupidatat sunt ad laborum incididunt est laboris ea sit. Labore ad consectetur sit enim. Nulla laboris elit minim.
          </Typography>
        </Stack>
        <Stack spacing={1}>
          <RowCenterStack gap={1}>
            <StyledPeopleDirectoryDrawerChip label="Work Eligibility" />
            <Typography variant="body1">Lorem ipsum dolor sit amet.</Typography>
          </RowCenterStack>
          <RowCenterStack gap={1}>
            <StyledPeopleDirectoryDrawerChip label="Employment" />
            <Typography variant="body1">Lorem ipsum dolor sit amet.</Typography>
          </RowCenterStack>
          <RowCenterStack gap={1}>
            <StyledPeopleDirectoryDrawerChip label="Payroll" />
            <Typography variant="body1">Lorem ipsum dolor sit amet.</Typography>
          </RowCenterStack>
        </Stack>
      </Stack>
      <Stack
        spacing={'30px'}
        sx={{
          backgroundColor: (theme) => theme.palette.grey[600],
          padding: '24px 16px',
          borderRadius: 4
        }}
      >
        <Stack spacing={1}>
          <Typography variant="h3">Equity</Typography>
          <Typography variant="body1">
            Duis nisi occaecat commodo cupidatat sunt ad laborum incididunt est laboris ea sit. Labore ad consectetur sit enim. Nulla laboris elit minim.
          </Typography>
        </Stack>
        <Stack spacing={1}>
          <RowCenterStack gap={1}>
            <StyledPeopleDirectoryDrawerChip label="Emergency" />
            <Typography variant="body1">Lorem ipsum dolor sit amet.</Typography>
          </RowCenterStack>
          <RowCenterStack gap={1}>
            <StyledPeopleDirectoryDrawerChip label="Training" />
            <Typography variant="body1">Lorem ipsum dolor sit amet.</Typography>
          </RowCenterStack>
          <RowCenterStack gap={1}>
            <StyledPeopleDirectoryDrawerChip label="User Data" />
            <Typography variant="body1">Lorem ipsum dolor sit amet.</Typography>
          </RowCenterStack>
          <RowCenterStack gap={1}>
            <StyledPeopleDirectoryDrawerChip label="Jobs" />
            <Typography variant="body1">Lorem ipsum dolor sit amet.</Typography>
          </RowCenterStack>
        </Stack>
      </Stack>
      <Stack
        spacing={'30px'}
        sx={{
          backgroundColor: (theme) => theme.palette.grey[600],
          padding: '24px 16px',
          borderRadius: 4
        }}
      >
        <Stack spacing={1}>
          <Typography variant="h3">Update Profile</Typography>
          <Typography variant="body1">
            Duis nisi occaecat commodo cupidatat sunt ad laborum incididunt est laboris ea sit. Labore ad consectetur sit enim. Nulla laboris elit minim
            excepteur. Cupidatat aute laborum incididunt nisi laboris.
          </Typography>
        </Stack>
        <Grid container spacing={1}>
          <Grid size={8}>
            <StyledPeopleDirectoryDrawerChip label="Go to Employee Record Page" />
          </Grid>
          <Grid size={4}>
            <Typography variant="body1">Lorem ipsum dolor sit amet.</Typography>
          </Grid>
        </Grid>
      </Stack>
      <Stack
        spacing={'30px'}
        sx={{
          backgroundColor: (theme) => theme.palette.grey[600],
          padding: '24px 16px',
          borderRadius: 4
        }}
      >
        <Stack spacing={1}>
          <Typography variant="h3">View Audit Trail</Typography>
          <Typography variant="body1">
            Duis nisi occaecat commodo cupidatat sunt ad laborum incididunt est laboris ea sit. Labore ad consectetur sit enim. Nulla laboris elit.
          </Typography>
        </Stack>
        <Stack spacing={1}>
          <Box
            sx={{
              position: 'relative',
              minWidth: 226
            }}
          >
            <Autocomplete
              popupIcon={<ChevronDownIcon />}
              disableClearable
              disablePortal
              options={tempEmployeeRecordPageCategories}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <StyledEmployeeRecordAutocompleteInput {...params} id="employee_record_category_input" label={t('category', { ns: 'employee_profile' })} />
              )}
              renderOption={(props, option) => (
                <StyledEmplyoeeRecordCategoryListItem {...props} key={option.id}>
                  {option.name}
                </StyledEmplyoeeRecordCategoryListItem>
              )}
              slotProps={{
                paper: {
                  elevation: 0,
                  sx: {
                    borderBottomLeftRadius: 'inherit',
                    borderBottomRightRadius: 'inherit'
                  }
                },
                popper: {
                  placement: 'bottom-end',
                  sx: {
                    border: '1px solid',
                    borderColor: (theme) => theme.palette.customTheme.contColor,
                    width: '100% !important'
                  }
                }
              }}
            />
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
};
