import { useTheme } from '@mui/material';
import React from 'react';

interface SettingsIconAttributes {
  className?: string;
}

export const SettingsIcon: React.FC<SettingsIconAttributes> = ({ className = '' }: SettingsIconAttributes) => {
  const theme = useTheme();

  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 25 25">
      <path
        fill={theme.palette.common.black}
        d="m22.218 15.25-1.073-.885a9.112 9.112 0 0 0 0-4.155l1.073-.885a1.5 1.5 0 0 0 .345-1.905l-.863-1.5a1.5 1.5 0 0 0-1.815-.697l-1.313.487a8.775 8.775 0 0 0-3.614-2.085l-.226-1.372A1.5 1.5 0 0 0 13.232 1h-1.724a1.5 1.5 0 0 0-1.5 1.253l-.226 1.372A8.775 8.775 0 0 0 6.205 5.71l-1.32-.487a1.5 1.5 0 0 0-1.823.652l-.862 1.5a1.5 1.5 0 0 0 .345 1.905l1.072.885a9.113 9.113 0 0 0 0 4.155l-1.065.93a1.5 1.5 0 0 0-.345 1.905l.863 1.5a1.5 1.5 0 0 0 1.815.623l1.312-.488a8.774 8.774 0 0 0 3.608 2.085l.225 1.373a1.5 1.5 0 0 0 1.5 1.252h1.725a1.5 1.5 0 0 0 1.5-1.252l.225-1.373a8.774 8.774 0 0 0 3.607-2.085l1.313.488a1.5 1.5 0 0 0 1.823-.653l.862-1.5a1.5 1.5 0 0 0-.367-1.875Zm-1.816 2.625-1.762-.66a.75.75 0 0 0-.81.188 7.394 7.394 0 0 1-3.705 2.145.75.75 0 0 0-.57.607L13.248 22h-1.725l-.308-1.845a.75.75 0 0 0-.57-.607 7.394 7.394 0 0 1-3.705-2.145.75.75 0 0 0-.81-.188l-1.763.66-.862-1.5 1.44-1.185a.75.75 0 0 0 .247-.787c-.41-1.401-.41-2.89 0-4.29a.75.75 0 0 0-.247-.788l-1.44-1.2.862-1.5 1.763.66a.75.75 0 0 0 .81-.187 7.395 7.395 0 0 1 3.705-2.145.75.75 0 0 0 .57-.608l.308-1.845h1.725l.307 1.845a.75.75 0 0 0 .57.608 7.395 7.395 0 0 1 3.705 2.145.75.75 0 0 0 .81.187l1.762-.66.863 1.5-1.44 1.185a.75.75 0 0 0-.247.788c.41 1.4.41 2.889 0 4.29a.75.75 0 0 0 .247.787l1.44 1.193-.863 1.507ZM12.386 7.75a4.5 4.5 0 1 0 0 9 4.5 4.5 0 0 0 0-9Zm0 7.5a3 3 0 1 1 0-5.998 3 3 0 0 1 0 5.998Z"
      />
    </svg>
  );
};
