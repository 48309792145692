import React from 'react';
import { Typography, Stack, Avatar, Box, boxClasses, stackClasses } from '@mui/material';
import { DateTime } from 'luxon';
import { PostContent, PostDoc, PostMedia, selectEmployeeRecentPost, selectEmployeeRecentPostReactionsGroupedByEmoji } from '@features/homepage';
import { ReactionIcon, CommentIcon, RowCenterStack, PictureSizeSuffix } from '@/shared';
import { useAppSelector } from '@/app/hooks';
import { useTranslation } from 'react-i18next';
import { initials } from '@/utils';
import { selectEmployeeProfileAndCoverReadSasToken } from '@features/employee-profile';
import { pictureUrl } from '@utils/pictureUrl';

interface EmployeeDrawerRecentPostProps {
  postId: string;
  authorName: string;
  authorPicture: string;
  date: DateTime;
  images: PostMedia[];
  docs: PostDoc[];
  message: string;
}

export const EmployeeDrawerRecentPost: React.FC<EmployeeDrawerRecentPostProps> = ({ postId, authorName, authorPicture, date, images, docs, message }) => {
  const { t } = useTranslation(['homepage', 'common']);
  const employeeRecentPosts = useAppSelector((state) => selectEmployeeRecentPost(state, postId));
  const groupedPostReactionsDict = useAppSelector((state) => selectEmployeeRecentPostReactionsGroupedByEmoji(state, postId));
  const profileAndCoverPictureSasToken = useAppSelector(selectEmployeeProfileAndCoverReadSasToken);

  return (
    <Stack
      gap={2}
      sx={{
        backgroundColor: (theme) => theme.palette.grey[600],
        borderRadius: (theme) => theme.spacing(2),
        padding: '20px 16px'
      }}
    >
      <RowCenterStack gap={1}>
        <Avatar
          variant="rounded"
          src={pictureUrl(authorPicture, profileAndCoverPictureSasToken, PictureSizeSuffix.sm)}
          alt={authorName}
          sx={{
            width: 36,
            height: 36
          }}
        >
          {initials(authorName)}
        </Avatar>
        <Typography sx={{ fontWeight: 500 }}>{authorName}</Typography>
        <RowCenterStack gap={1}>
          <Box
            component="span"
            sx={{
              display: 'block',
              width: 3,
              height: 3,
              bgcolor: (theme) => theme.palette.common.black,
              borderRadius: '50%'
            }}
          />
          <Typography
            component="span"
            sx={{
              fontSize: '10px'
            }}
          >
            {date.toRelative()}
          </Typography>
        </RowCenterStack>
      </RowCenterStack>
      <Box
        sx={{
          [`& .${stackClasses.root}`]: {
            width: '100%',
            [`& .${boxClasses.root}`]: {
              minWidth: 'auto'
            }
          }
        }}
      >
        <PostContent message={message} medias={images} docs={docs} />
      </Box>
      <Stack
        direction="row"
        gap={1}
        sx={{
          alignItems: 'center'
        }}
      >
        <Stack
          direction="row"
          gap={1}
          sx={{
            alignItems: 'center'
          }}
        >
          <ReactionIcon size={20} />
          {groupedPostReactionsDict !== undefined && (
            <Typography
              sx={{
                fontSize: 14
              }}
            >
              {t('reaction_with_count', { count: Object.keys(groupedPostReactionsDict).length })}
            </Typography>
          )}
        </Stack>
        <Stack
          direction="row"
          gap={1}
          sx={{
            alignItems: 'center'
          }}
        >
          <CommentIcon size={20} />
          <Typography
            sx={{
              fontSize: 14
            }}
          >
            {t('comment_with_count', { count: employeeRecentPosts.commentIds.length })}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
