import React from 'react';

interface EventIconProps {
  width?: number;
  height?: number;
  color?: string;
}

export const EventIcon: React.FC<EventIconProps> = ({ width: width = 24, height = 19, color = '#251D38' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none">
      <path
        d="M16.6846 1.33019H13.5852V0.784392C13.5852 0.350794 13.2344 0 12.8008 0C12.3672 0 12.0164 0.350794 12.0164 0.784392V1.33019H7.98334L7.98443 0.784392C7.98443 0.350794 7.63363 0 7.20003 0C6.76644 0 6.41564 0.350794 6.41564 0.784392V1.33019H3.31517C1.76272 1.33019 0.5 2.59392 0.5 4.14647V16.1847C0.5 17.7371 1.76264 18.9999 3.31517 18.9999H16.6848C18.2373 18.9999 19.5 17.7372 19.5 16.1847V4.14647C19.5 2.59403 18.2371 1.33019 16.6846 1.33019ZM17.9309 16.1847C17.9309 16.871 17.3709 17.431 16.6846 17.431H3.31491C2.62858 17.431 2.06859 16.8711 2.06859 16.1847V9.2416H17.9307L17.9309 16.1847ZM17.9309 7.67198H2.06875V4.14647C2.06875 3.45905 2.62872 2.89906 3.31508 2.89906H6.41444V3.44594C6.41444 3.87954 6.76523 4.23033 7.19883 4.23033C7.63243 4.23033 7.98322 3.87954 7.98322 3.44594V2.90015H12.0163V3.44703C12.0163 3.88063 12.3671 4.23142 12.8007 4.23142C13.2343 4.23142 13.5851 3.88063 13.5851 3.44703L13.584 2.90015H16.6834C17.3697 2.90015 17.9297 3.46011 17.9297 4.14756V7.67196L17.9309 7.67198Z"
        fill={color}
      />
      <path
        d="M8.19627 14.0017L7.85635 15.0475C7.61232 15.7982 8.47079 16.4213 9.10921 15.9572L9.99927 15.3112L10.8893 15.9583C11.5278 16.4224 12.3862 15.7981 12.1422 15.0486L11.8023 14.0028L12.6923 13.3556C13.3308 12.8915 13.0028 11.8827 12.2141 11.8827H11.1138L10.7738 10.8369C10.5298 10.0863 9.4687 10.0863 9.22467 10.8369L8.88475 11.8827H7.78443C6.99568 11.8827 6.66776 12.8916 7.30618 13.3556L8.19627 14.0017Z"
        fill={color}
      />
    </svg>
  );
};
