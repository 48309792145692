import { useMemo, useState, type ReactNode } from 'react';
import { DndContext, useSensor, useSensors, PointerSensor, KeyboardSensor, Active } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { EmployeeRecordSortableOverlay } from './EmployeeRecordSortableOverlay';
import Grid from '@mui/material/Grid2';
import { ErTemplateField } from '@thrivea/organization-client';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { selectErTemplateFieldPositions, selectErTemplateFields, updateSectionField } from '@features/employee-record-builder';

interface EmployeeRecordSortableListProps {
  renderItem(item: ErTemplateField): ReactNode;
}

export const EmployeeRecordSortableList: React.FC<EmployeeRecordSortableListProps> = ({ renderItem }) => {
  const dispatch = useAppDispatch();
  const [active, setActive] = useState<Active | null>(null);
  const erTemplateFields = useAppSelector(selectErTemplateFields);
  const erTemplateFieldPositions = useAppSelector(selectErTemplateFieldPositions);
  const activeItem = useMemo(() => erTemplateFields.find((field) => field.kind.value!.id === active?.id), [active, erTemplateFields]);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  return (
    <DndContext
      sensors={sensors}
      onDragStart={({ active }) => {
        setActive(active);
      }}
      onDragEnd={({ active, over }) => {
        if (over && active.id !== over.id) {
          const activeIndex = erTemplateFields.findIndex((field) => field.kind.value!.id === active!.id);
          const overIndex = erTemplateFields.findIndex((field) => field.kind.value!.id === over.id);
          const updatedItems = arrayMove(erTemplateFields, overIndex, activeIndex);
          console.log('updatedItems', updatedItems);
          dispatch(updateSectionField(updatedItems));
        }
        setActive(null);
      }}
      onDragCancel={() => {
        setActive(null);
      }}
    >
      <SortableContext items={erTemplateFieldPositions}>
        <Grid container columns={12} role="application" sx={{ width: '100%' }}>
          {erTemplateFields.map((item) => (
            <Grid size={12} key={item.kind.value!.id}>
              {renderItem(item)}
            </Grid>
          ))}
        </Grid>
      </SortableContext>
      <EmployeeRecordSortableOverlay>{activeItem ? renderItem(activeItem) : null}</EmployeeRecordSortableOverlay>
    </DndContext>
  );
};
