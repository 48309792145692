import React, { useId, useState } from 'react';
import { Button, MenuItem, Link, Box, alpha, Collapse, Stack } from '@mui/material';
import { t } from 'i18next';
import { Link as RouterLink } from 'react-router-dom';
import { AvailableBetaTag, ChevronDownIcon } from '@/shared';
import { RetrieveEmployeeBasicInfoResponse } from '@thrivea/organization-client';
import { AllowedTo } from 'react-abac';
import { GroupPermissions } from '@features/abac';
import { ViewProfileMenuItem } from '@features/admin-settings';
import { useTranslation } from 'react-i18next';

interface EmployeeActionsProps {
  employee: RetrieveEmployeeBasicInfoResponse;
}

export const EmployeeActions: React.FC<EmployeeActionsProps> = ({ employee }) => {
  const { t } = useTranslation(['drawer']);
  const menuId = useId();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuToggle = (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <Box>
      <Button
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={handleMenuToggle}
        variant="contained"
        endIcon={<ChevronDownIcon />}
        sx={{
          backgroundColor: (theme) => (isMenuOpen ? theme.palette.customTheme.contColor : 'transparent'),
          color: (theme) => (isMenuOpen ? theme.palette.common.white : theme.palette.common.black),
          border: (theme) => (isMenuOpen ? undefined : `1px solid ${theme.palette.customTheme.contColor}`),
          borderRadius: '7px',
          width: 226,
          padding: '12px',
          lineHeight: 'normal',
          fontWeight: 700,
          boxShadow: 'none',
          '& .MuiButton-endIcon': {
            marginTop: '2px',
            transform: isMenuOpen ? 'rotate(180deg)' : undefined,
            transition: 'transform 0.3s ease-in-out',
            '& path': {
              fill: (theme) => (isMenuOpen ? theme.palette.common.white : theme.palette.common.black)
            }
          },
          '&:hover': {
            backgroundColor: (theme) => (isMenuOpen ? theme.palette.primary.dark : theme.palette.secondary.dark),
            borderColor: 'transparent'
          }
        }}
      >
        {t('actions', { ns: 'common' })}
      </Button>
      <Collapse in={isMenuOpen}>
        <Stack
          sx={{
            backgroundColor: (theme) => theme.palette.common.white,
            width: 226,
            padding: '7px'
          }}
        >
          <Box
            sx={{
              position: 'relative',
              backgroundColor: 'transparent',
              padding: '12px 12px 12px 17px',
              borderRadius: '7px',
              transition: 'background-color 0.3 ease-in-out',
              minWidth: 70,
              borderLeft: '7px solid',
              borderColor: 'transparent',
              '&:hover': {
                backgroundColor: (theme) => alpha(theme.palette.grey[800], 0.35),
                borderColor: '#9747FF'
              }
            }}
          >
            <AllowedTo
              perform={GroupPermissions.VIEW_PROFILE}
              data={employee.employeeId}
              no={() => <ViewProfileMenuItem employeeId={employee.employeeId} isDisabled={true} />}
            >
              <ViewProfileMenuItem employeeId={employee.employeeId} isDisabled={false} />
            </AllowedTo>
          </Box>
          <Box
            sx={{
              position: 'relative',
              backgroundColor: (theme) => alpha(theme.palette.grey[800], 0.35),
              padding: '12px 12px 12px 24px',
              borderRadius: '7px'
            }}
          >
            <MenuItem
              disabled
              sx={{
                zIndex: 2,
                padding: 0
              }}
            >
              <Link
                component={RouterLink}
                to="/"
                sx={{
                  width: '100%',
                  padding: 0,
                  textDecoration: 'none',
                  position: 'relative'
                }}
              >
                {t('view_in_org', { ns: 'drawer' })}
              </Link>
            </MenuItem>
            <AvailableBetaTag right={10} top={12} />
          </Box>
          <Box
            sx={{
              position: 'relative',
              backgroundColor: 'transparent',
              padding: '12px 12px 12px 24px',
              borderRadius: '7px',
              transition: 'background-color 0.3 ease-in-out',
              '&:hover': {
                backgroundColor: (theme) => alpha(theme.palette.grey[800], 0.35)
              }
            }}
          >
            <MenuItem
              disabled
              sx={{
                padding: 0
              }}
            >
              <Link
                component={RouterLink}
                to="/"
                sx={{
                  width: '100%',
                  padding: 0,
                  textDecoration: 'none'
                }}
              >
                {t('send_kudos', { ns: 'drawer' })}
              </Link>
            </MenuItem>
            <AvailableBetaTag right={10} top={12} />
          </Box>
          <Box
            sx={{
              position: 'relative',
              backgroundColor: 'transparent',
              padding: '12px 12px 12px 24px',
              borderRadius: '7px',
              transition: 'background-color 0.3 ease-in-out',
              '&:hover': {
                backgroundColor: (theme) => alpha(theme.palette.grey[800], 0.35)
              }
            }}
          >
            <MenuItem
              disabled
              sx={{
                padding: 0
              }}
            >
              <Link
                component={RouterLink}
                to="/"
                sx={{
                  width: '100%',
                  padding: 0,
                  textDecoration: 'none'
                }}
              >
                {t('add_to_bookmarks', { ns: 'drawer' })}
              </Link>
            </MenuItem>
            <AvailableBetaTag right={10} top={12} />
          </Box>
          <Box
            sx={{
              position: 'relative',
              backgroundColor: (theme) => alpha(theme.palette.grey[800], 0.35),
              padding: '12px 12px 12px 24px',
              borderRadius: '7px'
            }}
          >
            <MenuItem
              disabled
              sx={{
                zIndex: 2,
                padding: 0
              }}
            >
              <Link
                component={RouterLink}
                to="/"
                sx={{
                  width: '100%',
                  padding: 0,
                  textDecoration: 'none',
                  position: 'relative'
                }}
              >
                {t('save_to_view', { ns: 'drawer' })}
              </Link>
            </MenuItem>
            <AvailableBetaTag right={10} top={12} />
          </Box>
        </Stack>
      </Collapse>
    </Box>
  );
};
