import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';
import { PayloadAction } from '@reduxjs/toolkit';
import { RetrieveAudienceDrawerItemsByEmployeeIdRequest, RetrieveAudienceDrawerItemsByEmployeeIdResponse } from '@thrivea/networking-client';
import { retrieveAudienceDrawerItemsByEmployeeId } from '@api/audience.api';
import {
  retrieveAudienceDrawerItemsByEmployeeIdSucceeded,
  retrieveAudienceDrawerItemsByEmployeeIdFailed,
  retrieveAudienceDrawerItemsByEmployeeIdRequested,
  retrieveAudienceDrawerEmployeeItemsSucceeded,
  retrieveAudienceDrawerEmployeeItemsFailed,
  retrieveAudienceDrawerEmployeeItemsRequested
} from '@features/drawer';
import { EmployeeResults, RetrieveEmployeesByIdsRequest } from '@thrivea/organization-client';
import { retrieveEmployeesByIds } from '@api/employees.api';

function* retrievePostAudienceItemsGenerator(action: PayloadAction<RetrieveAudienceDrawerItemsByEmployeeIdRequest>) {
  try {
    const response: RetrieveAudienceDrawerItemsByEmployeeIdResponse = yield call(retrieveAudienceDrawerItemsByEmployeeId, action.payload);
    yield put(retrieveAudienceDrawerItemsByEmployeeIdSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveAudienceDrawerItemsByEmployeeIdFailed());
  }
}

function* retrieveAudienceDrawerEmployeeItemsRequestedGenerator(action: PayloadAction<RetrieveEmployeesByIdsRequest>) {
  try {
    const response: EmployeeResults = yield call(retrieveEmployeesByIds, action.payload);
    yield put(retrieveAudienceDrawerEmployeeItemsSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveAudienceDrawerEmployeeItemsFailed());
  }
}

function* retrievePostAudienceItemsWatcher() {
  yield takeLatest(retrieveAudienceDrawerItemsByEmployeeIdRequested.type, retrievePostAudienceItemsGenerator);
}

function* retrieveAudienceDrawerEmployeeItemsRequestedWatcher() {
  yield takeLatest(retrieveAudienceDrawerEmployeeItemsRequested.type, retrieveAudienceDrawerEmployeeItemsRequestedGenerator);
}

export function* audienceDrawerSagas() {
  yield all([fork(retrievePostAudienceItemsWatcher), fork(retrieveAudienceDrawerEmployeeItemsRequestedWatcher)]);
}
