import { Paper, Typography, FormControlLabel, PaperProps } from '@mui/material';
import { RowCenterStack, StyledPermissionGroupCheckbox } from '@/shared';

// props are passed in autocomplete trough slotProps: { paper }, this way rerender of this paper component is prevented and it does not cause the scroll to top behavior if option is checked: https://github.com/mui/material-ui/issues/29508

export interface CustomAudienceGroupAutocompletePaperProps extends PaperProps {
  sitesSelected?: boolean;
  jobTitlesSelected?: boolean;
  handleSitesChecked?: () => void;
  handleJobTitlesChecked?: () => void;
  searchText: string;
}

export const CustomAudienceGroupAutocompletePaper = (paperProps) => (
  <Paper
    sx={{
      backgroundColor: 'transparent',
      px: 1,
      paddingBottom: 1,
      position: 'sticky',
      bottom: 1,
      '& .MuiAutocomplete-noOptions': { padding: '0 4px', margin: '16px 0' }
    }}
    elevation={0}
    onMouseDown={(event) => {
      event.preventDefault();
    }}
  >
    {paperProps.children}
    {paperProps.searchText.length >= 2 && (
      <RowCenterStack
        gap={4}
        sx={{
          backgroundColor: (theme) => theme.palette.customTheme.drawerBackground,
          p: 1,
          borderRadius: (theme) => theme.spacing(0.5)
        }}
      >
        <Typography>Displaying results for:</Typography>
        <RowCenterStack gap={2}>
          <FormControlLabel
            control={
              <StyledPermissionGroupCheckbox
                checked
                disabled
                onMouseDown={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                }}
              />
            }
            label="Employees"
          />
          <FormControlLabel
            onChange={paperProps.handleJobTitlesChecked}
            control={
              <StyledPermissionGroupCheckbox
                checked={paperProps.jobTitlesSelected}
                onMouseDown={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                }}
              />
            }
            label="Job Titles"
          />
          <FormControlLabel
            onChange={paperProps.handleSitesChecked}
            control={
              <StyledPermissionGroupCheckbox
                checked={paperProps.sitesSelected}
                onMouseDown={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                }}
              />
            }
            label="Sites"
          />
        </RowCenterStack>
      </RowCenterStack>
    )}
  </Paper>
);
