import { useTranslation } from 'react-i18next';
import { Typography, Stack } from '@mui/material';
import { RetrieveEmployeesByWorkingPatternIdRequest } from '@thrivea/organization-client';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import {
  retrieveEmployeesByWorkingPatternIdRequested,
  selectCurrentWorkingPatternId,
  selectCurrentWorkingPatternName,
  selectWorkingPatternCurrentEmployeesListPage,
  selectWorkingPatternEmployeeIds,
  selectWorkingPatternEmployees,
  selectWorkingPatternEmployeesListStatus,
  selectWorkingPatternHasMoreEmployees
} from '@features/admin-settings';
import { RowCenterStack, AlertIcon, ActionStatus, EmployeeList } from '@/shared';
import { useCallback, useEffect, useRef } from 'react';
import { DrawerItemSkeleton } from '@features/drawer';

export const WorkingPatternDrawerEmployeeList = () => {
  const { t } = useTranslation(['common']);
  const dispatch = useAppDispatch();
  const currentWpName = useAppSelector(selectCurrentWorkingPatternName);
  const currentWpId = useAppSelector(selectCurrentWorkingPatternId);
  const workingPatternEmployeesListIds = useAppSelector(selectWorkingPatternEmployeeIds);
  const workingPatternEmployees = useAppSelector(selectWorkingPatternEmployees);
  const workingPatternEmployeesListStatus = useAppSelector(selectWorkingPatternEmployeesListStatus);
  const workingPatternHasMoreEmployees = useAppSelector(selectWorkingPatternHasMoreEmployees);
  const workingPatternCurrentEmployeesListPage = useAppSelector(selectWorkingPatternCurrentEmployeesListPage);
  const observer = useRef<IntersectionObserver>();
  const bottomElementRef = useRef<HTMLDivElement>(null);

  const handleScroll = useCallback(() => {
    if (workingPatternHasMoreEmployees) {
      dispatch(
        retrieveEmployeesByWorkingPatternIdRequested(
          new RetrieveEmployeesByWorkingPatternIdRequest({
            workingPatternId: currentWpId,
            pageNumber: workingPatternCurrentEmployeesListPage + 1,
            pageSize: 10
          })
        )
      );
    }
  }, [workingPatternHasMoreEmployees, workingPatternCurrentEmployeesListPage]);

  useEffect(() => {
    observer.current = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && workingPatternEmployeesListStatus === ActionStatus.Idle) {
          handleScroll();
        }
      },
      { threshold: 1 } // Trigger the observer when the target is 100% visible
    );

    // Start observing the target element
    if (bottomElementRef.current && observer.current) {
      observer.current.observe(bottomElementRef.current);
    }

    return () => {
      // Clean up the observer and scroll event listener when the component unmounts
      if (observer.current && bottomElementRef.current) {
        observer.current.disconnect();
      }
    };
  }, [handleScroll]);

  return (
    <Stack
      spacing={1}
      sx={{
        margin: '16px auto',
        padding: '24px 20px',
        backgroundColor: (theme) => theme.palette.background.paper,
        width: '100%',
        height: '100%',
        borderRadius: (theme) => theme.spacing(2)
      }}
    >
      {workingPatternEmployeesListIds.length === 0 && (
        <>
          <RowCenterStack gap={1}>
            <AlertIcon />
            <Typography>{t('working_pattern_no_employees', { ns: 'drawer', currentWpName })}</Typography>
          </RowCenterStack>
        </>
      )}
      {workingPatternEmployeesListIds.length !== 0 && <EmployeeList employees={workingPatternEmployees} />}
      <div ref={bottomElementRef} style={{ width: '100%', height: '1px' }}></div>
      {workingPatternEmployeesListStatus === ActionStatus.Pending && Array.from({ length: 6 }).map(() => <DrawerItemSkeleton key={crypto.randomUUID()} />)}
    </Stack>
  );
};
