import { TableCell } from '@mui/material';
import { Site, SiteStatus } from '@thrivea/organization-client';

interface WorksAtSiteTableCellProps {
  site: Site | undefined;
}

export const WorksAtSiteTableCell: React.FC<WorksAtSiteTableCellProps> = ({ site }) => {
  switch (site?.status) {
    case SiteStatus.SITE_DELETED:
      return <TableCell>{`${site!.name} (Deleted)`}</TableCell>;
    case SiteStatus.SITE_UNCOMPLETED:
      return <TableCell>{`${site!.name} (Draft)`}</TableCell>;
    case SiteStatus.SITE_COMPLETED:
      return <TableCell>{site!.name}</TableCell>;
    default:
      return <TableCell>-</TableCell>;
  }
};
