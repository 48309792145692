import { Typography } from '@mui/material';
import { Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import { StyledHomeLinkButton } from '@/shared';
import { ErrorLayout } from '@app/error/components/ErrorLayout';
import { useTranslation } from 'react-i18next';

export const ForbiddenPage = () => {
  const { t } = useTranslation(['common']);

  return (
    <ErrorLayout>
      <Stack
        gap={2}
        sx={{
          width: '100%',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center'
        }}
      >
        <Typography variant="h4">{t('403_title', { ns: 'common' })}</Typography>
        <Typography>{t('403_copy', { ns: 'common' })}</Typography>
        <StyledHomeLinkButton component={Link} to="/" variant="contained">
          {t('return_to_home', { ns: 'common' })}
        </StyledHomeLinkButton>
      </Stack>
    </ErrorLayout>
  );
};
