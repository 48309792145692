import { Chip, Stack, Typography } from '@mui/material';
import { useAppSelector } from '@app/hooks';
import { useTranslation } from 'react-i18next';
import { ActionStatus, RowCenterStack } from '@/shared';
import { AudienceRole } from '@thrivea/networking-client';
import { selectAudienceDrawerItemIds, selectAudienceDrawerItemsById, selectAudienceDrawerItemsStatus } from '../audience-drawer.slice';

interface EmployeeDrawerAudiencesProps {
  employeeId: string;
  employeeName: string;
}

export const EmployeeDrawerAudiences: React.FC<EmployeeDrawerAudiencesProps> = ({ employeeName }) => {
  const { t } = useTranslation(['homepage']);
  const audienceDrawerItemsById = useAppSelector(selectAudienceDrawerItemsById);
  const audienceDrawerItemsIds = useAppSelector(selectAudienceDrawerItemIds);
  const audienceDrawerItemsStatus = useAppSelector(selectAudienceDrawerItemsStatus);

  return (
    <Stack gap={2}>
      <Stack gap={2}>
        <Typography variant="subtitle1">{employeeName}'s Audiences</Typography>
        {audienceDrawerItemsStatus === ActionStatus.Idle && (
          <>
            {audienceDrawerItemsIds.map((id) => {
              const audience = audienceDrawerItemsById[id];
              return (
                <RowCenterStack key={id} sx={{ justifyContent: 'space-between' }}>
                  <Stack>
                    <Typography sx={{ fontWeight: 700 }}>{audience.audienceName}</Typography>
                    <Typography>{audience.audienceDescription}</Typography>
                  </Stack>
                  <Chip color="secondary" label={t(AudienceRole[audience.role].toLowerCase(), { ns: 'settings_permissions' })} />
                </RowCenterStack>
              );
            })}
          </>
        )}
      </Stack>
    </Stack>
  );
};
