import React from 'react';

interface FieldsIconAttributes {
  width?: number;
  height?: number;
  color?: string;
}

export const FieldsIcon: React.FC<FieldsIconAttributes> = ({ width = 50, height = 35, color = '#251D38' }) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Vector">
        <path
          d="M45.8258 29.0216H21.9079C21.0562 29.0216 20.3649 28.3779 20.3649 27.5814C20.3649 26.785 21.0562 26.1412 21.9079 26.1412H45.8258C46.2502 26.1412 46.5974 25.8171 46.5974 25.4211V9.5787C46.5974 9.1812 46.2502 8.85859 45.8258 8.85859H21.9079C21.0562 8.85859 20.3649 8.21337 20.3649 7.41837C20.3649 6.62337 21.0562 5.97815 21.9079 5.97815H45.8258C47.9538 5.97815 49.6836 7.59408 49.6836 9.5787V25.4211C49.6836 27.4071 47.9538 29.0216 45.8258 29.0216Z"
          fill={color}
        />
        <path
          d="M9.56323 29.0216H4.16241C2.03603 29.0216 0.304688 27.4071 0.304688 25.4211V9.5787C0.304688 7.59408 2.03603 5.97815 4.16241 5.97815H9.56323C10.415 5.97815 11.1063 6.62337 11.1063 7.41837C11.1063 8.21337 10.415 8.85859 9.56323 8.85859H4.16241C3.73652 8.85859 3.39087 9.1812 3.39087 9.5787V25.4211C3.39087 25.8171 3.73652 26.1412 4.16241 26.1412H9.56323C10.415 26.1412 11.1063 26.785 11.1063 27.5814C11.1063 28.3779 10.415 29.0216 9.56323 29.0216Z"
          fill={color}
        />
        <path
          d="M15.7356 34.7825C14.8838 34.7825 14.1925 34.1387 14.1925 33.3423V1.6575C14.1925 0.862503 14.8838 0.217285 15.7356 0.217285C16.5874 0.217285 17.2787 0.862503 17.2787 1.6575V33.3423C17.2787 34.1387 16.5874 34.7825 15.7356 34.7825Z"
          fill={color}
        />
        <path
          d="M20.3649 3.09772H11.1063C10.2545 3.09772 9.56323 2.4525 9.56323 1.6575C9.56323 0.862503 10.2545 0.217285 11.1063 0.217285H20.3649C21.2166 0.217285 21.9079 0.862503 21.9079 1.6575C21.9079 2.4525 21.2166 3.09772 20.3649 3.09772Z"
          fill={color}
        />
        <path
          d="M20.3649 34.7825H11.1063C10.2545 34.7825 9.56323 34.1387 9.56323 33.3423C9.56323 32.5458 10.2545 31.9021 11.1063 31.9021H20.3649C21.2166 31.9021 21.9079 32.5458 21.9079 33.3423C21.9079 34.1387 21.2166 34.7825 20.3649 34.7825Z"
          fill={color}
        />
      </g>
    </svg>
  );
};
