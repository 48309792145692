import { ChangeEvent } from 'react';

interface IHandleChange {
  e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;
  onChange: (_value: number) => void;
}

export const handleNumericInputChange = ({ e, onChange }: IHandleChange): void => {
  const val = e.target.value.trim();

  // Prevent non-numeric values except for the decimal point
  if (val === '' || val.match(/[^0-9.]/)) {
    e.preventDefault();
    return;
  }

  // Parse the numeric value
  const number = parseFloat(val);

  // If the parsed number is a valid number, call the onChange handler
  if (!isNaN(number)) {
    onChange(number);
  }
};
