import React, { useEffect, useRef, createRef, useState } from 'react';
import ScrollSpy from 'react-ui-scrollspy';
import { Container, Stack, Typography } from '@mui/material';
import { RoundedAutocomplete, StyledEmplyoeeRecordAddSectionButton } from '@/shared';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { DrawerType, openDrawer } from '@features/drawer';
import Grid from '@mui/material/Grid2';
import {
  EmployeeRecordCard,
  EmployeeRecordGenericSection,
  EmployeeRecordMenu,
  retrieveDefaultErTemplateSectionRequested,
  retrieveErbFieldCategoriesAndSectionsRequested,
  retrieveErbFieldsByCategoryIdRequested,
  retrieveErTemplateRequested,
  selectErbFieldCategories,
  selectErbFieldSectionsByCategoryId
} from '@features/employee-record-builder';
import {
  ErbFieldCategory,
  ErTemplateSection,
  RetrieveDefaultErTemplateSectionRequest,
  RetrieveErbFieldsByCategoryIdRequest,
  RetrieveErTemplateRequest
} from '@thrivea/organization-client';
import { orderBy } from 'lodash';

const fieldsList = [
  {
    name: 'Overview',
    hash: '#',
    scrollId: 1,
    sectionScrollLabelID: 'overview'
  },
  {
    name: 'Basic info',
    hash: '#',
    scrollId: 2,
    sectionScrollLabelID: 'basic_info'
  },
  {
    name: 'Media',
    hash: '#',
    scrollId: 3,
    sectionScrollLabelID: 'media'
  },
  {
    name: 'Personal',
    hash: '#',
    scrollId: 4,
    sectionScrollLabelID: 'personal'
  },
  {
    name: 'Personal contact details',
    hash: '#',
    scrollId: 5,
    sectionScrollLabelID: 'personal_contact_details'
  },
  {
    name: 'Identification',
    hash: '#',
    scrollId: 6,
    sectionScrollLabelID: 'identification'
  },
  {
    name: 'Work',
    hash: '#',
    scrollId: 7,
    sectionScrollLabelID: 'work'
  },
  {
    name: 'Work contact details',
    hash: '#',
    scrollId: 8,
    sectionScrollLabelID: 'work_contact_details'
  },
  {
    name: 'Address',
    hash: '#',
    scrollId: 9,
    sectionScrollLabelID: 'address'
  },
  {
    name: 'Home',
    hash: '#',
    scrollId: 10,
    sectionScrollLabelID: 'home'
  },
  {
    name: 'About',
    hash: '#',
    scrollId: 11,
    sectionScrollLabelID: 'about'
  },
  {
    name: 'Financial',
    hash: '#',
    scrollId: 12,
    sectionScrollLabelID: 'financial'
  },
  {
    name: 'Work eligibility',
    hash: '#',
    scrollId: 13,
    sectionScrollLabelID: 'work_eligibility'
  },
  {
    name: 'Employment',
    hash: '#',
    scrollId: 14,
    sectionScrollLabelID: 'employment'
  },
  {
    name: 'Payroll',
    hash: '#',
    scrollId: 15,
    sectionScrollLabelID: 'payroll'
  },
  {
    name: 'Equity',
    hash: '#',
    scrollId: 16,
    sectionScrollLabelID: 'equity'
  },
  {
    name: 'Emergency',
    hash: '#',
    scrollId: 17,
    sectionScrollLabelID: 'emergency'
  },
  {
    name: 'Training',
    hash: '#',
    scrollId: 18,
    sectionScrollLabelID: 'training'
  },
  {
    name: 'Life cycle',
    hash: '#',
    scrollId: 19,
    sectionScrollLabelID: 'life_cycle'
  },
  {
    name: 'User data',
    hash: '#',
    scrollId: 20,
    sectionScrollLabelID: 'user_data'
  },
  {
    name: 'EEO',
    hash: '#',
    scrollId: 21,
    sectionScrollLabelID: 'eeo'
  },
  {
    name: 'People analytics',
    hash: '#',
    scrollId: 22,
    sectionScrollLabelID: 'people_analytics'
  },
  {
    name: 'Jobs',
    hash: '#',
    scrollId: 23,
    sectionScrollLabelID: 'jobs'
  }
];

// const tempEmployeeRecordPageCategories = [
//   {
//     id: '3c945333-ad81-43e4-9dc9-bc1e54585488',
//     name: 'Personal information',
//     sections: [
//       {
//         id: '015bc9b8-a06a-4a21-90da-aa36659c6fef',
//         name: 'Section 01'
//       },
//       {
//         id: '0f260a58-84ab-41a9-85a1-2c55f59c850e',
//         name: 'Section 02'
//       }
//     ]
//   },
//   {
//     id: '695a39fb-55e5-4c83-be7e-a2799665df22',
//     name: 'Work information',
//     sections: [
//       {
//         id: '015bc9b8-a06a-4a21-90da-aa36659c6fef',
//         name: 'Section 01'
//       }
//     ]
//   },
//   {
//     id: 'd702f344-34e2-4764-acb1-e40430c9fa14',
//     name: 'Organizational information',
//     sections: [
//       {
//         id: '015bc9b8-a06a-4a21-90da-aa36659c6fef',
//         name: 'Section 01'
//       }
//     ]
//   },
//   {
//     id: 'b99f71c4-d28c-4cc2-81be-2ca225e9bbfc',
//     name: 'Financial information',
//     sections: [
//       {
//         id: '015bc9b8-a06a-4a21-90da-aa36659c6fef',
//         name: 'Section 01'
//       }
//     ]
//   },
//   {
//     id: '229ddca9-1b41-4f4f-baa6-aca1354340f5',
//     name: 'Legal & Compliance',
//     sections: [
//       {
//         id: '015bc9b8-a06a-4a21-90da-aa36659c6fef',
//         name: 'Section 01'
//       }
//     ]
//   },
//   {
//     id: '48fcce5d-e592-470f-8c94-f9df743ff890',
//     name: 'Custom',
//     sections: [
//       {
//         id: '015bc9b8-a06a-4a21-90da-aa36659c6fef',
//         name: 'Section 01'
//       }
//     ]
//   }
// ];

interface EmployeeRecordSectionsProps {
  sections: ErTemplateSection[];
}

export const EmployeeRecordSections: React.FC<EmployeeRecordSectionsProps> = ({ sections }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['common', 'employee_profile']);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const tabContainerRef = useRef<HTMLDivElement>(null);
  const tabContainerMobileRef = useRef<HTMLDivElement>(null);
  const sectionRefs = useRef<Record<string, React.RefObject<HTMLDivElement>>>({});
  const categoryButtonRefs = useRef<Record<string, React.RefObject<HTMLButtonElement>>>({});
  const [selectedCategoryId, setSelectedCategoryId] = useState('');
  const erbFieldCategories = useAppSelector(selectErbFieldCategories);
  const erbFieldSections = useAppSelector((state) => selectErbFieldSectionsByCategoryId(state, selectedCategoryId));
  const [selectedSectionId, setSelectedSectionId] = useState('');

  useEffect(() => {
    for (const field of fieldsList) {
      sectionRefs.current[field.sectionScrollLabelID] = createRef();
      categoryButtonRefs.current[field.sectionScrollLabelID] = createRef();
    }

    if (erbFieldCategories.length == 0) {
      dispatch(retrieveErbFieldCategoriesAndSectionsRequested());
    }
  }, []);

  const scrollToSection = (e: React.MouseEvent<HTMLButtonElement>, sectionScrollLabelID: string) => {
    const section = sectionRefs.current[sectionScrollLabelID]?.current;

    if (section) {
      const scrollParent = scrollContainerRef.current;
      const headerOffset = 120;
      const elementPosition = section.getBoundingClientRect().top;
      const offsetPosition = elementPosition - headerOffset;

      if (scrollParent) {
        scrollParent.scrollBy({
          top: offsetPosition,
          behavior: 'smooth'
        });
      }
    }
  };

  useEffect(() => {
    for (const field of fieldsList) {
      sectionRefs.current[field.sectionScrollLabelID] = createRef();
      categoryButtonRefs.current[field.sectionScrollLabelID] = createRef();
    }
    if (erbFieldCategories.length == 0) {
      dispatch(retrieveErTemplateRequested(new RetrieveErTemplateRequest({ erTemplateId: '11183939-f57c-49fe-8a25-48c4a30f3a11' })));
      dispatch(retrieveErbFieldCategoriesAndSectionsRequested());
    }
  }, []);

  const handleEmployeeRecordBuilderDrawerButtonClick = () => {
    dispatch(
      retrieveDefaultErTemplateSectionRequested(new RetrieveDefaultErTemplateSectionRequest({ erTemplateSectionId: selectedSectionId, erTemplateId: '' }))
    );
    dispatch(retrieveErbFieldsByCategoryIdRequested(new RetrieveErbFieldsByCategoryIdRequest({ erbCategoryId: selectedCategoryId })));
    dispatch(openDrawer(DrawerType.EmployeeRecord));
  };

  const handleCategorySelect = (selectedOption: { id: string; name: string }) => {
    setSelectedCategoryId(selectedOption.id);
  };

  const handleSectionSelect = (selectedOption: { id: string; name: string }) => {
    setSelectedSectionId(selectedOption.id);
  };

  return (
    <Grid
      container
      sx={{
        position: 'relative',
        height: {
          xs: '100%',
          lg: 'calc(100vh - 124px)'
        }
      }}
    >
      <Grid
        ref={tabContainerRef}
        className="Mui-Tabs-Grid"
        size={{
          xs: 12,
          lg: 3
        }}
        sx={{
          backgroundColor: (theme) => theme.palette.customTheme.contBgr,
          border: '1px solid',
          borderColor: (theme) => theme.palette.customTheme.borderMaster,
          borderRadius: (theme) => theme.spacing(2),
          zIndex: (theme) => theme.zIndex.drawer,
          height: {
            xs: 'auto',
            lg: '100%'
          },
          mb: {
            xs: 2,
            lg: 0
          }
        }}
      >
        <EmployeeRecordMenu ref={tabContainerMobileRef} categoryButtonRefs={categoryButtonRefs} scrollToSection={scrollToSection} />
      </Grid>
      <Grid
        ref={scrollContainerRef}
        size={{
          xs: 12,
          lg: 9
        }}
        sx={{
          overflowY: 'scroll',
          transform: 'translate3d(0,0,0)',
          willTransform: 'transform',
          height: {
            xs: 'auto',
            lg: '100%'
          }
        }}
      >
        <Container
          sx={{
            maxWidth: {
              xs: '100%'
            },
            padding: {
              xs: 0,
              lg: '0 1rem'
            }
          }}
        >
          <Stack gap={2}>
            <Stack
              gap={2}
              sx={{
                padding: '24px 16px',
                border: '1px solid',
                borderColor: (theme) => theme.palette.customTheme.borderMaster,
                borderRadius: 4
              }}
            >
              <Stack>
                <Typography fontWeight={700}>{t('add_new_section_title', { ns: 'employee_profile' })}</Typography>
                <Typography>{t('add_new_section_desc', { ns: 'employee_profile' })}</Typography>
              </Stack>
              <RoundedAutocomplete options={erbFieldCategories} name={t('category', { ns: 'employee_profile' })} onOptionSelect={handleCategorySelect} />
              <RoundedAutocomplete options={erbFieldSections ?? []} name={t('section', { ns: 'employee_profile' })} onOptionSelect={handleSectionSelect} />
              <StyledEmplyoeeRecordAddSectionButton onClick={handleEmployeeRecordBuilderDrawerButtonClick}>
                Add new Section
              </StyledEmplyoeeRecordAddSectionButton>
            </Stack>
            <ScrollSpy activeClass="Mui-selected" parentScrollContainerRef={scrollContainerRef} useBoxMethod>
              <EmployeeRecordCard />
              {sections.map((section) => (
                <EmployeeRecordGenericSection key={section.id} section={section} isEditable />
              ))}
            </ScrollSpy>
          </Stack>
        </Container>
      </Grid>
    </Grid>
  );
};
