import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@app/store';
import { ActionStatus } from '@/shared/shared.model';
import {
  AudienceDrawerItem,
  RetrieveAudienceDrawerItemsByEmployeeIdRequest,
  RetrieveAudienceDrawerItemsByEmployeeIdResponse
} from '@thrivea/networking-client';
import { EmployeeListItem, EmployeeResults, RetrieveEmployeesByIdsRequest } from '@thrivea/organization-client';

export interface AudienceDrawerState {
  entities: {
    audienceItems: {
      byId: { [key: string]: AudienceDrawerItem };
      allIds: string[];
    };
    audienceDrawerEmployeeItems: {
      byId: { [key: string]: EmployeeListItem };
      allIds: string[];
    };
  };
  ui: {
    audienceItemsStatus: ActionStatus;
    audienceDrawerEmployeeItemsStatus: ActionStatus;
  };
}

const initialState: AudienceDrawerState = {
  entities: {
    audienceItems: {
      byId: {},
      allIds: []
    },
    audienceDrawerEmployeeItems: {
      byId: {},
      allIds: []
    }
  },
  ui: {
    audienceItemsStatus: ActionStatus.Idle,
    audienceDrawerEmployeeItemsStatus: ActionStatus.Idle
  }
};

export const audienceDrawerSlice = createSlice({
  name: 'audienceDrawer',
  initialState,
  reducers: {
    retrieveAudienceDrawerItemsByEmployeeIdRequested: (state, action: PayloadAction<RetrieveAudienceDrawerItemsByEmployeeIdRequest>) => {
      state.ui.audienceItemsStatus = ActionStatus.Pending;
      state.entities.audienceItems.byId = {};
      state.entities.audienceItems.allIds = [];
    },
    retrieveAudienceDrawerItemsByEmployeeIdSucceeded: (state, action: PayloadAction<RetrieveAudienceDrawerItemsByEmployeeIdResponse>) => {
      const { audiences } = action.payload;

      for (const audience of audiences) {
        state.entities.audienceItems.byId[audience.audienceId] = audience;
        state.entities.audienceItems.allIds.push(audience.audienceId);
      }
      state.ui.audienceItemsStatus = ActionStatus.Idle;
    },
    retrieveAudienceDrawerItemsByEmployeeIdFailed: (state) => {
      state.ui.audienceItemsStatus = ActionStatus.Failed;
    },
    retrieveAudienceDrawerEmployeeItemsRequested: (state, action: PayloadAction<RetrieveEmployeesByIdsRequest>) => {
      state.ui.audienceDrawerEmployeeItemsStatus = ActionStatus.Pending;
      state.entities.audienceDrawerEmployeeItems.byId = {};
      state.entities.audienceDrawerEmployeeItems.allIds = [];
    },
    retrieveAudienceDrawerEmployeeItemsSucceeded: (state, action: PayloadAction<EmployeeResults>) => {
      for (const employee of action.payload.employees) {
        state.entities.audienceDrawerEmployeeItems.byId[employee.employeeId] = employee;
        state.entities.audienceDrawerEmployeeItems.allIds.push(employee.employeeId);
      }
      state.ui.audienceDrawerEmployeeItemsStatus = ActionStatus.Idle;
    },
    retrieveAudienceDrawerEmployeeItemsFailed: (state) => {
      state.ui.audienceDrawerEmployeeItemsStatus = ActionStatus.Failed;
    }
  }
});

export const selectAudienceDrawerItemsById = (state: RootState) => state.audienceDrawer.entities.audienceItems.byId;
export const selectAudienceDrawerItemIds = (state: RootState) => state.audienceDrawer.entities.audienceItems.allIds;
export const selectAudienceDrawerItemsStatus = (state: RootState) => state.audienceDrawer.ui.audienceItemsStatus;

export const selectAudienceDrawerEmployeeItemsById = (state: RootState) => state.audienceDrawer.entities.audienceDrawerEmployeeItems.byId;
export const selectAudienceDrawerEmployeeItemsIds = (state: RootState) => state.audienceDrawer.entities.audienceDrawerEmployeeItems.allIds;
export const selectAudienceDrawerEmployeeItemsStatus = (state: RootState) => state.audienceDrawer.ui.audienceDrawerEmployeeItemsStatus;

export const selectAudienceDrawerEmployeeitems = createSelector(
  [selectAudienceDrawerEmployeeItemsById, selectAudienceDrawerEmployeeItemsIds],
  (audienceDrawerEmployeeItemsById, audienceDrawerEmployeeItemsIds) => audienceDrawerEmployeeItemsIds.map((id) => audienceDrawerEmployeeItemsById[id])
);

export const {
  retrieveAudienceDrawerItemsByEmployeeIdRequested,
  retrieveAudienceDrawerItemsByEmployeeIdSucceeded,
  retrieveAudienceDrawerItemsByEmployeeIdFailed,
  retrieveAudienceDrawerEmployeeItemsRequested,
  retrieveAudienceDrawerEmployeeItemsSucceeded,
  retrieveAudienceDrawerEmployeeItemsFailed
} = audienceDrawerSlice.actions;
export default audienceDrawerSlice.reducer;
