import { Avatar, Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import {
  DrawerType,
  openDrawer,
  PostAnalyticsListSkeleton,
  retrieveAudienceDrawerEmployeeItemsRequested,
  selectAudienceDrawerEmployeeitems
} from '@features/drawer';
import { RetrieveEmployeeBasicInfoRequest, RetrieveEmployeesByIdsRequest } from '@thrivea/organization-client';
import { selectPostAnalyticsStatus, selectReadEmployees } from '@features/homepage';
import { selectEmployeeProfileAndCoverReadSasToken } from '@features/employee-profile';
import { pictureUrl } from '@/utils';
import { ActionStatus, PictureSizeSuffix, StyledProfileLink } from '@/shared';
import { useTranslation } from 'react-i18next';
import { retrieveEmployeeBasicInfoRequested } from '@features/admin-settings';

export const PostAnalyticsReadList = () => {
  const { t } = useTranslation(['common', 'settings_permissions']);
  const dispatch = useAppDispatch();
  const readEmployees = useAppSelector(selectReadEmployees);
  const audienceDrawerEmployeeItems = useAppSelector(selectAudienceDrawerEmployeeitems);
  const postAnalyticsStatus = useAppSelector(selectPostAnalyticsStatus);
  const employeeProfileSasToken = useAppSelector(selectEmployeeProfileAndCoverReadSasToken);

  useEffect(() => {
    if (readEmployees) {
      dispatch(
        retrieveAudienceDrawerEmployeeItemsRequested(
          new RetrieveEmployeesByIdsRequest({
            employeeIds: readEmployees.map((employee) => employee.employeeId)
          })
        )
      );
    }
  }, [readEmployees]);

  const handleRetrieveSingleEmployeeInfo = (employeeId: string) => {
    dispatch(
      retrieveEmployeeBasicInfoRequested(
        new RetrieveEmployeeBasicInfoRequest({
          employeeId
        })
      )
    );
  };

  return (
    <Stack
      sx={{
        backgroundColor: (theme) => theme.palette.common.white,
        padding: 2,
        mt: 2,
        borderRadius: (theme) => theme.spacing(2)
      }}
    >
      {postAnalyticsStatus === ActionStatus.Pending && <PostAnalyticsListSkeleton />}
      {postAnalyticsStatus === ActionStatus.Idle &&
        audienceDrawerEmployeeItems.length > 0 &&
        audienceDrawerEmployeeItems.map((employee) => (
          <Stack
            key={crypto.randomUUID()}
            direction="row"
            spacing={2}
            sx={{
              borderRadius: '8px',
              padding: '8px',
              transition: 'background-color 0.3s ease-in-out',
              '&:hover': {
                backgroundColor: (theme) => theme.palette.common.white,
                '& .Mui-ProfileLink': {
                  opacity: 1
                }
              }
            }}
          >
            <Avatar
              src={pictureUrl(employee.profilePictureUrl, employeeProfileSasToken, PictureSizeSuffix.sm)}
              alt={employee.displayName}
              variant="rounded"
              sx={{
                border: (theme) => `1px solid ${theme.palette.customTheme.contColor}`,
                width: 38,
                height: 38
              }}
            />
            <Stack
              direction="row"
              sx={{
                width: '100%',
                justifyContent: 'space-between'
              }}
            >
              <Stack>
                <Typography fontWeight={700}>{employee.displayName}</Typography>
                <Typography>{employee.jobTitle}</Typography>
              </Stack>
              <StyledProfileLink onClick={() => handleRetrieveSingleEmployeeInfo(employee.employeeId)} className="Mui-ProfileLink" variant="text">
                {t('view_profile')}
              </StyledProfileLink>
            </Stack>
          </Stack>
        ))}
    </Stack>
  );
};
