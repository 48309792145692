import { Badge, Menu, MenuItem, Typography, Avatar, IconButton, Button, ListItemButton } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { selectCurrentUserInitials, selectCurrentUserProfilePictureUrl, selectCurrentUserDisplayName, selectCurrentEmployeeId } from '@app/user';
import { RowCenterStack, NotificationsIcon, PictureSizeSuffix, ActionStatus } from '@/shared';
import {
  retrieveNewNotificationsRequested,
  retrieveNotificationsRequested,
  selectCurrentNotificationPageNumber,
  selectHasMore,
  selectHasUnreadNotifications,
  selectNotificationStatus
} from '@features/notifications';
import { useMsal } from '@azure/msal-react';
import { selectEmployeeProfileAndCoverReadSasToken } from '@features/employee-profile';
import { pictureUrl } from '@utils/pictureUrl';
import { useTranslation } from 'react-i18next';
import { RetrieveNotificationsRequest } from '@thrivea/notification-client';
import { DrawerType, openDrawer } from 'src/features/drawer';

export const SiteAppbarUserAction = () => {
  const { t } = useTranslation(['common']);
  const { instance } = useMsal();
  const dispatch = useAppDispatch();

  const [accountAnchorEl, setAccoutAnchorEl] = useState<null | HTMLElement>(null);

  const currentUserDisplayName = useAppSelector(selectCurrentUserDisplayName);
  const currentUserProfilePicture = useAppSelector(selectCurrentUserProfilePictureUrl);
  const currentEmployeeId = useAppSelector(selectCurrentEmployeeId);
  const currentUserInitials = useAppSelector(selectCurrentUserInitials);
  const employeeProfileSasToken = useAppSelector(selectEmployeeProfileAndCoverReadSasToken);
  const hasUnreadNotifications = useAppSelector(selectHasUnreadNotifications);
  const hasMore = useAppSelector(selectHasMore);
  const notificationStatus = useAppSelector(selectNotificationStatus);
  const currentNotificationPageNumber = useAppSelector(selectCurrentNotificationPageNumber);

  const observer = useRef<IntersectionObserver>();
  const bottomElementRef = useRef<HTMLDivElement>(null);

  const handleScroll = useCallback(() => {
    if (hasMore) {
      dispatch(
        retrieveNotificationsRequested(
          new RetrieveNotificationsRequest({
            employeeId: currentEmployeeId,
            pageSize: 10,
            pageNumber: currentNotificationPageNumber + 1
          })
        )
      );
    }
  }, [hasMore, currentNotificationPageNumber]);

  useEffect(() => {
    observer.current = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && notificationStatus === ActionStatus.Idle) {
          handleScroll();
        }
      },
      { threshold: 1 } // Trigger the observer when the target is 100% visible
    );

    // Start observing the target element
    if (bottomElementRef.current && observer.current) {
      observer.current.observe(bottomElementRef.current);
    }

    return () => {
      // Clean up the observer and scroll event listener when the component unmounts
      if (observer.current && bottomElementRef.current) {
        observer.current.disconnect();
      }
    };
  }, [handleScroll]);

  const handleAccountMenuClick = () => {
    setAccoutAnchorEl(null);
  };

  const handleSignoutClick = () => {
    instance.logout();
  };

  const handleAvatarMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAccoutAnchorEl(event.currentTarget);
  };

  const handleBellIconButtonClick = () => {
    if (hasUnreadNotifications) {
      dispatch(
        retrieveNewNotificationsRequested(
          new RetrieveNotificationsRequest({
            employeeId: currentEmployeeId,
            pageSize: 10,
            pageNumber: 1
          })
        )
      );
    }
    dispatch(openDrawer(DrawerType.Notifications));
  };

  return (
    <RowCenterStack
      gap={1}
      sx={{
        justifyContent: 'flex-end'
      }}
    >
      <IconButton
        onClick={handleBellIconButtonClick}
        sx={{
          width: 36,
          height: 36,
          borderRadius: '7px'
        }}
      >
        {hasUnreadNotifications ? (
          <Badge color="error" overlap="circular" variant="dot">
            <NotificationsIcon />
          </Badge>
        ) : (
          <NotificationsIcon />
        )}
      </IconButton>
      <Typography variant="body2" sx={{ color: (theme) => theme.palette.common.black }}>
        {currentUserDisplayName}
      </Typography>
      <IconButton
        onClick={handleAvatarMenuClick}
        size="small"
        aria-controls={accountAnchorEl ? 'account-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={accountAnchorEl ? 'true' : undefined}
        sx={{
          padding: 0,
          margin: 0
        }}
      >
        <Avatar
          sx={{
            width: 46,
            height: 46,
            borderRadius: 2,
            zIndex: '2'
          }}
          src={pictureUrl(currentUserProfilePicture, employeeProfileSasToken, PictureSizeSuffix.md)}
          alt="Profile image"
        >
          {currentUserInitials}
        </Avatar>
      </IconButton>
      <Menu
        anchorEl={accountAnchorEl}
        id="account-menu"
        open={!!accountAnchorEl}
        onClose={handleAccountMenuClick}
        onClick={handleAccountMenuClick}
        hideBackdrop
        disableScrollLock
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        slotProps={{
          root: {
            sx: {
              '& .MuiList-root': {
                padding: '8px 0'
              }
            }
          },
          paper: {
            elevation: 2,
            sx: {
              borderRadius: '8px',
              overflow: 'visible',
              '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: (theme) => theme.palette.common.white,
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0
              }
            }
          }
        }}
      >
        <MenuItem
          onClick={handleAccountMenuClick}
          sx={{
            padding: 0,
            height: '50px'
          }}
        >
          <ListItemButton
            component={NavLink}
            to={`/profiles/${currentEmployeeId}`}
            sx={{
              width: '100%',
              height: '100%'
            }}
          >
            {t('view_profile', { ns: 'common' })}
          </ListItemButton>
        </MenuItem>
        <MenuItem
          onClick={handleAccountMenuClick}
          sx={{
            padding: 0,
            height: '50px'
          }}
        >
          <ListItemButton
            component={Button}
            variant="text"
            onClick={handleSignoutClick}
            sx={{
              width: '100%',
              height: '100%',
              padding: (theme) => theme.spacing(2),
              backgroundColor: 'transparent',
              '&:hover': {
                backgroundColor: 'transparent'
              }
            }}
          >
            {t('signout', { ns: 'common' })}
          </ListItemButton>
        </MenuItem>
      </Menu>
    </RowCenterStack>
  );
};
