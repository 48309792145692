import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@app/store';
import { Code } from '@connectrpc/connect';

export interface ErrorState {
  statusCode: Code;
}

// intialized with space because of the selectCurrentUserInitials logic (firstName[0])
const initialState: ErrorState = {
  statusCode: {} as Code
};

export const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    redirectToErrorPage: (state, action: PayloadAction<Code>) => {
      state.statusCode = action.payload;
    }
  }
});

export const selectErrorCode = (state: RootState) => state.error.statusCode;

export const { redirectToErrorPage } = errorSlice.actions;
export default errorSlice.reducer;
