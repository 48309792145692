export enum DrawerType {
  Site = 'site',
  SingleEmployee = 'singleEmployee',
  WorkingPattern = 'workingPattern',
  JobTitle = 'jobTitle',
  Audience = 'audience',
  AudienceRole = 'audienceRole',
  PostAnalytics = 'postAnalytics',
  Notifications = 'notifications',
  EmployeeRecord = 'employeeRecord',
  PeopleDirectory = 'peopleDirectory',
  Assets = 'assets'
}
