import { forwardRef, Fragment, MouseEvent, useId, useState } from 'react';
import { AvailableBetaTag, RowCenterStack, StyledListItemButton, StyledListItemButtonNested, VisuallyHidden } from '@/shared';
import { Box, Collapse, List, ListItemText, IconButton, Typography } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';

interface EmployeeRecordMenuProps {
  scrollToSection: (e: React.MouseEvent<HTMLButtonElement>, sectionScrollLabelID: string) => void;
  categoryButtonRefs: React.MutableRefObject<Record<string, React.RefObject<HTMLButtonElement>>>;
}

export interface SubActionItem {
  id: string;
  name: string;
  beta?: boolean;
  childItems?: SubActionItem[];
}

export interface SubAction {
  id: number;
  name: string;
  hash: string;
  items: SubActionItem[];
}

const subActionsList: SubAction[] = [
  {
    id: 1,
    name: '',
    hash: '#',
    items: [
      {
        id: 'employee-record-card',
        name: 'Employee Record Card'
      },
      {
        id: 'work-information',
        name: 'Work Information',
        childItems: [
          {
            id: 'work-section-01',
            name: 'Work Section 01'
          },
          {
            id: 'work-section-02',
            name: 'Work Section 02'
          }
        ]
      },
      {
        id: 'personal-information',
        name: 'Personal Information',
        childItems: [
          {
            id: 'personal-information-01',
            name: 'Personal information 01'
          }
        ]
      }
    ]
  }
];

export const EmployeeRecordMenu = forwardRef<HTMLDivElement, EmployeeRecordMenuProps>(({ scrollToSection, categoryButtonRefs }, ref) => {
  const { t } = useTranslation(['common']);
  const subHeaderId = useId();
  const [primaryItemOpen, setPrimaryItemOpen] = useState('Company');
  const [secondaryItemOpen, setSecondaryItemOpen] = useState('');

  const handlePrimaryItemClick = (e: MouseEvent<HTMLButtonElement>, name: string) => {
    e.preventDefault();
    setPrimaryItemOpen(name === primaryItemOpen ? '' : name);
  };

  const handleSecondaryItemClick = (name: string) => {
    setSecondaryItemOpen(name === secondaryItemOpen ? '' : name);
  };

  return (
    <Box component="nav" sx={{ backgroundColor: 'inherit', borderRadius: 'inherit', height: '100%', overflowY: 'scroll' }} ref={ref}>
      <Typography
        sx={{
          color: (theme) => theme.palette.customTheme.contColor,
          padding: '16px 24px 8px 28px',
          textTransform: 'uppercase',
          fontSize: '14px',
          fontWeight: 700
        }}
      >
        {t('categories', { ns: 'common' })}
      </Typography>
      {subActionsList.map((sal) => (
        <List
          key={sal.id}
          sx={{
            width: '100%',
            px: 1
          }}
          aria-labelledby={subHeaderId}
        >
          {sal.items.map((item) => (
            <Fragment key={item.id}>
              <StyledListItemButton>
                <ListItemText
                  primary={item.name}
                  primaryTypographyProps={{
                    fontSize: 14
                  }}
                />
                {item.childItems && (
                  <IconButton
                    disableFocusRipple
                    onClick={(e) => handlePrimaryItemClick(e, item.name)}
                    sx={{
                      p: 0,
                      m: 0
                    }}
                  >
                    {primaryItemOpen === item.name ? (
                      <>
                        <ExpandLess />
                        <VisuallyHidden>Expand {item.name}</VisuallyHidden>
                      </>
                    ) : (
                      <>
                        <ExpandMore />
                        <VisuallyHidden>Expand {item.name}</VisuallyHidden>
                      </>
                    )}
                  </IconButton>
                )}
                {item.beta && <AvailableBetaTag top={8} right={25} />}
              </StyledListItemButton>
              {item.childItems && (
                <Collapse in={primaryItemOpen === item.name} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {item.childItems?.map((ci) => (
                      <Fragment key={ci.id}>
                        <StyledListItemButtonNested disabled={ci.beta}>
                          <RowCenterStack
                            sx={{
                              gap: 1
                            }}
                          >
                            <ListItemText
                              primary={ci.name}
                              primaryTypographyProps={{
                                fontSize: 12
                              }}
                            />
                          </RowCenterStack>
                          {ci.childItems && (
                            <>
                              {secondaryItemOpen ? (
                                <IconButton
                                  disableFocusRipple
                                  onClick={() => handleSecondaryItemClick(ci.name)}
                                  sx={{
                                    p: 0,
                                    m: 0
                                  }}
                                >
                                  <ExpandLess />
                                </IconButton>
                              ) : (
                                <IconButton
                                  disableFocusRipple
                                  onClick={() => handleSecondaryItemClick(ci.name)}
                                  sx={{
                                    p: 0,
                                    m: 0
                                  }}
                                >
                                  <ExpandMore />
                                </IconButton>
                              )}
                            </>
                          )}
                        </StyledListItemButtonNested>
                      </Fragment>
                    ))}
                  </List>
                </Collapse>
              )}
            </Fragment>
          ))}
        </List>
      ))}
    </Box>
  );
});
