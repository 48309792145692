import React from 'react';
import { useTheme } from '@mui/material';

interface AddIconAttributes {
  size?: number; // Optional size prop to control the icon size
  color?: string; // Optional color prop to control the icon color
}

export const AddIcon: React.FC<AddIconAttributes> = ({ size = 24, color = '#000000' }: AddIconAttributes) => {
  const theme = useTheme();

  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1052_14200)">
        <path d="M19 13.5H13V19.5H11V13.5H5V11.5H11V5.5H13V11.5H19V13.5Z" fill={color} />
      </g>
      <defs>
        <clipPath id="clip0_1052_14200">
          <rect width={size} height={size} fill={color} transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};
