import { Box, Autocomplete } from '@mui/material';
import { ChevronDownIcon, StyledEmployeeRecordAutocompleteInput, StyledEmplyoeeRecordCategoryListItem } from '@/shared';

interface Option {
  id: string;
  name: string;
}

interface RoundedAutocompleteProps {
  options: Option[];
  name: string;
  onOptionSelect: (selectedOption: { id: string; name: string }) => void;
}

export const RoundedAutocomplete: React.FC<RoundedAutocompleteProps> = ({ options, name, onOptionSelect }) => {
  return (
    <Box
      sx={{
        position: 'relative',
        minWidth: 226
      }}
    >
      <Autocomplete
        popupIcon={<ChevronDownIcon />}
        disableClearable
        disablePortal
        blurOnSelect
        options={options}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => <StyledEmployeeRecordAutocompleteInput {...params} label={name} />}
        renderOption={(props, option) => (
          <StyledEmplyoeeRecordCategoryListItem {...props} key={option.id}>
            {option.name}
          </StyledEmplyoeeRecordCategoryListItem>
        )}
        onChange={(event, newValue) => {
          if (newValue) {
            onOptionSelect(newValue);
          }
        }}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              padding: 1,
              borderBottomLeftRadius: 10,
              borderBottomRightRadius: 10
            }
          },
          popper: {
            placement: 'bottom',
            sx: {
              border: '1px solid',
              // borderTop: 0,
              borderColor: (theme) => theme.palette.customTheme.contColor,
              width: '100% !important',
              borderBottomLeftRadius: 10,
              borderBottomRightRadius: 10
            }
          }
        }}
      />
    </Box>
  );
};
