import { useContext } from 'react';
import { SortableItemContext } from '@features/drawer';
import { IconButton } from '@mui/material';

export const EmployeeRecordDragHandle = () => {
  const { attributes, listeners, ref } = useContext(SortableItemContext);

  return (
    <IconButton
      {...attributes}
      {...listeners}
      ref={ref}
      sx={{
        cursor: 'grabbing'
      }}
    >
      <svg width="9" height="19" viewBox="0 0 9 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Group 1">
          <rect id="Rectangle 12" x="0.125" y="16.6333" width="8.125" height="2.00641" rx="1.0032" fill="#251D38" />
          <rect id="Rectangle 13" x="0.125" y="8.60742" width="8.125" height="2.00641" rx="1.0032" fill="#251D38" />
          <rect id="Rectangle 14" x="0.125" y="0.582031" width="8.125" height="2.00641" rx="1.0032" fill="#251D38" />
          <rect id="Rectangle 15" x="1.375" y="4.59473" width="5.625" height="2.00641" rx="1.0032" fill="#251D38" />
          <rect id="Rectangle 16" x="1.375" y="12.6206" width="5.625" height="2.00641" rx="1.0032" fill="#251D38" />
        </g>
      </svg>
    </IconButton>
  );
};
