import { Empty } from '@bufbuild/protobuf';
import { createPromiseClient } from '@connectrpc/connect';
import { createGrpcWebTransport } from '@connectrpc/connect-web';
import {
  CheckNewNotificationRequest,
  CheckNewNotificationResponse,
  DeleteAllNotificationsRequest,
  DeleteNotificationByIdRequest,
  HasUnreadNotificationsRequest,
  HasUnreadNotificationsResponse,
  MarkAllAsReadRequest,
  MarkNotificationAsReadRequest,
  NotificationService,
  RetrieveNotificationsRequest,
  RetrieveNotificationsResponse
} from '@thrivea/notification-client';
import { msalInstance } from 'src/main';
import { tokenRequest } from 'src/utils';

export const notificationClient = createPromiseClient(
  NotificationService,
  createGrpcWebTransport({
    baseUrl: import.meta.env.VITE_APP_API_URL,
    useBinaryFormat: true
  })
);

export const retrieveNotifications = async (request: RetrieveNotificationsRequest): Promise<RetrieveNotificationsResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await notificationClient.retrieveNotifications(request, { headers: new Headers({ Authorization: `Bearer ${account.accessToken}` }) });
};

export const checkNewNotification = async (request: CheckNewNotificationRequest): Promise<CheckNewNotificationResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await notificationClient.checkNewNotification(request, { headers: new Headers({ Authorization: `Bearer ${account.accessToken}` }) });
};

export const markAllAsRead = async (request: MarkAllAsReadRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await notificationClient.markAllAsRead(request, { headers: new Headers({ Authorization: `Bearer ${account.accessToken}` }) });
};

export const markNotificationAsRead = async (request: MarkNotificationAsReadRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await notificationClient.markNotificationAsRead(request, { headers: new Headers({ Authorization: `Bearer ${account.accessToken}` }) });
};

export const deleteNotificationById = async (request: DeleteNotificationByIdRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await notificationClient.deleteNotificationById(request, { headers: new Headers({ Authorization: `Bearer ${account.accessToken}` }) });
};

export const deleteAllNotifications = async (request: DeleteAllNotificationsRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await notificationClient.deleteAllNotifications(request, { headers: new Headers({ Authorization: `Bearer ${account.accessToken}` }) });
};

export const hasUnreadNotifications = async (request: HasUnreadNotificationsRequest): Promise<HasUnreadNotificationsResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await notificationClient.hasUnreadNotifications(request, { headers: new Headers({ Authorization: `Bearer ${account.accessToken}` }) });
};
