import { alertClasses, Box, CircularProgress, Collapse, collapseClasses, paperClasses, Snackbar, snackbarClasses, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { ActionStatus, RowCenterStack, StyledAlert } from '@/shared';
import {
  closeSnackbar,
  selectIsSnackbarOpen,
  selectSnackbarErrorMessage,
  selectSnackbarInfoMessage,
  selectSnackbarColor,
  selectSnackbarStatus,
  selectSnackbarLoadingMessage
} from '@features/snackbar';

export const GlobalSnackbar = () => {
  const dispatch = useAppDispatch();
  const isSnackbarOpen = useAppSelector(selectIsSnackbarOpen);
  const snackbarColor = useAppSelector(selectSnackbarColor);
  const snackbarErrorMessage = useAppSelector(selectSnackbarErrorMessage);
  const snackbarInfoMessage = useAppSelector(selectSnackbarInfoMessage);
  const snackbarLoadingMessage = useAppSelector(selectSnackbarLoadingMessage);
  const snackbarStatus = useAppSelector(selectSnackbarStatus);

  const handleSnackbarClose = () => {
    dispatch(closeSnackbar());
  };

  return (
    <Snackbar
      open={isSnackbarOpen}
      autoHideDuration={3000}
      onClose={handleSnackbarClose}
      TransitionComponent={Collapse}
      sx={{
        zIndex: (theme) => theme.zIndex.tooltip + 2,
        [`&.${snackbarClasses.root}`]: {
          position: 'sticky',
          top: 0,
          pt: 2,
          backgroundColor: 'transparent'
        },
        [`& .${collapseClasses.root}`]: {
          width: '100%',
          mr: 2,
          mb: 2
        },
        [`& .${paperClasses.root}.${alertClasses.root}`]: {
          pr: 2
        }
      }}
    >
      <Box
        sx={{
          width: '100%'
        }}
      >
        <StyledAlert severity={snackbarColor} onClose={handleSnackbarClose}>
          <RowCenterStack gap={2}>
            {snackbarStatus === ActionStatus.Pending && <CircularProgress size={16} />}
            <Typography
              sx={{
                color: (theme) => theme.palette.common.black
              }}
            >
              {snackbarStatus === ActionStatus.Pending && snackbarLoadingMessage}
              {snackbarStatus === ActionStatus.Idle && snackbarInfoMessage}
              {snackbarStatus === ActionStatus.Failed && (snackbarErrorMessage.rawMessage ?? snackbarErrorMessage)}
            </Typography>
          </RowCenterStack>
        </StyledAlert>
      </Box>
    </Snackbar>
  );
};
