import { MenuItem, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

interface ViewProfileMenuItemProps {
  employeeId: string;
  isDisabled: boolean;
}

export const ViewProfileMenuItem: React.FC<ViewProfileMenuItemProps> = ({ employeeId, isDisabled }) => {
  const { t } = useTranslation(['drawer']);

  return (
    <MenuItem
      disabled={isDisabled}
      sx={{
        padding: 0,
        '&:hover': {
          borderColor: 'transparent',
          backgroundColor: 'transparent'
        },
        '&.Mui-selected': {
          backgroundColor: 'transparent'
        }
      }}
    >
      <Link
        component={RouterLink}
        to={`/profiles/${employeeId}`}
        sx={{
          width: '100%',
          padding: 0,
          textDecoration: 'none'
        }}
      >
        {t('view_profile', { ns: 'drawer' })}
      </Link>
    </MenuItem>
  );
};
