import React from 'react';
import { PreviewModalImage } from '@/shared';
import { useAppSelector } from '@app/hooks';
import { FileWithStatus } from '@api/blob-storage.api';
import { deleteSiteMedia, selectSiteMediaUploadProgress, selectSiteReadSasToken } from '@features/admin-onboarding';

interface PreviewFileModalProps {
  fileWithStatus: FileWithStatus;
}

export const PreviewSiteAdminOnboardingImageModal: React.FC<PreviewFileModalProps> = ({ fileWithStatus }) => {
  const uploadProgress = useAppSelector(selectSiteMediaUploadProgress);
  const siteReadSasToken = useAppSelector(selectSiteReadSasToken);

  return (
    <PreviewModalImage fileWithStatus={fileWithStatus} fileUploadProgress={uploadProgress} deleteFileAction={deleteSiteMedia} sasToken={siteReadSasToken} />
  );
};
