// emojiMartUtils.js

import { Reaction } from '@thrivea/networking-client';
import { getEmojiDataFromNative } from 'emoji-mart';
import { Dictionary } from 'lodash';

export const fetchEmojisWithShortcodes = async (reactionsGroupedByEmoji: Dictionary<Reaction[]>) => {
  const emojis: { [key: string]: string } = {};
  await Promise.all(
    Object.keys(reactionsGroupedByEmoji).map(async (emoji) => {
      const emojiData = await getEmojiDataFromNative(emoji);
      if (emojiData && emojiData.shortcodes && emojiData.shortcodes.length > 0) {
        emojis[emoji] = emojiData.shortcodes;
      }
    })
  );

  return emojis;
};
