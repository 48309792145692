import Quill from 'quill';

const Embed = Quill.import('blots/embed') as any;

class EmojiBlot extends Embed {
  static blotName = 'emoji';
  static tagName = 'em-emoji';

  static create(value: any): HTMLElement {
    const node = super.create();
    const { native, size } = value;
    node.setAttribute('class', 'mention');
    node.setAttribute('loading', 'lazy');
    node.setAttribute('native', native);
    node.setAttribute('skin', '1');
    node.setAttribute('set', 'apple');
    node.setAttribute('size', size);
    return node as HTMLElement;
  }

  static value(node: HTMLElement): any {
    return {
      native: node.getAttribute('alt')!,
      size: parseInt(node.getAttribute('size')!)
    };
  }
}
export default EmojiBlot;
