import React from 'react';

interface ActionsIconProps {
  width?: number;
  height?: number;
  color?: string;
}

export const ActionsIcon: React.FC<ActionsIconProps> = ({ width = 41, height = 25, color = '#FFFFFF' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 41 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="actions_icon">
        <g id="Vector">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.9812 2.14904C13.7824 -0.0496804 10.2176 -0.0496804 8.01884 2.14904L1.64904 8.51884C-0.549681 10.7176 -0.54968 14.2824 1.64904 16.4812L8.01884 22.851C10.2176 25.0497 13.7824 25.0497 15.9812 22.851L16.1314 22.7006L16.2818 22.851C18.4805 25.0497 22.0453 25.0497 24.244 22.851L24.457 22.638L24.6699 22.851C26.8687 25.0497 30.4335 25.0497 32.6322 22.851L39.0021 16.4812C41.2008 14.2824 41.2008 10.7176 39.0021 8.51884L32.6322 2.14904C30.4335 -0.0496798 26.8687 -0.0496798 24.6699 2.14904L24.457 2.36201L24.244 2.14904C22.0453 -0.0496798 18.4805 -0.0496798 16.2818 2.14904L16.1314 2.29934L15.9812 2.14904ZM26.0494 3.95447L30.6138 8.51884C32.8125 10.7176 32.8125 14.2824 30.6138 16.4812L26.0494 21.0455L26.2624 21.2585C27.5816 22.5777 29.7205 22.5777 31.0398 21.2585L37.4096 14.8887C38.7288 13.5695 38.7288 11.4305 37.4096 10.1113L31.0398 3.7415C29.7205 2.42226 27.5816 2.42226 26.2624 3.7415L26.0494 3.95447ZM17.8742 21.2585L17.7239 21.1082L22.351 16.4812C24.5497 14.2824 24.5497 10.7176 22.351 8.51884L17.7239 3.89179L17.8742 3.7415C19.1934 2.42226 21.3323 2.42226 22.6516 3.7415L29.0214 10.1113C30.3406 11.4305 30.3406 13.5695 29.0214 14.8887L22.6516 21.2585C21.3323 22.5777 19.1934 22.5777 17.8742 21.2585ZM9.61134 3.7415C10.9305 2.42226 13.0695 2.42226 14.3887 3.7415L20.7585 10.1113C22.0777 11.4305 22.0777 13.5695 20.7585 14.8887L14.3887 21.2585C13.0695 22.5777 10.9305 22.5777 9.61134 21.2585L3.2415 14.8887C1.92226 13.5695 1.92226 11.4305 3.2415 10.1113L9.61134 3.7415Z"
            fill={color}
          />
          <path d="M12.9996 9.28849L13.8545 5.56716H9.43267L7.31924 13.3753H11.0206L11.6795 20.7687L16.3275 9.28849H12.9996Z" fill={color} />
        </g>
      </g>
    </svg>
  );
};
