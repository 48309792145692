import { Controller, useFormContext } from 'react-hook-form';
import Autocomplete from '@mui/material/Autocomplete';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';
import { ChevronDownIcon, StyledDatePickerFilledInput, StyledEmployeeRecordAutocompleteInput, StyledFilledInput } from '@/shared';
import Grid from '@mui/material/Grid2';
import { Box, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import { ErbFieldTypeCardinality, ErbFieldWidth, ErTemplateField, GenericSetOption } from '@thrivea/organization-client';
import { useAppDispatch } from '@app/hooks';
import { removeFieldFromSection } from '@features/employee-record-builder';

interface EmployeeRecordGenericFieldProps {
  templateField: ErTemplateField;
}

export const EmployeeRecordGenericField: React.FC<EmployeeRecordGenericFieldProps> = ({ templateField }) => {
  const { control } = useFormContext();
  const dispatch = useAppDispatch();

  return (
    <Grid
      size={{
        xs: 12,
        lg:
          templateField.kind.case === 'scalarField' && templateField.kind.value.erbScalarField && templateField.kind.value.width === ErbFieldWidth.FULL
            ? 12
            : templateField.kind.case === 'scalarField' && templateField.kind.value.erbScalarField && templateField.kind.value.width === ErbFieldWidth.HALF
              ? 6
              : 4
      }}
      sx={{ position: 'relative', transition: 'width 0.3s ease-in-out' }}
    >
      <IconButton
        onClick={() => dispatch(removeFieldFromSection(templateField.kind.value!.id))}
        sx={{
          position: 'absolute',
          top: -5.5,
          right: -5,
          backgroundColor: (theme) => theme.palette.common.black,
          borderRadius: '50%',
          width: 18,
          height: 18,
          padding: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1
        }}
      >
        <Close sx={{ fill: (theme) => theme.palette.common.white, fontSize: '1rem' }} />
      </IconButton>

      <Controller
        name={templateField.kind.value!.name}
        control={control}
        render={({ field, fieldState }) => {
          return (
            <>
              {templateField.kind.case === 'scalarField' && templateField.kind.value.erbScalarField?.type?.name === 'Text' && (
                <StyledFilledInput {...field} label={templateField.kind.value!.name} fullWidth />
              )}
              {templateField.kind.case === 'scalarField' && templateField.kind.value.erbScalarField?.type?.name === 'DateTime' && (
                <LocalizationProvider dateAdapter={AdapterLuxon}>
                  <DatePicker
                    {...field}
                    className="MuiDate-root"
                    format="yyyy-MM-dd"
                    value={field.value ? DateTime.fromISO(field.value) : null}
                    disableFuture
                    slots={{ textField: StyledDatePickerFilledInput }}
                    slotProps={{
                      textField: {
                        id: templateField.kind.value!.id,
                        placeholder: 'YYYY-MM-DD',
                        variant: 'filled',
                        label: templateField.kind.value!.name,
                        error: !!fieldState.error,
                        helperText: fieldState.error?.message,
                        required: true,
                        fullWidth: true,
                        InputProps: {
                          disableUnderline: true,
                          required: true,
                          onBlur: field.onBlur
                        },
                        InputLabelProps: {
                          shrink: true
                        }
                      }
                    }}
                    onChange={(date: DateTime | null) => {
                      if (date) {
                        const jsDate = date.toJSDate();
                        const stringDate = DateTime.fromJSDate(jsDate).toFormat('yyyy-MM-dd');
                        field.onChange(stringDate);
                      }
                    }}
                  />
                </LocalizationProvider>
              )}
              {templateField.kind.case === 'scalarField' && templateField.kind.value.erbScalarField?.type?.name === 'List' && (
                <Box
                  sx={{
                    position: 'relative'
                  }}
                >
                  <Autocomplete
                    {...field}
                    disableClearable
                    disablePortal
                    blurOnSelect
                    popupIcon={<ChevronDownIcon />}
                    multiple={templateField.kind.value.erbScalarField!.type!.cardinality === ErbFieldTypeCardinality.MULTIPLE}
                    options={templateField.kind.value.erbScalarField!.type!.options![0].kind.value!.options}
                    getOptionLabel={(option: GenericSetOption) => option.value}
                    onChange={(_event, value) => field.onChange(value)}
                    renderInput={(params) => <StyledEmployeeRecordAutocompleteInput {...params} label={templateField.kind.value!.name} />}
                    slotProps={{
                      paper: {
                        elevation: 0,
                        sx: {
                          padding: 1,
                          borderBottomLeftRadius: 10,
                          borderBottomRightRadius: 10
                        }
                      },
                      popper: {
                        placement: 'bottom',
                        sx: {
                          border: '1px solid',
                          // borderTop: 0,
                          borderColor: (theme) => theme.palette.customTheme.contColor,
                          width: '100% !important',
                          borderBottomLeftRadius: 10,
                          borderBottomRightRadius: 10
                        }
                      }
                    }}
                  />
                </Box>
              )}
            </>
          );
        }}
      />
    </Grid>
  );
};
