import React from 'react';

interface UserIconProps {
  size?: number; // Optional size prop to control the icon size
  color?: string; // Optional color prop to control the icon color
}

export const UserIcon: React.FC<UserIconProps> = ({ size = 24, color = 'black' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" fill="none" viewBox="0 0 24 24" width={size} height={size}>
      <defs>
        <path
          id="reuse-0"
          d="M22 22.128v-2.23c0-1.182-.527-2.316-1.465-3.152-.937-.836-2.209-1.306-3.535-1.306H7c-1.326 0-2.598.47-3.536 1.306C2.527 17.582 2 18.716 2 19.899v2.23"
        />
        <path id="reuse-1" d="M12 10.663c2.761 0 5-2.139 5-4.777 0-2.639-2.239-4.777-5-4.777S7 3.247 7 5.886c0 2.638 2.239 4.777 5 4.777Z" />
      </defs>
      <g stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" clipPath="url(#a)">
        <use xlinkHref="#reuse-0" />
        <use xlinkHref="#reuse-0" strokeOpacity=".2" />
        <use xlinkHref="#reuse-1" />
        <use xlinkHref="#reuse-1" strokeOpacity=".2" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#FFFFFF" d="M0 .153h24v22.93H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
