import React from 'react';

interface CircleIconProps {
  size?: number; // Optional size prop to control the icon size
  color?: string; // Optional color prop to control the icon color
}

export const CircleIcon: React.FC<CircleIconProps> = ({ size = 4, color = 'black' }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 3 4" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="1.5" cy="2" r="1.5" fill={color} />
    </svg>
  );
};
