import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  FileItem,
  RetrieveFilesRequest,
  RetrieveFilesResponse,
  RetrieveTopLevelFoldersRequest,
  RetrieveTopLevelFoldersResponse,
  TopLevelFolder,
  UploadFilesRequest
} from '@thrivea/organization-client';
import { RootState } from 'src/app/store';
import { ActionStatus } from 'src/shared';

export interface AssetsState {
  entities: {
    files: {
      byId: { [key: string]: FileItem };
      allIds: string[];
    };
    folders: {
      byId: { [key: string]: TopLevelFolder };
      allIds: string[];
    };
  };
  ui: {
    retrieveFilesStatus: ActionStatus;
    retrieveTopLevelFoldersStatus: ActionStatus;
    uploadFilesStatus: ActionStatus;
    filesTotalCount: number;
    foldersTotalCount: number;
  };
}

const initialState: AssetsState = {
  entities: {
    files: {
      byId: {},
      allIds: []
    },
    folders: {
      byId: {},
      allIds: []
    }
  },
  ui: {
    retrieveFilesStatus: ActionStatus.Idle,
    retrieveTopLevelFoldersStatus: ActionStatus.Idle,
    uploadFilesStatus: ActionStatus.Idle,
    filesTotalCount: 0,
    foldersTotalCount: 0
  }
};

export const assetSlice = createSlice({
  name: 'assets',
  initialState,
  reducers: {
    retrieveFilesRequested: (state, action: PayloadAction<RetrieveFilesRequest>) => {
      state.ui.retrieveFilesStatus = ActionStatus.Pending;
    },
    retrieveFilesSucceeded: (state, action: PayloadAction<RetrieveFilesResponse>) => {
      const { files, totalCount } = action.payload;

      for (const file of files) {
        state.entities.files.byId[file.id] = file;
        state.entities.files.allIds.push(file.id);
      }

      state.ui.retrieveFilesStatus = ActionStatus.Idle;
      state.ui.filesTotalCount = totalCount;
    },
    retrieveFilesFailed: (state) => {
      state.ui.retrieveFilesStatus = ActionStatus.Failed;
    },
    retrieveTopLevelFoldersRequested: (state, action: PayloadAction<RetrieveTopLevelFoldersRequest>) => {
      state.ui.retrieveTopLevelFoldersStatus = ActionStatus.Pending;
    },
    retrieveTopLevelFoldersSucceeded: (state, action: PayloadAction<RetrieveTopLevelFoldersResponse>) => {
      const { folders, totalCount } = action.payload;

      for (const folder of folders) {
        state.entities.folders.byId[folder.id] = folder;
        state.entities.folders.allIds.push(folder.id);
      }

      state.ui.retrieveTopLevelFoldersStatus = ActionStatus.Idle;
      state.ui.foldersTotalCount = totalCount;
    },
    retrieveTopLevelFoldersFailed: (state) => {
      state.ui.retrieveTopLevelFoldersStatus = ActionStatus.Failed;
    },
    uploadFilesRequested: (state, action: PayloadAction<UploadFilesRequest>) => {
      state.ui.uploadFilesStatus = ActionStatus.Pending;
    },
    uploadFilesSucceded: (state) => {
      state.ui.uploadFilesStatus = ActionStatus.Idle;
    },
    uploadFilesFailed: (state) => {
      state.ui.uploadFilesStatus = ActionStatus.Failed;
    }
  }
});

export const selectFileById = (state: RootState) => state.assets.entities.files.byId;
export const selectFileIds = (state: RootState) => state.assets.entities.files.allIds;
export const selectFilesTotalCount = (state: RootState) => state.assets.ui.filesTotalCount;
export const selectRetrieveFilesStatus = (state: RootState) => state.assets.ui.retrieveFilesStatus;

export const selectFolderById = (state: RootState) => state.assets.entities.folders.byId;
export const selectFolderIds = (state: RootState) => state.assets.entities.folders.allIds;
export const selectFoldersTotalCount = (state: RootState) => state.assets.ui.foldersTotalCount;
export const selectRetrieveTopLevelFoldersStatus = (state: RootState) => state.assets.ui.retrieveTopLevelFoldersStatus;

export const selectUploadFilesStatus = (state: RootState) => state.assets.ui.uploadFilesStatus;

export const {
  retrieveFilesRequested,
  retrieveFilesSucceeded,
  retrieveFilesFailed,
  retrieveTopLevelFoldersRequested,
  retrieveTopLevelFoldersSucceeded,
  retrieveTopLevelFoldersFailed,
  uploadFilesRequested,
  uploadFilesSucceded,
  uploadFilesFailed
} = assetSlice.actions;
export default assetSlice.reducer;
