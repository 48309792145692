import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '@app/store';

interface NavigationState {
  isNavigationDrawerOpen: boolean;
  drawerWidth: number;
}

const initialState: NavigationState = {
  isNavigationDrawerOpen: false,
  drawerWidth: 227
};

export const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    openNavigationDrawer: (state) => {
      state.isNavigationDrawerOpen = !state.isNavigationDrawerOpen;
    }
  }
});

export const { openNavigationDrawer } = navigationSlice.actions;

export const selectNavigationIsDesktopOpen = (state: RootState) => state.navigation.isNavigationDrawerOpen;
export const selectNavigationWidth = (state: RootState) => state.navigation.drawerWidth;

export default navigationSlice.reducer;
