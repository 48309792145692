import React from 'react';
import { selectPostReactionsGroupedByEmoji, BasePostReactions } from '@features/homepage';
import { useAppSelector } from '@app/hooks';

interface PostReactionsProps {
  postId: string;
}

export const PostReactions: React.FC<PostReactionsProps> = ({ postId }) => {
  const groupedReactionsDict = useAppSelector((state) => selectPostReactionsGroupedByEmoji(state, postId));
  return <BasePostReactions postId={postId} reactionsGroupedByEmoji={groupedReactionsDict} />;
};
