import React from 'react';

interface RenderEmojiProps {
  native: string;
}

export const RenderEmoji: React.FC<RenderEmojiProps> = ({ native }) => {
  // @ts-expect-error Emoji mart somehow handles this component itself and typescript doesn't know about it so it's not too happy.
  return <em-emoji loading="lazy" native={native} skin="1" set="apple" size={16} />;
};
