import { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { RetrieveSiteListItemByIdRequest, RetrieveSitesByWorkingPatternIdRequest } from '@thrivea/organization-client';
import { Avatar, Button, Stack, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import {
  retrieveSiteListItemByIdRequested,
  retrieveSitesByWorkingPatternIdRequested,
  selectCurrentWorkingPatternId,
  selectCurrentWorkingPatternName,
  selectSiteReadSasToken,
  selectWorkingPatternCurrentSitesPage,
  selectWorkingPatternHasMoreSites,
  selectWorkingPatternSitesList,
  selectWorkingPatternSitesListIds,
  selectWorkingPatternSitesListStatus
} from '@/features/admin-settings';
import { ActionStatus, AlertIcon, PictureSizeSuffix, RowCenterStack, StyledFixedWidthTypography, StyledProfileLink } from '@/shared';
import { DrawerItemSkeleton, DrawerType, openDrawer } from '@features/drawer';
import { pictureUrl } from '@/utils/pictureUrl';

export const WorkingPatternDrawerSitesList = () => {
  const { t } = useTranslation(['common']);
  const dispatch = useAppDispatch();
  const currentWpName = useAppSelector(selectCurrentWorkingPatternName);
  const currentWpId = useAppSelector(selectCurrentWorkingPatternId);
  const sasToken = useAppSelector(selectSiteReadSasToken);
  const workingPatternSitesList = useAppSelector(selectWorkingPatternSitesList);
  const workingPatternSitesListIds = useAppSelector(selectWorkingPatternSitesListIds);
  const workingPatternSitesArray = workingPatternSitesListIds.map((id) => workingPatternSitesList[id]);
  const workingPatternSitesListStatus = useAppSelector(selectWorkingPatternSitesListStatus);
  const workingPatternHasMoreSites = useAppSelector(selectWorkingPatternHasMoreSites);
  const workingPatternCurrentSitesPage = useAppSelector(selectWorkingPatternCurrentSitesPage);
  const observer = useRef<IntersectionObserver>();
  const bottomElementRef = useRef<HTMLDivElement>(null);

  const handleRetrieveSiteListItemById = (siteId: string) => {
    dispatch(
      retrieveSiteListItemByIdRequested(
        new RetrieveSiteListItemByIdRequest({
          siteId
        })
      )
    );
    dispatch(openDrawer(DrawerType.Site));
  };

  const handleScroll = useCallback(() => {
    if (workingPatternHasMoreSites) {
      dispatch(
        retrieveSitesByWorkingPatternIdRequested(
          new RetrieveSitesByWorkingPatternIdRequest({
            workingPatternId: currentWpId,
            pageNumber: workingPatternCurrentSitesPage + 1,
            pageSize: 10
          })
        )
      );
    }
  }, [workingPatternHasMoreSites, workingPatternCurrentSitesPage]);

  useEffect(() => {
    observer.current = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && workingPatternSitesListStatus === ActionStatus.Idle) {
          handleScroll();
        }
      },
      { threshold: 1 } // Trigger the observer when the target is 100% visible
    );

    // Start observing the target element
    if (bottomElementRef.current && observer.current) {
      observer.current.observe(bottomElementRef.current);
    }

    return () => {
      // Clean up the observer and scroll event listener when the component unmounts
      if (observer.current && bottomElementRef.current) {
        observer.current.disconnect();
      }
    };
  }, [handleScroll]);

  return (
    <Stack
      spacing={1}
      sx={{
        margin: '16px auto',
        padding: '16px',
        backgroundColor: (theme) => theme.palette.background.paper,
        width: '100%',
        height: '100%',
        borderRadius: (theme) => theme.spacing(2)
      }}
    >
      {workingPatternSitesArray.length === 0 && (
        <>
          <RowCenterStack gap={1}>
            <AlertIcon />
            <Typography>{t('working_pattern_no_sites', { ns: 'drawer', currentWpName })}</Typography>
          </RowCenterStack>
        </>
      )}
      {workingPatternSitesListIds.length !== 0 &&
        workingPatternSitesArray.map((wpsl) => (
          <Stack
            key={wpsl.id}
            direction="row"
            spacing={2}
            sx={{
              borderRadius: '8px',
              padding: '8px',
              transition: 'background-color 0.3s ease-in-out',
              '&:hover': {
                backgroundColor: (theme) => theme.palette.common.white,
                '& .Mui-ProfileLink': {
                  opacity: 1
                }
              }
            }}
          >
            <Avatar
              src={wpsl.coverImageUrl ? pictureUrl(wpsl.coverImageUrl, sasToken, PictureSizeSuffix.sm) : '/images/org-site-placeholder.png'}
              alt={wpsl.name}
              variant="rounded"
              sx={{
                border: (theme) => `1px solid ${theme.palette.customTheme.contColor}`,
                width: 38,
                height: 38
              }}
            />
            <Stack
              direction="row"
              sx={{
                width: '100%',
                justifyContent: 'space-between'
              }}
            >
              <Stack>
                <Typography fontWeight={700}>{wpsl.name}</Typography>
                <StyledFixedWidthTypography sx={{ maxWidth: 150 }}>
                  {wpsl.address}, {wpsl.city}, {wpsl.country}
                </StyledFixedWidthTypography>
              </Stack>
              <StyledProfileLink onClick={() => handleRetrieveSiteListItemById(wpsl.id)} className="Mui-ProfileLink" variant="text">
                {t('view_site', { ns: 'common' })}
              </StyledProfileLink>
            </Stack>
          </Stack>
        ))}
      <div ref={bottomElementRef} style={{ width: '100%', height: '1px' }}></div>
      {workingPatternSitesListStatus === ActionStatus.Pending && Array.from({ length: 6 }).map(() => <DrawerItemSkeleton key={crypto.randomUUID()} />)}
    </Stack>
  );
};
