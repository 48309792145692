import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { Empty } from '@bufbuild/protobuf';
import { RetrieveUserResponse } from '@thrivea/organization-client';
import { retrieveUserFailed, retrieveUserRequested, retrieveUserSucceeded } from '@app/user';
import { retrieveUser } from '@api/organization.api';
import { setCurrentAuthor } from '@features/homepage';
import * as Sentry from '@sentry/react';

function* retrieveUserGenerator() {
  try {
    const user: RetrieveUserResponse = yield call(retrieveUser, new Empty());
    yield put(retrieveUserSucceeded(user));
    yield put(setCurrentAuthor(user.id));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveUserFailed());
  }
}

function* retrieveUserWatcher() {
  yield takeLatest(retrieveUserRequested.type, retrieveUserGenerator);
}

export function* userSagas() {
  yield fork(retrieveUserWatcher);
}
