import { useCallback, useEffect } from 'react';
import { UseFormTrigger, FieldValues, Path } from 'react-hook-form';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from '@app/store';
import debounce from 'lodash/debounce';

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useDebounceValidate = <TFieldValues extends FieldValues>(trigger: UseFormTrigger<TFieldValues>, delay: number) => {
  const debounceValidate = useCallback(
    debounce((field: Path<TFieldValues>) => {
      trigger(field);
    }, delay),
    [trigger, delay]
  );

  useEffect(() => {
    return () => {
      debounceValidate.cancel();
    };
  }, [debounceValidate]);

  return debounceValidate;
};
