import { useTheme } from '@mui/material';
import React from 'react';

interface ReactionIconAttributes {
  size?: number; // Optional size prop to control the icon size
  color?: string; // Optional color prop to control the icon color
}

export const ReactionIcon: React.FC<ReactionIconAttributes> = ({ size = 16, color = 'black' }: ReactionIconAttributes) => {
  return (
    <svg width={size} height={size} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Vector">
        <path
          d="M8 0C3.58544 0 0 3.58477 0 7.99967C0 12.4144 3.58462 16 8 16C12.4146 16 16 12.4152 16 7.99967C16 3.58493 12.4154 0 8 0ZM8 14.8381C4.23234 14.8381 1.16185 11.7673 1.16185 7.99967C1.16185 4.23251 4.2325 1.16189 8 1.16189C11.7677 1.16189 14.8382 4.23267 14.8382 8.00033C14.8382 11.7675 11.7675 14.8381 8 14.8381Z"
          fill={color}
        />
        <path
          d="M12.7138 8.48101C12.3986 8.43094 12.0995 8.63056 12.0501 8.94572C11.7183 10.8873 10.0084 12.2981 8.00064 12.2981C5.97567 12.2981 4.26633 10.8539 3.95118 8.86224C3.90112 8.54708 3.60266 8.33141 3.28751 8.38084C2.97236 8.43091 2.7567 8.72938 2.80613 9.04454C3.22075 11.6005 5.41195 13.4433 8.01793 13.4433C10.5904 13.4433 12.7651 11.6339 13.1964 9.12801C13.2445 8.82955 13.0289 8.53108 12.7138 8.48101Z"
          fill={color}
        />
        <path
          d="M4.82994 6.45661C4.88 6.48998 4.92942 6.52272 4.99617 6.53941C5.04624 6.5561 5.11234 6.57279 5.16241 6.58948C5.22851 6.60616 5.27858 6.60616 5.34469 6.60616C5.4108 6.60616 5.46087 6.60616 5.52698 6.58948C5.59309 6.57279 5.64315 6.5561 5.69321 6.53941C5.74328 6.52272 5.7927 6.48934 5.84276 6.45661C5.89283 6.42323 5.94225 6.3905 5.97562 6.34043C6.009 6.29036 6.05842 6.25763 6.0918 6.20756C6.12517 6.1575 6.15791 6.10807 6.1746 6.04132C6.19128 5.99126 6.20797 5.92515 6.22466 5.87508C6.24135 5.80897 6.24135 5.7589 6.24135 5.69279C6.24135 5.62667 6.24135 5.57661 6.22466 5.51049C6.20797 5.44438 6.19128 5.39432 6.1746 5.34425C6.15791 5.29419 6.12453 5.22807 6.0918 5.17801C6.05842 5.12795 6.02569 5.07852 5.97562 5.04515C5.80939 4.8789 5.57704 4.77941 5.32865 4.77941C5.07962 4.77941 4.84726 4.8789 4.68167 5.04515C4.51544 5.21139 4.41595 5.44375 4.41595 5.69215C4.41595 5.92451 4.51543 6.17355 4.68167 6.33915C4.74714 6.37317 4.78052 6.42323 4.82994 6.45661Z"
          fill={color}
        />
        <path
          d="M9.8094 6.04121C9.82609 6.09128 9.85946 6.15739 9.8922 6.20745C9.92557 6.25752 9.95831 6.30694 10.0084 6.34032C10.0417 6.37369 10.0912 6.42312 10.1412 6.4565C10.1913 6.48987 10.2407 6.52261 10.3075 6.5393C10.3575 6.55599 10.4236 6.57267 10.4737 6.58936C10.5238 6.60605 10.5899 6.60605 10.656 6.60605C10.7221 6.60605 10.7722 6.60605 10.8383 6.58936C10.9044 6.57268 10.9544 6.55599 11.0045 6.5393C11.0546 6.52261 11.104 6.48923 11.1707 6.4565C11.2208 6.42312 11.2702 6.39038 11.3036 6.34032C11.337 6.29025 11.3864 6.25752 11.4198 6.20745C11.4531 6.15739 11.4859 6.10796 11.5026 6.04121C11.5193 5.99115 11.5359 5.92503 11.5526 5.87497C11.5693 5.80886 11.5693 5.75879 11.5693 5.69268C11.5693 5.62656 11.5693 5.5765 11.5526 5.51038C11.5359 5.44427 11.5193 5.3942 11.5026 5.34414C11.4859 5.29407 11.4525 5.22796 11.4198 5.1779C11.3864 5.12783 11.3537 5.07841 11.3036 5.04503C11.2535 5.01166 11.2208 4.96223 11.1707 4.92886C11.1207 4.89548 11.0713 4.86274 11.0045 4.84605C10.9544 4.82937 10.8883 4.81268 10.8383 4.79599C10.7221 4.7793 10.6059 4.7793 10.4898 4.79599C10.4236 4.81268 10.3736 4.82936 10.3235 4.84605C10.2735 4.86274 10.2073 4.89612 10.1573 4.92886C10.1072 4.96223 10.0578 4.99497 10.0244 5.04503C9.99169 5.0951 9.92558 5.14517 9.8922 5.19459C9.85883 5.24466 9.82609 5.29408 9.80941 5.36083C9.79272 5.4109 9.77603 5.47701 9.75934 5.52707C9.74265 5.59318 9.74265 5.64325 9.74265 5.70937C9.74265 5.77548 9.74265 5.82554 9.75934 5.89166C9.77603 5.95842 9.79271 5.99179 9.8094 6.04121Z"
          fill={color}
        />
      </g>
    </svg>
  );
};
