import { useTheme } from '@mui/material';
import React from 'react';

interface TalkIconAttributes {
  className?: string;
}

export const TalkIcon: React.FC<TalkIconAttributes> = ({ className = '' }: TalkIconAttributes) => {
  const theme = useTheme();

  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 25 24">
      <path
        fill={theme.palette.common.black}
        d="M23 3a.75.75 0 0 0-.75.75v.893L2.75 8.82v-.57a.75.75 0 0 0-1.5 0v7.5a.75.75 0 1 0 1.5 0v-.57l3.75.81v.51a4.5 4.5 0 0 0 8.775 1.365l6.975 1.5v.885a.75.75 0 1 0 1.5 0V3.75A.75.75 0 0 0 23 3ZM11 19.5a3 3 0 0 1-3-3 1.724 1.724 0 0 1 0-.195l5.783 1.245A3 3 0 0 1 11 19.5Zm-8.25-5.857v-3.286l19.5-4.177v11.64l-19.5-4.178Z"
      />
    </svg>
  );
};
