import { ForwardedRef, forwardRef, useEffect, useId, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import {
  createAboutSchema,
  selectAboutCategory,
  selectFoodPreferences,
  selectHobbies,
  selectProfileNotification,
  selectSuperpowers,
  updateAboutRequested
} from '@features/employee-profile';
import { Controller, useForm } from 'react-hook-form';
import { Box, Chip, CircularProgress, IconButton, ListItem, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { About, UpdateAboutRequest } from '@thrivea/organization-client';
import { useTranslation } from 'react-i18next';
import {
  RowCenterStack,
  VisuallyHidden,
  EditIcon,
  CancelEditIcon,
  ChevronDownIcon,
  StyledFormWrapper,
  StyledFormHeader,
  StyledCancelButton,
  StyledFormSubmitButton,
  StyledFilledInput,
  SocialLinkInput,
  StyledAutocomplete,
  ActionStatus
} from '@/shared';
import { zodResolver } from '@hookform/resolvers/zod';

import Facebook from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { snakeCase } from 'lodash';
import { GroupPermissions, ProfileCategoryInfo } from '@features/abac';
import { AllowedTo } from 'react-abac';

interface AboutCategoryProps {
  ref: ForwardedRef<HTMLDivElement>;
  employeeId: string;
}

export const AboutCategory = forwardRef<HTMLDivElement, AboutCategoryProps>(({ employeeId }, ref) => {
  const { t } = useTranslation(['common', 'employee_profile']);
  const name = t('about', { ns: 'employee_profile' });
  const id = useId();
  const dispatch = useAppDispatch();
  const aboutCategory = useAppSelector(selectAboutCategory);
  const { status, category } = useAppSelector(selectProfileNotification);
  const isPendingAndMatchingName = status === ActionStatus.Pending && category === 'about';
  const superpowers = useAppSelector(selectSuperpowers);
  const hobbies = useAppSelector(selectHobbies);
  const foodPreferences = useAppSelector(selectFoodPreferences);
  const [isEditable, setIsEditable] = useState(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [isModalListOpen, setIsModalListOpen] = useState(false);
  const aboutSchema = useMemo(() => createAboutSchema(t), [t]);
  const {
    formState: { dirtyFields, errors },
    control,
    reset,
    handleSubmit,
    getValues
  } = useForm<About>({
    mode: 'all',
    resolver: zodResolver(aboutSchema),
    defaultValues: {
      about: aboutCategory.about,
      facebookProfileUrl: aboutCategory.facebookProfileUrl,
      xProfileUrl: aboutCategory.xProfileUrl,
      linkedinProfileUrl: aboutCategory.linkedinProfileUrl,
      superPowerIds: aboutCategory.superPowerIds,
      hobbyIds: aboutCategory.hobbyIds,
      foodPreferenceIds: aboutCategory.foodPreferenceIds
    }
  });

  const handleNotificationOpen = () => setIsNotificationOpen(true);

  const handleCloseEditable = () => {
    setIsEditable(false);
    reset({
      about: aboutCategory.about,
      facebookProfileUrl: aboutCategory.facebookProfileUrl,
      xProfileUrl: aboutCategory.xProfileUrl,
      linkedinProfileUrl: aboutCategory.linkedinProfileUrl,
      superPowerIds: aboutCategory.superPowerIds,
      hobbyIds: aboutCategory.hobbyIds,
      foodPreferenceIds: aboutCategory.foodPreferenceIds
    });
  };

  const handleToggleEditable = () => {
    setIsEditable(!isEditable);
  };

  const onSubmit = (data: About) => {
    dispatch(
      updateAboutRequested({
        employeeId: employeeId,
        about: data.about,
        facebookProfileUrl: data.facebookProfileUrl,
        xProfileUrl: data.xProfileUrl,
        linkedinProfileUrl: data.linkedinProfileUrl,
        hobbyIds: data.hobbyIds,
        foodPreferenceIds: data.foodPreferenceIds,
        superPowerIds: data.superPowerIds
      } as UpdateAboutRequest)
    );
    handleNotificationOpen();
    setIsEditable(false);
  };

  useEffect(() => {
    reset({
      about: aboutCategory.about,
      facebookProfileUrl: aboutCategory.facebookProfileUrl,
      xProfileUrl: aboutCategory.xProfileUrl,
      linkedinProfileUrl: aboutCategory.linkedinProfileUrl,
      superPowerIds: aboutCategory.superPowerIds,
      hobbyIds: aboutCategory.hobbyIds,
      foodPreferenceIds: aboutCategory.foodPreferenceIds
    });
  }, [aboutCategory]);

  return (
    <StyledFormWrapper isEditable={isEditable} id={snakeCase(name)} ref={ref}>
      <Box
        component="form"
        name={name}
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          backgroundColor: 'transparent'
        }}
      >
        <Stack>
          <StyledFormHeader isEditable={isEditable} className="Mui-ProfileFiledHeader">
            <RowCenterStack gap={1}>
              {isPendingAndMatchingName && <CircularProgress size={24} thickness={4} />}
              <Typography component="h3" variant="h5" fontWeight={700}>
                {name}
              </Typography>
            </RowCenterStack>
            <AllowedTo perform={GroupPermissions.EDIT_PROFILE} data={{ employeeId, categoryName: 'about' } as ProfileCategoryInfo}>
              <IconButton
                className="Mui-Edit"
                onClick={handleToggleEditable}
                sx={{
                  opacity: '0',
                  display: isEditable ? 'none' : 'inline-flex'
                }}
              >
                <VisuallyHidden>Edit {name}</VisuallyHidden>
                <EditIcon />
              </IconButton>
            </AllowedTo>
            <RowCenterStack
              gap={2}
              sx={{
                display: isEditable ? 'flex' : 'none'
              }}
            >
              <StyledCancelButton variant="contained" onClick={handleCloseEditable} startIcon={<CancelEditIcon />}>
                {t('cancel', { ns: 'common' })}
              </StyledCancelButton>
              <StyledFormSubmitButton
                type="submit"
                disabled={Object.values(dirtyFields).length === 0 || Object.values(errors).length !== 0}
                variant="contained"
              >
                {t('done', { ns: 'common' })}
              </StyledFormSubmitButton>
            </RowCenterStack>
          </StyledFormHeader>
          <Grid
            container
            spacing={2}
            sx={{
              padding: 2
            }}
          >
            <Grid size={{ xs: 12, sm: 6, lg: isEditable ? 12 : 4 }}>
              <Controller
                name="about"
                control={control}
                render={({ field, fieldState }) => (
                  <StyledFilledInput
                    {...field}
                    label={t('about', { ns: 'employee_profile' })}
                    multiline
                    minRows={isEditable ? 4 : 1}
                    id="about"
                    disabled={!isEditable}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    onChange={(e) => field.onChange(e)}
                  />
                )}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
              <Controller
                name="facebookProfileUrl"
                control={control}
                render={({ field, fieldState }) => (
                  <SocialLinkInput
                    field={field}
                    isEditable={isEditable}
                    fieldState={fieldState}
                    predefinedPart="https://www.facebook.com/"
                    label={t('social_facebook', { ns: 'employee_profile' })}
                    icon={<Facebook />}
                  />
                )}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
              <Controller
                name="xProfileUrl"
                control={control}
                render={({ field, fieldState }) => (
                  <SocialLinkInput
                    field={field}
                    isEditable={isEditable}
                    fieldState={fieldState}
                    predefinedPart="https://twitter.com/"
                    label={t('social_x', { ns: 'employee_profile' })}
                    icon={<XIcon />}
                  />
                )}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
              <Controller
                name="linkedinProfileUrl"
                control={control}
                render={({ field, fieldState }) => (
                  <SocialLinkInput
                    field={field}
                    isEditable={isEditable}
                    fieldState={fieldState}
                    predefinedPart="https://www.linkedin.com/in/"
                    label={t('social_linkedin', { ns: 'employee_profile' })}
                    icon={<LinkedInIcon />}
                  />
                )}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6, lg: isEditable ? 12 : 4 }}>
              <Stack gap={4}>
                <Controller
                  key={name}
                  name="superPowerIds"
                  control={control}
                  render={({ field, fieldState }) => (
                    <StyledAutocomplete
                      multiple
                      disableClearable
                      value={superpowers.filter((option) => getValues(field.name).includes(option.id))}
                      disabled={!isEditable}
                      popupIcon={<ChevronDownIcon />}
                      limitTags={isEditable ? -1 : 1}
                      options={superpowers}
                      getOptionLabel={(option) => option.name}
                      onChange={(_, values) => {
                        field.onChange(values.map((option) => option.id));
                      }}
                      renderInput={(params) => (
                        <StyledFilledInput
                          {...params}
                          id={id + field.name}
                          label={t(snakeCase(field.name), { ns: 'employee_profile' })}
                          disabled={!isEditable}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                      renderTags={(tagValue, getTagProps) => {
                        return tagValue.map((option, index) => <Chip {...getTagProps({ index })} key={id + option.name} label={option.name} />);
                      }}
                      renderOption={(props, option) => {
                        return (
                          <ListItem {...props} key={id + option.name}>
                            {option.name}
                          </ListItem>
                        );
                      }}
                    />
                  )}
                />
              </Stack>
            </Grid>
            <Grid size={{ xs: 12, sm: 6, lg: isEditable ? 12 : 4 }}>
              <Stack gap={4}>
                <Controller
                  key={name}
                  name="hobbyIds"
                  control={control}
                  render={({ field, fieldState }) => (
                    <StyledAutocomplete
                      multiple
                      limitTags={isEditable ? -1 : 1}
                      disableClearable
                      value={hobbies.filter((option) => getValues(field.name).includes(option.id))}
                      disabled={!isEditable}
                      popupIcon={<ChevronDownIcon />}
                      options={hobbies}
                      getOptionLabel={(option) => option.name}
                      onChange={(_, values) => {
                        field.onChange(values.map((option) => option.id));
                      }}
                      renderInput={(params) => (
                        <StyledFilledInput
                          {...params}
                          id={id + field.name}
                          label={t(snakeCase(field.name), { ns: 'employee_profile' })}
                          disabled={!isEditable}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                      renderTags={(tagValue, getTagProps) => {
                        return tagValue.map((option, index) => <Chip {...getTagProps({ index })} key={id + option.name} label={option.name} />);
                      }}
                      renderOption={(props, option) => {
                        return (
                          <ListItem {...props} key={id + option.name}>
                            {option.name}
                          </ListItem>
                        );
                      }}
                    />
                  )}
                />
              </Stack>
            </Grid>
            <Grid size={{ xs: 12, sm: 6, lg: isEditable ? 12 : 4 }}>
              <Stack gap={4}>
                <Controller
                  name="foodPreferenceIds"
                  control={control}
                  render={({ field, fieldState }) => (
                    <StyledAutocomplete
                      multiple
                      limitTags={isEditable ? -1 : 1}
                      disableClearable
                      value={foodPreferences.filter((option) => getValues(field.name).includes(option.id))}
                      disabled={!isEditable}
                      popupIcon={<ChevronDownIcon />}
                      options={foodPreferences}
                      getOptionLabel={(option) => option.name}
                      onChange={(_, values) => {
                        field.onChange(values.map((option) => option.id));
                      }}
                      renderInput={(params) => (
                        <StyledFilledInput
                          {...params}
                          id={id + field.name}
                          label={t(snakeCase(field.name), { ns: 'employee_profile' })}
                          disabled={!isEditable}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                      renderTags={(tagValue, getTagProps) => {
                        return tagValue.map((option, index) => <Chip {...getTagProps({ index })} key={id + option.name} label={option.name} />);
                      }}
                      renderOption={(props, option) => {
                        return (
                          <ListItem {...props} key={id + option.name}>
                            {option.name}
                          </ListItem>
                        );
                      }}
                    />
                  )}
                />
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </Box>
    </StyledFormWrapper>
  );
});
