export enum ActionStatus {
  Idle,
  Pending,
  Failed
}

export enum PictureSizeSuffix {
  sm = '_sm',
  md = '_md',
  lg = '_lg'
}

export type Order = 'asc' | 'desc';
