import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import {
  ErbField,
  ErbFieldCategories,
  ErbFieldCategory,
  ErTemplate,
  ErTemplateSection,
  RetrieveDefaultErTemplateSectionRequest,
  RetrieveErbFieldsByCategoryIdRequest,
  RetrieveErbFieldsByCategoryIdResponse,
  RetrieveErTemplateRequest,
  SaveErTemplateSectionRequest,
  DeleteErTemplateSectionRequest,
  ErTemplateField,
  ErbFieldWidth
} from '@thrivea/organization-client';
import { RootState } from '@app/store';
import { ActionStatus } from '@/shared';
import { orderBy } from 'lodash';

export interface EmployeeRecordState {
  entities: {
    erbFieldCategories: {
      byId: { [key: string]: ErbFieldCategory };
      allIds: string[];
    };
    erTemplateFields: {
      byId: { [key: string]: ErTemplateField };
      allIds: string[];
    };
    erbFields: {
      byId: { [key: string]: ErbField };
      allIds: string[];
    };
  };
  ui: {
    retrieveErbFieldCategoriesAndSectionsStatus: ActionStatus;
    retrieveDefaultErTemplateSectionStatus: ActionStatus;
    saveErTemplateSectionStatus: ActionStatus;
    retrieveErTemplateStatus: ActionStatus;
    retrieveErbFieldsByCategoryIdStatus: ActionStatus;
    deleteErbTemplateSectionStatus: ActionStatus;
  };
  defaultErTemplateSection: ErTemplateSection;
  erTemplate: ErTemplate;
}

const initialState: EmployeeRecordState = {
  entities: {
    erbFieldCategories: {
      byId: {},
      allIds: []
    },
    erTemplateFields: {
      byId: {},
      allIds: []
    },
    erbFields: {
      byId: {},
      allIds: []
    }
  },
  ui: {
    retrieveErbFieldCategoriesAndSectionsStatus: ActionStatus.Idle,
    retrieveDefaultErTemplateSectionStatus: ActionStatus.Idle,
    saveErTemplateSectionStatus: ActionStatus.Idle,
    retrieveErTemplateStatus: ActionStatus.Idle,
    retrieveErbFieldsByCategoryIdStatus: ActionStatus.Idle,
    deleteErbTemplateSectionStatus: ActionStatus.Idle
  },
  defaultErTemplateSection: {} as ErTemplateSection,
  erTemplate: {} as ErTemplate
};

export const employeeRecordSlice = createSlice({
  name: 'employeeRecord',
  initialState,
  reducers: {
    retrieveErbFieldCategoriesAndSectionsRequested: (state) => {
      state.ui.retrieveErbFieldCategoriesAndSectionsStatus = ActionStatus.Pending;
    },
    retrieveErbFieldCategoriesAndSectionsSucceeded: (state, action: PayloadAction<ErbFieldCategories>) => {
      const { categories } = action.payload;

      for (const category of categories) {
        state.entities.erbFieldCategories.byId[category.id] = category;
        state.entities.erbFieldCategories.allIds.push(category.id);
      }

      state.ui.retrieveErbFieldCategoriesAndSectionsStatus = ActionStatus.Idle;
    },
    retrieveErbFieldCategoriesAndSectionsFailed: (state) => {
      state.ui.retrieveErbFieldCategoriesAndSectionsStatus = ActionStatus.Failed;
    },
    retrieveDefaultErTemplateSectionRequested: (state, action: PayloadAction<RetrieveDefaultErTemplateSectionRequest>) => {
      state.ui.retrieveDefaultErTemplateSectionStatus = ActionStatus.Pending;
    },
    retrieveDefaultErTemplateSectionSucceeded: (state, action: PayloadAction<ErTemplateSection>) => {
      state.defaultErTemplateSection = action.payload;
      state.ui.retrieveDefaultErTemplateSectionStatus = ActionStatus.Idle;
    },
    retrieveDefaultErTemplateSectionFailed: (state) => {
      state.ui.retrieveDefaultErTemplateSectionStatus = ActionStatus.Failed;
    },
    saveErTemplateSectionRequested: (state, _action: PayloadAction<SaveErTemplateSectionRequest>) => {
      state.ui.saveErTemplateSectionStatus = ActionStatus.Pending;
    },
    saveErTemplateSectionSucceeded: (state) => {
      state.ui.saveErTemplateSectionStatus = ActionStatus.Idle;
    },
    saveErTemplateSectionFailed: (state) => {
      state.ui.saveErTemplateSectionStatus = ActionStatus.Failed;
    },
    retrieveErTemplateRequested: (state, _action: PayloadAction<RetrieveErTemplateRequest>) => {
      state.ui.retrieveErTemplateStatus = ActionStatus.Pending;
    },
    retrieveErTemplateSucceeded: (state, action: PayloadAction<ErTemplate>) => {
      state.erTemplate = action.payload;
      state.ui.retrieveErTemplateStatus = ActionStatus.Idle;
    },
    retrieveErTemplateFailed: (state) => {
      state.ui.retrieveErTemplateStatus = ActionStatus.Failed;
    },
    retrieveErbFieldsByCategoryIdRequested: (state, _action: PayloadAction<RetrieveErbFieldsByCategoryIdRequest>) => {
      state.ui.retrieveErbFieldsByCategoryIdStatus = ActionStatus.Pending;
    },
    retrieveErbFieldsByCategoryIdSucceeded: (state, action: PayloadAction<RetrieveErbFieldsByCategoryIdResponse>) => {
      const { erbFields } = action.payload;

      for (const field of erbFields) {
        state.entities.erbFields.byId[field.kind.value!.id] = field;
        state.entities.erbFields.allIds.push(field.kind.value!.id);
      }

      state.ui.retrieveErbFieldsByCategoryIdStatus = ActionStatus.Idle;
    },
    retrieveErbFieldsByCategoryIdFailed: (state) => {
      state.ui.retrieveErbFieldsByCategoryIdStatus = ActionStatus.Failed;
    },
    deleteErbTemplateSectionRequested: (state, action: PayloadAction<DeleteErTemplateSectionRequest>) => {
      state.ui.deleteErbTemplateSectionStatus = ActionStatus.Pending;
    },
    deleteErbTemplateSectionSucceeded: (state) => {
      state.ui.deleteErbTemplateSectionStatus = ActionStatus.Idle;
    },
    deleteErbTemplateSectionFailed: (state) => {
      state.ui.deleteErbTemplateSectionStatus = ActionStatus.Failed;
    },
    addFieldToSection: (state, action: PayloadAction<ErTemplateField>) => {
      const field = action.payload;
      if (!state.entities.erTemplateFields.allIds.includes(field.kind.value!.id)) {
        state.entities.erTemplateFields.byId[field.kind.value!.id] = field;
        state.entities.erTemplateFields.allIds.push(field.kind.value!.id);
      }
    },
    updateSectionField: (state, action: PayloadAction<ErTemplateField[]>) => {
      state.entities.erTemplateFields.byId = {};
      state.entities.erTemplateFields.allIds = [];
      for (const field of action.payload) {
        state.entities.erTemplateFields.byId[field.kind.value!.id] = field;
        state.entities.erTemplateFields.allIds.push(field.kind.value!.id);
      }
    },
    removeFieldFromSection: (state, action: PayloadAction<string>) => {
      const fieldId = action.payload;

      if (state.entities.erTemplateFields.allIds.includes(fieldId)) {
        delete state.entities.erTemplateFields.byId[fieldId];
        state.entities.erTemplateFields.allIds = state.entities.erTemplateFields.allIds.filter((id) => id !== fieldId);
      }
    },
    updateField: (state, action: PayloadAction<{ id: string; width: ErbFieldWidth }>) => {
      const { id, width } = action.payload;
      if (state.entities.erTemplateFields.byId[id].kind.case === 'scalarField') {
        state.entities.erTemplateFields.byId[id].kind.value!.width = width;
      }
    }
  }
});

export const selectErbFieldCategoriesById = (state: RootState) => state.employeeRecord.entities.erbFieldCategories.byId;
export const selectErbFieldCategoriesIds = (state: RootState) => state.employeeRecord.entities.erbFieldCategories.allIds;
export const selectErbFieldCategoriesStatus = (state: RootState) => state.employeeRecord.ui.retrieveErbFieldCategoriesAndSectionsStatus;
export const selectErbFieldCategories = createSelector([selectErbFieldCategoriesById, selectErbFieldCategoriesIds], (byId, ids) => ids.map((id) => byId[id]));
export const selectErbFieldSectionsByCategoryId = (state: RootState, categoryId: string) => {
  if (state.employeeRecord.entities.erbFieldCategories.byId.hasOwnProperty(categoryId))
    return state.employeeRecord.entities.erbFieldCategories.byId[categoryId].sections;
};

export const selectErbFieldById = (state: RootState) => state.employeeRecord.entities.erbFields.byId;
export const selectErbFieldIds = (state: RootState) => state.employeeRecord.entities.erbFields.allIds;
export const selectErbScalarFields = createSelector([selectErbFieldById, selectErbFieldIds], (byId, ids) => ids.map((id) => byId[id]));

export const selectRetrieveErbFieldsBySectionIdStatus = (state: RootState) => state.employeeRecord.ui.retrieveErbFieldsByCategoryIdStatus;

export const selectDefaultErTemplateSection = (state: RootState) => state.employeeRecord.defaultErTemplateSection;
export const selectRetrieveDefaultErTemplateSectionStatus = (state: RootState) => state.employeeRecord.ui.retrieveDefaultErTemplateSectionStatus;

const selectErTemplate = (state: RootState) => state.employeeRecord.erTemplate;
export const selectOrderedErTemplateSections = createSelector([selectErTemplate], (template) => orderBy(template.sections, ['position']));

export const selectRetrieveErTemplateStatus = (state: RootState) => state.employeeRecord.ui.retrieveErTemplateStatus;

export const selectSaveErTemplateSectionStatus = (state: RootState) => state.employeeRecord.ui.saveErTemplateSectionStatus;
export const selectDeleteErbTemplateSectionRequested = (state: RootState) => state.employeeRecord.ui.deleteErbTemplateSectionStatus;

export const selectErTemplateFieldByIds = (state: RootState) => state.employeeRecord.entities.erTemplateFields.byId;
export const selectErTemplateFieldIds = (state: RootState) => state.employeeRecord.entities.erTemplateFields.allIds;
export const selectErTemplateFields = createSelector([selectErTemplateFieldByIds, selectErTemplateFieldIds], (byId, ids) => ids.map((id) => byId[id]));
export const selectErTemplateFieldPositions = createSelector([selectErTemplateFieldByIds, selectErTemplateFieldIds], (byId, ids) =>
  ids.map((id) => byId[id]).map((field) => field.kind.value!.position)
);

export const {
  retrieveErbFieldCategoriesAndSectionsRequested,
  retrieveErbFieldCategoriesAndSectionsSucceeded,
  retrieveErbFieldCategoriesAndSectionsFailed,
  retrieveDefaultErTemplateSectionRequested,
  retrieveDefaultErTemplateSectionSucceeded,
  retrieveDefaultErTemplateSectionFailed,
  saveErTemplateSectionRequested,
  saveErTemplateSectionSucceeded,
  saveErTemplateSectionFailed,
  retrieveErTemplateRequested,
  retrieveErTemplateSucceeded,
  retrieveErTemplateFailed,
  retrieveErbFieldsByCategoryIdRequested,
  retrieveErbFieldsByCategoryIdSucceeded,
  retrieveErbFieldsByCategoryIdFailed,
  deleteErbTemplateSectionRequested,
  deleteErbTemplateSectionSucceeded,
  deleteErbTemplateSectionFailed,
  addFieldToSection,
  updateSectionField,
  removeFieldFromSection,
  updateField
} = employeeRecordSlice.actions;
export default employeeRecordSlice.reducer;
