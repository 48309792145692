import { Box, Typography, alpha } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface AvailableBetaTagProps {
  top?: number;
  right?: number;
}

export const AvailableBetaTag: React.FC<AvailableBetaTagProps> = ({ top = -8, right = -4 }) => {
  const { t } = useTranslation(['common']);

  return (
    <Box
      sx={{
        padding: '3px 7px',
        borderRadius: '7px',
        backgroundColor: (theme) => alpha(theme.palette.customTheme.focusItem, 0.1),
        opacity: 1,
        position: 'absolute',
        top,
        right,
        zIndex: 1
      }}
    >
      <Typography
        sx={{
          color: (theme) => theme.palette.customTheme.focusItem,
          fontWeight: 500
        }}
      >
        {t('beta_tag_label', { ns: 'common' })}
      </Typography>
    </Box>
  );
};
