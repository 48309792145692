import React from 'react';

interface BinIconProps {
  size?: number; // Optional size prop to control the icon size
  color?: string; // Optional color prop to control the icon color
}

export const BinIcon: React.FC<BinIconProps> = ({ size = 24, color = '#000000' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
      <path
        fill={color}
        fillOpacity=".75"
        stroke={color}
        strokeOpacity=".75"
        strokeWidth=".5"
        d="M19.792 5.881h-3.914A2.925 2.925 0 0 0 13 3.408a2.925 2.925 0 0 0-2.878 2.473H6.208a1.288 1.288 0 0 0-1.206 1.356v2.522a.472.472 0 0 0 .474.479h.977V21.89c0 1.19.954 2.155 2.133 2.155h8.828a2.145 2.145 0 0 0 2.133-2.155V10.238h.977a.472.472 0 0 0 .474-.48V7.238a1.288 1.288 0 0 0-1.206-1.356ZM13 4.368c.906 0 1.695.625 1.913 1.513h-3.826A1.976 1.976 0 0 1 13 4.368Zm5.6 17.522c0 .318-.126.622-.348.847-.222.224-.523.35-.838.35H8.586A1.192 1.192 0 0 1 7.4 21.89V10.238h11.2V21.89Zm1.45-12.61H5.95V7.237c0-.227.137-.398.258-.398h13.584c.12 0 .259.17.259.398V9.28ZM9.919 21.167v-9.008c0-.265.212-.479.475-.479.261 0 .473.214.473.48v9.007a.477.477 0 0 1-.473.478.477.477 0 0 1-.475-.478Zm2.607 0 .001-9.008c0-.265.212-.479.474-.479s.474.214.474.48v9.007a.476.476 0 0 1-.474.478.477.477 0 0 1-.475-.478Zm2.608 0v-9.008c0-.265.213-.479.475-.479s.474.214.474.48v9.007a.477.477 0 0 1-.475.478.478.478 0 0 1-.474-.478Z"
      />
    </svg>
  );
};
