import React from 'react';

interface LinkIconProps {
  width?: number;
  height?: number;
  color?: string;
}

export const LinkIcon: React.FC<LinkIconProps> = ({ width: width = 24, height = 25, color = '#251D38' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none">
      <path
        d="M12.2791 19.4767C12.5873 19.1684 12.5873 18.6687 12.2791 18.3604C11.9708 18.0522 11.471 18.0522 11.1628 18.3604L9.76744 19.7558C8.3803 21.1429 6.13133 21.1429 4.74419 19.7558C3.35704 18.3686 3.35704 16.1197 4.74419 14.7325L8.65116 10.8255C10.0383 9.43839 12.2873 9.43839 13.6744 10.8255C14.0415 11.1926 14.3104 11.6183 14.4828 12.0716C14.6378 12.4791 15.0938 12.6838 15.5012 12.5288C15.9086 12.3738 16.1133 11.9178 15.9583 11.5104C15.7082 10.8529 15.3184 10.237 14.7907 9.70926C12.7871 7.70562 9.53852 7.70562 7.53488 9.70926L3.62791 13.6162C1.62427 15.6199 1.62427 18.8684 3.62791 20.872C5.63154 22.8757 8.88008 22.8757 10.8837 20.872L12.2791 19.4767Z"
        fill={color}
        stroke={color}
      />
      <path
        d="M12.7216 5.52306C12.4133 5.83132 12.4133 6.33108 12.7216 6.63934C13.0298 6.9476 13.5296 6.9476 13.8378 6.63934L15.2332 5.24399C16.6203 3.85685 18.8693 3.85685 20.2564 5.24399C21.6436 6.63114 21.6436 8.8801 20.2564 10.2672L16.3495 14.1742C14.9623 15.5614 12.7134 15.5614 11.3262 14.1742C10.9591 13.8071 10.6902 13.3815 10.5178 12.9281C10.3628 12.5207 9.90687 12.316 9.49943 12.471C9.09198 12.626 8.88731 13.0819 9.04231 13.4894C9.29238 14.1469 9.68222 14.7628 10.2099 15.2905C12.2136 17.2941 15.4621 17.2941 17.4658 15.2905L21.3727 11.3835C23.3764 9.37989 23.3764 6.13135 21.3727 4.12772C19.3691 2.12408 16.1206 2.12408 14.1169 4.12772L12.7216 5.52306Z"
        fill={color}
        stroke={color}
      />
    </svg>
  );
};
