import React from 'react';

interface TwoColumnIconProps {
  width?: number;
  height?: number;
  color?: string;
}

export const TwoColumnIcon: React.FC<TwoColumnIconProps> = ({ width = 18, height = 8, color = '#251D38' }: TwoColumnIconProps) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path id="Vector" d="M0.5 1H7.5M10.5 1H17.5M0.5 7H7.5M10.5 7H17.5" stroke={color} strokeLinecap="round" />
    </svg>
  );
};
