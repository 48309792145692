import { Empty } from '@bufbuild/protobuf';
import { createPromiseClient } from '@connectrpc/connect';
import { createGrpcWebTransport } from '@connectrpc/connect-web';
import { OnboardingService, CreateTenantRequest } from '@thrivea/admin-onboarding-client';

export const adminClient = createPromiseClient(
  OnboardingService,
  createGrpcWebTransport({
    baseUrl: import.meta.env.VITE_ADMIN_API_URL,
    useBinaryFormat: true
  })
);

export const createTenant = async (request: CreateTenantRequest): Promise<Empty> => {
  return await adminClient.createTenant(request);
};
