import { Stack, Skeleton } from '@mui/material';
import { RowCenterStack } from '@/shared';

export const AudienceDrawerSkeleton = () => {
  return (
    <Stack
      gap={2}
      sx={{
        margin: '16px auto',
        padding: '24px 20px',
        backgroundColor: (theme) => theme.palette.grey[800],
        width: 'calc(100% - 32px)',
        height: '100%',
        borderRadius: 4
      }}
    >
      <Skeleton width={50} variant="text" />
      <Skeleton width={100} variant="text" />
      {Array.from({ length: 12 }).map((_, index) => (
        <RowCenterStack key={index} gap={1}>
          <Skeleton width={38} height={38} variant="rounded" />
          <Stack gap={1}>
            <Skeleton width={150} height={20} />
            <Skeleton width={50} height={10} />
          </Stack>
          <Skeleton width={100} height={25} variant="rounded" sx={{ ml: 'auto' }} />
        </RowCenterStack>
      ))}
    </Stack>
  );
};
