import React from 'react';
import { useTheme } from '@mui/material';

interface BenefitsIconAttributes {
  className?: string;
}

export const BenefitsIcon: React.FC<BenefitsIconAttributes> = ({ className = '' }: BenefitsIconAttributes) => {
  const theme = useTheme();

  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill={theme.palette.customTheme.contColor} viewBox="0 0 25 24">
      <path
        fill="#000"
        d="M21.5 6.012h-2.49c.163-.252.287-.528.367-.817a2.887 2.887 0 0 0-.33-2.25 3 3 0 0 0-4.095-1.043c-.832.48-1.822 2.123-2.452 3.3-.63-1.177-1.62-2.82-2.453-3.3A3 3 0 0 0 5.952 2.96a2.888 2.888 0 0 0-.33 2.25 3 3 0 0 0 .368.802H3.5a2.25 2.25 0 0 0-2.25 2.25v1.5a2.25 2.25 0 0 0 1.5 2.115v8.385A2.25 2.25 0 0 0 5 22.512h15a2.25 2.25 0 0 0 2.25-2.25v-8.385a2.25 2.25 0 0 0 1.5-2.115v-1.5a2.25 2.25 0 0 0-2.25-2.25Zm-5.797-2.797a1.5 1.5 0 0 1 2.047.547 1.417 1.417 0 0 1-.094 1.603 1.456 1.456 0 0 1-.431.377 7.282 7.282 0 0 1-2.475.27h-.967a9.66 9.66 0 0 1 1.92-2.797ZM7.76 5.712a1.455 1.455 0 0 1-.683-.892 1.418 1.418 0 0 1 .173-1.058 1.5 1.5 0 0 1 1.297-.75c.264.004.522.073.75.203a9.66 9.66 0 0 1 1.92 2.797h-.967a7.282 7.282 0 0 1-2.49-.3Zm-5.01 2.55a.75.75 0 0 1 .75-.75h8.25v3H3.5a.75.75 0 0 1-.75-.75v-1.5Zm1.5 12V12.11h7.5v8.902H5a.75.75 0 0 1-.75-.75Zm16.5 0a.75.75 0 0 1-.75.75h-6.75V12.11h7.5v8.152Zm1.5-10.5a.75.75 0 0 1-.75.75h-8.25v-3h8.25a.75.75 0 0 1 .75.75v1.5Z"
      />
    </svg>
  );
};
