import React from 'react';
import Grid from '@mui/material/Grid2';
import { PrimaryTypographyHeader, RowCenterStack, StyledFilledInput, StyledFormWrapper } from '@/shared';
import { useTranslation } from 'react-i18next';
import { LockOutlined } from '@mui/icons-material';

export const EmployeeRecordCard: React.FC = () => {
  const { t } = useTranslation(['common', 'employee_profile', 'employment_contract_type']);

  return (
    <StyledFormWrapper id="overview" sx={{ margin: 0 }}>
      <RowCenterStack
        sx={{
          padding: (theme) => theme.spacing(2),
          backgroundColor: (theme) => theme.palette.customTheme.headerColorSub,
          justifyContent: 'space-between'
        }}
      >
        <PrimaryTypographyHeader>Employee Record Card</PrimaryTypographyHeader>
        <LockOutlined />
      </RowCenterStack>
      <Grid
        container
        spacing={2}
        sx={{
          padding: 2
        }}
      >
        <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
          <StyledFilledInput disabled id="employee_id" label={t('employee_id_label', { ns: 'employee_profile' })} />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
          <StyledFilledInput disabled id="first_name" label={t('first_name', { ns: 'employee_profile' })} />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
          <StyledFilledInput disabled id="last_name" label={t('last_name', { ns: 'employee_profile' })} />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
          <StyledFilledInput disabled id="job_title" label={t('job_title', { ns: 'employee_profile' })} />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
          <StyledFilledInput disabled id="email" label={t('email', { ns: 'employee_profile' })} />
        </Grid>
      </Grid>
    </StyledFormWrapper>
  );
};
