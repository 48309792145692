import { store } from '@app/store';
import { siteFileUploaded, updateSiteMediaUploadProgress, uploadSiteFile } from '@features/admin-onboarding';
import { FileWithStatus, UploadedFile } from '@api/blob-storage.api';

export const handleUpdateSiteMediaUploadProgress = (fileProgress: number): void => {
  store.dispatch(updateSiteMediaUploadProgress(fileProgress));
};

export const handleStartUploadingSiteMedia = (fileWithStatus: FileWithStatus): void => {
  store.dispatch(uploadSiteFile(fileWithStatus));
};

export const handleSiteMediaUploaded = (uploadedFile: UploadedFile): void => {
  store.dispatch(siteFileUploaded(uploadedFile));
};
