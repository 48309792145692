// ImageCropper.tsx
import React, { useRef, useEffect } from 'react';
import Cropper from 'cropperjs';
import { Box } from '@mui/material';
import { RowCenterStack, StyledCancelButton, StyledFormSubmitButton, CancelEditIcon } from '@/shared';
import { useTranslation } from 'react-i18next';
import 'cropperjs/dist/cropper.css';
import * as Sentry from '@sentry/react';

export interface MultiSizeImageBlobs {
  sm: Blob;
  md: Blob;
  lg: Blob;
  og: File;
}

interface ImageCropperProps {
  src: File;
  aspectRatio?: number;
  autoCropArea?: number;
  onCrop: (croppedDataBlobs: MultiSizeImageBlobs) => void;
  handleModalClose: () => void;
  sm?: number;
  md?: number;
  lg?: number;
}

const ImageCropper: React.FC<ImageCropperProps> = ({ src, aspectRatio = 16 / 9, autoCropArea = 0.65, onCrop, handleModalClose, sm, md, lg }) => {
  const { t } = useTranslation(['common']);
  const imageRef = useRef<HTMLImageElement>(null);
  const cropperRef = useRef<Cropper>();

  useEffect(() => {
    if (imageRef.current) {
      cropperRef.current = new Cropper(imageRef.current, {
        dragMode: 'move',
        viewMode: 1,
        aspectRatio,
        autoCropArea,
        restore: true,
        guides: false,
        center: true,
        highlight: false,
        cropBoxMovable: false,
        cropBoxResizable: false,
        toggleDragModeOnDblclick: false,
        autoCrop: true
      });
    }

    return () => {
      cropperRef.current?.destroy();
    };
  }, [src, aspectRatio, autoCropArea]);

  const handleCrop = () => {
    const canvas = cropperRef.current?.getCroppedCanvas();
    if (canvas) {
      const canvasSm = cropperRef.current!.getCroppedCanvas({ width: sm });
      const canvasMd = cropperRef.current!.getCroppedCanvas({ width: md });
      const canvasLg = cropperRef.current!.getCroppedCanvas({ width: lg });

      const toBlobAsync = async (canvas: HTMLCanvasElement, type: string = 'image/webp'): Promise<Blob> => {
        return new Promise((resolve, reject) => {
          canvas.toBlob((blob: Blob | null) => {
            if (blob) {
              resolve(blob);
            } else {
              reject(new Error('Blob creation failed'));
            }
          }, type);
        });
      };

      const createBlob = async (canvas: HTMLCanvasElement | null): Promise<Blob> => {
        if (!canvas) throw new Error('Canvas is null');

        return await toBlobAsync(canvas);
      };

      const processBlobs = async (): Promise<void> => {
        try {
          const [blobSm, blobMd, blobLg] = await Promise.all([createBlob(canvasSm), createBlob(canvasMd), createBlob(canvasLg)]);

          const croppedDataBlobs: MultiSizeImageBlobs = {
            sm: blobSm as Blob,
            md: blobMd as Blob,
            lg: blobLg as Blob,
            og: src
          };

          onCrop(croppedDataBlobs);
        } catch (error) {
          Sentry.captureException(error);
        }
      };

      processBlobs();
    }
  };

  return (
    <Box component="div" className="img-container">
      <img ref={imageRef} src={URL.createObjectURL(src)} alt="Source" />
      <RowCenterStack
        gap={1}
        sx={{
          justifyContent: 'flex-end',
          p: 1,
          backgroundColor: (theme) => theme.palette.common.white
        }}
      >
        <StyledCancelButton
          variant="contained"
          onClick={handleModalClose}
          startIcon={<CancelEditIcon />}
          sx={{
            ml: '80%',
            my: 1
          }}
        >
          {t('cancel', { ns: 'common' })}
        </StyledCancelButton>
        <StyledFormSubmitButton onClick={handleCrop} variant="contained">
          Crop
        </StyledFormSubmitButton>
      </RowCenterStack>
    </Box>
  );
};

export default ImageCropper;
