import { Avatar, Box, Stack, Typography } from '@mui/material';
import { pictureUrl } from '@/utils';
import { PictureSizeSuffix, PrimaryTypographyBodyBold, RowCenterStack } from '@/shared';
import { selectEmployeeProfileAndCoverReadSasToken } from '@features/employee-profile';
import { useAppSelector } from '@app/hooks';

export const AssetsActivityList = () => {
  const employeeProfileSasToken = useAppSelector(selectEmployeeProfileAndCoverReadSasToken);

  return (
    <Stack gap={2}>
      <Stack gap={2}>
        <Box
          sx={{
            pl: 2
          }}
        >
          <Typography variant="h4">Today</Typography>
        </Box>
        <Stack gap={1}>
          <RowCenterStack gap={2}>
            <Avatar
              src={pictureUrl(
                'https://stthriveadev001.blob.core.windows.net/data/b897e4ba-4348-4fee-ad3f-880582cdab3d/profile/019006af-dbb6-4614-ac4b-fbc200efebef/pictures/0ac3650e-2b7e-4f4e-a260-670728e800f7.jpeg',
                employeeProfileSasToken,
                PictureSizeSuffix.sm
              )}
              alt={'Stevo'}
              variant="rounded"
              sx={{
                border: (theme) => `1px solid ${theme.palette.customTheme.contColor}`
              }}
            />
            <Stack>
              <PrimaryTypographyBodyBold>Stevo Cvijetic</PrimaryTypographyBodyBold>
              <Typography variant="body2">09:08 AM Sept 12</Typography>
            </Stack>
          </RowCenterStack>
          <Stack>
            <PrimaryTypographyBodyBold>Stevo Cvijetic</PrimaryTypographyBodyBold>
            <Typography variant="body2">Created and shared an item</Typography>
          </Stack>
        </Stack>
        <Stack gap={1}>
          <RowCenterStack gap={2}>
            <Avatar
              src={pictureUrl(
                'https://stthriveadev001.blob.core.windows.net/data/b897e4ba-4348-4fee-ad3f-880582cdab3d/profile/019006e9-60ea-4320-b95b-47f906b65265/pictures/cbd9bed0-5f66-49a1-a2c0-58dd6bd1d979.jpeg',
                employeeProfileSasToken,
                PictureSizeSuffix.sm
              )}
              alt={'Marko'}
              variant="rounded"
              sx={{
                border: (theme) => `1px solid ${theme.palette.customTheme.contColor}`
              }}
            />
            <Stack>
              <PrimaryTypographyBodyBold>Marko Markovic</PrimaryTypographyBodyBold>
              <Typography variant="body2">09:08 AM Sept 12</Typography>
            </Stack>
          </RowCenterStack>
          <Stack>
            <PrimaryTypographyBodyBold>Marko Markovic</PrimaryTypographyBodyBold>
            <Typography variant="body2">Shared this document</Typography>
          </Stack>
        </Stack>
      </Stack>
      <Stack gap={2}>
        <Box
          sx={{
            pl: 2
          }}
        >
          <Typography variant="h4">Yesterday</Typography>
        </Box>
        <Stack gap={1}>
          <RowCenterStack gap={2}>
            <Avatar
              src={pictureUrl(
                'https://stthriveadev001.blob.core.windows.net/data/b897e4ba-4348-4fee-ad3f-880582cdab3d/profile/019006af-dbb6-4614-ac4b-fbc200efebef/pictures/0ac3650e-2b7e-4f4e-a260-670728e800f7.jpeg',
                employeeProfileSasToken,
                PictureSizeSuffix.sm
              )}
              alt={'Stevo'}
              variant="rounded"
              sx={{
                border: (theme) => `1px solid ${theme.palette.customTheme.contColor}`
              }}
            />
            <Stack>
              <PrimaryTypographyBodyBold>Stevo Cvijetic</PrimaryTypographyBodyBold>
              <Typography variant="body2">09:08 AM Sept 12</Typography>
            </Stack>
          </RowCenterStack>
          <Stack>
            <PrimaryTypographyBodyBold>Stevo Cvijetic</PrimaryTypographyBodyBold>
            <Typography variant="body2">Created and shared an item</Typography>
          </Stack>
        </Stack>
        <Stack gap={1}>
          <RowCenterStack gap={2}>
            <Avatar
              src={pictureUrl(
                'https://stthriveadev001.blob.core.windows.net/data/b897e4ba-4348-4fee-ad3f-880582cdab3d/profile/019006e9-60ea-4320-b95b-47f906b65265/pictures/cbd9bed0-5f66-49a1-a2c0-58dd6bd1d979.jpeg',
                employeeProfileSasToken,
                PictureSizeSuffix.sm
              )}
              alt={'Marko'}
              variant="rounded"
              sx={{
                border: (theme) => `1px solid ${theme.palette.customTheme.contColor}`
              }}
            />
            <Stack>
              <PrimaryTypographyBodyBold>Marko Markovic</PrimaryTypographyBodyBold>
              <Typography variant="body2">09:08 AM Sept 12</Typography>
            </Stack>
          </RowCenterStack>
          <Stack>
            <PrimaryTypographyBodyBold>Marko Markovic</PrimaryTypographyBodyBold>
            <Typography variant="body2">Shared this document</Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
