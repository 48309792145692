import React, { ChangeEvent, Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Paper,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  debounce,
  tableCellClasses
} from '@mui/material';
import Search from '@mui/icons-material/Search';
import {
  ActionStatus,
  CancelEditIcon,
  HighlightText,
  RowCenterStack,
  StyledBlurryModal,
  StyledCancelButton,
  StyledFormHeader,
  StyledFormSubmitButton,
  StyledModalContent,
  StyledSearch,
  StyledSettingsContainer,
  StyledTableCell
} from '@/shared';
import {
  AdminSettingsWorkingPatternAdd,
  WorkingPatternActions,
  deleteWorkingPatternByIdRequested,
  retrieveEmployeesByWorkingPatternIdRequested,
  retrieveSitesByWorkingPatternIdRequested,
  retrieveWorkingPatternItemsRequested,
  selectTotalNumberOfWorkingPatterns,
  selectWorkingPatternListItems,
  selectWorkingPatternListItemsIds,
  selectWorkingPatternListItemsStatus,
  setCurrentWorkingPatternDrawer,
  selectHasMoreWorkingPatternListItems,
  selectCurrentWorkingPatternListItemPage
} from '@features/admin-settings';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import {
  DeleteWorkingPatternByIdRequest,
  OrderDirection,
  RetrieveEmployeesByWorkingPatternIdRequest,
  RetrieveSitesByWorkingPatternIdRequest,
  RetrieveWorkingPatternListItemsRequest,
  WorkingPatternListColumnName
} from '@thrivea/organization-client';
import { DrawerType, openDrawer } from '@features/drawer';

const workingPatternListColumns = Object.keys(WorkingPatternListColumnName)
  .filter((key) => !isNaN(Number(key)))
  .slice(1) // remove first option
  .map((key) => ({
    id: parseInt(key), // deduct by 1 as we are removing first option
    name: WorkingPatternListColumnName[Number(key)].toLowerCase()
  }));

export const AdminSettingsWorkingPatternsLibrary = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['common', 'settings_working_patterns']);
  const workingPatternItems = useAppSelector(selectWorkingPatternListItems);
  const workingPatternItemsIds = useAppSelector(selectWorkingPatternListItemsIds);
  const workingPatternListItemsStatus = useAppSelector(selectWorkingPatternListItemsStatus);
  const hasMoreWorkingPatterns = useAppSelector(selectHasMoreWorkingPatternListItems);
  const currentWorkingPatternListItemPage = useAppSelector(selectCurrentWorkingPatternListItemPage);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [activeAddWorkingPatternModal, setActiveAddWorkingPatternModal] = useState(false);
  const [search, setSearch] = useState('');
  const workingPatternArray = workingPatternItemsIds.map((id) => workingPatternItems[id]);
  const [activeColumn, setActiveColumn] = useState(null);
  const [openMenu, setOpenMenu] = useState<string | null>(null);
  const [sortOrder, setSortOrder] = useState(OrderDirection.ASCENDING);
  const nameColumn = workingPatternListColumns.find((wplc) => wplc.name === 'working_pattern_name')!;
  const weeklyTotalHoursColumn = workingPatternListColumns.find((wplc) => wplc.name === 'working_pattern_weekly_working_hours')!;
  const totalNumberOfWorkingPatterns = useAppSelector(selectTotalNumberOfWorkingPatterns);
  const observer = useRef<IntersectionObserver>();
  const bottomElementRef = useRef<HTMLDivElement>(null);

  const handleScroll = useCallback(() => {
    if (hasMoreWorkingPatterns) {
      dispatch(
        retrieveWorkingPatternItemsRequested(
          new RetrieveWorkingPatternListItemsRequest({
            pageNumber: currentWorkingPatternListItemPage + 1,
            pageSize: 10,
            orderDirection: sortOrder,
            searchText: search
          })
        )
      );
    }
  }, [hasMoreWorkingPatterns, currentWorkingPatternListItemPage]);

  useEffect(() => {
    observer.current = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && workingPatternListItemsStatus === ActionStatus.Idle) {
          handleScroll();
        }
      },
      { threshold: 1 } // Trigger the observer when the target is 100% visible
    );

    // Start observing the target element
    if (bottomElementRef.current && observer.current) {
      observer.current.observe(bottomElementRef.current);
    }

    return () => {
      // Clean up the observer and scroll event listener when the component unmounts
      if (observer.current && bottomElementRef.current) {
        observer.current.disconnect();
      }
    };
  }, [handleScroll]);

  useEffect(() => {
    if (workingPatternArray.length === 0) {
      dispatch(
        retrieveWorkingPatternItemsRequested(
          new RetrieveWorkingPatternListItemsRequest({
            searchText: search,
            pageNumber: 1,
            pageSize: 10
          })
        )
      );
    }
  }, []);

  const toggleMenu = (menuName: string) => {
    setOpenMenu(openMenu === menuName ? null : menuName);
  };

  const closeMenu = (menuName: string) => {
    if (openMenu === menuName) {
      setOpenMenu(null);
    }
  };

  const handleOpenAddWorkingPatternModal = () => {
    setActiveAddWorkingPatternModal(true);
  };

  const handleCloseAddWorkingPatternModal = () => {
    setActiveAddWorkingPatternModal(false);
  };

  const handleSearchWorkingPatterns = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearch(e.target.value);
    dispatch(
      retrieveWorkingPatternItemsRequested(
        new RetrieveWorkingPatternListItemsRequest({
          searchText: e.target.value,
          pageNumber: 1,
          pageSize: 10
        })
      )
    );
  };

  const debouncedSearch = debounce(handleSearchWorkingPatterns, 500);

  const handleDeleteWorkingPatternById = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, wpId: string) => {
    e.stopPropagation();
    dispatch(
      deleteWorkingPatternByIdRequested(
        new DeleteWorkingPatternByIdRequest({
          workingPatternId: wpId
        })
      )
    );
    setIsDeleteModalOpen(false);
  };

  const handleShowWorkingPattern = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, wpId: string, wpName: string) => {
    e.stopPropagation();
    dispatch(
      retrieveEmployeesByWorkingPatternIdRequested(
        new RetrieveEmployeesByWorkingPatternIdRequest({
          workingPatternId: wpId,
          pageNumber: 1,
          pageSize: 10
        })
      )
    );
    dispatch(
      retrieveSitesByWorkingPatternIdRequested(
        new RetrieveSitesByWorkingPatternIdRequest({
          workingPatternId: wpId,
          pageNumber: 1,
          pageSize: 10
        })
      )
    );
    dispatch(
      setCurrentWorkingPatternDrawer({
        id: wpId,
        name: wpName
      })
    );
    dispatch(openDrawer(DrawerType.WorkingPattern));
  };

  const handleDeleteModalClose = () => {
    setIsDeleteModalOpen(false);
  };

  const handleRequestSort = (column) => {
    setSortOrder(sortOrder === OrderDirection.ASCENDING ? OrderDirection.DESCENDING : OrderDirection.ASCENDING);
    setActiveColumn(column.name);
    // Use column.id for orderBy because the state of orderByColumn variable wont be updated in time
    dispatch(
      retrieveWorkingPatternItemsRequested(
        new RetrieveWorkingPatternListItemsRequest({
          pageNumber: 1,
          pageSize: 10,
          orderBy: column.id,
          orderDirection: sortOrder,
          searchText: search
        })
      )
    );
  };

  return (
    <StyledSettingsContainer>
      <StyledFormHeader>
        <Typography variant="h4">{t('title', { ns: 'settings_working_patterns' })}</Typography>
      </StyledFormHeader>
      <RowCenterStack
        sx={{
          justifyContent: 'space-between',
          backgroundColor: (theme) => theme.palette.customTheme.drawerBackground,
          p: 2,
          flexWrap: 'wrap',
          gap: 1
        }}
      >
        <RowCenterStack gap={1}>
          <Button variant="contained" onClick={handleOpenAddWorkingPatternModal}>
            + {t('btn_txt', { ns: 'settings_working_patterns' })}
          </Button>
          <Typography>
            {t('total', { ns: 'common' })}: {totalNumberOfWorkingPatterns}
          </Typography>
        </RowCenterStack>
        <Box>
          <StyledSearch
            placeholder={t('search', { ns: 'common' })}
            InputProps={{
              startAdornment: <Search />
            }}
            onChange={debouncedSearch}
          />
        </Box>
      </RowCenterStack>
      <TableContainer component={Paper} elevation={0}>
        <Table
          sx={{
            minWidth: 760
          }}
        >
          <Fragment>
            <TableHead>
              <TableRow
                sx={{
                  [`& .${tableCellClasses.root}`]: {
                    backgroundColor: (theme) => theme.palette.common.white,
                    padding: '10px 30px',
                    height: 58,
                    border: 0
                  }
                }}
              >
                <StyledTableCell
                  sortDirection={activeColumn === nameColumn.name ? (sortOrder === OrderDirection.ASCENDING ? 'asc' : 'desc') : 'asc'}
                  align="left"
                >
                  <TableSortLabel
                    active={activeColumn === nameColumn.name}
                    direction={activeColumn === nameColumn.name ? (sortOrder === OrderDirection.ASCENDING ? 'asc' : 'desc') : 'asc'}
                    onClick={() => handleRequestSort(nameColumn)}
                  >
                    {t(nameColumn.name, { ns: 'settings_working_patterns' })}
                  </TableSortLabel>
                </StyledTableCell>
                <StyledTableCell align="left">{t('used_by', { ns: 'settings_working_patterns' })}</StyledTableCell>
                <StyledTableCell align="left">{t('description', { ns: 'settings_working_patterns' })}</StyledTableCell>
                <StyledTableCell align="left">
                  <TableSortLabel
                    active={activeColumn === weeklyTotalHoursColumn.name}
                    direction={activeColumn === weeklyTotalHoursColumn.name ? (sortOrder === OrderDirection.ASCENDING ? 'asc' : 'desc') : 'asc'}
                    onClick={() => handleRequestSort(weeklyTotalHoursColumn)}
                  >
                    {t(weeklyTotalHoursColumn.name, { ns: 'settings_working_patterns' })}
                  </TableSortLabel>
                </StyledTableCell>
                <TableCell align="left">{t('default_working_hours', { ns: 'settings_working_patterns' })}</TableCell>
                <TableCell align="left" />
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                position: 'relative'
              }}
            >
              <Fragment>
                {workingPatternArray.map((row) => (
                  <TableRow
                    hover
                    key={row.id}
                    sx={{
                      position: 'relative',
                      [`& .${tableCellClasses.root}`]: {
                        padding: '10px 30px',
                        height: 58,
                        border: 0,
                        transition: (theme) =>
                          theme.transitions.create('border', {
                            easing: theme.transitions.easing.sharp,
                            duration: theme.transitions.duration.enteringScreen
                          })
                      },
                      '&:hover': {
                        [`& .${tableCellClasses.root}:first-of-type`]: {
                          borderLeft: (theme) => `7px solid ${theme.palette.customTheme.focusItem}`,
                          borderRadius: '10px'
                        }
                      }
                    }}
                  >
                    <TableCell>
                      <Typography
                        variant="body1"
                        sx={{
                          fontWeight: 500
                        }}
                      >
                        <HighlightText text={row.name} highlight={search} />
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="text"
                        disableElevation
                        onClick={(e) => handleShowWorkingPattern(e, row.id, row.name)}
                        sx={{
                          color: (theme) => theme.palette.customTheme.focusItem,
                          padding: 0,
                          margin: 0,
                          backgroundColor: 'transparent',
                          textDecoration: 'underline',
                          '&:hover': {
                            backgroundColor: 'transparent',
                            textDecoration: 'underline'
                          }
                        }}
                      >
                        {t('working_pattern_with_count_sites', { count: row.countOfSites, ns: 'settings_working_patterns' })},{' '}
                        {t('people_with_count', { count: row.countOfEmployees, ns: 'settings_working_patterns' })}
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body1"
                        sx={{
                          fontWeight: 400
                        }}
                      >
                        <HighlightText text={row.description} highlight={search} />
                      </Typography>
                    </TableCell>
                    <TableCell align="center">{row.weeklyHours}</TableCell>
                    <TableCell align="center">{Math.floor(row.defaultWorkingHours)}</TableCell>
                    <TableCell align="center">
                      <WorkingPatternActions
                        workingPatternListItem={row}
                        menuName={row.id}
                        isWorkingPatternMenuOpen={openMenu === row.id}
                        handleWorkingPatternMenuToggle={toggleMenu}
                        closeMenu={closeMenu}
                      />
                    </TableCell>
                    <StyledBlurryModal component="div" open={isDeleteModalOpen} onClose={handleDeleteModalClose}>
                      <StyledModalContent
                        sx={{
                          position: 'relative',
                          justifyContent: 'center',
                          alignItems: 'flex-start',
                          minWidth: {
                            xs: 'auto',
                            lg: '670px'
                          },
                          height: {
                            xs: '80%',
                            lg: 'auto'
                          },
                          padding: '120px',
                          display: 'flex',
                          borderRadius: '20px',
                          boxShadow: 'none'
                        }}
                      >
                        <Stack
                          sx={{
                            width: '100%',
                            height: '100%',
                            alignItems: 'center',
                            justifyContent: 'center',
                            textAlign: 'center'
                          }}
                        >
                          <Typography variant="subtitle1">{t('delete_site', { ns: 'settings_sites' })}</Typography>
                          <Typography
                            variant="body1"
                            sx={{
                              margin: '24px 0 35px',
                              color: '#637381'
                            }}
                          >
                            {t('delete_question', { ns: 'settings_sites' })} <Box component="b">{row.name}</Box>
                          </Typography>
                          <RowCenterStack gap={2}>
                            <StyledCancelButton variant="contained" startIcon={<CancelEditIcon />} onClick={handleDeleteModalClose}>
                              {t('cancel', { ns: 'common' })}
                            </StyledCancelButton>
                            <StyledFormSubmitButton variant="contained" onClick={(e) => handleDeleteWorkingPatternById(e, row.id)}>
                              {t('delete', { ns: 'common' })}
                            </StyledFormSubmitButton>
                          </RowCenterStack>
                        </Stack>
                      </StyledModalContent>
                    </StyledBlurryModal>
                  </TableRow>
                ))}
              </Fragment>
              <div ref={bottomElementRef} style={{ width: '100%', height: '50px' }}></div>
              {workingPatternListItemsStatus === ActionStatus.Pending && (
                <Fragment>
                  {Array.from({ length: 5 }).map((_, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Skeleton width={236} height={60} />
                      </TableCell>
                      <TableCell>
                        <Skeleton width={100} height={60} />
                      </TableCell>
                      <TableCell>
                        <Skeleton width={100} height={60} />
                      </TableCell>
                      <TableCell align="center">
                        <Skeleton width={50} height={60} />
                      </TableCell>
                      <TableCell align="center">
                        <Skeleton width={50} height={60} />
                      </TableCell>
                      <TableCell>
                        <Skeleton width={180} height={60} />
                      </TableCell>
                    </TableRow>
                  ))}
                </Fragment>
              )}
            </TableBody>
          </Fragment>
        </Table>
      </TableContainer>
      <AdminSettingsWorkingPatternAdd isOpen={activeAddWorkingPatternModal} handleCloseModal={handleCloseAddWorkingPatternModal} />
    </StyledSettingsContainer>
  );
};
