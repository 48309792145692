import { Breadcrumbs as MuiBreadcrumbs, Link, Typography } from '@mui/material';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import HomeIcon from '@mui/icons-material/Home';
import { useAppSelector } from '@app/hooks';
import { selectAudienceItemById, selectPermissionItemById, selectSiteListItemById } from '@features/admin-settings';
import { selectEmployeeItemById } from '@features/employee-profile';

export const CustomBreadcrumbs = () => {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);
  const { siteId, audienceId, employeeId, permissionGroupId } = useParams();
  const siteListItem = useAppSelector((state) => selectSiteListItemById(state, siteId!));
  const audienceItem = useAppSelector((state) => selectAudienceItemById(state, audienceId!));
  const permissionGroupItem = useAppSelector((state) => selectPermissionItemById(state, permissionGroupId!));
  const employeeListItem = useAppSelector((state) => selectEmployeeItemById(state, employeeId!));
  const isParamBlank = !siteListItem && !audienceItem && !employeeListItem && !permissionGroupItem;

  return (
    <MuiBreadcrumbs
      separator={<NavigateNextIcon fontSize="small" sx={{ transform: 'rotate(180deg)' }} />}
      aria-label="breadcrumb"
      sx={{ color: (theme) => theme.palette.common.black }}
    >
      {/* Conditionally render the home icon based on whether the current path is the homepage or not */}
      {location.pathname === '/' ? (
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
            color: (theme) => theme.palette.common.black
          }}
        >
          <HomeIcon />
        </Typography>
      ) : (
        <Link
          component={RouterLink}
          to="/"
          sx={{
            display: 'flex',
            alignItems: 'center',
            color: (theme) => theme.palette.common.black
          }}
        >
          <HomeIcon />
        </Link>
      )}

      {/* Render each path segment as a link or the current page as text */}
      {pathnames.map((value, index) => {
        const isLast = index === pathnames.length - 1;
        const to = `/${pathnames.slice(0, index + 1).join('/')}`;

        return !isLast ? (
          <Link
            key={to}
            component={RouterLink}
            to={to}
            sx={{
              color: (theme) => theme.palette.common.black,
              textTransform: 'capitalize'
            }}
          >
            {value}
          </Link>
        ) : (
          <Typography
            key={to}
            sx={{
              color: (theme) => theme.palette.common.black,
              textTransform: 'capitalize'
            }}
          >
            {siteListItem && siteListItem.name}
            {audienceItem && audienceItem.audienceName}
            {employeeListItem && employeeListItem.displayName}
            {permissionGroupItem && permissionGroupItem.name}
            {isParamBlank && value.replace(/-/g, ' ')}
          </Typography>
        );
      })}
    </MuiBreadcrumbs>
  );
};
