import { useTranslation } from 'react-i18next';
import { Typography, Avatar, Button, Stack, Chip, Checkbox } from '@mui/material';
import { EmployeeListItem, RetrieveEmployeeBasicInfoRequest } from '@thrivea/organization-client';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { retrieveEmployeeBasicInfoRequested, selectCurrentAudienceGroup } from '@features/admin-settings';
import { selectEmployeeProfileAndCoverReadSasToken } from '@features/employee-profile';
import { PeopleIcon, PictureSizeSuffix, RowCenterStack, StyledAudienceLabel, StyledProfileLink, getBackgroundColor } from '@/shared';
import { pictureUrl } from '@/utils/pictureUrl';
import { AudienceRole } from '@thrivea/networking-client';
import { capitalize } from 'lodash';
import { selectAudienceDrawerEmployeeItemsById, selectAudienceDrawerEmployeeItemsIds } from '../audience-drawer.slice';
import { useEffect, useState } from 'react';
import { openDrawer, DrawerType } from '@features/drawer';

interface AudienceDrawerItemWithRole {
  employee: EmployeeListItem;
  role: number;
}

export const AudienceEmployeeList = () => {
  const { t } = useTranslation(['common', 'settings_permissions']);
  const dispatch = useAppDispatch();
  const audienceDrawerEmployeeItemsById = useAppSelector(selectAudienceDrawerEmployeeItemsById);
  const audienceDrawerEmployeeItemsIds = useAppSelector(selectAudienceDrawerEmployeeItemsIds);
  const employeeProfileSasToken = useAppSelector(selectEmployeeProfileAndCoverReadSasToken);
  const [mappedAudienceEmployeesWithRole, setMappedAudienceEmployeesWithRole] = useState<AudienceDrawerItemWithRole[]>([]);

  const audience = useAppSelector(selectCurrentAudienceGroup);

  useEffect(() => {
    if (audience) {
      setMappedAudienceEmployeesWithRole(
        audienceDrawerEmployeeItemsIds.map((id) => ({
          employee: audienceDrawerEmployeeItemsById[id],
          role: audience.employeeIdsWithRole.find((eiwr) => eiwr.employeeId === id)?.role ?? AudienceRole.VIEWER
        }))
      );
    }
  }, [audience]);

  const handleRetrieveSingleEmployeeInfo = (employeeId: string) => {
    dispatch(
      retrieveEmployeeBasicInfoRequested(
        new RetrieveEmployeeBasicInfoRequest({
          employeeId
        })
      )
    );
  };

  return (
    <Stack
      gap={2}
      sx={{
        margin: 'auto',
        width: '100%',
        height: '100%',
        borderRadius: (theme) => theme.spacing(2)
      }}
    >
      <Stack>
        <RowCenterStack gap={1}>
          <PeopleIcon />
          <Typography variant="subtitle1">{audience.name}</Typography>
        </RowCenterStack>
        <Typography variant="body1">{audience.description}</Typography>
        <StyledAudienceLabel disabled control={<Checkbox />} label={t('mandatory_email', { ns: 'settings_permissions' })} checked={audience.shouldSendEmail} />
      </Stack>
      <Stack
        sx={{
          backgroundColor: (theme) => theme.palette.background.paper,
          borderRadius: (theme) => theme.spacing(2),
          padding: '16px',
          height: '100%',
        }}
      >
        {mappedAudienceEmployeesWithRole.map((employeeWithRole) => {
          const { employee, role } = employeeWithRole;
          return (
            <Stack
              key={crypto.randomUUID()}
              direction="row"
              spacing={2}
              sx={{
                borderRadius: '8px',
                padding: '8px',
                transition: 'background-color 0.3s ease-in-out',
                '&:hover': {
                  backgroundColor: (theme) => theme.palette.common.white,
                  '& .Mui-ProfileLink': {
                    opacity: 1
                  }
                }
              }}
            >
              <Avatar
                src={pictureUrl(employee.profilePictureUrl, employeeProfileSasToken, PictureSizeSuffix.sm)}
                alt={employee.displayName}
                variant="rounded"
                sx={{
                  border: (theme) => `1px solid ${theme.palette.customTheme.contColor}`,
                  width: 38,
                  height: 38
                }}
              />
              <Stack
                direction="row"
                sx={{
                  width: '100%',
                  justifyContent: 'space-between'
                }}
              >
                <Stack>
                  <Typography fontWeight={700}>{employee.displayName}</Typography>
                  <Typography>{employee.jobTitle}</Typography>
                </Stack>
                <StyledProfileLink onClick={() => handleRetrieveSingleEmployeeInfo(employee.employeeId)} className="Mui-ProfileLink" variant="text">
                  {t('view_profile')}
                </StyledProfileLink>
                <Chip
                  sx={{
                    backgroundColor: (theme) => getBackgroundColor(capitalize(AudienceRole[role]), theme)
                  }}
                  label={t(AudienceRole[role].toLowerCase(), { ns: 'settings_permissions' })}
                />
              </Stack>
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
};
