import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '@app/store';

interface DarkModeState {
  enabled: boolean;
}

const initialState: DarkModeState = {
  enabled: false
};

const darkModeSlice = createSlice({
  name: 'darkMode',
  initialState,
  reducers: {
    toggleDarkMode: (state) => {
      state.enabled = !state.enabled;
    }
  }
});

export const { toggleDarkMode } = darkModeSlice.actions;

export const selectThemeMode: (state: RootState) => boolean = (state: RootState) => state.theme.enabled;
export default darkModeSlice.reducer;
