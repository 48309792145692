import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import {
  selectSiteEmployees,
  selectSiteReadSasToken,
  selectCurrentSiteEmployeesPageNumber,
  selectHasMoreSiteEmployees,
  selectSiteEmployeesStatus,
  retrieveEmployeesBySiteIdRequested,
  selectCurrentSiteListItem,
  selectHasLoadedSingleSite
} from '@features/admin-settings';
import { Typography, Box, Stack } from '@mui/material';
import { RetrieveEmployeesBySiteIdRequest } from '@thrivea/organization-client';
import { Fragment, useCallback, useEffect, useRef } from 'react';
import { ActionStatus, EmployeeList, PictureSizeSuffix } from '@/shared';
import { DrawerItemSkeleton } from './DrawerItemSkeleton';
import { pictureUrl } from '@utils/pictureUrl';

export const SiteDrawerContent = () => {
  const { t } = useTranslation(['common', 'drawer']);
  const dispatch = useAppDispatch();
  const siteEmployees = useAppSelector(selectSiteEmployees);
  const currentSiteEmployeesPage = useAppSelector(selectCurrentSiteEmployeesPageNumber);
  const hasMoreEmployees = useAppSelector(selectHasMoreSiteEmployees);
  const siteEmployeesStatus = useAppSelector(selectSiteEmployeesStatus);
  const sasToken = useAppSelector(selectSiteReadSasToken);
  const observer = useRef<IntersectionObserver>();
  const bottomElementRef = useRef<HTMLDivElement>(null);
  const currentSiteById = useAppSelector(selectCurrentSiteListItem);
  const hasLoadedSingleSite = useAppSelector(selectHasLoadedSingleSite);

  const handleScroll = useCallback(() => {
    if (hasMoreEmployees) {
      dispatch(
        retrieveEmployeesBySiteIdRequested(
          new RetrieveEmployeesBySiteIdRequest({
            siteId: currentSiteById.id,
            pageNumber: currentSiteEmployeesPage + 1,
            pageSize: 10
          })
        )
      );
    }
  }, [hasMoreEmployees, currentSiteEmployeesPage]);

  useEffect(() => {
    observer.current = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && siteEmployeesStatus === ActionStatus.Idle) {
          handleScroll();
        }
      },
      { threshold: 1 } // Trigger the observer when the target is 100% visible
    );

    // Start observing the target element
    if (bottomElementRef.current && observer.current) {
      observer.current.observe(bottomElementRef.current);
    }

    return () => {
      // Clean up the observer and scroll event listener when the component unmounts
      if (observer.current && bottomElementRef.current) {
        observer.current.disconnect();
      }
    };
  }, [handleScroll]);

  return (
    <Box
      sx={{
        position: 'relative',
        margin: '0 -16px',
        top: -88,
        height: '100%'
      }}
    >
      {hasLoadedSingleSite === ActionStatus.Idle && (
        <Fragment>
          <Box
            component="img"
            src={currentSiteById.coverImageUrl ? pictureUrl(currentSiteById.coverImageUrl, sasToken, PictureSizeSuffix.lg) : '/images/org-site-placeholder.png'}
            alt="site cover image"
            sx={{
              width: '100%',
              height: 220,
              objectFit: 'cover',
              borderRadius: (theme) => theme.spacing(2),
              boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)'
            }}
          />
          <Stack
            spacing={2}
            sx={{
              margin: '-32px auto 16px',
              padding: '24px 20px',
              backgroundColor: (theme) => theme.palette.background.paper,
              width: 'calc(100% - 32px)',
              height: '100%',
              borderRadius: (theme) => theme.spacing(2),
              zIndex: 1,
              position: 'relative'
            }}
          >
            <Stack gap={1}>
              <Typography variant="h4">
                {currentSiteById.name} {t('employee_with_count', { ns: 'common', count: currentSiteById.employeeCount })}
              </Typography>
              <Typography variant="body1">
                {currentSiteById.address}, {currentSiteById.city}, {currentSiteById.country}
              </Typography>
            </Stack>
            <Stack
              spacing={1}
              sx={{
                maxHeight: '60vh',
                overflowY: 'scroll'
              }}
            >
              {siteEmployees.length !== 0 && (
                // siteEmployeeIds.map((id) => {
                //   const employee = siteEmployees[id];
                //   return (
                //     <Stack
                //       key={crypto.randomUUID()}
                //       direction="row"
                //       spacing={2}
                //       sx={{
                //         borderRadius: '8px',
                //         padding: '8px',
                //         transition: 'background-color 0.3s ease-in-out',
                //         '&:hover': {
                //           backgroundColor: (theme) => theme.palette.common.white,
                //           '& .Mui-ProfileLink': {
                //             opacity: 1
                //           }
                //         }
                //       }}
                //     >
                //       <Avatar
                //         src={pictureUrl(employee.profilePicture, employeeProfileSasToken, PictureSizeSuffix.sm)}
                //         alt={employee.name}
                //         variant="rounded"
                //         sx={{
                //           border: (theme) => `1px solid ${theme.palette.customTheme.contColor}`,
                //           width: 36,
                //           height: 36
                //         }}
                //       />
                //       <Stack
                //         direction="row"
                //         sx={{
                //           width: '100%',
                //           justifyContent: 'space-between'
                //         }}
                //       >
                //         <Stack>
                //           <Typography fontWeight={700}>{employee.name}</Typography>
                //           <Typography>{employee.jobTitle}</Typography>
                //         </Stack>
                //         <StyledProfileLink onClick={() => handleRetrieveSingleEmployeeInfo(employee.id)} className="Mui-ProfileLink" variant="text">
                //           {t('view_profile', { ns: 'drawer' })}
                //         </StyledProfileLink>
                //       </Stack>
                //     </Stack>
                //   );
                // })
                <EmployeeList employees={siteEmployees} />
              )}
            </Stack>
            <div ref={bottomElementRef} style={{ width: '100%', height: '1px' }}></div>
            {siteEmployeesStatus === ActionStatus.Pending && Array.from({ length: 6 }).map(() => <DrawerItemSkeleton key={crypto.randomUUID()} />)}
          </Stack>
        </Fragment>
      )}
    </Box>
  );
};
