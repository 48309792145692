import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import {
  retrieveAudiencesByEmployeeIdFailed,
  retrieveAudiencesByEmployeeIdRequested,
  retrieveAudiencesByEmployeeIdSucceeded,
  retrieveGroupsFailed,
  retrieveGroupsRequested,
  retrieveGroupsSucceeded
} from '@features/abac';
import { RetrieveGroupsRequest, RetrieveGroupsResponse } from '@thrivea/auth-client';
import * as Sentry from '@sentry/react';
import { RetrieveAudiencesByEmployeeIdRequest, RetrieveAudiencesByEmployeeIdResponse } from '@thrivea/networking-client';
import { PayloadAction } from '@reduxjs/toolkit';
import { retrieveAudiencesByEmployeeId } from '@api/audience.api';
import { retrieveGroups } from '@api/group.api';

function* retrieveGroupsGenerator(action: PayloadAction<RetrieveGroupsRequest>) {
  try {
    const response: RetrieveGroupsResponse = yield call(retrieveGroups, action.payload);
    yield put(retrieveGroupsSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveGroupsFailed());
  }
}

function* retrieveAudiencesByEmployeeIdGenerator(action: PayloadAction<RetrieveAudiencesByEmployeeIdRequest>) {
  try {
    const response: RetrieveAudiencesByEmployeeIdResponse = yield call(retrieveAudiencesByEmployeeId, action.payload);

    yield put(retrieveAudiencesByEmployeeIdSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveAudiencesByEmployeeIdFailed());
  }
}

function* retrieveGroupsWatcher() {
  yield takeLatest(retrieveGroupsRequested.type, retrieveGroupsGenerator);
}

function* retrieveAudiencesByEmployeeIdWatcher() {
  yield takeLatest(retrieveAudiencesByEmployeeIdRequested.type, retrieveAudiencesByEmployeeIdGenerator);
}

export function* abacSagas() {
  yield all([fork(retrieveGroupsWatcher), fork(retrieveAudiencesByEmployeeIdWatcher)]);
}
