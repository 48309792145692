import { Box, Tabs, Tab, alpha, Stack } from '@mui/material';
import { useState } from 'react';
import { useAppDispatch } from '@app/hooks';
import { CustomTabPanel, EmployeeList } from '@/shared';
import { a11yProps } from '@/utils';
import { EmployeeDrawerListItem, EmployeeListItem } from '@thrivea/organization-client';
import { AssetsActivityList } from './AssetsActivityList';

const employeesTemp = [
  {
    id: '019006af-dbb6-4614-ac4b-fbc200efebef',
    name: 'Stevo Cvijetic',
    jobTitle: 'Frontend Developer',
    profilePicture:
      'https://stthriveadev001.blob.core.windows.net/data/b897e4ba-4348-4fee-ad3f-880582cdab3d/profile/019006af-dbb6-4614-ac4b-fbc200efebef/pictures/0ac3650e-2b7e-4f4e-a260-670728e800f7.jpeg'
  },
  {
    id: '019006e9-60ea-4320-b95b-47f906b65265',
    name: 'Marko Markovic',
    jobTitle: 'Drupal Developer',
    profilePicture:
      'https://stthriveadev001.blob.core.windows.net/data/b897e4ba-4348-4fee-ad3f-880582cdab3d/profile/019006e9-60ea-4320-b95b-47f906b65265/pictures/cbd9bed0-5f66-49a1-a2c0-58dd6bd1d979.jpeg'
  },
  {
    id: '019006e9-60f3-4c45-9b64-d10c7cf23047',
    name: 'Jovan Cvetic',
    jobTitle: 'IT Admin',
    profilePicture:
      'https://stthriveadev001.blob.core.windows.net/data/b897e4ba-4348-4fee-ad3f-880582cdab3d/profile/019006e9-60f3-4c45-9b64-d10c7cf23047/pictures/54bd104e-3ff3-4052-bfe5-48cf84e23a88.png'
  },
  {
    id: '01919dd0-d686-420d-aa1f-d38c08bc06e5',
    name: 'Cynthia Wiley',
    jobTitle: 'Secretary',
    profilePicture:
      'https://stthriveadev001.blob.core.windows.net/data/b897e4ba-4348-4fee-ad3f-880582cdab3d/profile/01919dd0-d686-420d-aa1f-d38c08bc06e5/pictures/8bb8fc4b-106a-49c7-af0b-a939f2804de9_lg.webp'
  }
] as EmployeeDrawerListItem[];

export const AssetsDrawerTabs = () => {
  const dispatch = useAppDispatch();
  const [activeTab, setActiveTab] = useState(0);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <Box
      sx={{
        width: '100%',
        mt: 2
      }}
    >
      <Tabs
        value={activeTab}
        onChange={handleChange}
        aria-label="Employee Panel"
        sx={{
          minHeight: 'auto',
          '& .MuiTabs-flexContainer': {
            direction: 'row',
            alignItems: 'center',
            justifyContent: 'center'
          }
        }}
      >
        <Tab
          label="Access"
          {...a11yProps('assets_access_tab', 0)}
          sx={{
            backgroundColor: (theme) => (activeTab === 0 ? alpha(theme.palette.customTheme.focusItem, 0.4) : 'transparent')
          }}
        />
        <Tab
          label="Activity"
          {...a11yProps('assets_activity_tab', 1)}
          sx={{
            backgroundColor: (theme) => (activeTab === 1 ? alpha(theme.palette.customTheme.focusItem, 0.4) : 'transparent')
          }}
        />
      </Tabs>
      <CustomTabPanel value={activeTab} index={0}>
        <Stack
          sx={{
            backgroundColor: (theme) => theme.palette.grey[600],
            padding: 2,
            mt: 2,
            borderRadius: (theme) => theme.spacing(2)
          }}
        >
          <EmployeeList employees={employeesTemp} />
        </Stack>
      </CustomTabPanel>
      <CustomTabPanel value={activeTab} index={1}>
        <Stack
          sx={{
            backgroundColor: (theme) => theme.palette.grey[600],
            padding: 2,
            mt: 2,
            borderRadius: (theme) => theme.spacing(2)
          }}
        >
          <AssetsActivityList />
        </Stack>
      </CustomTabPanel>
    </Box>
  );
};
