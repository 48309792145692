import React from 'react';
import { useTheme } from '@mui/material';

interface NotificationsIconAttributes {
  width?: number;
  height?: number;
}

export const NotificationsIcon: React.FC<NotificationsIconAttributes> = ({ width = 24, height = 24 }: NotificationsIconAttributes) => {
  const theme = useTheme();

  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" width={width} height={height} viewBox={`0 0 64 64`}>
      <path
        width={width}
        height={height}
        fill={theme.palette.customTheme.contColor}
        d="m57.27 52.796-1.988-3.082c-.398-.597-.597-1.194-.597-1.89v-20.68c0-5.867-2.486-11.335-7.06-15.412a23.121 23.121 0 0 0-13.521-5.766V3.977c0-1.193-.995-2.287-2.287-2.287-1.193 0-2.287.994-2.287 2.287v1.89c-.199 0-.398 0-.597.099C17.4 7.258 8.75 16.505 8.75 27.54v20.283c-.1.995-.299 1.492-.497 1.79l-1.89 3.182a3.052 3.052 0 0 0 0 3.181c.597.895 1.591 1.492 2.685 1.492h20.581v2.187c0 1.193.995 2.287 2.287 2.287 1.193 0 2.287-.994 2.287-2.287V57.47h20.482c1.094 0 2.088-.597 2.684-1.492.597-.994.597-2.187-.099-3.181Zm-45.836.199.696-1.194c.597-.994.895-2.187 1.094-3.579v-20.68c0-8.75 6.96-16.108 16.206-17.102 5.668-.597 11.236 1.094 15.312 4.673 3.58 3.181 5.568 7.457 5.568 12.03v20.681c0 1.492.398 2.884 1.293 4.276l.596.895H11.434Z"
      />
    </svg>
  );
};
