import { createPromiseClient } from '@connectrpc/connect';
import { createGrpcWebTransport } from '@connectrpc/connect-web';
import { Deployment, DeploymentService, RetrieveLatestDeploymentRequest } from '@thrivea/admin-onboarding-client';

export const deploymentClient = createPromiseClient(
  DeploymentService,
  createGrpcWebTransport({
    baseUrl: import.meta.env.VITE_ADMIN_API_URL,
    useBinaryFormat: true
  })
);

export const retrieveLatestDeployment = async (request: RetrieveLatestDeploymentRequest): Promise<Deployment> => {
  return await deploymentClient.retrieveLatestDeployment(request, {
    headers: new Headers({
      Authorization: `Basic ${btoa('SomeUserName:&nrkxdnc%qV986Nm')}`
    })
  });
};
