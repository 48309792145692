import { useTheme } from '@mui/material';
import React from 'react';

interface OrganizationIconProps {
  size?: number; // Optional size prop to control the icon size
  color?: string; // Optional color prop to control the icon color
}

export const OrganizationIcon: React.FC<OrganizationIconProps> = ({ size = 24, color = '#000000' }) => {
  const theme = useTheme();

  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" width={size} height={(size * 24) / 22} viewBox="0 0 22 27">
      <path
        fill={color}
        d="M22.25 15h-.75v-2.25a1.5 1.5 0 0 0-1.5-1.5h-6.75V9H14a1.5 1.5 0 0 0 1.5-1.5v-3A1.5 1.5 0 0 0 14 3h-3a1.5 1.5 0 0 0-1.5 1.5v3A1.5 1.5 0 0 0 11 9h.75v2.25H5a1.5 1.5 0 0 0-1.5 1.5V15h-.75a1.5 1.5 0 0 0-1.5 1.5v3a1.5 1.5 0 0 0 1.5 1.5h3a1.5 1.5 0 0 0 1.5-1.5v-3a1.5 1.5 0 0 0-1.5-1.5H5v-2.25h6.75V15H11a1.5 1.5 0 0 0-1.5 1.5v3A1.5 1.5 0 0 0 11 21h3a1.5 1.5 0 0 0 1.5-1.5v-3A1.5 1.5 0 0 0 14 15h-.75v-2.25H20V15h-.75a1.5 1.5 0 0 0-1.5 1.5v3a1.5 1.5 0 0 0 1.5 1.5h3a1.5 1.5 0 0 0 1.5-1.5v-3a1.5 1.5 0 0 0-1.5-1.5ZM11 4.5h3v3h-3v-3Zm-5.25 12v3h-3v-3h3Zm8.25 0v3h-3v-3h3Zm5.25 3v-3h3v3h-3Z"
      />
    </svg>
  );
};
