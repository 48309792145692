import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { StyledListItemButton } from '@/shared';

interface AdminSettingsSiteLinkProps {
  siteId: string;
}

export const AdminSettingsSiteLink: React.FC<AdminSettingsSiteLinkProps> = ({ siteId }) => {
  const { t } = useTranslation(['settings_sites']);
  const dispatch = useDispatch();

  return (
    <StyledListItemButton
      component={RouterLink}
      to={siteId}
      state={{ siteId }}
      sx={{
        width: '100%',
        padding: '12px 24px',
        textDecoration: 'none'
      }}
    >
      {t('view_and_edit', { ns: 'settings_sites' })}
    </StyledListItemButton>
  );
};
