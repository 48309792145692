const DOCUMENT_MIME_TYPES = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.oasis.opendocument.text',
  'application/vnd.oasis.opendocument.spreadsheet',
  'application/vnd.oasis.opendocument.presentation',
  'text/plain',
  'text/csv',
  'application/rtf'
];

export const DOCUMENT_FILE_TYPES = ['pdf', 'docx', 'doc', 'xlsx', 'xls', 'pptx', 'ppt', 'odt', 'ods', 'odp', 'txt', 'rtf'];

const DOCUMENT_EXTENSION_PATTERN = /\.(pdf|docx|doc|xlsx|xls|pptx|ppt|odt|ods|odp|txt|rtf|csv|msword)$/i;

export const isDocumentUrl = (url: string) => {
  try {
    return DOCUMENT_EXTENSION_PATTERN.test(new URL(url).pathname);
  } catch (e) {
    return false;
  }
};

export const isDocument = (filename: string) => DOCUMENT_EXTENSION_PATTERN.test(filename);

export const isDocumentMimeType = (mimeType: string) => DOCUMENT_MIME_TYPES.includes(mimeType);
