import React from 'react';

interface DocsIconAttributes {
  size?: number; // Optional size prop to control the icon size
  color?: string; // Optional color prop to control the icon color
}

export const DocsIcon: React.FC<DocsIconAttributes> = ({ size = 24, color = '#000000' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" width={size} height={(size * 24) / 22} viewBox="0 0 25 26">
      <path
        fill={color}
        d="M21.5 7h-3.75V4.75A2.25 2.25 0 0 0 15.5 2.5h-6a2.25 2.25 0 0 0-2.25 2.25V7H3.5a2.25 2.25 0 0 0-2.25 2.25v12A2.25 2.25 0 0 0 3.5 23.5h18a2.25 2.25 0 0 0 2.25-2.25v-12A2.25 2.25 0 0 0 21.5 7ZM8.75 4.75A.75.75 0 0 1 9.5 4h6a.75.75 0 0 1 .75.75V7h-7.5V4.75ZM3.5 8.5h18a.75.75 0 0 1 .75.75v5.25H2.75V9.25a.75.75 0 0 1 .75-.75ZM11 16h3v1.5h-3V16Zm10.5 6h-18a.75.75 0 0 1-.75-.75V16H9.5v1.5A1.5 1.5 0 0 0 11 19h3a1.5 1.5 0 0 0 1.5-1.5V16h6.75v5.25a.75.75 0 0 1-.75.75Z"
      />
    </svg>
  );
};
