import { Box } from '@mui/material';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { selectCurrentAudienceGroup, selectRetrievedAudienceByIdStatus } from '@features/admin-settings';
import {
  AudienceDrawerSkeleton,
  AudienceEmployeeList,
  retrieveAudienceDrawerEmployeeItemsRequested,
  selectAudienceDrawerEmployeeItemsStatus
} from '@features/drawer';
import { ActionStatus } from '@/shared';
import { useEffect } from 'react';
import { RetrieveEmployeesByIdsRequest } from '@thrivea/organization-client';

export const AudienceDrawerContent = () => {
  const dispatch = useAppDispatch();
  const audience = useAppSelector(selectCurrentAudienceGroup);
  const audienceByIdStatus = useAppSelector(selectRetrievedAudienceByIdStatus);
  const audienceDrawerEmployeeItemsStatus = useAppSelector(selectAudienceDrawerEmployeeItemsStatus);

  useEffect(() => {
    if (audience.employeeIdsWithRole) {
      dispatch(
        retrieveAudienceDrawerEmployeeItemsRequested(
          new RetrieveEmployeesByIdsRequest({
            employeeIds: audience.employeeIdsWithRole.map((eiwr) => eiwr.employeeId)
          })
        )
      );
    }
  }, [audience]);

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%'
      }}
    >
      {audienceByIdStatus === ActionStatus.Pending && audienceDrawerEmployeeItemsStatus === ActionStatus.Pending && <AudienceDrawerSkeleton />}
      {audienceByIdStatus === ActionStatus.Idle && audienceDrawerEmployeeItemsStatus === ActionStatus.Idle && <AudienceEmployeeList />}
    </Box>
  );
};
