import { Deployment, RetrieveLatestDeploymentRequest } from '@thrivea/admin-onboarding-client';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, fork, put, takeLatest } from 'redux-saga/effects';
import { retrieveLatestDeployment } from '@api/deployment.api';
import { retrieveLatestDeploymentFailed, retrieveLatestDeploymentRequested, retrieveLatestDeploymentSucceeded } from '@features/snackbar';
import * as Sentry from '@sentry/react';

function* retrieveLatestDeploymentGenerator(action: PayloadAction<RetrieveLatestDeploymentRequest>) {
  try {
    const response: Deployment = yield call(retrieveLatestDeployment, action.payload);
    yield put(retrieveLatestDeploymentSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveLatestDeploymentFailed());
  }
}

function* retrieveLatestDeploymentWatcher() {
  yield takeLatest(retrieveLatestDeploymentRequested.type, retrieveLatestDeploymentGenerator);
}

export function* snackbarSagas() {
  yield fork(retrieveLatestDeploymentWatcher);
}
