import React, { memo, useState } from 'react';
import { Stack, Box, Typography, Skeleton } from '@mui/material';
import { DateTime } from 'luxon';
import { PostActions, PostAuthor, PostContent, PostDoc, PostMedia, PostMenu, selectAudienceWithNameByAudienceId } from '@features/homepage';
import { AudienceWithName, RetrieveAudienceByIdRequest } from '@thrivea/networking-client';
import { RowCenterStack, StyledInlinePostAudienceChip, StyledTransparentButton } from '@/shared';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { retrieveAudienceByIdRequested } from '@features/admin-settings';
import { useTranslation } from 'react-i18next';
import { selectCurrentEmployeeId } from '@app/user';
import { DrawerType, openDrawer } from '@features/drawer';

interface PostViewProps {
  postId: string;
  authorId: string;
  date: DateTime;
  files: PostMedia[];
  docs: PostDoc[];
  message: string;
  audienceId: string;
  editedTime?: DateTime;
  isImporant: boolean;
  requiresReadConfirmation: boolean;
}

export const PostView: React.FC<PostViewProps> = memo(
  ({ postId, authorId, date, files, docs, message, audienceId, editedTime, isImporant, requiresReadConfirmation }) => {
    const { t } = useTranslation(['homepage']);
    const dispatch = useAppDispatch();
    const [editablePost, setEditablePost] = useState<string | null>(null);
    const audienceWithName = useAppSelector((state) => selectAudienceWithNameByAudienceId(state, audienceId));

    const handleSetPostEditable = (id: string) => {
      setEditablePost(editablePost === id ? null : id);
    };

    const handleRetrieveAudienceById = (audienceId: string) => {
      dispatch(retrieveAudienceByIdRequested(new RetrieveAudienceByIdRequest({ audienceId })));
      dispatch(openDrawer(DrawerType.Audience));
    };

    return (
      <Stack
        component="article"
        sx={{
          backgroundColor: (theme) => (isImporant ? theme.palette.customTheme.drawerBackground : theme.palette.customTheme.contBgr),
          border: '1px solid',
          borderColor: (theme) => theme.palette.customTheme.borderMaster,
          borderRadius: 2,
          padding: 0
        }}
      >
        <RowCenterStack
          sx={{
            padding: '0 16px',

            my: 1,
            flexWrap: 'wrap',
            justifyContent: {
              xs: 'flex-end',
              md: 'space-between'
            }
          }}
        >
          <PostAuthor authorId={authorId} postId={postId} date={date} editedTime={editedTime} />
          <RowCenterStack gap={1}>
            <StyledInlinePostAudienceChip
              label={
                <RowCenterStack>
                  <Typography component="span" variant="body2" sx={{ color: (theme) => theme.palette.customTheme.focusItem }}>
                    @
                  </Typography>
                  {audienceWithName === undefined ? (
                    <Skeleton animation="pulse" height={20} width={150} />
                  ) : (
                    <StyledTransparentButton onClick={() => handleRetrieveAudienceById(audienceWithName.audienceId)}>
                      <Typography component="span" variant="body2">
                        {audienceWithName.audienceName}
                      </Typography>
                    </StyledTransparentButton>
                  )}
                </RowCenterStack>
              }
            />
            {editablePost !== postId && <PostMenu postId={postId} handleSetPostEditable={handleSetPostEditable} />}
          </RowCenterStack>
        </RowCenterStack>
        <Box
          sx={{
            padding: '0 16px'
          }}
        >
          <PostContent
            postId={postId}
            authorId={authorId}
            message={message}
            medias={files}
            docs={docs}
            isEditable={editablePost === postId}
            handleSetPostEditable={handleSetPostEditable}
          />
        </Box>
        {editablePost !== postId && <PostActions postId={postId} />}
      </Stack>
    );
  }
);
