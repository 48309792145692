import { useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { AllowedTo } from 'react-abac';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { loginRequest } from '@utils/authConfig';
import { ErbFieldTypeCardinality, ErbFieldWidth, ErTemplateSection } from '@thrivea/organization-client';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { loadEmployeeProfileRequested } from '@features/employee-profile';
import { MainLayout } from '@/shared';
import { selectCurrentEmployeeId } from '@app/user';
import { EmployeeRecordSections, selectOrderedErTemplateSections } from '@features/employee-record-builder';
import { GroupPermissions } from '@features/abac';

const tempEmpSections = [
  {
    id: '6f2bbf84-8d96-4f19-8660-1cf17e9c687b',
    name: 'Generated Section 01',
    position: 1,
    erbFieldCategoryId: 'dfbdc670-5a35-4514-9485-eb834eafc8f1',
    erbFieldSectionId: '0e26bc7e-9468-4ad7-b77e-17bd7ba631f7',
    fields: [
      {
        kind: {
          value: {
            id: 'a0cd7e1d-4f29-4bb6-ab59-5805befdaf1e',
            name: 'Lorem ipsum section 02',
            position: 1,
            width: ErbFieldWidth.HALF,
            erbScalarField: {
              id: '21dc2cfa-a681-46b9-a8bc-6354f5ac15a6',
              name: 'Lorem',
              type: {
                id: '64365479-e01b-4d19-8832-2b88111a206f',
                name: 'DateTime',
                cardinality: ErbFieldTypeCardinality.ONE
              },
              isDefaultInSection: false,
              isWellKnown: false,
              isCalculated: false
            }
          },
          case: 'scalarField'
        }
      }
    ]
  }
] as ErTemplateSection[];

export const EmployeeRecordPage = () => {
  const dispatch = useAppDispatch();
  const { employeeId } = useParams();
  const signedInEmployeeId = useAppSelector(selectCurrentEmployeeId);
  const sections = useAppSelector(selectOrderedErTemplateSections);

  const [chosenEmployeeId] = useState<string>(employeeId ?? signedInEmployeeId);

  useEffect(() => {
    dispatch(loadEmployeeProfileRequested(chosenEmployeeId));
  }, [chosenEmployeeId]);

  return (
    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={loginRequest}>
      <MainLayout>
        <AllowedTo perform={GroupPermissions.VIEW_PROFILE} data={chosenEmployeeId} no={() => <Navigate to="/403" />}>
          <EmployeeRecordSections sections={sections} />
        </AllowedTo>
      </MainLayout>
    </MsalAuthenticationTemplate>
  );
};
