import React from 'react';

interface MoustacheIconProps {
  size?: number; // Optional size prop to control the icon size
  color?: string; // Optional color prop to control the icon color
}

export const MoustacheIcon: React.FC<MoustacheIconProps> = ({ size = 24, color = '#251D38' }) => {
  return (
    <svg width={size} height={size / 2} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.202 2.393a.75.75 0 0 0-1.38-.309 3.12 3.12 0 0 1-5.031.306A3.783 3.783 0 0 0 12 1.54a3.785 3.785 0 0 0-4.793.852 3.12 3.12 0 0 1-5.029-.308.75.75 0 0 0-1.38.31A6.765 6.765 0 0 0 12 8.263a6.764 6.764 0 0 0 11.202-5.87ZM19.84 7.258h-.001a5.235 5.235 0 0 1-7.279-.552.75.75 0 0 0-1.12 0 5.267 5.267 0 0 1-9.07-2.375 4.622 4.622 0 0 0 5.992-.982 2.274 2.274 0 0 1 3.166-.318.75.75 0 0 0 .944 0 2.272 2.272 0 0 1 3.164.316 4.625 4.625 0 0 0 5.995.983 5.229 5.229 0 0 1-1.79 2.928Z"
        fill={color}
        stroke={color}
      />
    </svg>
  );
};
