import React, { useEffect, useState } from 'react';
import { RetrieveAudienceDrawerItemsByEmployeeIdRequest, RetrieveRecentPostsByAuthorRequest } from '@thrivea/networking-client';
import { RetrieveEmployeeBasicInfoResponse, RetrieveEmployeesByManagerIdRequest } from '@thrivea/organization-client';
import { useAppDispatch } from '@app/hooks';
import { alpha, Tabs, Tab, Box, Stack } from '@mui/material';
import { retrieveEmployeeRecentPostsRequested } from '@features/homepage';
import { EmployeeDrawerTeamList, EmployeeDrawerRecentPosts, retrieveAudienceDrawerItemsByEmployeeIdRequested } from '@features/drawer';
import { a11yProps } from '@/utils';
import { CustomTabPanel } from '@/shared';
import { EmployeeDrawerAudiences } from './EmployeeDrawerAudiences';

interface EmployeeDrawerTabsProps {
  employee: RetrieveEmployeeBasicInfoResponse;
}

export const EmployeeDrawerTabs: React.FC<EmployeeDrawerTabsProps> = ({ employee }) => {
  const dispatch = useAppDispatch();
  const [activeTab, setActiveTab] = useState(0);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    dispatch(
      retrieveEmployeeRecentPostsRequested(
        new RetrieveRecentPostsByAuthorRequest({
          authorId: employee.employeeId,
          pageNumber: 1,
          pageSize: 10
        })
      )
    );
  }, []);

  useEffect(() => {
    dispatch(
      retrieveAudienceDrawerItemsByEmployeeIdRequested(
        new RetrieveAudienceDrawerItemsByEmployeeIdRequest({
          employeeId: employee.employeeId
        })
      )
    );
  }, []);

  return (
    <Box
      sx={{
        width: '100%',
        mt: 2
      }}
    >
      <Tabs
        value={activeTab}
        onChange={handleChange}
        aria-label="Employee Panel"
        sx={{
          minHeight: 'auto',
          '& .MuiTabs-flexContainer': {
            direction: 'row',
            alignItems: 'center',
            justifyContent: 'center'
          }
        }}
      >
        <Tab
          label="Recent posts"
          {...a11yProps('employee_recent_posts_tab', 0)}
          sx={{
            backgroundColor: (theme) => (activeTab === 0 ? alpha(theme.palette.customTheme.focusItem, 0.4) : 'transparent')
          }}
        />
        <Tab
          label="Team"
          {...a11yProps('emplyoee_team-tab', 1)}
          sx={{
            backgroundColor: (theme) => (activeTab === 1 ? alpha(theme.palette.customTheme.focusItem, 0.4) : 'transparent')
          }}
        />
        <Tab
          label="Audiences"
          {...a11yProps('employee_audiences_tab', 2)}
          sx={{
            backgroundColor: (theme) => (activeTab === 2 ? alpha(theme.palette.customTheme.focusItem, 0.4) : 'transparent')
          }}
        />
      </Tabs>
      <CustomTabPanel value={activeTab} index={0}>
        <EmployeeDrawerRecentPosts
          authorName={employee.employeeDisplayName}
          authorId={employee.employeeId}
          authorPicture={employee.employeeProfilePictureUrl}
        />
      </CustomTabPanel>
      <CustomTabPanel value={activeTab} index={1}>
        <Stack
          sx={{
            backgroundColor: (theme) => theme.palette.grey[800],
            padding: 2,
            mt: 2,
            borderRadius: (theme) => theme.spacing(2)
          }}
        >
          <EmployeeDrawerTeamList managerId={employee.employeeId} managerName={employee.employeeDisplayName} />
        </Stack>
      </CustomTabPanel>
      <CustomTabPanel value={activeTab} index={2}>
        <Stack
          sx={{
            backgroundColor: (theme) => theme.palette.grey[800],
            padding: 2,
            mt: 2,
            borderRadius: (theme) => theme.spacing(2)
          }}
        >
          <EmployeeDrawerAudiences employeeId={employee.employeeId} employeeName={employee.employeeDisplayName} />
        </Stack>
      </CustomTabPanel>
    </Box>
  );
};
