import React, { useEffect } from 'react';
import { Box, Button, CircularProgress, ListItem, Skeleton, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { RowCenterStack, ChevronDownIcon, AddIcon, EditPenIcon, StyledEditButton, StyledAutocomplete, StyledTransparentInput } from '@/shared';
import { ActionStatus } from '@shared/shared.model';
import {
  IndustryCategory,
  IndustrySubcategory,
  NameAndIndustryStep,
  RetrieveIndustrySubCategoriesRequest,
  UpdateNameAndIndustryStepRequest
} from '@thrivea/organization-client';
import {
  retrieveIndustryCategoriesRequested,
  retrieveIndustrySubcategoriesRequested,
  selectAdminNameAndIndustryStep,
  selectAdminOnboardingFlowStatus,
  selectIndustryCategories,
  selectIndustrySubcategories,
  selectIsCompanyIncorporationStepFinished,
  selectIsCompanyNameAndIndustryStepFinished,
  updateNameAndIndustryStepRequested,
  selectNameAndIndustryStepStatus
} from '@features/admin-onboarding';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from 'react-i18next';

interface AdminOnboardingNameAndIndustryProps {
  isEditable: boolean;
  handleSetActiveSubStep: (stepName: string) => (isExpanded: boolean) => void;
}

const formSchema = z.object({
  registeredName: z.string().min(1, { message: 'Registered name is required.' }),
  industryCategory: z.object({
    id: z.string().min(1, { message: 'Industry category is required.' })
  }),
  industrySubCategory: z.object({
    id: z.string().min(1, { message: 'Industry sub category is required.' })
  })
});

type FormDataType = z.infer<typeof formSchema>;

export const AdminOnboardingNameAndIndustry: React.FC<AdminOnboardingNameAndIndustryProps> = ({ isEditable, handleSetActiveSubStep }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['onboarding', 'common']);
  const nameAndIndustryStep = useAppSelector<NameAndIndustryStep | undefined>(selectAdminNameAndIndustryStep);
  const nameAndIndustryStepStatus = useAppSelector(selectNameAndIndustryStepStatus);
  const industryCategories = useAppSelector<IndustryCategory[]>(selectIndustryCategories);
  const industrySubcategories = useAppSelector<IndustrySubcategory[]>(selectIndustrySubcategories);
  const adminOnboardingFlowStatus = useAppSelector(selectAdminOnboardingFlowStatus);
  const isStepFinished = useAppSelector(selectIsCompanyNameAndIndustryStepFinished);
  const isNextStepFinished = useAppSelector(selectIsCompanyIncorporationStepFinished);
  const { handleSubmit, control, setValue, trigger, getValues, formState, reset } = useForm<FormDataType>({
    mode: 'onChange',
    resolver: zodResolver(formSchema),
    defaultValues: {
      registeredName: '',
      industryCategory: {
        id: ''
      },
      industrySubCategory: {
        id: ''
      }
    }
  });

  const handleSetEditable = () => {
    handleSetActiveSubStep('Step1_1')(!isEditable);
  };

  useEffect(() => {
    dispatch(retrieveIndustryCategoriesRequested());
  }, []);

  useEffect(() => {
    // step is already done, retrieve old info
    if (isStepFinished) {
      if (nameAndIndustryStep) {
        dispatch(
          retrieveIndustrySubcategoriesRequested({
            industryCategoryId: nameAndIndustryStep.industryCategoryId
          } as RetrieveIndustrySubCategoriesRequest)
        );

        reset({
          registeredName: nameAndIndustryStep?.registeredName,
          industryCategory: {
            id: nameAndIndustryStep?.industryCategoryId
          },
          industrySubCategory: {
            id: nameAndIndustryStep?.industrySubcategoryId
          }
        });
      }
    }
  }, [nameAndIndustryStep]);

  if (!isEditable) {
    if (adminOnboardingFlowStatus === ActionStatus.Pending) {
      return (
        <Grid container>
          <Grid size={3}>
            <Stack>
              <Skeleton width={180} height={20} />
              <Skeleton width={90} height={10} />
              <Skeleton width={90} height={10} />
              <Skeleton width={90} height={10} />
            </Stack>
          </Grid>
          <Grid size={9}>
            <Stack
              sx={{
                alignItems: 'flex-end',
                justifyContent: 'center',
                height: '100%'
              }}
            >
              <Skeleton width={90} height={40} />
            </Stack>
          </Grid>
        </Grid>
      );
    } else {
      return (
        <Grid container>
          <Grid size={6}>
            <Stack gap={1}>
              <Typography
                variant="subtitle1"
                sx={{
                  color: (theme) => theme.palette.primary.dark
                }}
              >
                Name & Industry
              </Typography>
              <Stack>
                <Typography
                  sx={{
                    fontWeight: 600
                  }}
                >
                  {getValues('registeredName')}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 600
                  }}
                >
                  {industryCategories.find((ic) => ic.id === getValues('industryCategory.id'))?.name}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 600
                  }}
                >
                  {industrySubcategories.find((isc) => isc.id === getValues('industrySubCategory.id'))?.name}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid size={6}>
            <Stack
              sx={{
                alignItems: 'flex-end',
                justifyContent: 'center',
                height: '100%'
              }}
            >
              <StyledEditButton variant="contained" startIcon={<EditPenIcon />} isStepCompleted={isStepFinished} onClick={handleSetEditable}>
                Edit
              </StyledEditButton>
            </Stack>
          </Grid>
        </Grid>
      );
    }
  } else {
    return (
      <Grid
        container
        rowSpacing={6}
        sx={{
          justifyContent: 'space-between'
        }}
      >
        <Grid size={{ xs: 12, lg: 3 }}>
          <Typography variant="subtitle1">{t('name_and_industry', { ns: 'onboarding' })}</Typography>
          <Typography>{t('name_and_industry_text', { ns: 'onboarding' })}</Typography>
        </Grid>
        <Grid size={{ xs: 12, lg: 8 }}>
          <Box
            component="form"
            onSubmit={handleSubmit((data) => {
              dispatch(
                updateNameAndIndustryStepRequested(
                  new UpdateNameAndIndustryStepRequest({
                    registeredName: data.registeredName,
                    industryCategoryId: data.industryCategory.id,
                    industrySubcategoryId: data.industrySubCategory.id
                  })
                )
              );
              handleSetEditable();
              handleSetActiveSubStep('Step1_2')(!isNextStepFinished);
            })}
          >
            <Stack gap={4}>
              <Controller
                name="registeredName"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <StyledTransparentInput
                    {...field}
                    label={t('company_name_industry_label.registered_name', { ns: 'onboarding' })}
                    required
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                  />
                )}
              />
              <Controller
                name="industryCategory"
                control={control}
                rules={{ required: true }}
                render={({ field }) => {
                  const selectedIndustryCategory = field.value && industryCategories.find((ic) => ic.id === field.value.id);

                  return (
                    <StyledAutocomplete
                      {...field}
                      options={industryCategories}
                      popupIcon={<ChevronDownIcon />}
                      getOptionLabel={(option) => option.name}
                      clearIcon={null}
                      value={selectedIndustryCategory || null}
                      onChange={(_, newValue) => {
                        if (newValue) {
                          field.onChange(newValue);
                          setValue('industrySubCategory', { id: '' }, { shouldValidate: true });

                          dispatch(
                            retrieveIndustrySubcategoriesRequested({
                              industryCategoryId: newValue.id
                            } as RetrieveIndustrySubCategoriesRequest)
                          );
                        }
                      }}
                      renderInput={(params) => {
                        return (
                          <StyledTransparentInput
                            {...params}
                            InputLabelProps={{ shrink: true }}
                            required
                            label={t('company_name_industry_label.industry_category', { ns: 'onboarding' })}
                            id="industryCategory"
                          />
                        );
                      }}
                      renderOption={(props, option) => (
                        <ListItem {...props} key={crypto.randomUUID()}>
                          {option.name}
                        </ListItem>
                      )}
                    />
                  );
                }}
              />
              <Controller
                name="industrySubCategory"
                control={control}
                render={({ field, fieldState }) => {
                  const selectedIndustrySubcategory = field.value && industrySubcategories.find((isc) => isc.id === field.value.id);

                  return (
                    <StyledAutocomplete
                      {...field}
                      options={industrySubcategories}
                      popupIcon={<ChevronDownIcon />}
                      getOptionLabel={(option) => option.name}
                      disableClearable={selectedIndustrySubcategory !== null}
                      value={selectedIndustrySubcategory || null}
                      onChange={(_event, newValue) => {
                        if (newValue) {
                          field.onChange(newValue);
                        }
                      }}
                      renderInput={(params) => {
                        return (
                          <StyledTransparentInput
                            {...params}
                            required
                            label={t('company_name_industry_label.industry_subcategory', { ns: 'onboarding' })}
                            id="industrySubCategory"
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                          />
                        );
                      }}
                      renderOption={(props, option) => (
                        <ListItem {...props} key={crypto.randomUUID()}>
                          {option.name}
                        </ListItem>
                      )}
                    />
                  );
                }}
              />
              <RowCenterStack
                gap={2}
                sx={{
                  justifyContent: 'flex-end',
                  mt: 5
                }}
              >
                <Button
                  variant="contained"
                  type="submit"
                  disabled={!formState.isValid}
                  endIcon={<AddIcon color="#FFFFFF" />}
                  sx={{
                    borderRadius: '10px',
                    minWidth: 100,
                    '&.Mui-disabled': {
                      color: (theme) => theme.palette.common.white,
                      fontWeight: 700
                    }
                  }}
                >
                  {!isStepFinished && t('submit', { ns: 'common' })}
                  {isStepFinished && t('update', { ns: 'common' })}
                  {nameAndIndustryStepStatus === ActionStatus.Pending && <CircularProgress />}
                </Button>
              </RowCenterStack>
            </Stack>
          </Box>
        </Grid>
      </Grid>
    );
  }
};
