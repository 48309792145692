import { Empty } from '@bufbuild/protobuf';
import { createPromiseClient } from '@connectrpc/connect';
import { createGrpcWebTransport } from '@connectrpc/connect-web';
import {
  RetrieveAnniversariesResponse,
  RetrieveBirthdaysResponse,
  RetrieveNewHiresResponse,
  RetrieveUpcomingEventsRequest,
  RetrieveUpcomingEventsResponse,
  WidgetsService
} from '@thrivea/organization-client';
import { msalInstance } from '@/main';
import { tokenRequest } from '@utils/authConfig';

export const widgetClient = createPromiseClient(
  WidgetsService,
  createGrpcWebTransport({
    baseUrl: import.meta.env.VITE_APP_API_URL,
    useBinaryFormat: true
  })
);

export const retrieveEmployeesBirthdays = async (request: Empty): Promise<RetrieveBirthdaysResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);

  return await widgetClient.retrieveBirthdays(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrieveUpcomingEvents = async (request: RetrieveUpcomingEventsRequest): Promise<RetrieveUpcomingEventsResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);

  return await widgetClient.retrieveUpcomingEvents(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrieveAnniversaries = async (request: Empty): Promise<RetrieveAnniversariesResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);

  return await widgetClient.retrieveAnniversaries(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrieveNewHires = async (request: Empty): Promise<RetrieveNewHiresResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);

  return await widgetClient.retrieveNewHires(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};
