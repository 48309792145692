import { Skeleton, Stack, Typography } from '@mui/material';
import { EmployeeDrawerRecentPost, EmployeeDrawerRecentPostSkeleton } from '@features/drawer';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import {
  retrieveEmployeeRecentPostsRequested,
  selectEmployeeCurrentRecentPostsPage,
  selectEmployeeHasMoreRecentPosts,
  selectEmployeeRecentPosts,
  selectEmployeeRecentPostsIds,
  selectEmployeeRecentPostsStatus,
  selectPostReadSasToken
} from '@features/homepage';
import { useTranslation } from 'react-i18next';
import { ActionStatus } from '@/shared';
import { useCallback, useEffect, useRef } from 'react';
import { RetrieveRecentPostsByAuthorRequest } from '@thrivea/networking-client';

interface EmployeeDrawerRecentPostsProps {
  authorName: string;
  authorId: string;
  authorPicture: string;
}

export const EmployeeDrawerRecentPosts: React.FC<EmployeeDrawerRecentPostsProps> = ({ authorName, authorId, authorPicture }) => {
  const { t } = useTranslation(['homepage']);
  const dispatch = useAppDispatch();
  const employeeRecentPostsIds = useAppSelector(selectEmployeeRecentPostsIds);
  const employeeRecentPosts = useAppSelector(selectEmployeeRecentPosts);
  const employeeRecentPostsStatus = useAppSelector(selectEmployeeRecentPostsStatus);
  const postReadSasToken = useAppSelector(selectPostReadSasToken);
  const employeeHasMoreRecentPosts = useAppSelector(selectEmployeeHasMoreRecentPosts);
  const employeeCurrentRecentPostsPage = useAppSelector(selectEmployeeCurrentRecentPostsPage);
  const observer = useRef<IntersectionObserver>();
  const bottomElementRef = useRef<HTMLDivElement>(null);

  const handleScroll = useCallback(() => {
    if (employeeHasMoreRecentPosts) {
      dispatch(
        retrieveEmployeeRecentPostsRequested(
          new RetrieveRecentPostsByAuthorRequest({
            authorId,
            pageNumber: employeeCurrentRecentPostsPage + 1,
            pageSize: 10
          })
        )
      );
    }
  }, [employeeHasMoreRecentPosts, employeeCurrentRecentPostsPage]);

  useEffect(() => {
    observer.current = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && employeeRecentPostsStatus === ActionStatus.Idle) {
          handleScroll();
        }
      },
      { threshold: 1 } // Trigger the observer when the target is 100% visible
    );

    // Start observing the target element
    if (bottomElementRef.current && observer.current) {
      observer.current.observe(bottomElementRef.current);
    }

    return () => {
      // Clean up the observer and scroll event listener when the component unmounts
      if (observer.current && bottomElementRef.current) {
        observer.current.disconnect();
      }
    };
  }, [handleScroll]);

  useEffect(() => {
    dispatch(
      retrieveEmployeeRecentPostsRequested(
        new RetrieveRecentPostsByAuthorRequest({
          authorId,
          pageNumber: 1,
          pageSize: 10
        })
      )
    );
  }, [authorId]);

  return (
    <Stack
      gap={2}
      sx={{
        padding: '16px 0',
        borderRadius: (theme) => theme.spacing(2),
        backgroundColor: employeeRecentPostsIds.length === 0 ? (theme) => theme.palette.grey[800] : 'transparent',
        mt: 2
      }}
    >
      <>
        {employeeRecentPostsIds.length === 0 && (
          <Stack sx={{ padding: '16px' }}>
            <Typography fontWeight={600}>{t('blank_posts_user', { ns: 'homepage', user: authorName })}</Typography>
          </Stack>
        )}
        {employeeRecentPostsIds.length > 0 &&
          employeeRecentPostsIds.map((id) => {
            const post = employeeRecentPosts[id];
            return (
              post.case === 'shoutout' && (
                <EmployeeDrawerRecentPost
                  key={post.id}
                  postId={post.id}
                  authorName={authorName}
                  authorPicture={authorPicture}
                  date={post.publishTime}
                  message={post.text}
                  images={post.mediaUrls.map((mu) => ({
                    key: crypto.randomUUID(),
                    alt: 'media url',
                    src: new URL(postReadSasToken, mu).toString(),
                    name: mu
                  }))}
                  docs={post.docUrls.map((du) => ({
                    key: crypto.randomUUID(),
                    alt: 'media url',
                    src: new URL(postReadSasToken, du).toString(),
                    name: du
                  }))}
                />
              )
            );
          })}
        {employeeRecentPostsStatus === ActionStatus.Pending &&
          Array.from({ length: 6 }).map(() => <EmployeeDrawerRecentPostSkeleton key={crypto.randomUUID()} />)}
        <div ref={bottomElementRef} style={{ width: '100%', height: '1px' }}></div>
      </>
      {employeeRecentPostsStatus === ActionStatus.Pending &&
        Array.from({ length: 6 }).map(() => <EmployeeDrawerRecentPostSkeleton key={crypto.randomUUID()} />)}
    </Stack>
  );
};
