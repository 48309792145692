import { Close } from '@mui/icons-material';
import {
  OneColumnIcon,
  RowCenterStack,
  StyledEmployeeRecordBuilderDrawerTabs,
  StyledEmployeeRecordFieldChip,
  StyledEmployeeRecordFieldColumnWidthTab,
  ThreeColumnIcon,
  TwoColumnIcon
} from '@/shared';
import { a11yProps } from '@/utils';
import { createContext, useMemo, useState } from 'react';
import { IconButton } from '@mui/material';
import { DraggableSyntheticListeners } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { EmployeeRecordDragHandle } from '@features/drawer';
import { ErTemplateField } from '@thrivea/organization-client';
import { useAppDispatch } from 'src/app/hooks';
import { removeFieldFromSection, updateField } from 'src/features/employee-record-builder';

interface EmployeeRecordDraggableFieldProps {
  field: ErTemplateField;
}

interface Context {
  attributes: Record<string, any>;
  listeners: DraggableSyntheticListeners;
  ref(node: HTMLElement | null): void;
}

export const SortableItemContext = createContext<Context>({
  attributes: {},
  listeners: undefined,
  ref() {}
});

export const EmployeeRecordDraggableField: React.FC<EmployeeRecordDraggableFieldProps> = ({ field }) => {
  const dispatch = useAppDispatch();
  const [activeColumnWidthTab, setActiveColumnWidthTab] = useState(field.kind.case === 'scalarField' && field.kind.value.width - 1);
  const { attributes, listeners, setNodeRef, setActivatorNodeRef, isDragging, transform, transition } = useSortable({ id: field.kind.value!.id });
  const context = useMemo(
    () => ({
      attributes,
      listeners,
      ref: setActivatorNodeRef
    }),
    [attributes, listeners, setActivatorNodeRef]
  );

  const handleChangeActiveColumnWidthTab = (_event: React.SyntheticEvent, newValue: number) => {
    console.log('handleChangeActiveColumnWidthTab', newValue);
    setActiveColumnWidthTab(newValue);
    dispatch(
      updateField({
        id: field.kind.value!.id,
        width: newValue + 1
      })
    );
  };

  console.log('EmployeeRecordDraggableField', field);

  return (
    <SortableItemContext.Provider value={context}>
      <RowCenterStack
        ref={setNodeRef}
        sx={{
          justifyContent: 'space-between',
          marginTop: '30px',
          opacity: isDragging ? 0.25 : 1,
          transform: CSS.Translate.toString(transform),
          transition
        }}
      >
        <RowCenterStack>
          <EmployeeRecordDragHandle />
          <StyledEmployeeRecordFieldChip
            icon={
              <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M0.414 12.414V0.78H3.172V1.746H1.506V11.448H3.172V12.414H0.414ZM4.84369 12.708V0.499999H5.93569V12.708H4.84369ZM13.2378 12.414H10.4798V11.448H12.1458V1.746H10.4798V0.78H13.2378V12.414Z"
                  fill="#DDD5E8"
                />
              </svg>
            }
            label={field.kind.value!.name}
            selected
          />
        </RowCenterStack>
        <RowCenterStack gap={1}>
          <StyledEmployeeRecordBuilderDrawerTabs value={activeColumnWidthTab} onChange={handleChangeActiveColumnWidthTab} aria-label="Field width">
            <StyledEmployeeRecordFieldColumnWidthTab label={<ThreeColumnIcon />} {...a11yProps('employee_record_three_column_width', 0)} />
            <StyledEmployeeRecordFieldColumnWidthTab label={<TwoColumnIcon />} {...a11yProps('employee_record_two_column_width', 1)} />
            <StyledEmployeeRecordFieldColumnWidthTab label={<OneColumnIcon />} {...a11yProps('employee_record_one_column_width', 2)} />
          </StyledEmployeeRecordBuilderDrawerTabs>
          <IconButton
            onClick={() => dispatch(removeFieldFromSection(field.kind.value!.id))}
            sx={{
              width: 16,
              height: 16,
              padding: 0,
              backgroundColor: (theme) => theme.palette.common.black,
              '&:hover': {
                backgroundColor: (theme) => theme.palette.common.black
              }
            }}
          >
            <Close sx={{ fill: (theme) => theme.palette.common.white, fontSize: '0.8rem' }} />
          </IconButton>
        </RowCenterStack>
      </RowCenterStack>
    </SortableItemContext.Provider>
  );
};
