import React from 'react';

interface OneColumnIconProps {
  width?: number;
  height?: number;
  color?: string;
}

export const OneColumnIcon: React.FC<OneColumnIconProps> = ({ width = 20, height = 8, color = '#251D38' }: OneColumnIconProps) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path id="Vector" d="M1 1H19M1 7H19" stroke={color} strokeLinecap="round" />
    </svg>
  );
};
