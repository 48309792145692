import React from 'react';

interface EmailIconProps {
  size?: number; // Optional size prop to control the icon size
  color?: string; // Optional color prop to control the icon color
}

export const EmailIcon: React.FC<EmailIconProps> = ({ size = 24, color = '#000000' }) => {
  return (
    <svg width={size} height={(size * 24) / 22} viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="mail">
        <g id="Vector">
          <path
            d="M3.66659 4.67969H18.3333C19.3416 4.67969 20.1666 5.53958 20.1666 6.59055V18.0557C20.1666 19.1067 19.3416 19.9666 18.3333 19.9666H3.66659C2.65825 19.9666 1.83325 19.1067 1.83325 18.0557V6.59055C1.83325 5.53958 2.65825 4.67969 3.66659 4.67969Z"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M3.66659 4.67969H18.3333C19.3416 4.67969 20.1666 5.53958 20.1666 6.59055V18.0557C20.1666 19.1067 19.3416 19.9666 18.3333 19.9666H3.66659C2.65825 19.9666 1.83325 19.1067 1.83325 18.0557V6.59055C1.83325 5.53958 2.65825 4.67969 3.66659 4.67969Z"
            stroke={color}
            strokeOpacity="0.2"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <g id="Vector_2">
          <path d="M20.1666 6.59045L10.9999 13.2785L1.83325 6.59045" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          <path
            d="M20.1666 6.59045L10.9999 13.2785L1.83325 6.59045"
            stroke={color}
            strokeOpacity="0.2"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  );
};
