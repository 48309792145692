import { useTranslation } from 'react-i18next';
import { Typography, Avatar, Button, Stack } from '@mui/material';
import { RetrieveEmployeeBasicInfoRequest, RetrieveEmployeesByJobTitleRequest } from '@thrivea/organization-client';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import {
  retrieveEmployeeBasicInfoRequested,
  retrieveEmployeesByJobTitleRequested,
  selectCurrentJobTitleName,
  selectJobTitleEmployeesCurrentListPage,
  selectJobTitleEmployeesListById,
  selectJobTitleEmployeesListIds,
  selectJobTitleEmployeesStatus,
  selectJobTitleHasMoreEmployees
} from '@features/admin-settings';
import { selectEmployeeProfileAndCoverReadSasToken } from '@features/employee-profile';
import { RowCenterStack, AlertIcon, ActionStatus, PictureSizeSuffix, StyledProfileLink } from '@/shared';
import { useCallback, useEffect, useRef } from 'react';
import { DrawerItemSkeleton } from './DrawerItemSkeleton';
import { pictureUrl } from '@/utils/pictureUrl';

export const JobTitleEmployeeList = () => {
  const { t } = useTranslation(['common', 'settings_permissions']);
  const dispatch = useAppDispatch();
  const currentJobTitleName = useAppSelector(selectCurrentJobTitleName);
  const jobTitleEmployeesListIds = useAppSelector(selectJobTitleEmployeesListIds);
  const jobTitleEmployeesById = useAppSelector(selectJobTitleEmployeesListById);
  const jobTitleEmployeesListStatus = useAppSelector(selectJobTitleEmployeesStatus);
  const jobTitleHasMoreEmployees = useAppSelector(selectJobTitleHasMoreEmployees);
  const jobTitleEmployeesCurrentListPage = useAppSelector(selectJobTitleEmployeesCurrentListPage);
  const employeeProfileSasToken = useAppSelector(selectEmployeeProfileAndCoverReadSasToken);
  const observer = useRef<IntersectionObserver>();
  const bottomElementRef = useRef<HTMLDivElement>(null);

  const handleRetrieveSingleEmployeeInfo = (employeeId: string) => {
    dispatch(
      retrieveEmployeeBasicInfoRequested(
        new RetrieveEmployeeBasicInfoRequest({
          employeeId
        })
      )
    );
  };

  const handleScroll = useCallback(() => {
    if (jobTitleHasMoreEmployees) {
      dispatch(
        retrieveEmployeesByJobTitleRequested(
          new RetrieveEmployeesByJobTitleRequest({
            jobTitle: '', // set later
            pageNumber: jobTitleEmployeesCurrentListPage + 1,
            pageSize: 10
          })
        )
      );
    }
  }, [jobTitleHasMoreEmployees, jobTitleEmployeesCurrentListPage]);

  useEffect(() => {
    observer.current = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && jobTitleEmployeesListStatus === ActionStatus.Idle) {
          handleScroll();
        }
      },
      { threshold: 1 } // Trigger the observer when the target is 100% visible
    );

    // Start observing the target element
    if (bottomElementRef.current && observer.current) {
      observer.current.observe(bottomElementRef.current);
    }

    return () => {
      // Clean up the observer and scroll event listener when the component unmounts
      if (observer.current && bottomElementRef.current) {
        observer.current.disconnect();
      }
    };
  }, [handleScroll]);

  return (
    <Stack
      spacing={1}
      sx={{
        padding: '16px 20px',
        backgroundColor: (theme) => theme.palette.customTheme.contBgr,
        width: '100%',
        height: '100%',
        borderRadius: (theme) => theme.spacing(2)
      }}
    >
      {jobTitleEmployeesListIds.length === 0 && (
        <RowCenterStack gap={1}>
          <AlertIcon />
          <Typography>{t('job_title_employees_blank', { ns: 'settings_permissions', name: currentJobTitleName })}</Typography>
        </RowCenterStack>
      )}
      {jobTitleEmployeesListIds.length !== 0 &&
        jobTitleEmployeesListIds.map((id) => {
          const employee = jobTitleEmployeesById[id];
          return (
            <Stack
              key={crypto.randomUUID()}
              direction="row"
              spacing={2}
              sx={{
                borderRadius: '8px',
                padding: '8px',
                transition: 'background-color 0.3s ease-in-out',
                '&:hover': {
                  backgroundColor: (theme) => theme.palette.common.white,
                  '& .Mui-ProfileLink': {
                    opacity: 1
                  }
                }
              }}
            >
              <Avatar
                src={pictureUrl(employee.profilePicture, employeeProfileSasToken, PictureSizeSuffix.sm)}
                alt={employee.name}
                variant="rounded"
                sx={{
                  border: (theme) => `1px solid ${theme.palette.customTheme.contColor}`,
                  width: 38,
                  height: 38
                }}
              />
              <Stack
                direction="row"
                sx={{
                  width: '100%',
                  justifyContent: 'space-between'
                }}
              >
                <Stack>
                  <Typography fontWeight={700}>{employee.name}</Typography>
                  <Typography>{employee.jobTitle}</Typography>
                </Stack>
                <StyledProfileLink onClick={() => handleRetrieveSingleEmployeeInfo(employee.id)} className="Mui-ProfileLink" variant="text">
                  {t('view_profile')}
                </StyledProfileLink>
              </Stack>
            </Stack>
          );
        })}
      <div ref={bottomElementRef} style={{ width: '100%', height: '1px' }}></div>
      {jobTitleEmployeesListStatus === ActionStatus.Pending && Array.from({ length: 6 }).map(() => <DrawerItemSkeleton key={crypto.randomUUID()} />)}
    </Stack>
  );
};
