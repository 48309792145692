import { StyledEmployeeRecordFieldChip } from '@/shared';
import { ErbScalarField, ErTemplateField } from '@thrivea/organization-client';
import React, { MouseEventHandler } from 'react';

interface EmployeeRecordBuilderDrawerFieldProps {
  field: ErTemplateField;
  selected?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement> | undefined;
}

export const EmployeeRecordBuilderDrawerField: React.FC<EmployeeRecordBuilderDrawerFieldProps> = ({ field, selected, onClick }) => {
  return (
    <StyledEmployeeRecordFieldChip
      icon={
        selected ? (
          <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0.414 12.414V0.78H3.172V1.746H1.506V11.448H3.172V12.414H0.414ZM4.84369 12.708V0.499999H5.93569V12.708H4.84369ZM13.2378 12.414H10.4798V11.448H12.1458V1.746H10.4798V0.78H13.2378V12.414Z"
              fill="#DDD5E8"
            />
          </svg>
        ) : (
          <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0.414 12.414V0.78H3.172V1.746H1.506V11.448H3.172V12.414H0.414ZM13.2378 12.414H10.4798V11.448H12.1458V1.746H10.4798V0.78H13.2378V12.414Z"
              fill="#9747FF"
            />
            <path d="M4.84369 12.708V0.499999H5.93569V12.708H4.84369Z" fill="#251D38" />
          </svg>
        )
      }
      label={field.kind.value!.name}
      onClick={onClick}
      selected={selected}
    />
  );
};
