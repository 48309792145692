import React from 'react';

interface ThreeColumnIconProps {
  width?: number;
  height?: number;
  color?: string;
}

export const ThreeColumnIcon: React.FC<ThreeColumnIconProps> = ({ width = 20, height = 8, color = '#251D38' }: ThreeColumnIconProps) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path id="Vector" d="M1 1H5M8 1H12M15 1H19M1 7H5M8 7H12M15 7H19" stroke={color} strokeLinecap="round" />
    </svg>
  );
};
