import { createPromiseClient } from '@connectrpc/connect';
import { createGrpcWebTransport } from '@connectrpc/connect-web';
import {
  AssetsService,
  RetrieveFilesRequest,
  RetrieveFilesResponse,
  RetrieveTopLevelFoldersRequest,
  RetrieveTopLevelFoldersResponse,
  UploadFilesRequest
} from '@thrivea/organization-client';
import { msalInstance } from '@/main';
import { tokenRequest } from '@utils/authConfig';
import { Empty } from '@bufbuild/protobuf';

export const assetsClient = createPromiseClient(
  AssetsService,
  createGrpcWebTransport({
    baseUrl: import.meta.env.VITE_APP_API_URL,
    useBinaryFormat: true
  })
);

export const retrieveFiles = async (request: RetrieveFilesRequest): Promise<RetrieveFilesResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);

  return await assetsClient.retrieveFiles(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrieveTopLevelFolders = async (request: RetrieveTopLevelFoldersRequest): Promise<RetrieveTopLevelFoldersResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);

  return await assetsClient.retrieveTopLevelFolders(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const uploadFiles = async (request: UploadFilesRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);

  return await assetsClient.uploadFiles(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};
