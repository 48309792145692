import { alertClasses, Box, Button, Collapse, collapseClasses, Link, paperClasses, Snackbar, snackbarClasses, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { CloseIcon, RowCenterStack, StyledAlert } from '@/shared';
import { closePostDraftSnackbar, selectIsPostDraftSnackbarOpen } from '@features/snackbar/snackbar.slice';
import { useTranslation } from 'react-i18next';
import { applyDraftedPost, clearDraftedPost, deleteDraftPostsRequested, selectPostDraft } from '@features/homepage';
import { DateTime } from 'luxon';
import { DeleteDraftPostsRequest } from '@thrivea/networking-client';
import { selectCurrentEmployeeId } from '@app/user';

export const PostDraftSnackbar = () => {
  const { t } = useTranslation(['common', 'homepage']);
  const dispatch = useAppDispatch();
  const isPostDraftSnackbarOpen = useAppSelector(selectIsPostDraftSnackbarOpen);
  const currentEmployeeId = useAppSelector(selectCurrentEmployeeId);
  const postDraft = useAppSelector(selectPostDraft);

  const handleSnackbarClose = () => {
    dispatch(closePostDraftSnackbar());
    dispatch(clearDraftedPost());
    dispatch(
      deleteDraftPostsRequested(
        new DeleteDraftPostsRequest({
          authorId: currentEmployeeId
        })
      )
    );
  };

  const handleSetPostDraft = () => {
    dispatch(applyDraftedPost());
    dispatch(
      deleteDraftPostsRequested(
        new DeleteDraftPostsRequest({
          authorId: currentEmployeeId
        })
      )
    );
    dispatch(closePostDraftSnackbar());
  };

  return (
    <Snackbar
      open={isPostDraftSnackbarOpen}
      TransitionComponent={Collapse}
      sx={{
        [`&.${snackbarClasses.root}`]: {
          position: 'sticky',
          top: 16
        },
        [`& .${collapseClasses.root}`]: {
          width: '100%',
          mr: 2,
          mb: 2
        },
        [`& .${paperClasses.root}.${alertClasses.root}`]: {
          pr: 2
        }
      }}
    >
      <Box
        sx={{
          width: '100%'
        }}
      >
        <StyledAlert
          action={
            <Button onClick={handleSnackbarClose} endIcon={<CloseIcon />}>
              <Typography>{t('dismiss_draft', { ns: 'common' })}</Typography>
            </Button>
          }
          severity="success"
          sx={{ width: '100%', '& .MuiAlert-action': { padding: 0 } }}
        >
          <RowCenterStack
            gap={1}
            sx={{
              justifyContent: 'space-between',
              width: '100%'
            }}
          >
            <Typography
              sx={{
                color: (theme) => theme.palette.common.black
              }}
            >
              {t('post_draft_available', {
                ns: 'common',
                time: DateTime.fromISO(postDraft.savedAt).toLocaleString({ month: 'long', day: 'numeric', hour: 'numeric' })
              })}
            </Typography>
            <Link
              underline="always"
              onClick={handleSetPostDraft}
              sx={{
                color: (theme) => theme.palette.common.white,
                cursor: 'pointer'
              }}
            >
              {t('continue_writing', { ns: 'homepage' })}
            </Link>
          </RowCenterStack>
        </StyledAlert>
      </Box>
    </Snackbar>
  );
};
