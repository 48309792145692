import React from 'react';

interface MentionIconProps {
  width?: number;
  height?: number;
  color?: string;
}

export const MentionIcon: React.FC<MentionIconProps> = ({ width: width = 24, height = 25, color = '#251D38' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none">
      <path
        d="M11.7222 2.7897C6.6538 2.93508 2.43457 7.15509 2.28921 12.2245C2.1362 17.5731 6.33248 21.9959 11.596 22.214C11.8179 22.2216 12.0015 22.038 12.0015 21.8161V20.6492C12.0015 20.4426 11.837 20.2781 11.6266 20.2666C7.00954 20.0485 3.43295 15.7788 4.38161 10.9428C4.97452 7.91645 7.41502 5.4755 10.4408 4.88248C15.4059 3.9107 19.7743 7.70603 19.7743 12.5038C19.7743 13.7816 19.5066 14.6884 18.994 15.1245C18.5311 15.5186 17.9688 15.4382 17.965 15.4344C17.9038 15.4229 17.8426 15.4191 17.7814 15.4191C17.2726 15.4191 16.8595 15.0059 16.8595 14.4971V8.6166C16.8595 8.08097 16.4234 7.64481 15.8879 7.64481C15.3524 7.64481 14.9163 8.08097 14.9163 8.6166V8.64338C13.8414 7.82846 12.4414 7.42673 10.9228 7.76724C9.09047 8.17662 7.61011 9.66491 7.24288 11.509C6.61937 14.6233 8.99484 17.3665 12.0015 17.3665C13.302 17.3665 14.4802 16.8462 15.3524 16.0083C15.8458 16.8003 16.7103 17.3397 17.7011 17.3665C17.789 17.378 18.9481 17.7338 20.2142 16.6396C21.1935 15.7941 21.7137 14.4244 21.7137 12.5076C21.7176 7.05179 17.2076 2.63283 11.7222 2.7897ZM12.0015 15.4191C10.3949 15.4191 9.08664 14.1106 9.08664 12.5038C9.08664 10.8969 10.3949 9.58839 12.0015 9.58839C13.6081 9.58839 14.9163 10.8969 14.9163 12.5038C14.9163 14.1106 13.6081 15.4191 12.0015 15.4191Z"
        fill={color}
      />
    </svg>
  );
};
