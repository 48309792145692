import { z } from 'zod';
import { TFunction } from 'i18next';

export const createSiteLocationSchema = (t: TFunction) =>
  z.object({
    siteName: z.string().min(
      1,
      t('field_required', {
        field: t('site_name', { ns: 'settings_sites' }),
        ns: 'common'
      })
    ),
    addressLine1: z
      .union([
        z.string().min(
          1,
          t('field_optional_min_length', {
            field: t('address_two', { ns: 'employee_profile' }),
            length: t('character_with_count', { count: 1, ns: 'common' }),
            ns: 'common'
          })
        ),
        z.string().length(0)
      ])
      .optional()
      .transform((e) => (e === '' ? undefined : e)),
    addressLine2: z
      .union([
        z.string().min(
          1,
          t('field_optional_min_length', {
            field: t('address_two', { ns: 'settings_sites' }),
            length: t('character_with_count', { count: 1, ns: 'common' }),
            ns: 'common'
          })
        ),
        z.string().length(0)
      ])
      .optional()
      .transform((e) => (e === '' ? undefined : e)),
    city: z.string().min(
      1,
      t('field_required', {
        field: t('city', { ns: 'settings_sites' }),
        ns: 'common'
      })
    ),
    postalCode: z
      .union([
        z.string().min(
          1,
          t('field_optional_min_length', {
            field: t('postal_code', { ns: 'settings_sites' }),
            length: t('character_with_count', { count: 2, ns: 'common' }),
            ns: 'common'
          })
        ),
        z.string().length(0)
      ])
      .optional()
      .transform((e) => (e === '' ? undefined : e)),
    country: z.string().min(
      1,
      t('field_required', {
        field: t('country', { ns: 'employee_profile' }),
        ns: 'common'
      })
    ),
    state: z
      .union([
        z.string().min(
          2,
          t('field_optional_min_length', {
            field: t('state', { ns: 'employee_profile' }),
            length: t('character_with_count', { count: 2, ns: 'common' }),
            ns: 'common'
          })
        ),
        z.string().length(0)
      ])
      .optional()
      .transform((e) => (e === '' ? undefined : e)),
    province: z
      .union([
        z.string().min(
          2,
          t('field_optional_min_length', {
            field: t('province', { ns: 'employee_profile' }),
            length: t('character_with_count', { count: 2, ns: 'common' }),
            ns: 'common'
          })
        ),
        z.string().length(0)
      ])
      .optional()
      .transform((e) => (e === '' ? undefined : e)),
    region: z
      .union([
        z.string().min(
          2,
          t('field_optional_min_length', {
            field: t('region', { ns: 'employee_profile' }),
            length: t('character_with_count', { count: 2, ns: 'common' }),
            ns: 'common'
          })
        ),
        z.string().length(0)
      ])
      .optional()
      .transform((e) => (e === '' ? undefined : e)),
    reason: z
      .union([
        z.string().min(
          2,
          t('field_optional_min_length', {
            field: t('reason', { ns: 'employee_profile' }),
            length: t('character_with_count', { count: 2, ns: 'common' }),
            ns: 'common'
          })
        ),
        z.string().length(0)
      ])
      .optional()
      .transform((e) => (e === '' ? undefined : e))
  });

export const createSiteLocalizationSchema = (t: TFunction) =>
  z.object({
    language: z.string().min(
      1,
      t('field_required', {
        field: t('language', { ns: 'settings_sites' }),
        ns: 'common'
      })
    ),
    currency: z.string().min(
      1,
      t('field_required', {
        field: t('currency', { ns: 'employee_profile' }),
        ns: 'common'
      })
    ),
    timeZone: z.string().min(
      1,
      t('field_required', {
        field: t('time_zone', { ns: 'employee_profile' }),
        ns: 'common'
      })
    ),
    timeFormat: z.string().min(
      1,
      t('field_required', {
        field: t('time_format', { ns: 'employee_profile' }),
        ns: 'common'
      })
    ),
    dateFormat: z.string().min(
      1,
      t('field_required', {
        field: t('date_format', { ns: 'employee_profile' }),
        ns: 'common'
      })
    ),
    firstDayOfTheWeek: z.number().min(
      0,
      t('field_required', {
        field: t('first_day_of_the_week', { ns: 'employee_profile' }),
        ns: 'common'
      })
    )
  });

export const createSiteCalendarSchema = (t: TFunction) =>
  z.object({
    calendarId: z.string().min(
      1,
      t('field_required', {
        field: t('calendar_id', { ns: 'settings_sites' }),
        ns: 'common'
      })
    )
  });

export const createSiteWorkingPatternSchema = (t: TFunction) =>
  z.object({
    workingPatternId: z.string().min(
      1,
      t('field_required', {
        field: t('working_pattern_id', { ns: 'settings_sites' }),
        ns: 'common'
      })
    )
  });

export const createWorkingPatternSchema = (t: TFunction) =>
  z.object({
    name: z.string().min(
      1,
      t('field_required', {
        field: t('add_working_pattern_form.name_label', { ns: 'settings_working_patterns' }),
        ns: 'common'
      })
    ),
    description: z.string().min(
      1,
      t('field_required', {
        field: t('add_working_pattern_form.desc_label', { ns: 'settings_working_patterns' }),
        ns: 'common'
      })
    ),
    workDays: z
      .object({
        id: z.string().optional(),
        name: z.string().optional(),
        hours: z
          .number()
          .min(0, t('min_workday_value', { ns: 'settings_working_patterns' }))
          .max(24, t('max_workday_value', { ns: 'settings_working_patterns' }))
      })
      .array()
  });
