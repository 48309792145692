import { useTheme } from '@mui/material';
import React from 'react';

interface HomeIconAttributes {
  className?: string;
}

export const HomeIcon: React.FC<HomeIconAttributes> = ({ className = '' }: HomeIconAttributes) => {
  const theme = useTheme();

  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 25 24">
      <path
        fill={theme.palette.common.black}
        d="M23.436 10.409 21.5 9.026V3.749a1.502 1.502 0 0 0-1.5-1.5h-2.25a1.502 1.502 0 0 0-1.5 1.5v1.527L13.37 3.219a1.503 1.503 0 0 0-1.743.001L1.564 10.41a.75.75 0 0 0 .872 1.22l1.064-.76V19.5a2.253 2.253 0 0 0 2.25 2.25h13.5a2.253 2.253 0 0 0 2.25-2.25V10.87l1.064.76a.75.75 0 1 0 .872-1.22Zm-5.686-6.66H20v4.205l-2.25-1.607V3.75ZM14 20.25h-3V15.75a.75.75 0 0 1 .75-.75h1.5a.75.75 0 0 1 .75.75v4.502Zm6-.75a.75.75 0 0 1-.75.75H15.5V15.75a2.252 2.252 0 0 0-2.25-2.25h-1.5a2.253 2.253 0 0 0-2.25 2.25v4.502H5.75a.75.75 0 0 1-.75-.75V9.797l7.5-5.357 3.965 2.832c.03.025.061.048.095.068L20 9.797v9.704Z"
      />
    </svg>
  );
};
