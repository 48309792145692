import { useTheme } from '@mui/material';
import React from 'react';

interface EditPenIconAttributes {
  className?: string;
}

export const EditPenIcon: React.FC<EditPenIconAttributes> = ({ className = '' }: EditPenIconAttributes) => {
  const theme = useTheme();

  return (
    <svg width="25" height="28" viewBox="0 0 25 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="edit-2">
        <path
          id="Vector"
          d="M17.2148 3.58799C17.4775 3.28686 17.7893 3.048 18.1325 2.88503C18.4756 2.72206 18.8434 2.63818 19.2148 2.63818C19.5863 2.63818 19.9541 2.72206 20.2972 2.88503C20.6404 3.048 20.9522 3.28686 21.2148 3.58799C21.4775 3.88912 21.6858 4.2466 21.828 4.64004C21.9701 5.03348 22.0433 5.45517 22.0433 5.88103C22.0433 6.30688 21.9701 6.72857 21.828 7.12201C21.6858 7.51545 21.4775 7.87294 21.2148 8.17406L7.71484 23.6521L2.21484 25.3718L3.71484 19.066L17.2148 3.58799Z"
          stroke={theme.palette.common.white}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector 1"
          d="M4 19.1308L5.2332 20.5447M8 23.7169L5.2332 20.5447M20.5 3.07959L5.2332 20.5447M21.5 4.22611L6.2332 21.6912"
          stroke={theme.palette.common.white}
        />
      </g>
    </svg>
  );
};
