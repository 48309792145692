import groupBy from 'lodash/groupBy';
import { isEmployeeListItem, isJobTitleListItem, isSiteListItem, OptionType } from '@features/admin-settings';

export const groupOptionsByType = (options: OptionType[]): { [key: string]: OptionType[] } =>
  groupBy(options, (option) => {
    if (isSiteListItem(option)) {
      return 'Sites';
    }

    if (isJobTitleListItem(option)) {
      return 'Job Titles';
    }

    if (isEmployeeListItem(option)) {
      return `Employees`;
    }

    return 'Other';
  });
