import { ForwardedRef, useId, useState, forwardRef } from 'react';
import Grid from '@mui/material/Grid2';
import { Box, Stack } from '@mui/material';
import {
  RowCenterStack,
  CancelEditIcon,
  StyledCancelButton,
  StyledFormSubmitButton,
  InlineImageUpload,
  MultiSizeImageBlobs,
  PictureSizeSuffix,
  StyledFormLabel,
  StyledEmployeeGenericForm,
  EditPenIcon,
  StyledErbButton,
  PrimaryTypographyHeader
} from '@/shared';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import {
  removeEmployeeProfileCoverPictureFileMultiSize,
  removeEmployeeProfilePictureFileMultipleSizes,
  resetEmployeeProfileCoverPictureFileMultiSize,
  resetEmployeeProfilePictureFileMultipleSizes,
  selectEmployeeCoverPictureFileMultiSize,
  selectEmployeeProfileAndCoverReadSasToken,
  selectEmployeeProfileMedia,
  selectEmployeeProfilePictureFileMultiSize,
  setEmployeeCoverPictureFileMultipleSizes,
  setEmployeeProfilePictureFileMultipleSizes,
  updateProfileMediaRequested
} from '@features/employee-profile';
import { UpdateProfileMediaRequest } from '@thrivea/organization-client';
import { Controller, useForm } from 'react-hook-form';
import { pictureUrl } from '@utils/pictureUrl';
import { AllowedTo } from 'react-abac';
import { GroupPermissions, ProfileCategoryInfo } from '@features/abac';
import { snakeCase } from 'lodash';

interface PersonalBrandingCategoryProps {
  ref: ForwardedRef<HTMLDivElement>;
  employeeId: string;
}

const EMPLOYEE_PROFILE_PICTURE_PLACEHOLDER = '/images/logo-placeholder.png';

export const PersonalBrandingCategory = forwardRef<HTMLDivElement, PersonalBrandingCategoryProps>(({ employeeId }, ref) => {
  const { t } = useTranslation(['common', 'employee_profile']);
  const name = t('personal_branding', { ns: 'employee_profile' });
  const dispatch = useAppDispatch();
  const [isEditable, setIsEditable] = useState(false);
  const employeeProfileMedia = useAppSelector(selectEmployeeProfileMedia);
  const employeeProfilePictureFileMultiSize = useAppSelector(selectEmployeeProfilePictureFileMultiSize);
  const employeeProfileCoverFileMultiSize = useAppSelector(selectEmployeeCoverPictureFileMultiSize);
  const employeeProfileAndCoverReadSasToken = useAppSelector(selectEmployeeProfileAndCoverReadSasToken);
  const profilePictureId = useId();
  const coverPictureId = useId();

  const { control, handleSubmit, reset } = useForm<UpdateProfileMediaRequest>({
    mode: 'all',
    defaultValues: {
      employeeId: employeeId,
      profilePictureUrl: employeeProfileMedia.profilePictureUrl,
      coverPictureUrl: employeeProfileMedia.coverPictureUrl
    }
  });

  const handleToggleEditable = () => {
    setIsEditable(!isEditable);
  };

  const handleCancel = () => {
    dispatch(resetEmployeeProfilePictureFileMultipleSizes());
    dispatch(resetEmployeeProfileCoverPictureFileMultiSize());
    setIsEditable(false);
  };

  const handleSetEmployeeProfilePictureFile = (file: MultiSizeImageBlobs | undefined) => {
    if (file) {
      dispatch(setEmployeeProfilePictureFileMultipleSizes(file));
    } else {
      dispatch(setEmployeeProfilePictureFileMultipleSizes(undefined));
    }
  };

  const handleRemoveEmployeeProfilePictureFile = () => {
    dispatch(removeEmployeeProfilePictureFileMultipleSizes());
    reset({
      employeeId: employeeId,
      profilePictureUrl: ''
    });
  };

  const handleSetEmployeeProfileCoverFileMultipleSize = (file: MultiSizeImageBlobs | undefined) => {
    if (file) {
      dispatch(setEmployeeCoverPictureFileMultipleSizes(file));
    } else {
      dispatch(setEmployeeCoverPictureFileMultipleSizes(undefined));
    }
  };

  const handleRemoveEmployeeProfileCoverFile = () => {
    dispatch(removeEmployeeProfileCoverPictureFileMultiSize());
    reset({
      employeeId: employeeId,
      coverPictureUrl: ''
    });
  };

  const onSubmit = (data: UpdateProfileMediaRequest) => {
    dispatch(updateProfileMediaRequested(data));
    setIsEditable(false);
  };

  return (
    <StyledEmployeeGenericForm isEditable={isEditable} id={snakeCase(name)} ref={ref}>
      <RowCenterStack
        sx={{
          padding: (theme) => theme.spacing(2),
          backgroundColor: (theme) => theme.palette.customTheme.headerColorSub,
          justifyContent: 'space-between'
        }}
      >
        <PrimaryTypographyHeader>{name}</PrimaryTypographyHeader>
        <RowCenterStack
          gap={1}
          sx={{
            display: isEditable ? 'none' : 'inline-flex'
          }}
        >
          <AllowedTo perform={GroupPermissions.EDIT_PROFILE} data={{ employeeId, categoryName: 'personal_branding' } as ProfileCategoryInfo}>
            <StyledErbButton variant="contained" startIcon={<EditPenIcon />} onClick={handleToggleEditable}>
              Edit
            </StyledErbButton>
          </AllowedTo>
        </RowCenterStack>
        <RowCenterStack
          gap={2}
          sx={{
            display: isEditable ? 'flex' : 'none'
          }}
        >
          <StyledCancelButton variant="contained" onClick={handleCancel} startIcon={<CancelEditIcon />}>
            {t('cancel', { ns: 'common' })}
          </StyledCancelButton>
          <StyledFormSubmitButton type="submit" variant="contained">
            {t('done', { ns: 'common' })}
          </StyledFormSubmitButton>
        </RowCenterStack>
      </RowCenterStack>
      <Box
        component="form"
        name={name}
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          backgroundColor: 'transparent'
        }}
      >
        <Grid
          container
          rowSpacing={2}
          sx={{
            p: 2
          }}
        >
          <Grid size={12}>
            <Controller
              name="profilePictureUrl"
              control={control}
              render={({ field }) => (
                <Stack gap={2}>
                  <StyledFormLabel disabled shrink htmlFor={profilePictureId}>
                    Profile picture
                  </StyledFormLabel>
                  <Box id={profilePictureId}>
                    <InlineImageUpload
                      isEditable={isEditable}
                      width={150}
                      height={150}
                      sm={1000}
                      md={1000}
                      lg={1000}
                      placeholderSrc={EMPLOYEE_PROFILE_PICTURE_PLACEHOLDER}
                      urlSrc={pictureUrl(field.value, employeeProfileAndCoverReadSasToken, PictureSizeSuffix.lg)!}
                      imageAltText="user profile picture"
                      buttonTextLabel={t('upload_btn_txt', { ns: 'employee_profile' })}
                      uploadTextLabel={t('upload_copy', { ns: 'employee_profile' })}
                      removeTextLabel={t('remove_logo_txt', { ns: 'employee_profile' })}
                      multiSizeImage={employeeProfilePictureFileMultiSize}
                      onChange={handleSetEmployeeProfilePictureFile}
                      onRemove={handleRemoveEmployeeProfilePictureFile}
                    />
                  </Box>
                </Stack>
              )}
            />
          </Grid>
          <Grid size={12}>
            <Controller
              name="coverPictureUrl"
              control={control}
              render={() => (
                <Stack gap={2}>
                  <StyledFormLabel disabled shrink htmlFor={coverPictureId}>
                    {t('cover_picture', { ns: 'common' })}
                  </StyledFormLabel>
                  <Box id={coverPictureId}>
                    <InlineImageUpload
                      isEditable={isEditable}
                      width={433}
                      height={220}
                      sm={500}
                      md={700}
                      lg={900}
                      placeholderSrc="/images/cover-image-placeholder.png"
                      urlSrc={
                        employeeProfileMedia.coverPictureUrl &&
                        pictureUrl(employeeProfileMedia.coverPictureUrl, employeeProfileAndCoverReadSasToken, PictureSizeSuffix.lg)!
                      }
                      imageAltText="user profile picture"
                      buttonTextLabel={t('upload_cover_btn_txt', { ns: 'employee_profile' })}
                      uploadTextLabel={t('upload_cover_copy', { ns: 'employee_profile' })}
                      removeTextLabel={t('remove_cover_txt', { ns: 'employee_profile' })}
                      multiSizeImage={employeeProfileCoverFileMultiSize}
                      onChange={handleSetEmployeeProfileCoverFileMultipleSize}
                      onRemove={handleRemoveEmployeeProfileCoverFile}
                    />
                  </Box>
                </Stack>
              )}
            />
          </Grid>
        </Grid>
      </Box>
    </StyledEmployeeGenericForm>
  );
});
