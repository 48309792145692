import { Skeleton, Stack } from '@mui/material';

export const GroupAutocompleteItemsSkeleton = () => {
  return (
    <Stack gap={2}>
      <Skeleton sx={{ width: '100%', height: 60 }} />
      <Skeleton sx={{ width: '100%', height: 60 }} />
      <Skeleton sx={{ width: '100%', height: 60 }} />
      <Skeleton sx={{ width: '100%', height: 60 }} />
      <Skeleton sx={{ width: '100%', height: 60 }} />
      <Skeleton sx={{ width: '100%', height: 60 }} />
    </Stack>
  );
};
