import { Box, IconButton, Typography } from '@mui/material';
import React, { useState } from 'react';
import { CommentIcon, NotificationSnackbar, RowCenterStack, ShoutoutIcon, StyledTransparentButton } from '@/shared';
import Close from '@mui/icons-material/Close';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import {
  deleteNotificationByIdRequested,
  markNotificationAsReadRequested,
  selectEmployeesWithDisplayNamesByIds,
  selectNotificationIsReadById,
  selectNotificationsById
} from '@features/notifications';
import { DeleteNotificationByIdRequest, MarkNotificationAsReadRequest } from '@thrivea/notification-client';
import { Trans, useTranslation } from 'react-i18next';

interface NotificationItemProps {
  notificationId: number;
}

export const NotificationItem: React.FC<NotificationItemProps> = ({ notificationId }) => {
  const { t } = useTranslation(['common']);
  const dispatch = useAppDispatch();
  const [isNotificationOpen, setIsNotificationOpen] = useState(true);
  const employeesWithNames = useAppSelector(selectEmployeesWithDisplayNamesByIds);
  const notification = useAppSelector((state) => selectNotificationsById(state, notificationId));
  const isRead = useAppSelector((state) => selectNotificationIsReadById(state, notificationId));

  const handleDeleteNotification = () => {
    setIsNotificationOpen(false);
    dispatch(deleteNotificationByIdRequested(new DeleteNotificationByIdRequest({ notificationId: notification.notificationId })));
  };

  const handleMarkAsReadClick = () => {
    dispatch(
      markNotificationAsReadRequested(
        new MarkNotificationAsReadRequest({
          notificationId: notification.notificationId
        })
      )
    );
  };

  switch (notification.data!.content.case) {
    case 'commentMention':
      return (
        <Box
          sx={{
            position: 'relative',
            minHeight: 60,
            mb: 2
          }}
        >
          <NotificationSnackbar isRead={isRead} open={isNotificationOpen}>
            <RowCenterStack
              sx={{
                width: '100%',
                justifyContent: 'space-between'
              }}
            >
              <RowCenterStack gap={4}>
                <CommentIcon />
                <Typography sx={{ maxWidth: 150 }}>
                  <Trans
                    i18nKey="has_mentioned_in_comment"
                    ns="common"
                    values={{ name: employeesWithNames[notification.data!.content.value.commentAuthorId].displayName }}
                    components={{ bold: <strong /> }}
                  />
                </Typography>
              </RowCenterStack>
              {/* <StyledTransparentButton variant="text" onClick={handleMarkAsReadClick}>
                {t('read_now', { ns: 'common' })}
              </StyledTransparentButton> */}
              <IconButton onClick={handleDeleteNotification}>
                <Close />
              </IconButton>
            </RowCenterStack>
          </NotificationSnackbar>
        </Box>
      );

    case 'commentOnPost':
      return (
        <Box
          sx={{
            position: 'relative',
            minHeight: 60,
            mb: 2
          }}
        >
          <NotificationSnackbar isRead={isRead} open={isNotificationOpen}>
            <RowCenterStack
              sx={{
                width: '100%',
                justifyContent: 'space-between'
              }}
            >
              <RowCenterStack gap={4}>
                <ShoutoutIcon />
                <Typography sx={{ maxWidth: 150 }}>
                  <Trans
                    i18nKey="has_commented_on_post"
                    ns="common"
                    values={{ name: employeesWithNames[notification.data!.content.value.commentAuthorId].displayName }}
                    components={{ bold: <strong /> }}
                  />
                </Typography>
              </RowCenterStack>
              {/* <StyledTransparentButton variant="text" onClick={handleMarkAsReadClick}>
                {t('read_now', { ns: 'common' })}
              </StyledTransparentButton> */}
              <IconButton onClick={handleDeleteNotification}>
                <Close />
              </IconButton>
            </RowCenterStack>
          </NotificationSnackbar>
        </Box>
      );
    case 'postMention':
      return (
        <Box
          sx={{
            position: 'relative',
            minHeight: 60,
            mb: 2
          }}
        >
          <NotificationSnackbar isRead={isRead} open={isNotificationOpen}>
            <RowCenterStack
              sx={{
                width: '100%',
                justifyContent: 'space-between'
              }}
            >
              <RowCenterStack gap={4}>
                <ShoutoutIcon />
                <Typography sx={{ maxWidth: 150 }}>
                  <Trans
                    i18nKey="has_mentioned_on_post"
                    ns="common"
                    values={{ name: employeesWithNames[notification.data!.content.value.authorId].displayName }}
                    components={{ bold: <strong /> }}
                  />
                </Typography>
              </RowCenterStack>
              {/* <StyledTransparentButton variant="text" onClick={handleMarkAsReadClick}>
                {t('read_now', { ns: 'common' })}
              </StyledTransparentButton> */}
              <IconButton onClick={handleDeleteNotification}>
                <Close />
              </IconButton>
            </RowCenterStack>
          </NotificationSnackbar>
        </Box>
      );
  }
};
