import { PayloadAction } from '@reduxjs/toolkit';
import { all, call, delay, fork, put, select, takeLatest } from 'redux-saga/effects';
import { deleteFile } from '@api/blob-storage.api';
import {
  deleteSiteMediaFailed,
  deleteSiteMediaRequested,
  deleteSiteMediaSucceeded,
  retrieveSiteDeleteSasTokenFailed,
  retrieveSiteDeleteSasTokenRequested,
  retrieveSiteDeleteSasTokenSucceeded,
  retrieveSiteReadSasTokenFailed,
  retrieveSiteReadSasTokenRequested,
  retrieveSiteReadSasTokenSucceeded,
  selectSiteDeleteSasToken
} from '@shared/sites';

import { Empty } from '@bufbuild/protobuf';
import { RetrieveSiteDeleteSasTokenRequest, SasToken } from '@thrivea/auth-client';
import { retrieveSiteDeleteSasToken, retrieveSiteReadSasToken } from '@api/shared-access-signature.api';

const MAX_RETRY_COUNT = 5;

function* deleteSiteMediaGenerator(action: PayloadAction<string>) {
  let retryCount = 0;
  const sasToken: SasToken = yield call(retrieveSiteDeleteSasToken, new RetrieveSiteDeleteSasTokenRequest({ siteMediaPath: action.payload }));
  yield put(retrieveSiteDeleteSasTokenSucceeded(sasToken));

  while (retryCount < MAX_RETRY_COUNT) {
    const sasToken: string = yield select(selectSiteDeleteSasToken);
    try {
      yield call(deleteFile, sasToken, action.payload);
      yield put(deleteSiteMediaSucceeded());
      return;
    } catch (error) {
      retryCount++;
      const sasToken: SasToken = yield call(retrieveSiteDeleteSasToken, new RetrieveSiteDeleteSasTokenRequest({ siteMediaPath: action.payload }));
      yield put(retrieveSiteDeleteSasTokenSucceeded(sasToken));

      yield delay(500 * retryCount + 1);
    }
  }

  yield put(deleteSiteMediaFailed());
}

function* retrieveSiteDeleteSasTokenGenerator(action: PayloadAction<RetrieveSiteDeleteSasTokenRequest>) {
  try {
    const sasToken: SasToken = yield call(retrieveSiteDeleteSasToken, action.payload);
    yield put(retrieveSiteDeleteSasTokenSucceeded(sasToken));
  } catch (error) {
    yield put(retrieveSiteDeleteSasTokenFailed());
  }
}

function* retrieveSiteReadSasTokenGenerator() {
  try {
    const sasToken: SasToken = yield call(retrieveSiteReadSasToken, new Empty());
    yield put(retrieveSiteReadSasTokenSucceeded(sasToken));
  } catch (error) {
    yield put(retrieveSiteReadSasTokenFailed());
  }
}

function* deleteSiteMediaRequestedWatcher() {
  yield takeLatest(deleteSiteMediaRequested.type, deleteSiteMediaGenerator);
}

function* retrieveSiteDeleteSasTokenRequestedWatcher() {
  yield takeLatest(retrieveSiteDeleteSasTokenRequested.type, retrieveSiteDeleteSasTokenGenerator);
}

function* retrieveSiteReadSasTokenRequestedWatcher() {
  yield takeLatest(retrieveSiteReadSasTokenRequested.type, retrieveSiteReadSasTokenGenerator);
}

export function* sitesSagas() {
  yield all([fork(deleteSiteMediaRequestedWatcher), fork(retrieveSiteDeleteSasTokenRequestedWatcher), fork(retrieveSiteReadSasTokenRequestedWatcher)]);
}
