import React, { ReactNode, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { Navigation, selectNavigationIsDesktopOpen, selectNavigationWidth, openNavigationDrawer } from '@features/navigation';
import { retrieveOrganizationLogoSasTokenRequested, retrieveOrganizationRequested } from '@features/admin-settings';
import Grid from '@mui/material/Grid2';

interface ErrorLayoutProps {
  children: ReactNode;
}

export const ErrorLayout: React.FC<ErrorLayoutProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const navOpen = useAppSelector(selectNavigationIsDesktopOpen);
  const navWidth = useAppSelector(selectNavigationWidth);

  useEffect(() => {
    const loaderElem = document.getElementById('loader');

    dispatch(retrieveOrganizationLogoSasTokenRequested());
    dispatch(retrieveOrganizationRequested());

    setTimeout(() => {
      if (loaderElem) {
        loaderElem.remove();
      }
    }, 500);
  }, []);

  return (
    <Grid
      container
      sx={{
        padding: (theme) => theme.spacing(2),
        alignItems: 'stretch',
        width: '100%'
      }}
    >
      <Grid
        component="aside"
        sx={{
          width: {
            xs: 0,
            md: 106
          },
          height: 'calc(100vh - 32px)',
          position: 'fixed',
          top: 16,
          bottom: 16,
          zIndex: (theme) => theme.zIndex.drawer + 1
        }}
      >
        <Navigation width={navWidth} isOpen={navOpen} />
      </Grid>
      <Grid
        sx={{
          width: 'calc(100% - 106px)',
          height: 'calc(100vh - 32px)',
          paddingLeft: '16px',
          marginLeft: {
            xs: 0,
            md: '106px'
          }
        }}
      >
        {children}
      </Grid>
    </Grid>
  );
};
