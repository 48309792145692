import { ListItem, ListItemButton, ListItemIcon, Typography, ListItemText } from '@mui/material';
import { Stack } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { SettingsIcon } from '@/shared';

interface NavSettingsListItemProps {
  isDrawerOpened: boolean;
  isDisabled: boolean;
}

export const NavSettingsListItem: React.FC<NavSettingsListItemProps> = ({ isDrawerOpened, isDisabled }) => {
  return (
    <ListItem
      sx={{
        padding: 0
      }}
    >
      <ListItemButton
        disableGutters
        disabled={isDisabled}
        component={NavLink}
        to="/settings"
        sx={{
          padding: 0,
          justifyContent: 'center',
          '&.active': {
            color: (theme) => theme.palette.customTheme.focusItem,
            '& path': {
              fill: (theme) => theme.palette.customTheme.focusItem
            }
          }
        }}
      >
        <Stack
          gap={1}
          sx={{
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <ListItemIcon
            className={!isDrawerOpened ? 'MuiLinkIcon' : ''}
            sx={{
              minWidth: 'auto',
              width: 24,
              height: 29,
              marginRight: isDrawerOpened ? '12px' : 0,
              '& path': {
                fill: (theme) => theme.palette.common.black
              }
            }}
          >
            <SettingsIcon />
          </ListItemIcon>
          {!isDrawerOpened && (
            <Typography className="MuiLinkText" variant="body2">
              Settings
            </Typography>
          )}
        </Stack>
        {isDrawerOpened && (
          <ListItemText
            primary={'Settings'}
            sx={{
              opacity: isDrawerOpened ? '1' : '0',
              visibility: isDrawerOpened ? 'visible' : 'hidden',
              transition: (theme) =>
                theme.transitions.create(['opacity', 'visibility'], {
                  easing: theme.transitions.easing.easeInOut,
                  duration: theme.transitions.duration.standard
                })
            }}
          />
        )}
      </ListItemButton>
    </ListItem>
  );
};
