import React, { useState } from 'react';
import { alpha, Tabs, Tab, Stack } from '@mui/material';
import { a11yProps } from '@/utils';
import { CustomTabPanel } from '@/shared';
import { PostAnalyticsReadList, PostAnalyticsUnreadList } from '@features/drawer';

export const PostAnalyticsTab = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <Stack
      sx={{
        width: '100%'
      }}
    >
      <Tabs
        value={activeTab}
        onChange={handleChange}
        aria-label="Employee Panel"
        sx={{
          minHeight: 'auto',
          '& .MuiTabs-flexContainer': {
            direction: 'row',
            alignItems: 'center',
            justifyContent: 'center'
          }
        }}
      >
        <Tab
          label="Read"
          {...a11yProps('post_read_tab', 0)}
          sx={{
            backgroundColor: (theme) => (activeTab === 0 ? alpha(theme.palette.customTheme.focusItem, 0.4) : 'transparent')
          }}
        />
        <Tab
          label="Unread"
          {...a11yProps('post_unread_tab', 1)}
          sx={{
            backgroundColor: (theme) => (activeTab === 1 ? alpha(theme.palette.customTheme.focusItem, 0.4) : 'transparent')
          }}
        />
      </Tabs>
      <CustomTabPanel value={activeTab} index={0}>
        <PostAnalyticsReadList />
      </CustomTabPanel>
      <CustomTabPanel value={activeTab} index={1}>
        <PostAnalyticsUnreadList />
      </CustomTabPanel>
    </Stack>
  );
};
