import { Avatar, Button, Stack, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import {
  retrieveEmployeeBasicInfoRequested,
  retrieveEmployeesByManagerIdRequested,
  selectCurrentManagerEmployeesPage,
  selectManagerEmployees,
  selectManagerEmployeesIds,
  selectManagerEmployeesStatus,
  selectManagerHasMoreEmployees
} from '@features/admin-settings';
import React, { useCallback, useEffect, useRef } from 'react';
import { RetrieveEmployeeBasicInfoRequest, RetrieveEmployeesByManagerIdRequest } from '@thrivea/organization-client';
import { ActionStatus, PictureSizeSuffix, StyledProfileLink } from '@/shared';
import { DrawerItemSkeleton } from './DrawerItemSkeleton';
import { pictureUrl } from '@/utils';
import { selectEmployeeProfileAndCoverReadSasToken } from '@features/employee-profile';
import { useTranslation } from 'react-i18next';

interface EmployeeDrawerTeamListProps {
  managerId: string;
  managerName: string;
}

export const EmployeeDrawerTeamList: React.FC<EmployeeDrawerTeamListProps> = ({ managerId, managerName }) => {
  const { t } = useTranslation(['drawer']);
  const dispatch = useAppDispatch();
  const managerEmployees = useAppSelector(selectManagerEmployees);
  const managerEmployeesIds = useAppSelector(selectManagerEmployeesIds);
  const managerEmployeesStatus = useAppSelector(selectManagerEmployeesStatus);
  const managerEmployeesCurrentPage = useAppSelector(selectCurrentManagerEmployeesPage);
  const managerHasMoreEmployees = useAppSelector(selectManagerHasMoreEmployees);
  const employeeProfileSasToken = useAppSelector(selectEmployeeProfileAndCoverReadSasToken);
  const observer = useRef<IntersectionObserver>();
  const bottomElementRef = useRef<HTMLDivElement>(null);

  const handleRetrieveSingleEmployeeInfo = (employeeId: string) => {
    dispatch(
      retrieveEmployeeBasicInfoRequested(
        new RetrieveEmployeeBasicInfoRequest({
          employeeId
        })
      )
    );
  };

  const handleScroll = useCallback(() => {
    if (managerHasMoreEmployees) {
      dispatch(
        retrieveEmployeesByManagerIdRequested(
          new RetrieveEmployeesByManagerIdRequest({
            employeeId: managerId,
            pageNumber: managerEmployeesCurrentPage + 1,
            pageSize: 10
          })
        )
      );
    }
  }, [managerHasMoreEmployees, managerEmployeesCurrentPage]);

  useEffect(() => {
    observer.current = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && managerEmployeesStatus === ActionStatus.Idle) {
          handleScroll();
        }
      },
      { threshold: 1 } // Trigger the observer when the target is 100% visible
    );

    // Start observing the target element
    if (bottomElementRef.current && observer.current) {
      observer.current.observe(bottomElementRef.current);
    }

    return () => {
      // Clean up the observer and scroll event listener when the component unmounts
      if (observer.current && bottomElementRef.current) {
        observer.current.disconnect();
      }
    };
  }, [handleScroll]);

  useEffect(() => {
    dispatch(
      retrieveEmployeesByManagerIdRequested(
        new RetrieveEmployeesByManagerIdRequest({
          employeeId: managerId,
          pageSize: 10,
          pageNumber: 1
        })
      )
    );
  }, [managerId]);

  return (
    <Stack spacing={2}>
      <Typography variant="subtitle1">{t('team_members', { ns: 'drawer', manager: managerName })}</Typography>
      {managerEmployeesIds.length === 0 && <Typography>{t('no_team_membesr', { ns: 'drawer', manager: managerName })}</Typography>}
      <Stack
        spacing={1}
        sx={{
          margin: '16px auto',
          backgroundColor: (theme) => theme.palette.background.paper,
          width: '100%',
          height: '100%',
          borderRadius: (theme) => theme.spacing(2)
        }}
      >
        {managerEmployeesIds.length !== 0 &&
          managerEmployeesIds.map((id) => {
            const employee = managerEmployees[id];
            return (
              <Stack
                key={crypto.randomUUID()}
                direction="row"
                spacing={2}
                sx={{
                  borderRadius: '8px',
                  padding: '8px',
                  transition: 'background-color 0.3s ease-in-out',
                  '&:hover': {
                    backgroundColor: (theme) => theme.palette.common.white,
                    '& .Mui-ProfileLink': {
                      opacity: 1
                    }
                  }
                }}
              >
                <Avatar
                  src={pictureUrl(employee.profilePicture, employeeProfileSasToken, PictureSizeSuffix.sm)}
                  alt={employee.name}
                  variant="rounded"
                  sx={{
                    border: (theme) => `1px solid ${theme.palette.customTheme.contColor}`
                  }}
                />
                <Stack
                  direction="row"
                  sx={{
                    width: '100%',
                    justifyContent: 'space-between'
                  }}
                >
                  <Stack>
                    <Typography fontWeight={700}>{employee.name}</Typography>
                    <Typography>{employee.jobTitle}</Typography>
                  </Stack>
                  <StyledProfileLink onClick={() => handleRetrieveSingleEmployeeInfo(employee.id)} className="Mui-ProfileLink" variant="text">
                    {t('view_profile', { ns: 'drawer' })}
                  </StyledProfileLink>
                </Stack>
              </Stack>
            );
          })}
        <div ref={bottomElementRef} style={{ width: '100%', height: '1px' }}></div>
        {managerEmployeesStatus === ActionStatus.Pending && Array.from({ length: 6 }).map(() => <DrawerItemSkeleton key={crypto.randomUUID()} />)}
      </Stack>
    </Stack>
  );
};
