import { store } from '@app/store';
import { postFileUploaded, updatePostFileUploadProgress, uploadPostFile } from '@features/homepage';
import { FileWithStatus, UploadedFile } from '@api/blob-storage.api';

export const handleUpdateFileUploadProgress = (fileProgress: number, totalProgress: number): void => {
  store.dispatch(updatePostFileUploadProgress({ fileProgress, totalProgress }));
};

export const handleStartUploadingFile = (fileWithStatus: FileWithStatus): void => {
  store.dispatch(uploadPostFile(fileWithStatus));
};

export const handleFileUploaded = (uploadedFile: UploadedFile): void => {
  store.dispatch(postFileUploaded(uploadedFile));
};
