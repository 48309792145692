import { useState } from 'react';
import { Typography, Tab, Stack, alpha, Tabs, Box } from '@mui/material';
import { useAppSelector } from '@app/hooks';
import { selectCurrentWorkingPatternName } from '@features/admin-settings';
import { CustomTabPanel, RowCenterStack, WorkingPatternIcon } from '@/shared';
import { a11yProps } from '@utils/a11yProps';
import { WorkingPatternDrawerSitesList, WorkingPatternDrawerEmployeeList } from '@features/drawer';

export const WorkingPatternDrawerContent = () => {
  const currentWpName = useAppSelector(selectCurrentWorkingPatternName);
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <Box>
      <Stack
        sx={{
          pb: 2
        }}
      >
        <WorkingPatternIcon size={76} />
        <RowCenterStack
          sx={{
            justifyContent: 'space-between'
          }}
        >
          <Typography variant="subtitle1">{currentWpName}</Typography>
        </RowCenterStack>
      </Stack>
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        aria-label="Employee Panel"
        sx={{
          minHeight: 'auto',
          '& .MuiTabs-flexContainer': {
            direction: 'row',
            alignItems: 'center',
            justifyContent: 'center'
          }
        }}
      >
        <Tab
          label="employees"
          {...a11yProps('employees', 0)}
          sx={{
            backgroundColor: (theme) => (activeTab === 0 ? alpha(theme.palette.customTheme.focusItem, 0.4) : 'transparent'),
            fontWeight: 700
          }}
        />
        <Tab
          label="Sites"
          {...a11yProps('sites', 1)}
          sx={{
            backgroundColor: (theme) => (activeTab === 1 ? alpha(theme.palette.customTheme.focusItem, 0.4) : 'transparent'),
            fontWeight: 700
          }}
        />
      </Tabs>
      <CustomTabPanel value={activeTab} index={0}>
        <WorkingPatternDrawerEmployeeList />
      </CustomTabPanel>
      <CustomTabPanel value={activeTab} index={1}>
        <WorkingPatternDrawerSitesList />
      </CustomTabPanel>
    </Box>
  );
};
