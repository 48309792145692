import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { ActionStatus, MultiSizeImageBlobs } from '@/shared';
import {
  Calendar,
  DeleteSiteByIdRequest,
  DuplicateSiteRequest,
  EmployeeDrawerListItem,
  Organization,
  RetrieveEmployeeBasicInfoRequest,
  RetrieveEmployeeBasicInfoResponse,
  RetrieveEmployeesBySiteIdRequest,
  RetrieveEmployeesByWorkingPatternIdRequest,
  RetrieveOrganizationResponse,
  RetrieveSiteByIdRequest,
  RetrieveSiteByIdResponse,
  RetrieveSitesByWorkingPatternIdRequest,
  RetrieveSiteListRequest,
  RetrieveSiteListResponse,
  RetrieveWorkingPatternListItemsRequest,
  RetrieveWorkingPatternsResponse,
  SiteListItem,
  UpdateOrganizationRequest,
  UpsertSiteNameAndLocationRequest,
  UpsertSiteNameAndLocationResponse,
  WorkingPatternListItem,
  SiteDrawerItem,
  CreateWorkingPatternRequest,
  DeleteWorkingPatternByIdRequest,
  UpdateSiteLocalizationRequest,
  UpdateSiteLocalizationResponse,
  UpdateSiteCalendarRequest,
  UpdateSiteCalendarResponse,
  UpdateSiteWorkingPatternRequest,
  UpdateSiteWorkingPatternResponse,
  RetrieveWorkingPatternByIdRequest,
  WorkingPattern,
  UpdateWorkingPatternRequest,
  WorkWeek,
  RetrieveEmployeesByManagerIdRequest,
  RetrieveWorkingPatternListItemsResponse,
  RetrieveEmployeesBySiteIdResponse,
  RetrieveEmployeesByManagerIdResponse,
  RetrieveSitesByWorkingPatternIdResponse,
  RetrieveSiteListItemByIdResponse,
  RetrieveSiteListItemByIdRequest,
  RetrieveEmployeesByWorkingPatternIdResponse,
  RetrieveEmployeesByJobTitleRequest,
  RetrieveEmployeesByJobTitleResponse,
  ImportEmployeesRequest
} from '@thrivea/organization-client';
import { RootState } from '@app/store';
import { RetrieveOrganizationLogoCreateSasUriRequest, SasToken, SasUri } from '@thrivea/auth-client';
import { RoleListItem } from './admin-settings.model';

export interface SiteNotification {
  label: string;
  isVisible: boolean;
}

interface AdminSettingsState {
  entities: {
    siteListItems: {
      byId: { [key: string]: SiteListItem };
      allIds: string[];
    };
    workingPatternSitesList: {
      byId: { [key: string]: SiteDrawerItem };
      allIds: string[];
    };
    workingPatternEmployeesList: {
      byId: { [key: string]: EmployeeDrawerListItem };
      allIds: string[];
    };
    employees: {
      byId: { [key: string]: EmployeeDrawerListItem };
      allIds: string[];
    };
    calendars: {
      byId: { [key: string]: Calendar };
      allIds: string[];
    };
    workingPatterns: {
      byId: { [key: string]: WorkingPattern };
      allIds: string[];
    };
    workingPatternItems: {
      byId: { [key: string]: WorkingPatternListItem };
      allIds: string[];
    };
    managerEmployees: {
      byId: { [key: string]: EmployeeDrawerListItem };
      allIds: string[];
    };
    jobTitleEmployeeList: {
      byId: { [key: string]: EmployeeDrawerListItem };
      allIds: string[];
    };
  };
  ui: {
    hasLoadedOrganization: ActionStatus;
    hasLoadedSingleSite: ActionStatus;
    uploadSiteCoverImageStatus: ActionStatus;
    siteListItemsStatus: ActionStatus;
    hasMore: boolean;
    currentSitePage: number;
    totalNumberOfSites: number;
    totalNumberOfWorkingPatterns: number;
    siteDeleteStatus: ActionStatus;
    siteDuplicateStatus: ActionStatus;
    workingPatternListItemsStatus: ActionStatus;
    hasMoreWorkingPatternListItems: boolean;
    currentWorkingPatternListItemPage: number;
    workingPatternListItemStatus: ActionStatus;
    hasMoreSiteEmployees: boolean;
    currentSiteEmployeesPage: number;
    siteEmployeesStatus: ActionStatus;
    managerHasMoreEmployees: boolean;
    managerEmployeesStatus: ActionStatus;
    currentManagerEmployeesPage: number;
    workingPatternSitesListStatus: ActionStatus;
    workingPatternHasMoreSites: boolean;
    workingPatternCurrentSitesListPage: number;
    workingPatternEmployeesListStatus: ActionStatus;
    workingPatternHasMoreEmployees: boolean;
    workingPatternCurrentEmployeesListPage: number;
    jobTitleEmployeeListStatus: ActionStatus;
    jobTitleHasMoreEmployees: boolean;
    jobTitleCurrentListPage: number;
    employeeBasicInfoStatus: ActionStatus;
    importEmployeesStatus: ActionStatus;
  };
  organization: Organization;
  singleSiteItem: RetrieveSiteByIdResponse;
  singleWorkingPattern: WorkingPattern;
  currentWorkingPatternName: string;
  currentWorkingPatternId: string;
  drawerSite: SiteListItem;
  employeeBasicInfo: RetrieveEmployeeBasicInfoResponse;
  organizationLogoCreateSasUri: string;
  organizationLogoReadSasToken: string;
  organizationLogoFile: MultiSizeImageBlobs | undefined | null;
  siteNotification: SiteNotification;
  siteCoverImageFile: MultiSizeImageBlobs | undefined | null;
  siteReadSasToken: string;
  currentDeleteSiteId?: string;
  siteListItem: SiteListItem;
  currentJobTitleName: string;
  currentDrawerRole?: RoleListItem;
}

const initialState: AdminSettingsState = {
  entities: {
    siteListItems: { byId: {}, allIds: [] },
    workingPatternSitesList: { byId: {}, allIds: [] },
    workingPatternEmployeesList: { byId: {}, allIds: [] },
    employees: { byId: {}, allIds: [] },
    calendars: { byId: {}, allIds: [] },
    workingPatterns: { byId: {}, allIds: [] },
    workingPatternItems: { byId: {}, allIds: [] },
    managerEmployees: { byId: {}, allIds: [] },
    jobTitleEmployeeList: { byId: {}, allIds: [] }
  },
  ui: {
    hasLoadedOrganization: ActionStatus.Idle,
    hasLoadedSingleSite: ActionStatus.Idle,
    uploadSiteCoverImageStatus: ActionStatus.Idle,
    siteListItemsStatus: ActionStatus.Idle,
    hasMore: false,
    currentSitePage: 1,
    totalNumberOfSites: 0,
    totalNumberOfWorkingPatterns: 0,
    siteDeleteStatus: ActionStatus.Idle,
    siteDuplicateStatus: ActionStatus.Idle,
    workingPatternListItemsStatus: ActionStatus.Idle,
    hasMoreWorkingPatternListItems: false,
    currentWorkingPatternListItemPage: 1,
    workingPatternListItemStatus: ActionStatus.Idle,
    hasMoreSiteEmployees: false,
    currentSiteEmployeesPage: 1,
    siteEmployeesStatus: ActionStatus.Idle,
    managerEmployeesStatus: ActionStatus.Idle,
    managerHasMoreEmployees: false,
    currentManagerEmployeesPage: 1,
    workingPatternSitesListStatus: ActionStatus.Idle,
    workingPatternHasMoreSites: false,
    workingPatternCurrentSitesListPage: 1,
    workingPatternEmployeesListStatus: ActionStatus.Idle,
    workingPatternHasMoreEmployees: false,
    workingPatternCurrentEmployeesListPage: 1,
    jobTitleCurrentListPage: 0,
    jobTitleEmployeeListStatus: ActionStatus.Idle,
    jobTitleHasMoreEmployees: false,
    employeeBasicInfoStatus: ActionStatus.Idle,
    importEmployeesStatus: ActionStatus.Idle
  },
  organization: {} as Organization,
  organizationLogoCreateSasUri: '',
  organizationLogoReadSasToken: '',
  singleSiteItem: {} as RetrieveSiteByIdResponse,
  singleWorkingPattern: new WorkingPattern({
    id: '',
    name: '',
    workWeek: new WorkWeek({
      id: '',
      defaultHoursPerDay: 0,
      workDays: []
    })
  }),
  currentWorkingPatternName: '',
  currentWorkingPatternId: '',
  drawerSite: {} as SiteListItem,
  employeeBasicInfo: {} as RetrieveEmployeeBasicInfoResponse,
  siteNotification: {
    label: '',
    isVisible: false
  },
  siteReadSasToken: '',
  siteListItem: {} as SiteListItem,
  currentJobTitleName: '',
  siteCoverImageFile: undefined,
  organizationLogoFile: undefined
};

export const adminSettingsSlice = createSlice({
  name: 'admin-settings',
  initialState,
  reducers: {
    retrieveOrganizationRequested: (state) => {
      state.ui.hasLoadedOrganization = ActionStatus.Pending;
    },
    retrieveOrganizationSucceeded: (state, action: PayloadAction<RetrieveOrganizationResponse>) => {
      state.organization = {
        registeredName: action.payload.registeredName,
        incorporationCountry: action.payload.incorporationCountry,
        incorporationDate: action.payload.incorporationDate,
        industryCategoryId: action.payload.industryCategoryId,
        industrySubcategoryId: action.payload.industrySubcategoryId,
        tradingNumber: action.payload.tradingNumber,
        logoUrl: action.payload.logoUrl
      } as Organization;
      state.ui.hasLoadedOrganization = ActionStatus.Idle;
    },
    retrieveOrganizationFailed: (state) => {
      state.ui.hasLoadedOrganization = ActionStatus.Failed;
    },
    updateOrganizationRequested: (state, action: PayloadAction<UpdateOrganizationRequest>) => {},
    updateOrganizationSucceeded: (state, action: PayloadAction<UpdateOrganizationRequest>) => {},
    updateOrganizationFailed: (state) => {},
    retrieveOrganizationLogoCreateSasUriRequested: (state, action: PayloadAction<RetrieveOrganizationLogoCreateSasUriRequest>) => {},
    retrieveOrganizationLogoCreateSasUriSucceeded: (state, action: PayloadAction<SasUri>) => {
      state.organizationLogoCreateSasUri = action.payload.uri;
    },
    retrieveOrganizationLogoCreateSasUriFailed: (state) => {},
    retrieveOrganizationLogoSasTokenRequested: (state) => {},
    retrieveOrganizationLogoSasTokenSucceeded: (state, action: PayloadAction<SasToken>) => {
      state.organizationLogoReadSasToken = action.payload.token;
    },
    retrieveOrganizationLogoSasTokenFailed: (state) => {},
    setOrganizationLogoFile: (state, action: PayloadAction<MultiSizeImageBlobs | undefined>) => {
      state.organizationLogoFile = action.payload;
    },
    removeOrganizationLogoFile: (state) => {
      state.organizationLogoFile = null;
    },
    resetOrganizationLogoFile: (state) => {
      state.organizationLogoFile = undefined;
    },
    setCurrentWorkingPatternDrawer: (state, action: PayloadAction<{ id: string; name: string }>) => {
      state.currentWorkingPatternName = action.payload.name;
      state.currentWorkingPatternId = action.payload.id;
    },
    resetWorkingPatternDrawer: (state) => {
      state.currentWorkingPatternName = '';
      state.currentWorkingPatternId = '';
    },
    retrieveCalendarsRequested: (state) => {},
    retrieveCalendarsSucceeded: (state, action: PayloadAction<Calendar[]>) => {
      const calendars = action.payload;

      for (const calendar of calendars) {
        state.entities.calendars.byId[calendar.id] = calendar;
        state.entities.calendars.allIds.push(calendar.id);
      }
    },
    retrieveCalendarsFailed: (state) => {},
    retrieveWorkingPatternsRequested: (state) => {},
    retrieveWorkingPatternsSucceeded: (state, action: PayloadAction<RetrieveWorkingPatternsResponse>) => {
      const workingPatterns = action.payload.workingPatterns;

      for (const wp of workingPatterns) {
        state.entities.workingPatterns.byId[wp.id] = wp;
        state.entities.workingPatterns.allIds.push(wp.id);
      }
    },
    retrieveWorkingPatternsFailed: (state) => {},
    retrieveSiteListItemsRequested: (state, action: PayloadAction<RetrieveSiteListRequest>) => {
      state.ui.siteListItemsStatus = ActionStatus.Pending;

      if (action.payload.pageNumber === 1) {
        state.entities.siteListItems.byId = {};
        state.entities.siteListItems.allIds = [];
      }

      state.ui.currentSitePage = action.payload.pageNumber;
    },
    retrieveSiteListItemsSucceeded: (state, action: PayloadAction<RetrieveSiteListResponse>) => {
      // TODO - Check based on allIds
      const siteListItems = action.payload.siteListItems.filter((s) => !state.entities.siteListItems.byId.hasOwnProperty(s.id));

      for (const site of siteListItems) {
        state.entities.siteListItems.byId[site.id] = site;
        state.entities.siteListItems.allIds.push(site.id);
      }

      state.ui.siteListItemsStatus = ActionStatus.Idle;
      state.ui.hasMore = state.entities.siteListItems.allIds.length < action.payload.totalCount;
      state.ui.totalNumberOfSites = action.payload.totalCount;
    },
    retrieveSiteListItemsFailed: (state) => {
      state.ui.siteListItemsStatus = ActionStatus.Failed;
    },
    retrieveSiteByIdRequested: (state, action: PayloadAction<RetrieveSiteByIdRequest>) => {
      state.ui.hasLoadedSingleSite = ActionStatus.Pending;
    },
    retrieveSiteByIdSucceeded: (state, action: PayloadAction<RetrieveSiteByIdResponse>) => {
      state.singleSiteItem = action.payload;
      state.ui.hasLoadedSingleSite = ActionStatus.Idle;
      state.drawerSite = new SiteListItem({
        id: action.payload.id,
        name: action.payload.name,
        address: action.payload.location!.addressLine1,
        city: action.payload.location!.city,
        country: action.payload.location!.country,
        coverImageUrl: action.payload.coverImageUrl,
        employeeCount: action.payload.employeeCount,
        status: action.payload.status
      });
    },
    retrieveSiteByIdFailed: (state) => {
      state.ui.hasLoadedSingleSite = ActionStatus.Failed;
    },
    duplicateSiteRequested: (state, action: PayloadAction<DuplicateSiteRequest>) => {
      state.ui.siteDuplicateStatus = ActionStatus.Pending;
    },
    duplicateSiteSucceeded: (state, action: PayloadAction<SiteListItem>) => {
      const site = action.payload;

      state.entities.siteListItems.byId[site.id] = site;
      state.entities.siteListItems.allIds.push(site.id);
      state.entities.workingPatternSitesList.byId[site.id] = site;
      state.entities.workingPatternSitesList.allIds.push(site.id);
      state.ui.totalNumberOfSites++;
      state.ui.siteDuplicateStatus = ActionStatus.Idle;
    },
    duplicateSiteFailed: (state) => {
      state.ui.siteDuplicateStatus = ActionStatus.Failed;
    },
    deleteSiteByIdRequested: (state, action: PayloadAction<DeleteSiteByIdRequest>) => {
      state.ui.siteDeleteStatus = ActionStatus.Pending;
      state.currentDeleteSiteId = action.payload.siteId;
    },
    deleteSiteByIdSucceeded: (state, action: PayloadAction<string>) => {
      const siteId = action.payload;

      delete state.entities.siteListItems.byId[siteId];
      state.entities.siteListItems.allIds = state.entities.siteListItems.allIds.filter((id) => id !== siteId);

      state.ui.totalNumberOfSites--;
      state.ui.siteDeleteStatus = ActionStatus.Idle;
      state.currentDeleteSiteId = undefined;
    },
    deleteSiteByIdFailed: (state) => {
      state.ui.siteDeleteStatus = ActionStatus.Failed;
      state.currentDeleteSiteId = undefined;
    },
    upsertSiteNameAndLocationRequested: (state, action: PayloadAction<UpsertSiteNameAndLocationRequest>) => {},
    upsertSiteNameAndLocationSucceeded: (state, action: PayloadAction<UpsertSiteNameAndLocationResponse>) => {
      if (!(action.payload.siteListItem!.id in state.entities.siteListItems.byId)) {
        state.entities.siteListItems.allIds.push(action.payload.siteListItem!.id);
      }

      state.entities.siteListItems.byId[action.payload.siteListItem!.id] = action.payload.siteListItem!;
      state.singleSiteItem = action.payload.siteListItem!;
      state.siteCoverImageFile = undefined;
    },
    upsertSiteNameAndLocationFailed: (state) => {},
    upsertSiteLocalizationRequested: (state, action: PayloadAction<UpdateSiteLocalizationRequest>) => {},
    upsertSiteLocalizationSucceeded: (state, action: PayloadAction<UpdateSiteLocalizationResponse>) => {},
    upsertSiteLocalizationFailed: (state) => {},
    updateSiteCalendarRequested: (state, action: PayloadAction<UpdateSiteCalendarRequest>) => {},
    updateSiteCalendarSucceeded: (state, action: PayloadAction<UpdateSiteCalendarResponse>) => {},
    updateSiteCalendarFailed: (state) => {},
    upsertSiteWorkingPatternRequested: (state, action: PayloadAction<UpdateSiteWorkingPatternRequest>) => {},
    upsertSiteWorkingPatternSucceeded: (state, action: PayloadAction<UpdateSiteWorkingPatternResponse>) => {},
    upsertSiteWorkingPatternFailed: (state) => {},
    updateSitePart: (state, action: PayloadAction<SiteNotification>) => {
      state.siteNotification = action.payload;
    },
    retrieveEmployeesBySiteIdRequested: (state, action: PayloadAction<RetrieveEmployeesBySiteIdRequest>) => {
      state.ui.siteEmployeesStatus = ActionStatus.Pending;

      if (action.payload.pageNumber === 1) {
        state.entities.employees.byId = {};
        state.entities.employees.allIds = [];
      }

      state.ui.currentSiteEmployeesPage = action.payload.pageNumber;
    },
    retrieveEmployeesBySiteIdSucceeded: (state, action: PayloadAction<RetrieveEmployeesBySiteIdResponse>) => {
      const { employees } = action.payload;

      for (const employee of employees) {
        state.entities.employees.byId[employee.id] = employee;
        state.entities.employees.allIds.push(employee.id);
      }

      state.ui.hasMoreSiteEmployees = state.entities.employees.allIds.length < action.payload.totalCount;
      state.ui.siteEmployeesStatus = ActionStatus.Idle;
    },
    retrieveEmployeesBySiteIdFailed: (state) => {
      state.ui.siteEmployeesStatus = ActionStatus.Failed;
    },
    retrieveEmployeeBasicInfoRequested: (state, action: PayloadAction<RetrieveEmployeeBasicInfoRequest>) => {
      state.ui.employeeBasicInfoStatus = ActionStatus.Pending;
    },
    retrieveEmployeeBasicInfoSucceeded: (state, action: PayloadAction<RetrieveEmployeeBasicInfoResponse>) => {
      state.employeeBasicInfo = action.payload;
      state.ui.employeeBasicInfoStatus = ActionStatus.Idle;
    },
    retrieveEmployeeBasicInfoFailed: (state) => {
      state.ui.employeeBasicInfoStatus = ActionStatus.Failed;
    },
    setCurrentEmployeeProfilePicture: (state, action: PayloadAction<string>) => {
      state.employeeBasicInfo.employeeProfilePictureUrl = action.payload;
    },
    setCurrentEmployeeProfileCoverPicture: (state, action: PayloadAction<string>) => {
      state.employeeBasicInfo.employeeCoverPictureUrl = action.payload;
    },
    retrieveSiteReadSasTokenRequested: (state) => {},
    retrieveSiteReadSasTokenSucceeded: (state, action: PayloadAction<SasToken>) => {
      state.siteReadSasToken = action.payload.token;
    },
    retrieveSiteReadSasTokenFailed: (state) => {},
    setSiteCoverImageFile: (state, action: PayloadAction<MultiSizeImageBlobs>) => {
      state.siteCoverImageFile = action.payload;
    },
    resetCoverImageFile: (state) => {
      state.siteCoverImageFile = undefined;
    },
    removeCoverImageFile: (state) => {
      state.siteCoverImageFile = null;
    },
    clearSiteListItem: (state) => {
      state.singleSiteItem = {} as SiteListItem;
    },
    setDrawerSite: (state, action: PayloadAction<SiteListItem>) => {
      state.drawerSite = action.payload;
    },
    retrieveSitesByWorkingPatternIdRequested: (state, action: PayloadAction<RetrieveSitesByWorkingPatternIdRequest>) => {
      if (action.payload.pageNumber === 1) {
        state.entities.workingPatternSitesList.allIds = [];
        state.entities.workingPatternSitesList.byId = {};
      }

      state.ui.workingPatternSitesListStatus = ActionStatus.Pending;
      state.ui.workingPatternCurrentSitesListPage = action.payload.pageNumber;
    },
    retrieveSitesByWorkingPatternIdSucceeded: (state, action: PayloadAction<RetrieveSitesByWorkingPatternIdResponse>) => {
      const { sites } = action.payload;
      for (const site of sites) {
        state.entities.workingPatternSitesList.byId[site.id] = site;
        state.entities.workingPatternSitesList.allIds.push(site.id);
      }

      state.ui.workingPatternHasMoreSites = state.entities.workingPatternSitesList.allIds.length < action.payload.totalCount;
      state.ui.workingPatternSitesListStatus = ActionStatus.Idle;
    },
    retrieveSitesByWorkingPatternIdFailed: (state) => {
      state.ui.workingPatternSitesListStatus = ActionStatus.Failed;
    },
    retrieveEmployeesByWorkingPatternIdRequested: (state, action: PayloadAction<RetrieveEmployeesByWorkingPatternIdRequest>) => {
      if (action.payload.pageNumber === 1) {
        state.entities.workingPatternEmployeesList.allIds = [];
        state.entities.workingPatternEmployeesList.byId = {};
      }
      state.ui.workingPatternEmployeesListStatus = ActionStatus.Pending;
      state.ui.workingPatternCurrentEmployeesListPage = action.payload.pageNumber;
    },
    retrieveEmployeesByWorkingPatternIdSucceeded: (state, action: PayloadAction<RetrieveEmployeesByWorkingPatternIdResponse>) => {
      const { employees } = action.payload;

      for (const employee of employees) {
        state.entities.workingPatternEmployeesList.byId[employee.id] = employee;
        state.entities.workingPatternEmployeesList.allIds.push(employee.id);
      }
      state.ui.workingPatternHasMoreEmployees = state.entities.workingPatternEmployeesList.allIds.length < action.payload.totalCount;
      state.ui.workingPatternEmployeesListStatus = ActionStatus.Idle;
    },
    retrieveEmployeesByWorkingPatternIdFailed: (state) => {
      state.ui.workingPatternEmployeesListStatus = ActionStatus.Failed;
    },
    retrieveWorkingPatternItemsRequested: (state, action: PayloadAction<RetrieveWorkingPatternListItemsRequest>) => {
      state.ui.currentWorkingPatternListItemPage = action.payload.pageNumber;

      if (action.payload.pageNumber === 1) {
        state.entities.workingPatternItems.allIds = [];
        state.entities.workingPatternItems.byId = {};
      }
      state.ui.workingPatternListItemsStatus = ActionStatus.Pending;
    },
    retrieveWorkingPatternItemsSucceeded: (state, action: PayloadAction<RetrieveWorkingPatternListItemsResponse>) => {
      const { workingPatternItems } = action.payload;

      for (const wp of workingPatternItems) {
        state.entities.workingPatternItems.byId[wp.id] = wp;
        state.entities.workingPatternItems.allIds.push(wp.id);
      }

      state.ui.hasMoreWorkingPatternListItems = state.entities.workingPatternItems.allIds.length < action.payload.totalCount;
      state.ui.totalNumberOfWorkingPatterns = action.payload.totalCount;
      state.ui.workingPatternListItemsStatus = ActionStatus.Idle;
    },
    retrieveWorkingPatternItemsFailed: (state) => {
      state.ui.workingPatternListItemsStatus = ActionStatus.Failed;
    },
    createWorkingPatternRequested: (state, action: PayloadAction<CreateWorkingPatternRequest>) => {},
    createWorkingPatternSucceeded: (state, action: PayloadAction<WorkingPatternListItem>) => {
      state.entities.workingPatternItems.byId[action.payload.id] = action.payload;
      state.entities.workingPatternItems.allIds.push(action.payload.id);
      state.ui.totalNumberOfWorkingPatterns++;
    },
    createWorkingPatternFailed: (state) => {},
    retrieveWorkingPatternByIdRequested: (state, action: PayloadAction<RetrieveWorkingPatternByIdRequest>) => {
      state.ui.workingPatternListItemStatus = ActionStatus.Pending;
    },
    retrieveWorkingPatternByIdSucceeded: (state, action: PayloadAction<WorkingPattern>) => {
      state.singleWorkingPattern = action.payload;
      state.ui.workingPatternListItemStatus = ActionStatus.Idle;
    },
    retrieveWorkingPatternByIdFailed: (state) => {
      state.ui.workingPatternListItemStatus = ActionStatus.Failed;
    },
    updateWorkingPatternByIdRequested: (state, action: PayloadAction<UpdateWorkingPatternRequest>) => {},
    updateWorkingPatternByIdSucceeded: (state, action: PayloadAction<WorkingPatternListItem>) => {
      state.entities.workingPatternItems.byId[action.payload.id] = action.payload;
    },
    updateWorkingPatternByIdFailed: (state) => {},
    deleteWorkingPatternByIdRequested: (state, action: PayloadAction<DeleteWorkingPatternByIdRequest>) => {},
    deleteWorkingPatternByIdSucceeded: (state, action: PayloadAction<string>) => {
      const wpId = action.payload;

      delete state.entities.workingPatternItems.byId[wpId];
      state.entities.workingPatternItems.allIds = state.entities.workingPatternItems.allIds.filter((id) => id !== wpId);

      state.ui.totalNumberOfWorkingPatterns--;
    },
    deleteWorkingPatternByIdFailed: (state) => {},
    retrieveEmployeesByManagerIdRequested: (state, action: PayloadAction<RetrieveEmployeesByManagerIdRequest>) => {
      if (action.payload.pageNumber === 1) {
        state.entities.managerEmployees.allIds = [];
        state.entities.managerEmployees.byId = {};
      }
      state.ui.managerEmployeesStatus = ActionStatus.Pending;
    },
    retrieveEmployeesByManagerIdSucceeded: (state, action: PayloadAction<RetrieveEmployeesByManagerIdResponse>) => {
      const { employees } = action.payload;
      for (const employee of employees) {
        state.entities.managerEmployees.byId[employee.id] = employee;
        state.entities.managerEmployees.allIds.push(employee.id);
      }
      state.ui.managerHasMoreEmployees = state.entities.managerEmployees.allIds.length < action.payload.totalCount;
      state.ui.managerEmployeesStatus = ActionStatus.Idle;
    },
    retrieveEmployeesByManagerIdFailed: (state) => {
      state.ui.managerEmployeesStatus = ActionStatus.Failed;
    },
    retrieveSiteListItemByIdRequested: (state, action: PayloadAction<RetrieveSiteListItemByIdRequest>) => {},
    retrieveSiteListItemByIdSucceeded: (state, action: PayloadAction<RetrieveSiteListItemByIdResponse>) => {
      const { site } = action.payload;
      state.siteListItem = site!;
    },
    retrieveSiteListItemByIdFailed: (state) => {},
    retrieveEmployeesByJobTitleRequested: (state, action: PayloadAction<RetrieveEmployeesByJobTitleRequest>) => {
      if (action.payload.pageNumber === 1) {
        state.entities.jobTitleEmployeeList.allIds = [];
        state.entities.jobTitleEmployeeList.byId = {};
      }
      state.ui.jobTitleEmployeeListStatus = ActionStatus.Pending;
      state.ui.jobTitleCurrentListPage = action.payload.pageNumber;
    },
    retrieveEmployeesByJobTitleSucceeded: (state, action: PayloadAction<RetrieveEmployeesByJobTitleResponse>) => {
      const { employees } = action.payload;
      for (const employee of employees) {
        state.entities.jobTitleEmployeeList.byId[employee.id] = employee;
        state.entities.jobTitleEmployeeList.allIds.push(employee.id);
      }
      state.ui.workingPatternHasMoreEmployees = state.entities.jobTitleEmployeeList.allIds.length < action.payload.totalCount;
      state.ui.jobTitleEmployeeListStatus = ActionStatus.Idle;
    },
    retrieveEmployeesByJobTitleFailed: (state) => {
      state.ui.jobTitleEmployeeListStatus = ActionStatus.Failed;
    },
    setCurrentJobTitle: (state, action: PayloadAction<string>) => {
      state.currentJobTitleName = action.payload;
    },
    setCurrentAudienceRole: (state, action: PayloadAction<RoleListItem>) => {
      state.currentDrawerRole = action.payload;
    },
    importEmployeesRequested: (state, action: PayloadAction<ImportEmployeesRequest>) => {
      state.ui.importEmployeesStatus = ActionStatus.Pending;
    },
    importEmployeesSucceeded: (state) => {
      state.ui.importEmployeesStatus = ActionStatus.Idle;
    },
    importEmployeesFailed: (state) => {
      state.ui.importEmployeesStatus = ActionStatus.Failed;
    }
  }
});

export const selectOrganization = (state: RootState) => state.adminSettings.organization;
export const selectOrganizationId = (state: RootState) => state.adminSettings.organization.id;
export const selectOrganizationLogoCreateSasUri = (state: RootState) => state.adminSettings.organizationLogoCreateSasUri;
export const selectOrganizationLogoReadSasToken = (state: RootState) => state.adminSettings.organizationLogoReadSasToken;
export const selectOrganizationLogoFile = (state: RootState) => state.adminSettings.organizationLogoFile!;
export const selectOrganizationLogoImageUrl = (state: RootState) => state.adminSettings.organization.logoUrl!;
export const selectSiteListItemsIds = (state: RootState) => state.adminSettings.entities.siteListItems.allIds;
export const selectSiteListItems = (state: RootState) => state.adminSettings.entities.siteListItems.byId;
export const selectSiteListItem = (state: RootState, siteListItemId: string) => state.adminSettings.entities.siteListItems.byId[siteListItemId];
export const selectSiteNotification = (state: RootState) => state.adminSettings.siteNotification;
export const selectSiteItem = (state: RootState) => state.adminSettings.singleSiteItem!;
export const selectSiteId = (state: RootState) => state.adminSettings.singleSiteItem.id;
export const selectSiteName = (state: RootState) => state.adminSettings.singleSiteItem.name;
export const selectSiteCoverImageUrl = (state: RootState) => state.adminSettings.singleSiteItem.coverImageUrl;

export const selectSiteEmployeeById = (state: RootState) => state.adminSettings.entities.employees.byId;
export const selectSiteEmployeeIds = (state: RootState) => state.adminSettings.entities.employees.allIds;
export const selectSiteEmployees = createSelector([selectSiteEmployeeById, selectSiteEmployeeIds], (byId, ids) => ids.map((id) => byId[id]));

export const selectCurrentSiteEmployeesPageNumber = (state: RootState) => state.adminSettings.ui.currentSiteEmployeesPage;
export const selectHasMoreSiteEmployees = (state: RootState) => state.adminSettings.ui.hasMoreSiteEmployees;
export const selectSiteEmployeesStatus = (state: RootState) => state.adminSettings.ui.siteEmployeesStatus;
export const selectEmployeeBasicInfo = (state: RootState) => state.adminSettings.employeeBasicInfo;
export const selectEmployeeBasicInfoStatus = (state: RootState) => state.adminSettings.ui.employeeBasicInfoStatus;
export const selectHasLoadedSingleSite = (state: RootState) => state.adminSettings.ui.hasLoadedSingleSite;
export const selectSiteCoverImageFile = (state: RootState) => state.adminSettings.siteCoverImageFile;
export const selectCalendarIds = (state: RootState) => state.adminSettings.entities.calendars.allIds;
export const selectCalendars = (state: RootState) => state.adminSettings.entities.calendars.byId;
export const selectWorkingPatternIds = (state: RootState) => state.adminSettings.entities.workingPatterns.allIds;
export const selectWorkingPatterns = (state: RootState) => state.adminSettings.entities.workingPatterns.byId;
export const selectSiteReadSasToken = (state: RootState) => state.adminSettings.siteReadSasToken;
export const selectSiteListItemsStatus = (state: RootState) => state.adminSettings.ui.siteListItemsStatus;
export const selectCurrentSitePageNumber = (state: RootState) => state.adminSettings.ui.currentSitePage;
export const selectHasMoreSites = (state: RootState) => state.adminSettings.ui.hasMore;
export const selectDrawerSite = (state: RootState) => state.adminSettings.drawerSite;
export const selectTotalNumberOfSites = (state: RootState) => state.adminSettings.ui.totalNumberOfSites;
export const selectDeleteActionStatus = (state: RootState) => state.adminSettings.ui.siteDeleteStatus;
export const selectCurrentDeleteSiteId = (state: RootState) => state.adminSettings.currentDeleteSiteId;
export const selectSiteListItemById = (state: RootState, siteId: string) => state.adminSettings.entities.siteListItems.byId[siteId];
export const selectDuplicateActionStatus = (state: RootState) => state.adminSettings.ui.siteDuplicateStatus;
export const selectWorkingPatternListItemsIds = (state: RootState) => state.adminSettings.entities.workingPatternItems.allIds;
export const selectWorkingPatternListItems = (state: RootState) => state.adminSettings.entities.workingPatternItems.byId;
export const selectWorkingPatternListItemById = (state: RootState, wpId: string) => state.adminSettings.entities.workingPatternItems.byId[wpId];
export const selectHasMoreWorkingPatternListItems = (state: RootState) => state.adminSettings.ui.hasMoreWorkingPatternListItems;
export const selectCurrentWorkingPatternListItemPage = (state: RootState) => state.adminSettings.ui.currentWorkingPatternListItemPage;
export const selectWorkingPatternSitesListIds = (state: RootState) => state.adminSettings.entities.workingPatternSitesList.allIds;
export const selectWorkingPatternSitesList = (state: RootState) => state.adminSettings.entities.workingPatternSitesList.byId;
export const selectWorkingPatternSiteListItemById = (state: RootState, wpId: string) => state.adminSettings.entities.workingPatternSitesList.byId[wpId];
export const selectWorkingPatternSitesListStatus = (state: RootState) => state.adminSettings.ui.workingPatternSitesListStatus;
export const selectWorkingPatternHasMoreSites = (state: RootState) => state.adminSettings.ui.workingPatternHasMoreSites;
export const selectWorkingPatternCurrentSitesPage = (state: RootState) => state.adminSettings.ui.workingPatternCurrentSitesListPage;
export const selectCurrentWorkingPatternName = (state: RootState) => state.adminSettings.currentWorkingPatternName;
export const selectCurrentWorkingPatternId = (state: RootState) => state.adminSettings.currentWorkingPatternId;
export const selectSingleWorkingPattern = (state: RootState) => state.adminSettings.singleWorkingPattern;
export const selectWorkingPatternListItemStatus = (state: RootState) => state.adminSettings.ui.workingPatternListItemStatus;
export const selectWorkingPatternListItemsStatus = (state: RootState) => state.adminSettings.ui.workingPatternListItemsStatus;
export const selectTotalNumberOfWorkingPatterns = (state: RootState) => state.adminSettings.ui.totalNumberOfWorkingPatterns;
export const selectManagerEmployees = (state: RootState) => state.adminSettings.entities.managerEmployees.byId;
export const selectManagerEmployeesIds = (state: RootState) => state.adminSettings.entities.managerEmployees.allIds;
export const selectCurrentManagerEmployeesPage = (state: RootState) => state.adminSettings.ui.currentManagerEmployeesPage;
export const selectManagerEmployeesStatus = (state: RootState) => state.adminSettings.ui.managerEmployeesStatus;
export const selectManagerHasMoreEmployees = (state: RootState) => state.adminSettings.ui.managerHasMoreEmployees;
export const selectCurrentSiteListItem = (state: RootState) => state.adminSettings.siteListItem;

export const selectWorkingPatternEmployeeById = (state: RootState) => state.adminSettings.entities.workingPatternEmployeesList.byId;
export const selectWorkingPatternEmployeeIds = (state: RootState) => state.adminSettings.entities.workingPatternEmployeesList.allIds;
export const selectWorkingPatternEmployees = createSelector([selectWorkingPatternEmployeeById, selectWorkingPatternEmployeeIds], (byId, ids) =>
  ids.map((id) => byId[id])
);

export const selectWorkingPatternEmployeesListStatus = (state: RootState) => state.adminSettings.ui.workingPatternEmployeesListStatus;
export const selectWorkingPatternHasMoreEmployees = (state: RootState) => state.adminSettings.ui.workingPatternHasMoreEmployees;
export const selectWorkingPatternCurrentEmployeesListPage = (state: RootState) => state.adminSettings.ui.workingPatternCurrentEmployeesListPage;
export const selectJobTitleEmployeesListIds = (state: RootState) => state.adminSettings.entities.jobTitleEmployeeList.allIds;
export const selectJobTitleEmployeesListById = (state: RootState) => state.adminSettings.entities.jobTitleEmployeeList.byId;
export const selectJobTitleEmployeesStatus = (state: RootState) => state.adminSettings.ui.jobTitleEmployeeListStatus;
export const selectJobTitleHasMoreEmployees = (state: RootState) => state.adminSettings.ui.jobTitleHasMoreEmployees;
export const selectJobTitleEmployeesCurrentListPage = (state: RootState) => state.adminSettings.ui.jobTitleCurrentListPage;
export const selectCurrentJobTitleName = (state: RootState) => state.adminSettings.currentJobTitleName;
export const selectCurrentDrawerRole = (state: RootState) => state.adminSettings.currentDrawerRole;
export const selectImportEmployeesStatus = (state: RootState) => state.adminSettings.ui.importEmployeesStatus;

export const {
  retrieveOrganizationFailed,
  retrieveOrganizationRequested,
  retrieveOrganizationSucceeded,
  updateOrganizationFailed,
  updateOrganizationRequested,
  updateOrganizationSucceeded,
  retrieveOrganizationLogoCreateSasUriRequested,
  retrieveOrganizationLogoCreateSasUriSucceeded,
  retrieveOrganizationLogoCreateSasUriFailed,
  retrieveOrganizationLogoSasTokenRequested,
  retrieveOrganizationLogoSasTokenSucceeded,
  retrieveOrganizationLogoSasTokenFailed,
  setOrganizationLogoFile,
  removeOrganizationLogoFile,
  resetOrganizationLogoFile,
  setCurrentWorkingPatternDrawer,
  resetWorkingPatternDrawer,
  retrieveSiteListItemsRequested,
  retrieveSiteListItemsSucceeded,
  retrieveSiteListItemsFailed,
  retrieveSiteByIdRequested,
  retrieveSiteByIdSucceeded,
  retrieveSiteByIdFailed,
  duplicateSiteRequested,
  duplicateSiteSucceeded,
  duplicateSiteFailed,
  deleteSiteByIdRequested,
  deleteSiteByIdSucceeded,
  deleteSiteByIdFailed,
  upsertSiteNameAndLocationRequested,
  upsertSiteNameAndLocationSucceeded,
  upsertSiteNameAndLocationFailed,
  upsertSiteLocalizationRequested,
  upsertSiteLocalizationSucceeded,
  upsertSiteLocalizationFailed,
  updateSiteCalendarRequested,
  updateSiteCalendarSucceeded,
  updateSiteCalendarFailed,
  upsertSiteWorkingPatternRequested,
  upsertSiteWorkingPatternSucceeded,
  upsertSiteWorkingPatternFailed,
  retrieveEmployeesBySiteIdRequested,
  retrieveEmployeesBySiteIdSucceeded,
  retrieveEmployeesBySiteIdFailed,
  retrieveEmployeeBasicInfoRequested,
  retrieveEmployeeBasicInfoSucceeded,
  retrieveEmployeeBasicInfoFailed,
  setCurrentEmployeeProfilePicture,
  setCurrentEmployeeProfileCoverPicture,
  setSiteCoverImageFile,
  resetCoverImageFile,
  removeCoverImageFile,
  retrieveCalendarsRequested,
  retrieveCalendarsSucceeded,
  retrieveCalendarsFailed,
  retrieveWorkingPatternsRequested,
  retrieveWorkingPatternsSucceeded,
  retrieveWorkingPatternsFailed,
  retrieveSiteReadSasTokenRequested,
  retrieveSiteReadSasTokenSucceeded,
  retrieveSiteReadSasTokenFailed,
  updateSitePart,
  clearSiteListItem,
  setDrawerSite,
  retrieveSitesByWorkingPatternIdRequested,
  retrieveSitesByWorkingPatternIdSucceeded,
  retrieveSitesByWorkingPatternIdFailed,
  retrieveWorkingPatternItemsRequested,
  retrieveWorkingPatternItemsSucceeded,
  retrieveWorkingPatternItemsFailed,
  retrieveEmployeesByWorkingPatternIdRequested,
  retrieveEmployeesByWorkingPatternIdSucceeded,
  retrieveEmployeesByWorkingPatternIdFailed,
  createWorkingPatternRequested,
  createWorkingPatternSucceeded,
  createWorkingPatternFailed,
  retrieveWorkingPatternByIdRequested,
  retrieveWorkingPatternByIdSucceeded,
  retrieveWorkingPatternByIdFailed,
  deleteWorkingPatternByIdRequested,
  deleteWorkingPatternByIdSucceeded,
  deleteWorkingPatternByIdFailed,
  updateWorkingPatternByIdRequested,
  updateWorkingPatternByIdSucceeded,
  updateWorkingPatternByIdFailed,
  retrieveEmployeesByManagerIdRequested,
  retrieveEmployeesByManagerIdSucceeded,
  retrieveEmployeesByManagerIdFailed,
  retrieveSiteListItemByIdRequested,
  retrieveSiteListItemByIdSucceeded,
  retrieveSiteListItemByIdFailed,
  retrieveEmployeesByJobTitleRequested,
  retrieveEmployeesByJobTitleSucceeded,
  retrieveEmployeesByJobTitleFailed,
  setCurrentJobTitle,
  setCurrentAudienceRole,
  importEmployeesRequested,
  importEmployeesSucceeded,
  importEmployeesFailed
} = adminSettingsSlice.actions;
export default adminSettingsSlice.reducer;
