import { Typography, Stack } from '@mui/material';
import { useAppSelector } from '@app/hooks';
import { selectCurrentJobTitleName } from '@features/admin-settings';
import { JobTitleEmployeeList } from '@features/drawer';
import { useTranslation } from 'react-i18next';
import { WorkOutline } from '@mui/icons-material';

export const JobTitleDrawerContent = () => {
  const { t } = useTranslation(['common', 'settings_permissions']);
  const currentJobTitleName = useAppSelector(selectCurrentJobTitleName);

  return (
    <Stack gap={2}>
      <Stack gap={1}>
        <WorkOutline sx={{ fontSize: '3rem' }} />
        <Typography variant="subtitle1">
          {t('job_title_employees', {
            ns: 'settings_permissions',
            name: currentJobTitleName
          })}
        </Typography>
      </Stack>
      <JobTitleEmployeeList />
    </Stack>
  );
};
