import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AnniversaryItem,
  BirthdayItem,
  NewHireItem,
  RetrieveAnniversariesResponse,
  RetrieveBirthdaysResponse,
  RetrieveNewHiresResponse,
  RetrieveUpcomingEventsRequest,
  RetrieveUpcomingEventsResponse,
  UpcomingEvent
} from '@thrivea/organization-client';
import { RootState } from '@app/store';
import { ActionStatus } from '@/shared';

export interface WidgetsState {
  birthdays: BirthdayItem[];
  upcomingEvents: UpcomingEvent[];
  anniversaries: AnniversaryItem[];
  newHires: NewHireItem[];
  ui: {
    birthdaysStatus: ActionStatus;
    upcomingEventsStatus: ActionStatus;
    anniversariesStatus: ActionStatus;
    newHiresStatus: ActionStatus;
  };
}

const initialState: WidgetsState = {
  birthdays: [],
  upcomingEvents: [],
  anniversaries: [],
  newHires: [],
  ui: {
    birthdaysStatus: ActionStatus.Idle,
    upcomingEventsStatus: ActionStatus.Idle,
    anniversariesStatus: ActionStatus.Idle,
    newHiresStatus: ActionStatus.Idle
  }
};

export const widgetSlice = createSlice({
  name: 'widgets',
  initialState,
  reducers: {
    retrieveBirthdaysRequested: (state) => {
      state.ui.birthdaysStatus = ActionStatus.Pending;
    },
    retrieveBirthdaysSucceeded: (state, action: PayloadAction<RetrieveBirthdaysResponse>) => {
      state.birthdays = action.payload.birthdayItems;
      state.ui.birthdaysStatus = ActionStatus.Idle;
    },
    retrieveBirthdaysFailed: (state) => {
      state.ui.birthdaysStatus = ActionStatus.Failed;
    },
    retrieveUpcomingEventsRequested: (state, action: PayloadAction<RetrieveUpcomingEventsRequest>) => {
      state.ui.upcomingEventsStatus = ActionStatus.Pending;
    },
    retrieveUpcomingEventsSucceeded: (state, action: PayloadAction<RetrieveUpcomingEventsResponse>) => {
      state.upcomingEvents = action.payload.upcomingEvents;
      state.ui.upcomingEventsStatus = ActionStatus.Idle;
    },
    retrieveUpcomingEventsFailed: (state) => {
      state.ui.upcomingEventsStatus = ActionStatus.Failed;
    },
    retrieveAnniversariesRequested: (state) => {
      state.ui.anniversariesStatus = ActionStatus.Pending;
    },
    retrieveAnniversariesSucceeded: (state, action: PayloadAction<RetrieveAnniversariesResponse>) => {
      state.anniversaries = action.payload.anniversaryItems;
      state.ui.anniversariesStatus = ActionStatus.Idle;
    },
    retrieveAnniversariesFailed: (state) => {
      state.ui.anniversariesStatus = ActionStatus.Failed;
    },
    retrieveNewHiresRequested: (state) => {
      state.ui.newHiresStatus = ActionStatus.Pending;
    },
    retrieveNewHiresSucceeded: (state, action: PayloadAction<RetrieveNewHiresResponse>) => {
      state.newHires = action.payload.newHireItems;
      state.ui.newHiresStatus = ActionStatus.Idle;
    },
    retrieveNewHiresFailed: (state) => {
      state.ui.newHiresStatus = ActionStatus.Failed;
    }
  }
});

export const selectBirthdays = (state: RootState) => state.widgets.birthdays;
export const selectUpcomingEvents = (state: RootState) => state.widgets.upcomingEvents;
export const selectNewHires = (state: RootState) => state.widgets.newHires;
export const selectAnniversaries = (state: RootState) => state.widgets.anniversaries;
export const selectBirthdaysStatus = (state: RootState) => state.widgets.ui.birthdaysStatus;
export const selectUpcomingEventsStatus = (state: RootState) => state.widgets.ui.upcomingEventsStatus;
export const selectNewHiresStatus = (state: RootState) => state.widgets.ui.newHiresStatus;
export const selectAnniversariesStatus = (state: RootState) => state.widgets.ui.anniversariesStatus;

export const {
  retrieveBirthdaysRequested,
  retrieveBirthdaysSucceeded,
  retrieveBirthdaysFailed,
  retrieveUpcomingEventsRequested,
  retrieveUpcomingEventsSucceeded,
  retrieveUpcomingEventsFailed,
  retrieveAnniversariesRequested,
  retrieveAnniversariesSucceeded,
  retrieveAnniversariesFailed,
  retrieveNewHiresRequested,
  retrieveNewHiresSucceeded,
  retrieveNewHiresFailed
} = widgetSlice.actions;
export default widgetSlice.reducer;
