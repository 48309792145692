import { useTranslation } from 'react-i18next';
import { useId, useState } from 'react';
import { Box, Button, Collapse, Divider, Stack } from '@mui/material';
import { BinIcon, ChevronDownIcon, ChevronRightIcon, StyledTransparentAssetsActionButton } from '@/shared';
import { ArrowRight } from '@mui/icons-material';

export const AssetsAction = () => {
  const { t } = useTranslation(['drawer']);
  const menuId = useId();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuToggle = (_event: React.MouseEvent<HTMLButtonElement>) => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <Box>
      <Button
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={handleMenuToggle}
        variant="contained"
        endIcon={<ChevronDownIcon />}
        sx={{
          backgroundColor: (theme) => (isMenuOpen ? theme.palette.customTheme.contColor : 'transparent'),
          color: (theme) => (isMenuOpen ? theme.palette.common.white : theme.palette.common.black),
          border: (theme) => (isMenuOpen ? undefined : `1px solid ${theme.palette.customTheme.contColor}`),
          borderRadius: '7px',
          width: 332,
          padding: '16px 12px',
          lineHeight: 'normal',
          fontWeight: 700,
          boxShadow: 'none',
          '& .MuiButton-endIcon': {
            marginTop: '2px',
            transform: isMenuOpen ? 'rotate(180deg)' : undefined,
            transition: 'transform 0.3s ease-in-out',
            '& path': {
              fill: (theme) => (isMenuOpen ? theme.palette.common.white : theme.palette.common.black)
            }
          },
          '&:hover': {
            backgroundColor: (theme) => (isMenuOpen ? theme.palette.primary.dark : theme.palette.secondary.dark),
            borderColor: 'transparent'
          }
        }}
      >
        {t('actions', { ns: 'common' })}
      </Button>
      <Collapse in={isMenuOpen}>
        <Stack
          sx={{
            backgroundColor: (theme) => theme.palette.common.white,
            width: 332,
            borderBottomLeftRadius: 16,
            borderBottomRightRadius: 16,
            boxShadow: '0px 5px 15px 0px rgba(0, 0, 0, 0.15)',
            padding: 1
          }}
        >
          <StyledTransparentAssetsActionButton endIcon={<ChevronRightIcon />}>Open with</StyledTransparentAssetsActionButton>
          <Divider
            sx={{
              borderTop: '1px dotted #D0D0D0'
            }}
          />
          <StyledTransparentAssetsActionButton>Download</StyledTransparentAssetsActionButton>
          <StyledTransparentAssetsActionButton>Rename</StyledTransparentAssetsActionButton>
          <StyledTransparentAssetsActionButton>Make a copy</StyledTransparentAssetsActionButton>
          <Divider
            sx={{
              borderTop: '1px dotted #D0D0D0'
            }}
          />
          <StyledTransparentAssetsActionButton endIcon={<ChevronRightIcon />}>Share</StyledTransparentAssetsActionButton>
          <StyledTransparentAssetsActionButton endIcon={<ChevronRightIcon />}>Organize</StyledTransparentAssetsActionButton>
          <StyledTransparentAssetsActionButton endIcon={<BinIcon />}>Delete</StyledTransparentAssetsActionButton>
        </Stack>
      </Collapse>
    </Box>
  );
};
