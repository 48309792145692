import { Paper, PaperProps } from '@mui/material';
import { EmployeeListItem } from '@thrivea/organization-client';
import { forwardRef } from 'react';

export const CustomPermissionGroupAutocompletePaper = forwardRef<HTMLDivElement, PaperProps & { selectedOptions: EmployeeListItem[] | undefined }>(
  ({ children, selectedOptions, ...props }, ref) => (
    <Paper
      {...props}
      ref={ref}
      sx={{
        backgroundColor: 'transparent',
        px: 1
      }}
      elevation={0}
    >
      {children}
    </Paper>
  )
);
