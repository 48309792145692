import React, { useId, useMemo, useState } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Modal,
  Typography,
  IconButton,
  Stack,
  FormControl,
  Box,
  TablePagination,
  ListItem
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import CloseIcon from '@mui/icons-material/Close';
import { addNewPayrollEvent, createPayrollEventSchema, selectPayrollCategoryTableData } from '@features/employee-profile';
import {
  AddIcon,
  AlertIcon,
  ChevronDownIcon,
  AddNewRow,
  CancelButton,
  UpdateButton,
  RowCenterStack,
  StyledModalContent,
  StyledTransparentInput,
  StyledAutocomplete,
  StyledTransparentInputDatePicker
} from '@/shared';
import { useAppSelector } from '@app/hooks';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';

import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { countries } from 'countries-list';
import uniq from 'lodash/uniq';
import { PayrollEvent, SalaryPayFrequency, SalaryPayPeriod } from '@thrivea/organization-client';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { DateTime } from 'luxon';
import { useDispatch } from 'react-redux';
import { zodResolver } from '@hookform/resolvers/zod';
import { snakeCase } from 'lodash';
import { handleNumericInputChange } from '@utils/parseNumberInput';
import { AllowedTo } from 'react-abac';
import { GroupPermissions, ProfileCategoryInfo } from '@features/abac';

const currencies = uniq(Object.values(countries).flatMap((c) => c.currency));

const salaryPayPeriods = Object.keys(SalaryPayPeriod)
  .filter((key) => !isNaN(Number(key)))
  .slice(1) //exclude first option
  .map((key) => ({
    id: parseInt(key, 10) - 1, //deduct by 1 as we are removing first option
    name: SalaryPayPeriod[Number(key)].toLowerCase()
  }));

const salaryPayFrequencies = Object.keys(SalaryPayFrequency)
  .filter((key) => !isNaN(Number(key)))
  .slice(1) //exclude first option
  .map((key) => ({
    id: parseInt(key, 10) - 1, //deduct by 1 as we are removing first option
    name: SalaryPayFrequency[Number(key)].toLowerCase()
  }));

interface PayrollTableProps {
  employeeId: string;
  handleSetEditable: () => void;
}

export const PayrollCategoryTable: React.FC<PayrollTableProps> = ({ employeeId, handleSetEditable }) => {
  const id = useId();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation(['common', 'employee_profile', 'salary_pay_period_enum', 'salary_pay_frequency_enum']);
  const dispatch = useDispatch();
  const payrollCategoryTableData = useAppSelector(selectPayrollCategoryTableData);
  const payrollEventSchema = useMemo(() => createPayrollEventSchema(t), [t]);

  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid },
    setValue
  } = useForm<PayrollEvent>({
    mode: 'all',
    resolver: zodResolver(payrollEventSchema),
    defaultValues: {
      effectiveDate: '',
      baseSalary: 0,
      currencyCode: '',
      salaryPayPeriod: 0,
      salaryPayFrequency: 0,
      reason: ''
    }
  });
  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleModalOpen = () => {
    setIsModalOpen(true);
    handleSetEditable();
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    reset({
      effectiveDate: '',
      baseSalary: 0,
      currencyCode: '',
      salaryPayPeriod: 0,
      salaryPayFrequency: 0,
      reason: ''
    });
  };

  const handleRowEdit = (event: React.MouseEvent<HTMLElement>, id: string) => {
    event.stopPropagation();
    // setActiveEditModal(id);
  };

  const onSubmit = (data: PayrollEvent) => {
    dispatch(addNewPayrollEvent(data));
    reset();
    setIsModalOpen(false);
  };

  return (
    <Stack
      gap={2}
      sx={{
        height: '100%',
        justifyContent: 'stretch',
        padding: 0
      }}
    >
      <Paper
        elevation={0}
        sx={{
          width: '100%',
          overflow: 'hidden',
          '&.MuiPaper-root': {
            backgroundColor: (theme) => (payrollCategoryTableData.length === 0 ? theme.palette.customTheme.drawerBackground : 'transparent')
          }
        }}
      >
        <TableContainer sx={{ maxHeight: 440 }} tabIndex={0}>
          {payrollCategoryTableData.length === 0 && (
            <Stack
              sx={{
                width: '100%',
                height: 250,
                alignItems: 'center',
                justifyContent: 'center',
                gap: '16px',
                '& svg': {
                  width: 24,
                  height: 24
                }
              }}
            >
              <AlertIcon />
              <Typography>{t('empty_table', { ns: 'employee_profile' })}</Typography>
              <AllowedTo perform={GroupPermissions.EDIT_PROFILE} data={{ employeeId, categoryName: 'payroll' } as ProfileCategoryInfo}>
                <AddNewRow variant="outlined" endIcon={<AddIcon />} onClick={handleModalOpen}>
                  {t('add_new_row', { ns: 'common' })}
                </AddNewRow>
              </AllowedTo>
            </Stack>
          )}
          {payrollCategoryTableData.length !== 0 && (
            <>
              <Table stickyHeader>
                <TableHead
                  sx={{
                    position: 'relative'
                  }}
                >
                  <TableRow>
                    <TableCell>{t('effective_date', { ns: 'employee_profile' })}</TableCell>
                    <TableCell>{t('base_salary', { ns: 'employee_profile' })}</TableCell>
                    <TableCell>{t('salary_pay_period', { ns: 'employee_profile' })}</TableCell>
                    <TableCell>{t('salary_pay_frequency', { ns: 'employee_profile' })}</TableCell>
                    <TableCell>{t('reason', { ns: 'employee_profile' })}</TableCell>
                    {/* <TableCell
                      sx={{
                        position: 'sticky',
                        right: 0,
                        backgroundColor: (theme) => theme.palette.common.white,
                        minWidth: '90px',
                        height: '100%',
                        boxShadow: '-5px 0px 3px 0px rgba(0,0,0,0.12)'
                      }}
                    /> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {payrollCategoryTableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, rowIndex) => (
                    <TableRow hover key={rowIndex}>
                      <TableCell>{row.effectiveDate}</TableCell>
                      <TableCell>
                        {row.baseSalary} {row.currencyCode}
                      </TableCell>
                      <TableCell>{t(salaryPayPeriods[row.salaryPayPeriod].name, { ns: 'salary_pay_period' })}</TableCell>
                      <TableCell>{t(salaryPayFrequencies[row.salaryPayFrequency].name, { ns: 'salary_pay_frequency' })}</TableCell>
                      <TableCell>{row.reason}</TableCell>
                      {/* TODO - Implement after adding edit/delete actions */}
                      {/* <TableCell
                        sx={{
                          position: 'sticky',
                          right: 0,
                          backgroundColor: (theme) => theme.palette.common.white,
                          minWidth: '90px',
                          height: '100%',
                          boxShadow: '-5px 0px 3px 0px rgba(0,0,0,0.12)',
                          textAlign: 'center'
                        }}
                      >
                        <IsolatedMenu id={crypto.randomUUID()} handleRowEdit={(e) => handleRowEdit(e, crypto.randomUUID())} />
                      </TableCell> */}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 20]}
                component="div"
                count={payrollCategoryTableData!.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
              <AddNewRow
                variant="outlined"
                endIcon={<AddIcon />}
                onClick={handleModalOpen}
                sx={{
                  ml: 2,
                  mb: 2
                }}
              >
                {t('add_new_row', { ns: 'common' })}
              </AddNewRow>
            </>
          )}
        </TableContainer>
        <Modal
          open={isModalOpen}
          onClose={handleModalClose}
          sx={{
            background: 'rgba(217, 217, 217, 0.60)',
            backdropFilter: 'blur(10px)'
          }}
        >
          <StyledModalContent
            sx={{
              position: 'relative',
              justifyContent: 'center',
              alignItems: 'center',
              minWidth: {
                xs: 'auto',
                lg: '1316px'
              },
              height: {
                xs: '80%',
                lg: 'auto'
              },
              padding: '120px',
              display: 'flex',
              borderRadius: '20px',
              boxShadow: '0px 5px 12px 0px rgba(0, 0, 0, 0.10)'
            }}
          >
            <Typography
              variant="h3"
              sx={{
                mb: 3
              }}
            >
              {t('add_new_row', { ns: 'common' })}
            </Typography>
            <Grid
              container
              columnSpacing={2}
              rowGap={2}
              sx={{
                mt: 2,
                mb: 4
              }}
            >
              <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    padding: 2
                  }}
                >
                  <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
                    <Controller
                      name="effectiveDate"
                      control={control}
                      rules={{ required: true }}
                      render={({ field, fieldState }) => (
                        <FormControl fullWidth>
                          <LocalizationProvider dateAdapter={AdapterLuxon}>
                            {' '}
                            <DatePicker
                              {...field}
                              className="MuiDate-root"
                              format="yyyy-MM-dd"
                              value={field.value ? DateTime.fromISO(field.value) : null}
                              disableFuture
                              slots={{ textField: StyledTransparentInputDatePicker }}
                              slotProps={{
                                textField: {
                                  id: id + field.name,
                                  placeholder: 'YYYY-MM-DD',
                                  variant: 'filled',
                                  label: t(snakeCase(field.name), { ns: 'employee_profile' }),
                                  error: !!fieldState.error,
                                  helperText: fieldState.error?.message,
                                  required: true,
                                  fullWidth: true,
                                  InputProps: {
                                    disableUnderline: true,
                                    required: true,
                                    onBlur: () => {
                                      field.onBlur();
                                    }
                                  },
                                  InputLabelProps: {
                                    shrink: true
                                  }
                                }
                              }}
                              onChange={(date: DateTime | null) => {
                                if (date) {
                                  const jsDate = date.toJSDate();
                                  const stringDate = DateTime.fromJSDate(jsDate).toFormat('yyyy-MM-dd');
                                  field.onChange(stringDate);
                                }
                              }}
                            />
                          </LocalizationProvider>
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
                    <Grid container spacing={1}>
                      <Grid size={7}>
                        <Controller
                          name="baseSalary"
                          control={control}
                          render={({ field, fieldState }) => (
                            <StyledTransparentInput
                              {...field}
                              required
                              id={id + field.name}
                              label={t(snakeCase(field.name), { ns: 'employee_profile' })}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                              onChange={(e) =>
                                handleNumericInputChange({
                                  e: e,
                                  onChange: field.onChange
                                })
                              }
                              inputProps={{ inputMode: 'numeric' }}
                              onBlur={field.onBlur}
                            />
                          )}
                        />
                      </Grid>
                      <Grid size={5}>
                        <Controller
                          name="currencyCode"
                          control={control}
                          rules={{
                            required: true
                          }}
                          render={({ field, fieldState }) => (
                            <StyledAutocomplete
                              popupIcon={<ChevronDownIcon />}
                              disableClearable
                              limitTags={2}
                              options={currencies}
                              getOptionLabel={(option) => option}
                              onChange={(event, value, reason) => {
                                // check on blur if the value is empty
                                if (reason === 'blur' && !value) {
                                  setValue('currencyCode', '');
                                } else {
                                  field.onChange(value);
                                }
                              }}
                              renderInput={(params) => (
                                <StyledTransparentInput
                                  {...params}
                                  id={id + field.name}
                                  required
                                  label={t(snakeCase(field.name), { ns: 'employee_profile' })}
                                  error={!!fieldState.error}
                                  helperText={fieldState.error?.message}
                                  onBlur={field.onBlur}
                                />
                              )}
                              renderOption={(props, option) => (
                                <ListItem {...props} key={crypto.randomUUID()}>
                                  {option}
                                </ListItem>
                              )}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
                    <Controller
                      name="salaryPayPeriod"
                      control={control}
                      rules={{
                        required: true
                      }}
                      render={({ field, fieldState }) => (
                        <StyledAutocomplete
                          popupIcon={<ChevronDownIcon />}
                          disableClearable
                          options={salaryPayPeriods}
                          getOptionLabel={(option) => t(option.name, { ns: 'salary_pay_period' })}
                          onChange={(_, value) => field.onChange(value.id)}
                          renderInput={(params) => (
                            <StyledTransparentInput
                              {...params}
                              id={id + field.name}
                              label={t(snakeCase(field.name), { ns: 'employee_profile' })}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                              required
                              onBlur={() => field.onBlur()}
                            />
                          )}
                          renderOption={(props, option) => (
                            <ListItem {...props} key={crypto.randomUUID()}>
                              {t(option.name, { ns: 'salary_pay_period' })}
                            </ListItem>
                          )}
                        />
                      )}
                    />
                  </Grid>
                  <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
                    <Controller
                      name="salaryPayFrequency"
                      control={control}
                      rules={{
                        required: true
                      }}
                      render={({ field, fieldState }) => (
                        <StyledAutocomplete
                          popupIcon={<ChevronDownIcon />}
                          disableClearable
                          limitTags={2}
                          options={salaryPayFrequencies}
                          getOptionLabel={(option) => t(option.name, { ns: 'salary_pay_frequency' })}
                          onChange={(_, value) => field.onChange(value.id)}
                          renderInput={(params) => (
                            <StyledTransparentInput
                              {...params}
                              id={id + field.name}
                              label={t(snakeCase(field.name), { ns: 'employee_profile' })}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                              required
                              onBlur={() => field.onBlur()}
                            />
                          )}
                          renderOption={(props, option) => (
                            <ListItem {...props} key={crypto.randomUUID()}>
                              {t(option.name, { ns: 'salary_pay_frequency' })}
                            </ListItem>
                          )}
                        />
                      )}
                    />
                  </Grid>
                  <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
                    <Controller
                      name="reason"
                      control={control}
                      render={({ field, fieldState }) => (
                        <StyledTransparentInput
                          {...field}
                          id={id + field.name}
                          label={t(snakeCase(field.name), { ns: 'employee_profile' })}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          onChange={(e) => field.onChange(e)}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <RowCenterStack
                  gap={2}
                  sx={{
                    justifyContent: 'flex-end'
                  }}
                >
                  <CancelButton variant="outlined" onClick={handleModalClose}>
                    {t('cancel', { ns: 'common' })}
                  </CancelButton>
                  <UpdateButton type="submit" variant="contained" disabled={!isValid}>
                    {t('add', { ns: 'common' })}
                  </UpdateButton>
                </RowCenterStack>
              </Box>
            </Grid>
            <IconButton
              sx={{
                position: 'absolute',
                top: 10,
                right: 10,
                zIndex: 1
              }}
              onClick={handleModalClose}
            >
              <CloseIcon />
            </IconButton>
          </StyledModalContent>
        </Modal>
      </Paper>
    </Stack>
  );
};
