import React from 'react';

interface CloseIconAttributes {
  width?: number;
  height?: number;
  color?: string;
}

export const CloseIcon: React.FC<CloseIconAttributes> = ({ width = 18, height = 19, color = '#251D38' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group 12">
        <path
          id="Vector"
          d="M10.1641 3.78809L3.72443 10.2277"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round" // Corrected strokeLinejoin
        />
        <path
          id="Vector_2"
          d="M3.72266 3.78809L10.1623 10.2277"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round" // Corrected strokeLinejoin
        />
      </g>
    </svg>
  );
};
