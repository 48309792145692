import React from 'react';

interface SmartPhoneIconProps {
  size?: number; // Optional size prop to control the icon size
  color?: string; // Optional color prop to control the icon color
}

export const SmartPhoneIcon: React.FC<SmartPhoneIconProps> = ({ size = 24, color = 'black' }) => {
  return (
    <svg width={size} height={(size * 24) / 22} viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="smartphone">
        <g id="Vector">
          <path
            d="M15.5833 2.83276H6.41659C5.40406 2.83276 4.58325 3.68829 4.58325 4.74363V20.0305C4.58325 21.0859 5.40406 21.9414 6.41659 21.9414H15.5833C16.5958 21.9414 17.4166 21.0859 17.4166 20.0305V4.74363C17.4166 3.68829 16.5958 2.83276 15.5833 2.83276Z"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15.5833 2.83276H6.41659C5.40406 2.83276 4.58325 3.68829 4.58325 4.74363V20.0305C4.58325 21.0859 5.40406 21.9414 6.41659 21.9414H15.5833C16.5958 21.9414 17.4166 21.0859 17.4166 20.0305V4.74363C17.4166 3.68829 16.5958 2.83276 15.5833 2.83276Z"
            stroke={color}
            strokeOpacity="0.2"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <g id="Vector_2">
          <path d="M11 18.1196H11.0092" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M11 18.1196H11.0092" stroke={color} strokeOpacity="0.2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </g>
      </g>
    </svg>
  );
};
