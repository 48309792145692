export const joinUrlParts = (...parts: string[]): string =>
  parts
    .map((part, index) => {
      // Remove leading slashes for all but the first part, and trailing slashes for all but the last part
      let sanitizedPart = part;
      if (index !== 0) {
        sanitizedPart = part.replace(/^\/+/, ''); // Remove leading slashes
      }

      if (index !== parts.length - 1) {
        sanitizedPart = part.replace(/\/+$/, ''); // Remove trailing slashes
      }

      return sanitizedPart;
    })
    .join('/');
