import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import { useAppSelector } from '@app/hooks';
import { selectPostAnalyticsStatus, selectPostViewData } from '@features/homepage';
import { ActionStatus } from 'src/shared';

const chartSetting = {
  width: 400,
  height: 250,
  sx: {
    [`.${axisClasses.left} .${axisClasses.label}`]: {
      transform: 'translate(-20px, 0)'
    }
  }
};

const viewValueFormatter = (value: number | null) => `${value} views`;

export const PostAnalyticsViewsChart = () => {
  const viewDataSet = useAppSelector(selectPostViewData);
  const postAnalyticsStatus = useAppSelector(selectPostAnalyticsStatus);

  return (
    <BarChart
      loading={postAnalyticsStatus === ActionStatus.Pending}
      dataset={viewDataSet}
      series={[{ dataKey: 'views', label: 'Daily Views', valueFormatter: viewValueFormatter }]}
      xAxis={[
        {
          dataKey: 'date',
          scaleType: 'band'
        }
      ]}
      {...chartSetting}
    />
  );
};
