import React from 'react';

interface MapPinIconProps {
  width?: number;
  height?: number;
  color?: string;
}

export const MapPinIcon: React.FC<MapPinIconProps> = ({ width: width = 24, height = 25, color = '#251D38' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none">
      <path
        d="M11.9992 1.64307C11.9811 1.64479 11.9632 1.64746 11.9455 1.65076C7.24424 1.67656 3.42773 4.98073 3.42773 9.03894C3.42773 10.3406 3.83252 11.5552 4.5193 12.6174C4.51927 12.6198 4.51927 12.6225 4.5193 12.625C6.81413 16.1315 9.19791 19.6397 11.5429 23.1366C11.7455 23.4345 12.3396 23.4299 12.536 23.1289L19.3985 12.7484L19.4164 12.7252C19.4184 12.7223 19.4234 12.7206 19.4253 12.7176C20.0732 11.4913 20.5571 10.166 20.5706 9.03894C20.5706 4.96519 16.7258 1.64307 11.9992 1.64307Z"
        stroke={color}
        strokeWidth="2"
      />
      <path
        d="M9.14258 9.0715C9.14258 7.49354 10.4218 6.21436 11.9997 6.21436C13.5777 6.21436 14.8569 7.49354 14.8569 9.0715C14.8569 10.6495 13.5777 11.9286 11.9997 11.9286C10.4218 11.9286 9.14258 10.6495 9.14258 9.0715Z"
        fill={color}
      />
    </svg>
  );
};
