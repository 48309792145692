import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@app/store';
import { ActionStatus } from '@/shared';
import { CreateNewTenantRequest } from '@features/welcome';

interface WelcomeState {
  createNewTenantStatus?: ActionStatus;
}

const initialState: WelcomeState = {
  createNewTenantStatus: undefined
};

export const welcomeSlice = createSlice({
  name: 'welcome',
  initialState,
  reducers: {
    createNewTenantRequested: (state, action: PayloadAction<CreateNewTenantRequest>) => {
      state.createNewTenantStatus = ActionStatus.Pending;
    },
    createNewTenantSucceeded: (state) => {
      state.createNewTenantStatus = ActionStatus.Idle;
    },
    createNewTenantFailed: (state) => {
      state.createNewTenantStatus = ActionStatus.Failed;
    }
  }
});

export const selectCreateNewTenantStatus = (state: RootState) => state.welcome.createNewTenantStatus;

export const { createNewTenantRequested, createNewTenantSucceeded, createNewTenantFailed } = welcomeSlice.actions;
export default welcomeSlice.reducer;
