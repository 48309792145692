import { useEffect } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { alpha, AppBar, Box, IconButton, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import {
  AppBarSearch,
  AppBarSearchIconWrapper,
  AppBarStyledInputBase,
  RowCenterStack,
  AvailableBetaTag,
  SiteAppbarUserAction,
  CustomBreadcrumbs
} from '@/shared';
import {
  deleteCommentOnPost,
  deletePost,
  editShoutout,
  newPostPublished,
  RenderEmoji,
  showNewCommentRequested,
  showNewReactionRequested,
  showNewReactionToCommentRequested,
  withdrawCommentReaction,
  withdrawPostReaction
} from '@features/homepage';
import { useTranslation } from 'react-i18next';
import {
  selectLastNotificationDateTime,
  selectNotificationInitialCheck,
  setHasUnreadNotifications,
  setLastNotificationDateTime
} from '@features/notifications';
import { notifyNewDeployment, selectIsSnackbarVisible, selectLastCommitHash } from '@features/snackbar';
import { selectCurrentEmployeeId, selectCurrentUserDisplayName } from '@app/user';
import { Deployment, RetrieveLatestDeploymentRequest } from '@thrivea/admin-onboarding-client';
import { retrieveLatestDeployment } from '@api/deployment.api';
import * as Sentry from '@sentry/react';
import { checkNewNotification } from '@api/notification.api';
import { CheckNewNotificationRequest } from '@thrivea/notification-client';
import { useSignalR } from '@app/contexts';
import {
  ReactedToPost,
  PostReactionWithdrawn,
  CommentedOnPost,
  CommentOnPostDeleted,
  ReactedToComment,
  CommentReactionWithdrawn,
  PostDeleted,
  ShoutoutEdited
} from '@thrivea/networking-client';
import { openNavigationDrawer } from '@features/navigation';
import MenuIcon from '@mui/icons-material/Menu';

export const SiteAppBar = () => {
  const { t } = useTranslation('common');
  const dispatch = useAppDispatch();
  const { connection } = useSignalR();
  const currentUserDisplayName = useAppSelector(selectCurrentUserDisplayName);
  const lastCommitHash = useAppSelector(selectLastCommitHash);
  const currentUserEmployeeId = useAppSelector(selectCurrentEmployeeId);
  const lastNotificationDateTime = useAppSelector(selectLastNotificationDateTime);
  const notificationInitialCheck = useAppSelector(selectNotificationInitialCheck);
  const isSnackbarVisible = useAppSelector(selectIsSnackbarVisible);

  useEffect(() => {
    if (!connection) return;

    connection.on('ShoutoutPublished', () => {
      dispatch(newPostPublished());
    });
    connection.on('ReactedToPost', (message: ReactedToPost) => {
      console.warn('showNewReactionRequested', message);
      dispatch(showNewReactionRequested(message));
    });

    connection.on('PostReactionWithdrawn', (message: PostReactionWithdrawn) => {
      dispatch(withdrawPostReaction(message));
    });
    connection.on('CommentedOnPost', (message: CommentedOnPost) => {
      dispatch(showNewCommentRequested(message));
    });
    connection.on('CommentOnPostDeleted', (message: CommentOnPostDeleted) => {
      dispatch(deleteCommentOnPost(message));
    });
    connection.on('ReactedToComment', (message: ReactedToComment) => {
      dispatch(showNewReactionToCommentRequested(message));
    });
    connection.on('CommentReactionWithdrawn', (message: CommentReactionWithdrawn) => {
      dispatch(withdrawCommentReaction(message));
    });
    connection.on('PostDeleted', (message: PostDeleted) => {
      dispatch(deletePost(message));
    });
    connection.on('ShoutoutEdited', (message: ShoutoutEdited) => {
      dispatch(editShoutout(message));
    });

    connection.on('NotifiedEmployee', () => {
      dispatch(setHasUnreadNotifications());
    });

    const fetchLatestDeployment = async () => {
      try {
        const response = await retrieveLatestDeployment(new RetrieveLatestDeploymentRequest({ branch: '' }));

        if (response.commitHash !== lastCommitHash) {
          dispatch(notifyNewDeployment(new Deployment({ ...response })));
        }
      } catch (error) {
        Sentry.captureException(error);
      }
    };

    const fetchNewNotifications = async () => {
      try {
        const response = await checkNewNotification(new CheckNewNotificationRequest({ employeeId: currentUserEmployeeId }));

        if (response.notificationCreationDateTime > lastNotificationDateTime) {
          dispatch(setLastNotificationDateTime(response.notificationCreationDateTime));
        }
      } catch (error) {
        Sentry.captureException(error);
      }
    };
    if (!notificationInitialCheck) {
      fetchNewNotifications();
    }
    const intervalId = setInterval(fetchLatestDeployment, 60000);

    return () => {
      clearInterval(intervalId);
      connection.off('ShoutoutPublished');
      connection.off('ReactedToPost');
      connection.off('PostReactionWithdrawn');
      connection.off('CommentedOnPost');
      connection.off('CommentOnPostDeleted');
      connection.off('ReactedToComment');
      connection.off('CommentReactionWithdrawn');
      connection.off('PostDeleted');
      connection.off('ShoutoutEdited');
      connection.off('NotifiedEmployee');
    };
  }, [connection, lastCommitHash, lastNotificationDateTime]);

  const navigationDrawerOpenButtonClick = () => {
    dispatch(openNavigationDrawer());
  };

  return (
    <AppBar
      position="sticky"
      elevation={0}
      sx={{
        flexDirection: {
          xs: 'column',
          md: 'row'
        },
        alignItems: {
          xs: 'flex-start',
          md: 'flex-end'
        },
        gap: '10px',
        justifyContent: 'space-between',
        padding: {
          xs: '0 0 16px 0',
          md: '16px 16px'
        },
        zIndex: (theme) => theme.zIndex.tooltip + 1,
        '&.MuiPaper-root': {
          background: (theme) => alpha(theme.palette.background.default, 0.75),
          backdropFilter: 'blur(10px)',
          borderRadius: 4,
          width: '100%',
          mb: 2,
          top: isSnackbarVisible ? 66 : 16,
          transition: (theme) =>
            theme.transitions.create('top', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen
            }),
          '&::before': {
            content: '""',
            backgroundColor: (theme) => theme.palette.background.default,
            position: 'absolute',
            top: isSnackbarVisible ? 0 : -50,
            left: 0,
            width: '100%',
            height: isSnackbarVisible ? 0 : 50
          }
        }
      }}
    >
      <Grid container spacing={2} sx={{ width: '100%' }}>
        <Grid size={{ xs: 12, lg: 4 }}>
          <Stack gap={1}>
            <RowCenterStack gap={1}>
              <Typography
                sx={{
                  color: (theme) => theme.palette.common.black
                }}
              >
                {t('welcome')}
                {','}{' '}
                <Box component="span" sx={{ fontWeight: 600 }}>
                  {currentUserDisplayName}
                </Box>
              </Typography>
              <RenderEmoji native="👋" />
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={navigationDrawerOpenButtonClick}
                sx={{
                  display: { md: 'none' },
                  marginLeft: 'auto'
                }}
              >
                <MenuIcon fontSize={'medium'} color="primary" />
              </IconButton>
            </RowCenterStack>
            <CustomBreadcrumbs />
          </Stack>
        </Grid>
        <Grid
          size={{
            xs: 12,
            md: 6,
            lg: 4
          }}
          sx={{
            order: {
              xs: 2,
              md: 1
            }
          }}
        >
          <AppBarSearch>
            <AvailableBetaTag top={7} right={15} />
            <AppBarSearchIconWrapper>
              <SearchIcon />
            </AppBarSearchIconWrapper>
            <AppBarStyledInputBase disabled placeholder="Search Anything" inputProps={{ 'aria-label': 'search' }} />
          </AppBarSearch>
        </Grid>
        <Grid
          size={{
            xs: 12,
            md: 6,
            lg: 4
          }}
          sx={{
            order: {
              xs: 1,
              md: 2
            }
          }}
        >
          <SiteAppbarUserAction />
        </Grid>
      </Grid>
    </AppBar>
  );
};
