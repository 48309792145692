import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import { useAppSelector } from '@app/hooks';
import { selectPostAnalyticsStatus, selectPostViewAndReadData } from '@features/homepage';
import { ActionStatus } from 'src/shared';

const chartSetting = {
  width: 400,
  height: 250,
  sx: {
    [`.${axisClasses.left} .${axisClasses.label}`]: {
      transform: 'translate(-20px, 0)'
    }
  }
};

const viewValueFormatter = (value: number | null) => `${value} views`;
const readValueFormatter = (value: number | null) => `${value} reads`;

export const PostAnalyticsViewsAndReadsChart = () => {
  const viewsAndReadsDataset = useAppSelector(selectPostViewAndReadData);
  const postAnalyticsStatus = useAppSelector(selectPostAnalyticsStatus);

  return (
    <BarChart
      loading={postAnalyticsStatus === ActionStatus.Pending}
      dataset={viewsAndReadsDataset}
      series={[
        { dataKey: 'views', label: 'Daily Views ', valueFormatter: viewValueFormatter },
        { dataKey: 'reads', label: 'Daily Reads', valueFormatter: readValueFormatter }
      ]}
      xAxis={[
        {
          dataKey: 'date',
          scaleType: 'band'
        }
      ]}
      {...chartSetting}
    />
  );
};
