import { Box } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { FormProvider, useForm } from 'react-hook-form';
import { PrimaryTypographyHeader, RowCenterStack, StyledFormWrapper } from '@/shared';
import { EmployeeRecordGenericField, selectErTemplateFields } from '@features/employee-record-builder';
import { ErTemplateSection } from '@thrivea/organization-client';
import { orderBy } from 'lodash';
import { useAppDispatch, useAppSelector } from '@app/hooks';

interface EmployeeRecordGenericSectionProps {
  section: ErTemplateSection;
  isEditable?: boolean;
}

export const EmployeeRecordGenericSection: React.FC<EmployeeRecordGenericSectionProps> = ({ section, isEditable }) => {
  const methods = useForm();
  const dispatch = useAppDispatch();
  const erTemplateFields = useAppSelector(selectErTemplateFields);
  const handleSubmit = (data: any) => {
    console.log(data);
  };

  const orderedFields = orderBy(section.fields, ['kind.value.position']);

  return (
    <StyledFormWrapper isEditable={isEditable}>
      <RowCenterStack
        sx={{
          padding: (theme) => theme.spacing(2),
          backgroundColor: (theme) => theme.palette.customTheme.headerColorSub,
          justifyContent: 'space-between'
        }}
      >
        <PrimaryTypographyHeader> {section.name}</PrimaryTypographyHeader>
      </RowCenterStack>
      <FormProvider {...methods}>
        <Box
          component="form"
          sx={{
            backgroundColor: 'transparent'
          }}
          onSubmit={methods.handleSubmit(handleSubmit)}
        >
          <Grid
            container
            spacing={2}
            sx={{
              padding: 2
            }}
          >
            {orderedFields.map((field) => {
              if (field.kind.case === 'scalarField') {
                return <EmployeeRecordGenericField key={field.kind.value.id} templateField={field} />;
              }
            })}
            {erTemplateFields.map((field) => (
              <EmployeeRecordGenericField key={field.kind.value!.id} templateField={field} />
            ))}
          </Grid>
        </Box>
      </FormProvider>
    </StyledFormWrapper>
  );
};
