import { Stack, Skeleton } from '@mui/material';
import { RowCenterStack } from '@/shared';

export const EmployeeDrawerRecentPostSkeleton = () => {
  return (
    <Stack
      gap={1}
      sx={{
        p: 1,
        borderRadius: 2
      }}
    >
      <Skeleton width={200} height={25} />
      <Skeleton width={50} height={10} />
      <RowCenterStack gap={1}>
        <RowCenterStack gap={1}>
          <Skeleton width={20} height={20} variant="circular" />
          <Skeleton width={15} height={10} />
          <Skeleton width={25} height={10} />
        </RowCenterStack>
        <RowCenterStack gap={1}>
          <Skeleton width={20} height={20} variant="circular" />
          <Skeleton width={15} height={10} />
          <Skeleton width={25} height={10} />
        </RowCenterStack>
      </RowCenterStack>
    </Stack>
  );
};
