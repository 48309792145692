import React from 'react';
import { Drawer, Box, IconButton, useMediaQuery, Stack } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import {
  AssetsDrawerContent,
  AudienceDrawerContent,
  AudienceRoleDrawerContent,
  DrawerType,
  EmployeeRecordBuilderDrawerContent,
  JobTitleDrawerContent,
  NotificationsDrawerContent,
  PeopleDirectoryDrawerContent,
  PostAnalyticsDrawerContent,
  SingleEmployeeDrawerContent,
  SiteDrawerContent,
  WorkingPatternDrawerContent,
  goToPrevious,
  resetDrawer,
  selectDrawerStack,
  selectIsDrawerOpen
} from '@features/drawer';
import { Close } from '@mui/icons-material';
import { RowCenterStack, StyledTransparentButton } from '@/shared';
import { useTranslation } from 'react-i18next';

export const MainDrawer: React.FC = () => {
  const { t } = useTranslation(['common']);
  const dispatch = useAppDispatch();
  const drawerStack = useAppSelector(selectDrawerStack);
  const isDrawerOpen = useAppSelector(selectIsDrawerOpen);
  const mobile = useMediaQuery('(max-width:767px)');

  const currentDrawer = drawerStack[drawerStack.length - 1];

  const handleGoToPreviousButtonClick = () => {
    dispatch(goToPrevious());
  };

  const handleCloseDrawerButtonClick = () => {
    dispatch(resetDrawer());
  };

  return (
    <Drawer
      anchor="right"
      open={isDrawerOpen}
      onClose={handleCloseDrawerButtonClick}
      variant={mobile ? 'temporary' : 'persistent'}
      sx={{
        width: '100%',
        position: 'sticky',
        top: 16
      }}
      PaperProps={{
        sx: {
          position: 'relative',
          top: 'auto',
          right: 'auto',
          bottom: 'auto',
          left: 'auto',
          width: {
            xs: 'calc(100% - 32px)',
            lg: '100%'
          },
          height: 'calc(100vh - 32px)',
          backgroundColor: (theme) => theme.palette.customTheme.drawerBackground,
          margin: {
            xs: 'auto',
            lg: 0
          },
          border: 0,
          borderRadius: (theme) => theme.spacing(2),
          overflow: 'hidden'
        }
      }}
    >
      <Stack gap={2} sx={{ width: '100%', height: '100%', p: 2, overflowY: 'scroll' }} role="presentation">
        <RowCenterStack
          sx={{
            position: 'sticky',
            top: -16,
            zIndex: (theme) => theme.zIndex.tooltip,
            p: 0,
            justifyContent: drawerStack.length > 1 ? 'space-between' : 'flex-end'
          }}
        >
          {drawerStack.length > 1 && (
            <StyledTransparentButton startIcon={<ArrowBackIcon />} onClick={handleGoToPreviousButtonClick}>
              {t('back', { ns: 'common' })}
            </StyledTransparentButton>
          )}
          <IconButton
            onClick={handleCloseDrawerButtonClick}
            sx={{
              backgroundColor: (theme) => theme.palette.common.white,
              '& path': {
                fill: (theme) => theme.palette.common.black
              },
              '&:hover': {
                backgroundColor: (theme) => theme.palette.common.white
              }
            }}
          >
            <Close />
          </IconButton>
        </RowCenterStack>
        {currentDrawer === DrawerType.PostAnalytics && <PostAnalyticsDrawerContent />}
        {currentDrawer === DrawerType.SingleEmployee && <SingleEmployeeDrawerContent />}
        {currentDrawer === DrawerType.WorkingPattern && <WorkingPatternDrawerContent />}
        {currentDrawer === DrawerType.Site && <SiteDrawerContent />}
        {currentDrawer === DrawerType.Audience && <AudienceDrawerContent />}
        {currentDrawer === DrawerType.JobTitle && <JobTitleDrawerContent />}
        {currentDrawer === DrawerType.AudienceRole && <AudienceRoleDrawerContent />}
        {currentDrawer === DrawerType.Notifications && <NotificationsDrawerContent />}
        {currentDrawer === DrawerType.EmployeeRecord && <EmployeeRecordBuilderDrawerContent />}
        {currentDrawer === DrawerType.PeopleDirectory && <PeopleDirectoryDrawerContent />}
        {currentDrawer === DrawerType.Assets && <AssetsDrawerContent />}
      </Stack>
    </Drawer>
  );
};
