import React from 'react';
import { useTheme } from '@mui/material';

interface CancelEditIconAttributes {
  className?: string;
}

export const CancelEditIcon: React.FC<CancelEditIconAttributes> = ({ className = '' }: CancelEditIconAttributes) => {
  const theme = useTheme();

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.8984 4.5L18.9 4.50001C19.4562 4.5025 19.9889 4.72453 20.3822 5.11781C20.7754 5.51108 20.9975 6.04376 21 6.59993L21 6.6016V17.3984L21 17.4001C20.9975 17.9562 20.7754 18.4889 20.3822 18.8822C19.9889 19.2755 19.4562 19.4975 18.9 19.5L18.8984 19.5H7.35296C7.05338 19.4999 6.7573 19.4357 6.4846 19.3116C6.21191 19.1876 5.96891 19.0067 5.77193 18.781L5.58701 18.5691V18.5123L1.27203 12.0001L5.74004 5.25505L5.77324 5.21714C5.9703 4.99211 6.21312 4.81174 6.48548 4.68807C6.75783 4.56439 7.05344 4.50028 7.35256 4.5L18.8984 4.5ZM7.35368 6C7.26812 6.00012 7.18357 6.01848 7.10566 6.05385C7.03995 6.08369 6.98024 6.12506 6.92932 6.17589L3.07135 11.9999L6.93198 17.8265C6.98224 17.8763 7.041 17.9168 7.10559 17.9462C7.18346 17.9816 7.26801 18 7.35355 18H18.8946C19.0548 17.9989 19.2082 17.9348 19.3215 17.8215C19.4348 17.7082 19.4989 17.5549 19.5 17.3947V6.60534C19.4989 6.44514 19.4348 6.29177 19.3215 6.17847C19.2082 6.06517 19.0549 6.00107 18.8947 6H7.35368Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7343 11.0019L15.2511 8.48514L16.3117 9.5458L13.7949 12.0626L16.3117 14.5794L15.2511 15.64L12.7343 13.1232L10.2175 15.64L9.15683 14.5794L11.6736 12.0626L9.15683 9.5458L10.2175 8.48514L12.7343 11.0019Z"
        fill="white"
      />
    </svg>
  );
};
