import { useTheme } from '@mui/material';
import React from 'react';

interface ShoutoutIconAttributes {
  width?: number;
  height?: number;
}

export const ShoutoutIcon: React.FC<ShoutoutIconAttributes> = ({ width = 24, height = 24 }: ShoutoutIconAttributes) => {
  const theme = useTheme();

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
      <path
        fill={theme.palette.customTheme.contColor}
        d="M22.75 3.633a.75.75 0 0 0-.75.75v.893L2.5 9.453v-.57a.75.75 0 0 0-1.5 0v7.5a.75.75 0 1 0 1.5 0v-.57l3.75.81v.51a4.5 4.5 0 0 0 8.775 1.365l6.975 1.5v.885a.75.75 0 1 0 1.5 0v-16.5a.75.75 0 0 0-.75-.75Zm-12 16.5a3 3 0 0 1-3-3 1.724 1.724 0 0 1 0-.195l5.783 1.245a3 3 0 0 1-2.783 1.95ZM2.5 14.276V10.99L22 6.813v11.64L2.5 14.276Z"
      />
    </svg>
  );
};
