import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { DrawerType } from '@features/drawer';
import { RootState } from '@app/store';

export interface DrawerState {
  drawerStack: DrawerType[];
}

const initialState: DrawerState = {
  drawerStack: [] as DrawerType[]
};

export const drawerSlice = createSlice({
  name: 'drawer',
  initialState,
  reducers: {
    openDrawer: (state, action: PayloadAction<DrawerType>) => {
      if (state.drawerStack[state.drawerStack.length - 1] !== action.payload) {
        state.drawerStack.push(action.payload);
      }
    },
    goToPrevious: (state) => {
      state.drawerStack.pop();
    },
    resetDrawer: (state) => {
      state.drawerStack = [];
    }
  }
});

export const selectDrawerStack = (state: RootState) => state.drawer.drawerStack;
export const selectIsDrawerOpen = (state: RootState) => state.drawer.drawerStack.length > 0;

export const { openDrawer, goToPrevious, resetDrawer } = drawerSlice.actions;
export default drawerSlice.reducer;
