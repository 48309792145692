import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { selectCurrentDrawerRole } from '@features/admin-settings';
import { Typography, Stack, IconButton, FormControlLabel, Chip, Skeleton } from '@mui/material';
import { Close, LockOutlined } from '@mui/icons-material';
import { StyledPermissionGroupCheckbox } from '@/shared';

export const AudienceRoleDrawerContent = () => {
  const { t } = useTranslation(['common', 'settings_permissions']);
  const dispatch = useAppDispatch();
  const currentAudienceRole = useAppSelector(selectCurrentDrawerRole);

  if (currentAudienceRole === undefined) {
    return (
      <Stack>
        <Skeleton
          sx={{
            width: '100%',
            height: 500,
            transfrom: 'none'
          }}
        />
      </Stack>
    );
  }

  return (
    <Stack>
      <Stack
        gap={1}
        sx={{
          mb: 1,
          p: 2
        }}
      >
        <LockOutlined sx={{ width: 76, height: 76, ml: -1 }} />
        <Chip
          label={currentAudienceRole.role}
          sx={{
            backgroundColor: (theme) => theme.palette.success.light,
            fontWeight: 700,
            maxWidth: 100
          }}
        />
        <Typography>{currentAudienceRole.description}</Typography>
      </Stack>
      {currentAudienceRole.features.map((carf) => (
        <Stack
          key={carf.name}
          sx={{
            backgroundColor: (theme) => theme.palette.grey[600],
            borderRadius: 4,
            p: 2,
            mb: 2
          }}
        >
          <Stack
            sx={{
              mb: 1
            }}
          >
            <Typography variant="subtitle1">{carf.name}</Typography>
            <Typography>{carf.description}</Typography>
          </Stack>
          {carf.permissions.map((p) => (
            <FormControlLabel
              disabled
              control={<StyledPermissionGroupCheckbox checked={p.isAllowed} />}
              label={<Typography>{t(p.name, { ns: 'settings_permissions' })}</Typography>}
            />
          ))}
        </Stack>
      ))}
    </Stack>
  );
};
