import { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { DateTime } from 'luxon';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { Stack, Typography, Button, Box, Skeleton, CircularProgress, ListItem } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import {
  RowCenterStack,
  AddIcon,
  ChevronDownIcon,
  EditPenIcon,
  StyledEditButton,
  StyledTransparentInputDatePicker,
  StyledTransparentInput,
  StyledAutocomplete
} from '@/shared';
import { ActionStatus } from '@shared/shared.model';
import {
  selectAdminOnboardingFlowStatus,
  selectIncorporationStep,
  selectIsCompanyEmployeeStepFinished,
  selectIsCompanyIncorporationStepFinished,
  updateIncorporationStepRequested,
  selectNameAndIndustryStepStatus,
  selectIncorporationStepStatus
} from '@features/admin-onboarding';
import { IncorporationStep, UpdateIncorporationStepRequest } from '@thrivea/organization-client';
import countries from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';
import { z } from 'zod';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from 'react-i18next';

const incorporationFormSchema = z.object({
  incorporationDate: z.string().min(1, { message: 'Incorporation date is required and cannot be empty.' }),
  incorporationCountry: z.string().min(1, { message: 'Incorporation country is required and cannot be empty.' }),
  tradingNumber: z.string().min(1, { message: 'Trading number is required and cannot be empty.' })
});

countries.registerLocale(enLocale);

interface AdminOnboardingIncorporationProps {
  isEditable: boolean;
  handleSetActiveSubStep: (stepName: string) => (isExpanded: boolean) => void;
}

export const AdminOnboardingIncorporation: React.FC<AdminOnboardingIncorporationProps> = ({ isEditable, handleSetActiveSubStep }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['onboarding', 'common']);
  const incorporationStep = useAppSelector<IncorporationStep | undefined>(selectIncorporationStep);
  const incorporationStepStatus = useAppSelector(selectIncorporationStepStatus);
  const previousStepStatus = useAppSelector(selectNameAndIndustryStepStatus);
  const isStepCompleted = useAppSelector(selectIsCompanyIncorporationStepFinished);
  const isNextStepFinished = useAppSelector(selectIsCompanyEmployeeStepFinished);
  const { handleSubmit, getValues, reset, control, formState } = useForm<UpdateIncorporationStepRequest>({
    mode: 'onChange',
    defaultValues: {
      incorporationDate: '',
      incorporationCountry: '',
      tradingNumber: ''
    },
    resolver: zodResolver(incorporationFormSchema)
  });
  const adminOnboardingFlowStatus = useAppSelector(selectAdminOnboardingFlowStatus);
  const [allCountries] = useState(Object.entries(countries.getNames('en')).map(([code, name]) => ({ id: code, label: name })));

  const handleDispatchIncorporationStep = (data: UpdateIncorporationStepRequest) => {
    dispatch(
      updateIncorporationStepRequested({
        incorporationDate: data.incorporationDate,
        incorporationCountry: data.incorporationCountry,
        tradingNumber: data.tradingNumber
      } as UpdateIncorporationStepRequest)
    );
  };

  const handleSetEditable = () => {
    handleSetActiveSubStep('Step1_2')(!isEditable);
  };

  useEffect(() => {
    if (incorporationStep) {
      reset({
        incorporationDate: incorporationStep?.incorporationDate,
        incorporationCountry: incorporationStep?.incorporationCountry,
        tradingNumber: incorporationStep?.tradingNumber
      });
    }
  }, [incorporationStep]);

  if (!isEditable) {
    if (adminOnboardingFlowStatus === ActionStatus.Pending) {
      return (
        <Grid container>
          <Grid size={3}>
            <Stack>
              <Skeleton width={180} height={20} />
              <Skeleton width={90} height={10} />
              <Skeleton width={90} height={10} />
              <Skeleton width={90} height={10} />
            </Stack>
          </Grid>
          <Grid size={9}>
            <Stack
              sx={{
                alignItems: 'flex-end',
                justifyContent: 'center',
                height: '100%'
              }}
            >
              <Skeleton width={90} height={40} />
            </Stack>
          </Grid>
        </Grid>
      );
    } else {
      return (
        <Grid container>
          <Grid size={6}>
            <Stack gap={1}>
              <Typography
                variant="subtitle1"
                sx={{
                  color: (theme) => theme.palette.primary.dark
                }}
              >
                {t('incorporation', { ns: 'onboarding' })}
              </Typography>
              <Stack>
                <Typography
                  sx={{
                    fontWeight: '600'
                  }}
                >
                  {DateTime.fromISO(getValues('incorporationDate')).toFormat('yyyy LLL dd')}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: '600'
                  }}
                >
                  {allCountries.find((ac) => ac.id === getValues('incorporationCountry'))?.label}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: '600'
                  }}
                >
                  {getValues('tradingNumber')}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid size={6}>
            <Stack
              sx={{
                alignItems: 'flex-end',
                justifyContent: 'center',
                height: '100%'
              }}
            >
              <StyledEditButton variant="contained" startIcon={<EditPenIcon />} isStepCompleted={isStepCompleted} onClick={handleSetEditable}>
                {t('edit', { ns: 'common' })}
              </StyledEditButton>
            </Stack>
          </Grid>
        </Grid>
      );
    }
  }

  if (adminOnboardingFlowStatus === ActionStatus.Idle || previousStepStatus === ActionStatus.Idle) {
    return (
      <Box
        component="form"
        onSubmit={handleSubmit((data) => {
          handleDispatchIncorporationStep(data);
          handleSetEditable();
          handleSetActiveSubStep('Step1_3')(!isNextStepFinished);
        })}
      >
        <Grid
          container
          rowSpacing={6}
          sx={{
            justifyContent: 'space-between'
          }}
        >
          <Grid size={{ xs: 12, lg: 3 }}>
            <Typography variant="h4"> {t('incorporation', { ns: 'onboarding' })}</Typography>
            <Typography>{t('incorporation_text', { ns: 'onboarding' })}</Typography>
          </Grid>
          <Grid size={{ xs: 12, lg: 8 }}>
            <Stack gap={4}>
              <Controller
                name="incorporationDate"
                control={control}
                render={({ field, fieldState }) => (
                  <LocalizationProvider dateAdapter={AdapterLuxon}>
                    <DatePicker
                      {...field}
                      disableFuture
                      format="yyyy-MM-dd"
                      slots={{ textField: StyledTransparentInputDatePicker }}
                      slotProps={{
                        textField: {
                          label: t('company_incorporation_label.incorporation_date', { ns: 'onboarding' }),
                          variant: 'filled',
                          error: !!fieldState.error,
                          helperText: fieldState.error?.message,
                          required: true,
                          fullWidth: true,
                          InputProps: {
                            disableUnderline: true,
                            required: true,
                            onBlur: () => {
                              field.onBlur();
                            }
                          },
                          InputLabelProps: {
                            shrink: true
                          }
                        }
                      }}
                      value={field.value ? DateTime.fromISO(field.value) : null}
                      onChange={(date: DateTime | null) => {
                        if (date) {
                          const jsDate = date.toJSDate();
                          const stringDate = DateTime.fromJSDate(jsDate).toFormat('yyyy-MM-dd');
                          field.onChange(stringDate);
                        }
                      }}
                    />
                  </LocalizationProvider>
                )}
              />
              <Controller
                name="incorporationCountry"
                control={control}
                render={({ field }) => {
                  const selectedCountry = field.value && allCountries.find((ac) => ac.id === field.value);

                  return (
                    <StyledAutocomplete
                      {...field}
                      options={allCountries}
                      popupIcon={<ChevronDownIcon />}
                      clearIcon={null}
                      getOptionLabel={(option) => option.label}
                      value={selectedCountry || null}
                      onChange={(_, newValue) => {
                        if (newValue) {
                          field.onChange(newValue.id);
                        }
                      }}
                      renderInput={(params) => {
                        return (
                          <StyledTransparentInput
                            {...params}
                            required
                            InputLabelProps={{ shrink: true }}
                            label={t('company_incorporation_label.incorporation_country', { ns: 'onboarding' })}
                            id="incorporationCountry"
                          />
                        );
                      }}
                      renderOption={(props, option) => (
                        <ListItem {...props} key={crypto.randomUUID()}>
                          {option.label}
                        </ListItem>
                      )}
                    />
                  );
                }}
              />
              <Controller
                name="tradingNumber"
                control={control}
                render={({ field }) => (
                  <StyledTransparentInput
                    {...field}
                    required
                    label={t('company_incorporation_label.trading_number', { ns: 'onboarding' })}
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                  />
                )}
              />
              <RowCenterStack
                gap={2}
                sx={{
                  justifyContent: 'flex-end'
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    minWidth: 100
                  }}
                  type="submit"
                  disabled={Object.values(formState.errors).length !== 0}
                  endIcon={<AddIcon color="#FFFFFF" />}
                >
                  {!isStepCompleted && t('submit', { ns: 'common' })}
                  {isStepCompleted && t('update', { ns: 'common' })}
                  {incorporationStepStatus === ActionStatus.Pending && <CircularProgress />}
                </Button>
              </RowCenterStack>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    );
  }
};
