import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RetrieveAdminOnboardingFlowStateResponse } from '@thrivea/organization-client';
import { RootState } from '@app/store';
import { ActionStatus } from '@/shared';

interface ProtectedRouteState {
  adminOnboardingFlowStatus: ActionStatus;
  isCompletedAdminOnboardingFlow?: boolean;
}

const initialState: ProtectedRouteState = {
  adminOnboardingFlowStatus: ActionStatus.Idle,
  isCompletedAdminOnboardingFlow: undefined
};

export const protectedRouteSlice = createSlice({
  name: 'protected-route',
  initialState,
  reducers: {
    retrieveInitialAppStateAndUserRequested: (state) => {
      state.adminOnboardingFlowStatus = ActionStatus.Pending;
    },
    retrieveInitialAppStateAndUserSucceeded: (state, action: PayloadAction<RetrieveAdminOnboardingFlowStateResponse>) => {
      state.isCompletedAdminOnboardingFlow = action.payload.isCompleted;
      state.adminOnboardingFlowStatus = ActionStatus.Idle;
    },
    retrieveInitialAppStateAndUserFailed: (state) => {
      state.adminOnboardingFlowStatus = ActionStatus.Failed;
    },
    finishAdminOnboardingFlow: (state) => {
      state.isCompletedAdminOnboardingFlow = true;
    }
  }
});

export const selectAdminOnboardingFlowStatus = (state: RootState) => state.protectedRoute.adminOnboardingFlowStatus;
export const selectIsCompletedAdminOnboardingFlow = (state: RootState) => state.protectedRoute.isCompletedAdminOnboardingFlow;

export const {
  retrieveInitialAppStateAndUserFailed,
  retrieveInitialAppStateAndUserRequested,
  retrieveInitialAppStateAndUserSucceeded,
  finishAdminOnboardingFlow
} = protectedRouteSlice.actions;
export default protectedRouteSlice.reducer;
