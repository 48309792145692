import { z } from 'zod';
import { TFunction, t } from 'i18next';
import { snakeCase } from 'lodash';

export const phoneRegex = new RegExp(/^(\+[\s0-9]+)?(\d{3}|[(][0-9]+[)])?([-][\s]?[0-9]+)+$/);

export const createLocalizedFieldSchema = (fieldName: string, minLength: number) => {
  return z
    .union([
      z.string().min(
        minLength,
        t('field_optional_min_length', {
          field: t(snakeCase(fieldName), { ns: 'employee_profile' }),
          length: t('character_with_count', { count: minLength, ns: 'common' }),
          ns: 'common'
        })
      ),
      z.string().length(0)
    ])
    .optional()
    .transform((value) => (value === '' ? undefined : value));
};

export const ChildItemSchema = z.object({
  childFirstName: z.string(),
  childLastName: z.string(),
  childDateOfBirth: z.string(),
  childGenderId: z.string()
});

export const LocationSchema = z.object({
  city: z.string(),
  state: z.string(),
  country: z.string()
});

export const AddressEventSchema = z.object({
  effectiveDate: z.string(),
  location: LocationSchema.optional(),
  reason: z.string()
});

const WorkEventSchema = z.object({
  effectiveDate: z.string(),
  jobTitle: z.string(),
  departmentId: z.string(),
  reportsToId: z.string(),
  worksAtSiteId: z.string(),
  changeType: z.number(),
  reason: z.string()
});

export const WorkEligibilitySchema = z.object({
  workEligibilityTypeId: z.string(),
  docIds: z.array(z.string()).optional(),
  workEligibilityExpirationDate: z.string(),
  workEligibilityNumber: z.string(),
  workEligibilityIssuedBy: z.string(),
  workEligibilityValidFromDate: z.string(),
  workEligibilityApplicationDate: z.string()
});

export const EmploymentEventSchema = z.object({
  effectiveDate: z.string(),
  employmentContract: z.number(),
  employmentTypeId: z.string(),
  flsaCode: z.number(),
  salaryPayType: z.number(),
  vacationCalendarId: z.string(),
  workingPatternId: z.string(),
  reason: z.string().optional()
});

export const PayrollEventSchema = z.object({
  effectiveDate: z.string(),
  baseSalary: z.number(),
  currencyCode: z.string(),
  salaryPayPeriod: z.number(),
  salaryPayFrequency: z.number(),
  reason: z.string().optional()
});

export const createBasicInfoSchema = (t: TFunction) =>
  z.object({
    emailAddress: z.string().readonly().optional(),
    firstName: z
      .union([
        z.string().min(
          2,
          t('field_optional_min_length', {
            field: t('first_name', { ns: 'employee_profile' }),
            length: t('character_with_count', { count: 2, ns: 'common' }),
            ns: 'common'
          })
        ),
        z.string().length(0)
      ])
      .optional()
      .transform((e) => (e === '' ? undefined : e)),
    lastName: z
      .union([
        z.string().min(
          2,
          t('field_optional_min_length', {
            field: t('last_name', { ns: 'employee_profile' }),
            length: t('character_with_count', { count: 2, ns: 'common' }),
            ns: 'common'
          })
        ),
        z.string().length(0)
      ])
      .optional()
      .transform((e) => (e === '' ? undefined : e)),
    fullName: z.string().readonly().optional(),
    displayName: z
      .union([
        z.string().min(
          2,
          t('field_optional_min_length', {
            field: t('display_name', { ns: 'employee_profile' }),
            length: t('character_with_count', { count: 2, ns: 'common' }),
            ns: 'common'
          })
        ),
        z.string().length(0)
      ])
      .optional()
      .transform((e) => (e === '' ? undefined : e)),
    middleName: z
      .union([
        z.string().min(
          2,
          t('field_optional_min_length', {
            field: t('middle_name', { ns: 'employee_profile' }),
            length: t('character_with_count', { count: 2, ns: 'common' }),
            ns: 'common'
          })
        ),
        z.string().length(0)
      ])
      .optional()
      .transform((e) => (e === '' ? undefined : e))
  });

export const createPersonalSchema = (t: TFunction) =>
  z.object({
    prefixId: z.string().optional(),
    pronounsId: z.string().optional(),
    dateOfBirth: z.string().optional(),
    nationalityCodes: z.array(z.string()).optional()
  });

export const createPersonalContactSchema = (t: TFunction) =>
  z.object({
    personalEmailAddress: z
      .union([z.string().email(t('invalid_email', { ns: 'common' })), z.string().length(0)])
      .optional()
      .transform((e) => (e === '' ? undefined : e)),
    personalLandlinePhoneNumber: z
      .union([
        z.string().min(
          6,
          t('field_optional_min_length', {
            field: t('personal_phone_number', { ns: 'employee_profile' }),
            length: t('character_with_count', { count: 6, ns: 'common' }),
            ns: 'common'
          })
        ),
        z.string().length(0)
      ])
      .optional()
      .transform((e) => (e === '' ? undefined : e)),
    personalMobilePhoneNumber: z
      .union([
        z.string().min(
          5,
          t('field_optional_min_length', {
            field: t('personal_mobile', { ns: 'employee_profile' }),
            length: t('character_with_count', { count: 5, ns: 'common' }),
            ns: 'common'
          })
        ),
        z.string().length(0)
      ])
      .optional()
      .transform((e) => (e === '' ? undefined : e))
  });

export const createIdentificationSchema = (t: TFunction) =>
  z.object({
    passportNumber: z
      .union([
        z.string().min(
          2,
          t('field_optional_min_length', {
            field: t('passport_number', { ns: 'employee_profile' }),
            length: t('character_with_count', { count: 2, ns: 'common' }),
            ns: 'common'
          })
        ),
        z.string().length(0)
      ])
      .optional()
      .transform((e) => (e === '' ? undefined : e)),
    nationalId: z
      .union([
        z.string().min(
          2,
          t('field_optional_min_length', {
            field: t('national_id', { ns: 'employee_profile' }),
            length: t('character_with_count', { count: 2, ns: 'common' }),
            ns: 'common'
          })
        ),
        z.string().length(0)
      ])
      .optional()
      .transform((e) => (e === '' ? undefined : e)),
    niNumber: z
      .union([
        z.string().min(
          2,
          t('field_optional_min_length', {
            field: t('ni_number', { ns: 'employee_profile' }),
            length: t('character_with_count', { count: 2, ns: 'common' }),
            ns: 'common'
          })
        ),
        z.string().length(0)
      ])
      .optional()
      .transform((e) => (e === '' ? undefined : e)),
    ssn: z
      .union([
        z.string().min(
          2,
          t('field_optional_min_length', {
            field: t('ssn', { ns: 'employee_profile' }),
            length: t('character_with_count', { count: 2, ns: 'common' }),
            ns: 'common'
          })
        ),
        z.string().length(0)
      ])
      .optional()
      .transform((e) => (e === '' ? undefined : e))
  });

export const createWorkEventSchema = (t: TFunction) =>
  z.object({
    effectiveDate: z.string().min(
      1,
      t('field_required', {
        field: t('effective_date', { ns: 'employee_profile' }),
        ns: 'common'
      })
    ),
    jobTitle: z.string().min(
      1,
      t('field_required', {
        field: t('job_title', { ns: 'employee_profile' }),
        ns: 'common'
      })
    ),
    departmentId: z.string().optional(),
    reportsToId: z.string().min(
      1,
      t('field_required', {
        field: t('reports_to', { ns: 'employee_profile' }),
        ns: 'common'
      })
    ),
    worksAtSiteId: z.string().min(
      1,
      t('field_required', {
        field: t('site', { ns: 'employee_profile' }),
        ns: 'common'
      })
    ),
    changeType: z.number().min(
      0,
      t('field_required', {
        field: t('change_type', { ns: 'employee_profile' }),
        ns: 'common'
      })
    ),
    reason: z.string().optional()
  });

export const createWorkSchema = (t: TFunction) =>
  z.object({
    buddyId: z.string().optional(),
    hrbpId: z.string().optional(),
    payrollManagerId: z.string().optional(),
    itAdminId: z.string().optional(),
    customEmployeeId: z.string().readonly(),
    isManager: z.boolean().readonly().optional(),
    workEvents: z.array(WorkEventSchema).optional()
  });

export const createWorkContactSchema = (t: TFunction) =>
  z.object({
    workMobilePhoneNumber: z
      .union([
        z.string().min(
          4,
          t('field_optional_min_length', {
            field: t('work_mobile', { ns: 'employee_profile' }),
            length: t('character_with_count', { count: 4, ns: 'common' }),
            ns: 'common'
          })
        ),
        z.string().length(0)
      ])
      .optional()
      .transform((e) => (e === '' ? undefined : e)),
    workLandlinePhoneNumber: z
      .union([
        z.string().min(
          4,
          t('field_optional_min_length', {
            field: t('work_landline', { ns: 'employee_profile' }),
            length: t('character_with_count', { count: 4, ns: 'common' }),
            ns: 'common'
          })
        ),
        z.string().length(0)
      ])
      .optional()
      .transform((e) => (e === '' ? undefined : e)),
    slackUsername: z
      .union([
        z.string().min(
          2,
          t('field_optional_min_length', {
            field: t('slack_username', { ns: 'employee_profile' }),
            length: t('character_with_count', { count: 2, ns: 'common' }),
            ns: 'common'
          })
        ),
        z.string().length(0)
      ])
      .optional()
      .transform((e) => (e === '' ? undefined : e)),
    skypeUsername: z
      .union([
        z.string().min(
          2,
          t('field_optional_min_length', {
            field: t('skype_username', { ns: 'employee_profile' }),
            length: t('character_with_count', { count: 2, ns: 'common' }),
            ns: 'common'
          })
        ),
        z.string().length(0)
      ])
      .optional()
      .transform((e) => (e === '' ? undefined : e))
  });

export const createAddressEventSchema = (t: TFunction) =>
  z.object({
    effectiveDate: z.string().min(
      1,
      t('field_required', {
        field: t('effective_date', { ns: 'employee_profile' }),
        ns: 'common'
      })
    ),
    location: z.object({
      addressLine1: z.string().min(
        1,
        t('field_required', {
          field: t('address_one', { ns: 'employee_profile' }),
          ns: 'common'
        })
      ),
      addressLine2: z.string().optional(),
      city: z.string().min(
        1,
        t('field_required', {
          field: t('city', { ns: 'employee_profile' }),
          ns: 'common'
        })
      ),
      postalCode: z.string().min(
        1,
        t('field_required', {
          field: t('postal', { ns: 'employee_profile' }),
          ns: 'common'
        })
      ),
      country: z.string().min(
        1,
        t('field_required', {
          field: t('country', { ns: 'employee_profile' }),
          ns: 'common'
        })
      ),
      state: z.string().optional(),
      province: z.string().optional(),
      region: z.string().optional()
    }),
    reason: z.string().optional()
  });

export const createAddressSchema = (t: TFunction) =>
  z.object({
    addressEvents: z.array(AddressEventSchema).optional()
  });

export const createAboutSchema = (t: TFunction) =>
  z.object({
    about: z
      .union([
        z.string().min(
          3,
          t('field_optional_min_length', {
            field: t('about', { ns: 'employee_profile' }),
            length: t('character_with_count', { count: 3, ns: 'common' }),
            ns: 'common'
          })
        ),
        z.string().length(0)
      ])
      .optional()
      .transform((e) => (e === '' ? undefined : e)),
    facebookProfileUrl: z.string().optional(),
    xProfileUrl: z.string().optional(),
    linkedinProfileUrl: z.string().optional(),
    hobbyIds: z.array(z.string()).optional(),
    foodPreferenceIds: z.array(z.string()).optional()
  });

export const createChildItemSchema = (t: TFunction) =>
  z.object({
    childFirstName: z.string().min(
      1,
      t('field_required', {
        field: t('child_first_name', { ns: 'employee_profile' }),
        ns: 'common'
      })
    ),
    childLastName: z.string().min(
      1,
      t('field_required', {
        field: t('child_last_name', { ns: 'employee_profile' }),
        ns: 'common'
      })
    ),
    childDateOfBirth: z.string().min(
      1,
      t('field_required', {
        field: t('child_dob', { ns: 'employee_profile' }),
        ns: 'common'
      })
    ),
    childGenderId: z.string().min(
      1,
      t('field_required', {
        field: t('child_gender', { ns: 'employee_profile' }),
        ns: 'common'
      })
    )
  });

export const createHomeSchema = (t: TFunction) =>
  z.object({
    maritalStatus: z.number().optional(),
    genderId: z.string().optional(),
    spouseFirstName: z
      .union([
        z.string().min(
          2,
          t('field_optional_min_length', {
            field: t('spouse_first_name', { ns: 'employee_profile' }),
            length: t('character_with_count', { count: 2, ns: 'common' }),
            ns: 'common'
          })
        ),
        z.string().length(0)
      ])
      .optional()
      .transform((e) => (e === '' ? undefined : e)),
    spouseLastName: z
      .union([
        z.string().min(
          2,
          t('field_optional_min_length', {
            field: t('spouse_last_name', { ns: 'employee_profile' }),
            length: t('character_with_count', { count: 2, ns: 'common' }),
            ns: 'common'
          })
        ),
        z.string().length(0)
      ])
      .optional()
      .transform((e) => (e === '' ? undefined : e)),
    spouseDateOfBirth: z.string().optional(),
    spouseGenderId: z.string().optional(),
    childItems: z.array(ChildItemSchema).optional()
  });

export const createFinancialSchema = (t: TFunction) =>
  z.object({
    accountName: z
      .union([
        z.string().min(
          2,
          t('field_optional_min_length', {
            field: t('account_name', { ns: 'employee_profile' }),
            length: t('character_with_count', { count: 2, ns: 'common' }),
            ns: 'common'
          })
        ),
        z.string().length(0)
      ])
      .optional()
      .transform((e) => (e === '' ? undefined : e)),
    routingNumber: z
      .union([
        z.string().min(
          2,
          t('field_optional_min_length', {
            field: t('routing_number', { ns: 'employee_profile' }),
            length: t('character_with_count', { count: 2, ns: 'common' }),
            ns: 'common'
          })
        ),
        z.string().length(0)
      ])
      .optional()
      .transform((e) => (e === '' ? undefined : e)),
    accountNumber: z
      .union([
        z.string().min(
          2,
          t('field_optional_min_length', {
            field: t('account_name', { ns: 'employee_profile' }),
            length: t('character_with_count', { count: 2, ns: 'common' }),
            ns: 'common'
          })
        ),
        z.string().length(0)
      ])
      .optional()
      .transform((e) => (e === '' ? undefined : e)),
    bankName: z
      .union([
        z.string().min(
          2,
          t('field_optional_min_length', {
            field: t('bank_name', { ns: 'employee_profile' }),
            length: t('character_with_count', { count: 2, ns: 'common' }),
            ns: 'common'
          })
        ),
        z.string().length(0)
      ])
      .optional()
      .transform((e) => (e === '' ? undefined : e)),
    branchAddress: z
      .union([
        z.string().min(
          2,
          t('field_optional_min_length', {
            field: t('branch_address', { ns: 'employee_profile' }),
            length: t('character_with_count', { count: 2, ns: 'common' }),
            ns: 'common'
          })
        ),
        z.string().length(0)
      ])
      .optional()
      .transform((e) => (e === '' ? undefined : e)),
    swiftCode: z
      .union([
        z.string().min(
          2,
          t('field_optional_min_length', {
            field: t('swift_code', { ns: 'employee_profile' }),
            length: t('character_with_count', { count: 2, ns: 'common' }),
            ns: 'common'
          })
        ),
        z.string().length(0)
      ])
      .optional()
      .transform((e) => (e === '' ? undefined : e)),
    iban: z
      .union([
        z.string().min(
          2,
          t('field_optional_min_length', {
            field: t('iban', { ns: 'employee_profile' }),
            length: t('character_with_count', { count: 2, ns: 'common' }),
            ns: 'common'
          })
        ),
        z.string().length(0)
      ])
      .optional()
      .transform((e) => (e === '' ? undefined : e)),
    sortCode: z
      .union([
        z.string().min(
          2,
          t('field_optional_min_length', {
            field: t('sort_code', { ns: 'employee_profile' }),
            length: t('character_with_count', { count: 2, ns: 'common' }),
            ns: 'common'
          })
        ),
        z.string().length(0)
      ])
      .optional()
      .transform((e) => (e === '' ? undefined : e)),
    accountType: z.number().optional()
  });

export const createWorkEligibilityCategorySchema = (t: TFunction) =>
  z.object({
    workEligibilityItems: z.array(WorkEligibilitySchema).optional()
  });

export const createWorkEligibilitySchema = (t: TFunction) =>
  z.object({
    workEligibilityTypeId: z.string().min(
      1,
      t('field_required', {
        field: t('work_eligibility_type_id', { ns: 'employee_profile' }),
        ns: 'common'
      })
    ),
    workEligibilityExpirationDate: z.string().min(
      1,
      t('field_required', {
        field: t('work_eligibility_expiration_date', { ns: 'employee_profile' }),
        ns: 'common'
      })
    ),
    workEligibilityNumber: z.string().min(
      1,
      t('field_required', {
        field: t('work_eligibility_number', { ns: 'employee_profile' }),
        ns: 'common'
      })
    ),
    workEligibilityIssuedBy: z.string().min(
      1,
      t('field_required', {
        field: t('work_eligibility_issued_by', { ns: 'employee_profile' }),
        ns: 'common'
      })
    ),
    workEligibilityValidFromDate: z.string().min(
      1,
      t('field_required', {
        field: t('work_eligibility_valid_from_date', { ns: 'employee_profile' }),
        ns: 'common'
      })
    ),
    workEligibilityApplicationDate: z.string().min(
      1,
      t('field_required', {
        field: t('work_eligibility_application_date', { ns: 'employee_profile' }),
        ns: 'common'
      })
    )
  });

export const createEmploymentCategorySchema = (t: TFunction) =>
  z.object({
    employmentEvents: z.array(EmploymentEventSchema).optional()
  });

export const createEmploymentSchema = (t: TFunction) =>
  z.object({
    effectiveDate: z.string().min(
      1,
      t('field_required', {
        field: t('effective_date', { ns: 'employee_profile' }),
        ns: 'common'
      })
    ),
    employmentContract: z.number().min(
      0,
      t('field_required', {
        field: t('employment_contract', { ns: 'employee_profile' }),
        ns: 'common'
      })
    ),
    employmentTypeId: z.string().min(
      1,
      t('field_required', {
        field: t('employment_type_id', { ns: 'employee_profile' }),
        ns: 'common'
      })
    ),
    flsaCode: z.number().min(
      0,
      t('field_required', {
        field: t('flsa_code', { ns: 'employee_profile' }),
        ns: 'common'
      })
    ),
    salaryPayType: z.number().min(
      0,
      t('field_required', {
        field: t('salary_pay_type', { ns: 'employee_profile' }),
        ns: 'common'
      })
    ),
    vacationCalendarId: z.string().min(
      1,
      t('field_required', {
        field: t('vacation_calendar_id', { ns: 'employee_profile' }),
        ns: 'common'
      })
    ),
    workingPatternId: z.string().min(
      1,
      t('field_required', {
        field: t('working_pattern_id', { ns: 'employee_profile' }),
        ns: 'common'
      })
    ),
    reason: z.string().optional()
  });

export const createPayrollEventSchema = (t: TFunction) =>
  z.object({
    effectiveDate: z.string().min(
      1,
      t('field_required', {
        field: t('effective_date', { ns: 'employee_profile' }),
        ns: 'common'
      })
    ),
    baseSalary: z.number().min(
      1,
      t('field_required', {
        field: t('base_salary', { ns: 'employee_profile' }),
        ns: 'common'
      })
    ),
    currencyCode: z.string().min(
      1,
      t('field_required', {
        field: t('currency_code', { ns: 'employee_profile' }),
        ns: 'common'
      })
    ),
    salaryPayPeriod: z.number().min(
      0,
      t('field_required', {
        field: t('salary_pay_period', { ns: 'employee_profile' }),
        ns: 'common'
      })
    ),
    salaryPayFrequency: z.number().min(
      0,
      t('field_required', {
        field: t('salary_pay_frequency', { ns: 'employee_profile' }),
        ns: 'common'
      })
    ),
    reason: z.string().optional()
  });

export const createPayrollCategorySchema = (t: TFunction) =>
  z.object({
    additionalPensionContribution: z
      .union([
        z.string().min(
          2,
          t('field_optional_min_length', {
            field: t('additional_pension_contribution', { ns: 'employee_profile' }),
            length: t('character_with_count', { count: 2, ns: 'common' }),
            ns: 'common'
          })
        ),
        z.string().length(0)
      ])
      .optional()
      .transform((e) => (e === '' ? undefined : e)),
    taxCode: z
      .union([
        z.string().min(
          2,
          t('field_optional_min_length', {
            field: t('tax_code', { ns: 'employee_profile' }),
            length: t('character_with_count', { count: 2, ns: 'common' }),
            ns: 'common'
          })
        ),
        z.string().length(0)
      ])
      .optional()
      .transform((e) => (e === '' ? undefined : e)),
    payrollEvents: z.array(PayrollEventSchema).optional()
  });

export const createEmergencyCategorySchema = (t: TFunction) =>
  z.object({
    emergencyContactAddress: z
      .union([
        z.string().min(
          2,
          t('field_optional_min_length', {
            field: t(snakeCase('emergencyContactAddress'), { ns: 'employee_profile' }),
            length: t('character_with_count', { count: 2, ns: 'common' }),
            ns: 'common'
          })
        ),
        z.string().length(0)
      ])
      .optional()
      .transform((e) => (e === '' ? undefined : e)),
    emergencyContactCityOfResidence: z
      .union([
        z.string().min(
          2,
          t('field_optional_min_length', {
            field: t(snakeCase('emergencyContactCityOfResidence'), { ns: 'employee_profile' }),
            length: t('character_with_count', { count: 2, ns: 'common' }),
            ns: 'common'
          })
        ),
        z.string().length(0)
      ])
      .optional()
      .transform((e) => (e === '' ? undefined : e)),
    emergencyContactEmailAddress: z
      .union([
        z.string().min(
          2,
          t('field_optional_min_length', {
            field: t(snakeCase('emergencyContactEmailAddress'), { ns: 'employee_profile' }),
            length: t('character_with_count', { count: 2, ns: 'common' }),
            ns: 'common'
          })
        ),
        z.string().length(0)
      ])
      .optional()
      .transform((e) => (e === '' ? undefined : e)),
    emergencyContactCountryOfResidence: z
      .union([
        z.string().min(
          2,
          t('field_optional_min_length', {
            field: t(snakeCase('emergencyContactCountryOfResidence'), { ns: 'employee_profile' }),
            length: t('character_with_count', { count: 2, ns: 'common' }),
            ns: 'common'
          })
        ),
        z.string().length(0)
      ])
      .optional()
      .transform((e) => (e === '' ? undefined : e)),
    emergencyContactFirstName: z
      .union([
        z.string().min(
          2,
          t('field_optional_min_length', {
            field: t(snakeCase('emergencyContactFirstName'), { ns: 'employee_profile' }),
            length: t('character_with_count', { count: 2, ns: 'common' }),
            ns: 'common'
          })
        ),
        z.string().length(0)
      ])
      .optional()
      .transform((e) => (e === '' ? undefined : e)),
    emergencyContactLandlinePhoneNumber: z
      .union([
        z.string().min(
          2,
          t('field_optional_min_length', {
            field: t(snakeCase('emergencyContactLandlinePhoneNumber'), { ns: 'employee_profile' }),
            length: t('character_with_count', { count: 2, ns: 'common' }),
            ns: 'common'
          })
        ),
        z.string().length(0)
      ])
      .optional()
      .transform((e) => (e === '' ? undefined : e)),
    emergencyContactLastName: z
      .union([
        z.string().min(
          2,
          t('field_optional_min_length', {
            field: t(snakeCase('emergencyContactLastName'), { ns: 'employee_profile' }),
            length: t('character_with_count', { count: 2, ns: 'common' }),
            ns: 'common'
          })
        ),
        z.string().length(0)
      ])
      .optional()
      .transform((e) => (e === '' ? undefined : e)),
    emergencyContactMiddleName: z
      .union([
        z.string().min(
          2,
          t('field_optional_min_length', {
            field: t(snakeCase('emergencyContactMiddleName'), { ns: 'employee_profile' }),
            length: t('character_with_count', { count: 2, ns: 'common' }),
            ns: 'common'
          })
        ),
        z.string().length(0)
      ])
      .optional()
      .transform((e) => (e === '' ? undefined : e)),
    emergencyContactMobilePhoneNumber: z
      .union([
        z.string().min(
          2,
          t('field_optional_min_length', {
            field: t(snakeCase('emergencyContactMobilePhoneNumber'), { ns: 'employee_profile' }),
            length: t('character_with_count', { count: 2, ns: 'common' }),
            ns: 'common'
          })
        ),
        z.string().length(0)
      ])
      .optional()
      .transform((e) => (e === '' ? undefined : e)),
    emergencyContactPostCode: z
      .union([
        z.string().min(
          2,
          t('field_optional_min_length', {
            field: t(snakeCase('emergencyContactPostCode'), { ns: 'employee_profile' }),
            length: t('character_with_count', { count: 2, ns: 'common' }),
            ns: 'common'
          })
        ),
        z.string().length(0)
      ])
      .optional()
      .transform((e) => (e === '' ? undefined : e)),
    emergencyContactRelation: z
      .union([
        z.number().min(
          0,
          t('field_optional_min_length', {
            field: t(snakeCase('emergencyContactRelation'), { ns: 'employee_profile' }),
            length: t('character_with_count', { count: 0, ns: 'common' }),
            ns: 'common'
          })
        ),
        z.string().length(0)
      ])
      .optional()
      .transform((e) => (e === '' ? undefined : e))
  });

export const createEEOCategorySchema = (t: TFunction) =>
  z.object({
    ethnicityId: z
      .union([
        z.string().min(
          2,
          t('field_optional_min_length', {
            field: t(snakeCase('ethnicityId'), { ns: 'employee_profile' }),
            length: t('character_with_count', { count: 2, ns: 'common' }),
            ns: 'common'
          })
        ),
        z.string().length(0)
      ])
      .optional()
      .transform((e) => (e === '' ? undefined : e)),
    jobCategoryId: z
      .union([
        z.string().min(
          2,
          t('field_optional_min_length', {
            field: t(snakeCase('jobCategoryId'), { ns: 'employee_profile' }),
            length: t('character_with_count', { count: 2, ns: 'common' }),
            ns: 'common'
          })
        ),
        z.string().length(0)
      ])
      .optional()
      .transform((e) => (e === '' ? undefined : e))
  });
