import { Box, PopperProps, autocompleteClasses } from '@mui/material';
import { forwardRef } from 'react';

export const CustomPermissionGroupAutocompletePopper = forwardRef<HTMLDivElement, PopperProps>(function CustomPopper(props, ref) {
  const { children, open, anchorEl, ...other } = props;

  const getChildren = () => {
    if (typeof children === 'function') {
      return children({ placement: 'bottom-start' });
    }

    return children;
  };

  return (
    <Box
      ref={ref}
      sx={{
        display: open ? 'block' : 'none',
        width: anchorEl ? (anchorEl as HTMLElement).clientWidth : '100%',
        [`&.${autocompleteClasses.popper}`]: {
          position: 'static'
        }
      }}
      {...other}
    >
      {getChildren()}
    </Box>
  );
});
