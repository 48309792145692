import { useTheme } from '@mui/material';
import React from 'react';

interface CheckedIconAttributes {
  className?: string;
}

export const CheckedIcon: React.FC<CheckedIconAttributes> = ({ className = '' }: CheckedIconAttributes) => {
  const theme = useTheme();

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Icon / Check">
        <path id="Vector" d="M20 6L9 17L4 12" stroke={theme.palette.common.white} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </g>
    </svg>
  );
};
