import { useTheme } from '@mui/material';
import React from 'react';

interface ChevronRightIconAttributes {
  className?: string;
}

export const ChevronRightIcon: React.FC<ChevronRightIconAttributes> = ({ className = '' }: ChevronRightIconAttributes) => {
  const theme = useTheme();

  return (
    <svg width="24" height="22" viewBox="0 0 24 22" fill={theme.palette.customTheme.contColor} xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_972_5235)">
        <path
          d="M10.0001 5.76685L8.59009 6.99666L13.1701 11.0001L8.59009 15.0035L10.0001 16.2333L16.0001 11.0001L10.0001 5.76685Z"
          fill={theme.palette.common.black}
        />
      </g>
      <defs>
        <clipPath id="clip0_972_5235">
          <rect width="24" height="20.9329" fill={theme.palette.common.white} transform="translate(0 0.533569)" />
        </clipPath>
      </defs>
    </svg>
  );
};
