import React, { useMemo } from 'react';
import { Box, CircularProgress, IconButton, Stack, ImageListItem } from '@mui/material';
import { useAppDispatch } from '@app/hooks';
import { CloseIcon, RowCenterStack, StyledFixedWidthTypography } from '@/shared';
import { FileWithStatus } from '@api/blob-storage.api';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { SlideshowOutlined } from '@mui/icons-material';

interface PreviewModalVideoProps {
  fileWithStatus: FileWithStatus;
  fileUploadProgress: number;
  deleteFileAction: ActionCreatorWithPayload<any, string>;
  sasToken: string;
}

export const PreviewModalVideo: React.FC<PreviewModalVideoProps> = ({ fileWithStatus, fileUploadProgress, deleteFileAction, sasToken }) => {
  const dispatch = useAppDispatch();

  const src = useMemo(() => {
    return fileWithStatus.file !== undefined ? URL.createObjectURL(fileWithStatus.file) : new URL(sasToken, fileWithStatus.url).toString();
  }, [fileWithStatus.file, fileWithStatus.url, sasToken]);

  const key = useMemo(() => {
    return fileWithStatus.file ? crypto.randomUUID() : fileWithStatus.url;
  }, [fileWithStatus.file, fileWithStatus.url]);

  const handleDeleteFile = () => {
    dispatch(deleteFileAction(fileWithStatus.url));
  };

  return (
    <ImageListItem
      sx={{
        position: 'relative',
        width: 135
      }}
    >
      <RowCenterStack
        gap={1}
        sx={{
          '&:hover': {
            '& > .Mui-DeleteFile': {
              display: 'flex'
            }
          }
        }}
      >
        <Box
          className="Mui-Media"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: 25,
            minHeight: 25,
            backgroundColor: (theme) => theme.palette.grey[100],
            position: 'relative',
            borderRadius: 1,
            border: '1px solid',
            borderColor: (theme) => theme.palette.common.black
          }}
        >
          <SlideshowOutlined />
        </Box>
        {fileWithStatus.file !== undefined && (
          <>
            <StyledFixedWidthTypography title={fileWithStatus.file.name}>{fileWithStatus.file.name}</StyledFixedWidthTypography>
            <IconButton
              className="Mui-DeleteFile"
              sx={{
                width: 25,
                height: 25,
                zIndex: 1,
                backgroundColor: (theme) => theme.palette.grey[100],
                color: (theme) => theme.palette.common.black,
                display: 'none',
                borderRadius: 1,
                padding: 0.5
              }}
              onClick={handleDeleteFile}
            >
              <CloseIcon />
            </IconButton>
          </>
        )}
      </RowCenterStack>
      <Stack
        sx={{
          maxWidth: '200px'
        }}
      ></Stack>
      {!fileWithStatus.isUploaded && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            width: 25,
            height: 25,
            backgroundImage: 'linear-gradient(0deg, rgba(0,0,0,0.8), rgba(0,0,0,0.4))',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          {fileWithStatus.isUploading && (
            <CircularProgress
              variant="determinate"
              size={12}
              value={fileUploadProgress}
              sx={{
                color: (theme) => theme.palette.common.white
              }}
            />
          )}
          {fileWithStatus.isUploading && (
            <IconButton
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                color: (theme) => theme.palette.common.white
              }}
            >
              <CloseIcon />
            </IconButton>
          )}
        </Box>
      )}
    </ImageListItem>
  );
};
