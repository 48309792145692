import React from 'react';

interface HeartIconProps {
  width?: number;
  height?: number;
  color?: string;
}

export const HeartIcon: React.FC<HeartIconProps> = ({ width = 22, height = 22, color = '#251D38' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none">
      <path
        d="M10.9835 19.9434C10.6998 19.9434 10.4169 19.8401 10.1941 19.6343L7.26923 16.9321C6.79842 16.4972 6.7697 15.7637 7.2057 15.2941C7.64169 14.8244 8.37706 14.7958 8.84873 15.2307L10.9713 17.1916L15.5862 12.7949C16.0509 12.3522 16.7863 12.3696 17.2301 12.8322C17.6731 13.2949 17.6566 14.0293 17.1919 14.472L11.7858 19.6213C11.5613 19.8349 11.2724 19.9425 10.9826 19.9425L10.9835 19.9434ZM5.60878 14.7828C5.31899 14.7828 5.02832 14.6751 4.80293 14.4581L1.80752 11.5814C1.76661 11.5415 1.72832 11.4998 1.69351 11.4546C0.599605 10.2941 0 8.78364 0 7.18555C0 3.74371 2.80744 0.944228 6.25799 0.944228C7.8932 0.944228 9.54233 1.59006 10.7824 2.71506C10.8721 2.79666 10.96 2.88086 11.0444 2.96593C12.2575 1.70464 13.9684 0.943359 15.7429 0.943359C19.1934 0.943359 22 3.74371 22 7.18468C22 8.93034 21.2594 10.6083 19.9688 11.7888C19.5163 12.202 18.8192 12.1942 18.3771 11.7697C17.9142 11.3262 17.8941 10.5857 18.3397 10.1239C18.3867 10.0744 18.4372 10.031 18.4903 9.99197C19.2448 9.25586 19.6756 8.24023 19.6756 7.18468C19.6756 5.02148 17.9116 3.26194 15.7437 3.26194C14.2373 3.26194 12.8006 4.11784 12.0835 5.44249C11.8824 5.81402 11.4943 6.04753 11.0705 6.051C10.6484 6.04926 10.2551 5.82791 10.0471 5.45898C9.8356 5.08312 9.55625 4.73589 9.21772 4.42773C8.40143 3.68642 7.32318 3.26107 6.25886 3.26107C4.09019 3.26107 2.32532 5.02062 2.32532 7.18381C2.32532 8.21593 2.72476 9.19076 3.44968 9.92773C3.47057 9.94944 3.49146 9.97114 3.5106 9.99458L6.41638 12.7854C6.87848 13.2298 6.89328 13.9633 6.44771 14.4251C6.2197 14.6612 5.91511 14.7802 5.60965 14.7802L5.60878 14.7828Z"
        fill={color}
      />
    </svg>
  );
};
