import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import {
  RetrieveFilesRequest,
  RetrieveFilesResponse,
  RetrieveTopLevelFoldersRequest,
  RetrieveTopLevelFoldersResponse,
  UploadFilesRequest
} from '@thrivea/organization-client';
import * as Sentry from '@sentry/react';
import {
  retrieveFilesFailed,
  retrieveFilesRequested,
  retrieveFilesSucceeded,
  retrieveTopLevelFoldersFailed,
  retrieveTopLevelFoldersRequested,
  retrieveTopLevelFoldersSucceeded,
  uploadFilesFailed,
  uploadFilesRequested,
  uploadFilesSucceded
} from './assets.slice';
import { retrieveFiles, retrieveTopLevelFolders, uploadFiles } from '@api/assets.api';
import { PayloadAction } from '@reduxjs/toolkit';

function* retrieveFilesRequestedGenerator(action: PayloadAction<RetrieveFilesRequest>) {
  try {
    const response: RetrieveFilesResponse = yield call(retrieveFiles, action.payload);
    yield put(retrieveFilesSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveFilesFailed());
  }
}

function* retrieveTopLevelFoldersRequestedGenerator(action: PayloadAction<RetrieveTopLevelFoldersRequest>) {
  try {
    const response: RetrieveTopLevelFoldersResponse = yield call(retrieveTopLevelFolders, action.payload);
    yield put(retrieveTopLevelFoldersSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveTopLevelFoldersFailed());
  }
}

function* uploadFilesRequestedGenerator(action: PayloadAction<UploadFilesRequest>) {
  try {
    const response = yield call(uploadFiles, action.payload);
    yield put(uploadFilesSucceded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(uploadFilesFailed());
  }
}

function* retrieveFilesRequestedWatcher() {
  yield takeLatest(retrieveFilesRequested.type, retrieveFilesRequestedGenerator);
}

function* retrieveTopLevelFoldersRequestedWatcher() {
  yield takeLatest(retrieveTopLevelFoldersRequested.type, retrieveTopLevelFoldersRequestedGenerator);
}

function* uploadFilesRequestedWatcher() {
  yield takeLatest(uploadFilesRequested.type, uploadFilesRequestedGenerator);
}

export function* assetsSaga() {
  yield all([fork(retrieveFilesRequestedWatcher), fork(retrieveTopLevelFoldersRequestedWatcher), fork(uploadFilesRequestedWatcher)]);
}
