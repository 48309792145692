import React, { ReactNode, useEffect } from 'react';
import { Snackbar, Alert, Typography, Stack } from '@mui/material';
import { CheckedDarkIcon, SiteAppBar } from '@/shared';
import { useAppSelector, useAppDispatch } from '@app/hooks';
import { Navigation, selectNavigationIsDesktopOpen, selectNavigationWidth } from '@features/navigation';
import {
  closeNotification,
  retrieveEmployeeProfileAndCoverReadSasTokenRequested,
  selectIsNotificationVisible,
  selectProfileNotification
} from '@features/employee-profile';
import { ActionStatus } from '@/shared/shared.model';
import { useTranslation } from 'react-i18next';
import { retrieveOrganizationLogoSasTokenRequested, retrieveOrganizationRequested, retrieveSiteReadSasTokenRequested } from '@features/admin-settings';
import { selectIsDrawerOpen } from '@features/drawer';
import { DeploymentSnackbar, GlobalSnackbar, retrieveLatestDeploymentRequested, PostDraftSnackbar, selectIsSnackbarVisible } from '@features/snackbar';
import { RetrieveLatestDeploymentRequest } from '@thrivea/admin-onboarding-client';
import { MainDrawer } from '@features/drawer/components/MainDrawer';
import Grid from '@mui/material/Grid2';

interface MainProps {
  children: ReactNode;
}

export interface TempSingleEmployee {
  fullName: string;
  position: string;
  imageUrl: string;
  imageAlt: string;
  status: 'active' | 'busy' | 'offline';
}

export const MainLayout: React.FC<MainProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['employee_profile']);
  const isNotificationVisible = useAppSelector(selectIsNotificationVisible);
  const profileNotification = useAppSelector(selectProfileNotification);
  const isDesktopNavOpen = useAppSelector(selectNavigationIsDesktopOpen);
  const navWidth = useAppSelector(selectNavigationWidth);

  const isDrawerOpen = useAppSelector(selectIsDrawerOpen);
  const isSnackbarVisible = useAppSelector(selectIsSnackbarVisible);

  useEffect(() => {
    const loaderElem = document.getElementById('loader');
    dispatch(retrieveSiteReadSasTokenRequested());
    dispatch(retrieveEmployeeProfileAndCoverReadSasTokenRequested());
    dispatch(retrieveOrganizationLogoSasTokenRequested());
    dispatch(retrieveOrganizationRequested());
    dispatch(retrieveLatestDeploymentRequested(new RetrieveLatestDeploymentRequest({ branch: '' })));

    // At this point of time loader is already removed from UI with settings opacity to 0 but it would be nice to remove it from DOM as well, so set a timeout of 1 sec to remove it behind "doors"
    setTimeout(() => {
      if (loaderElem) {
        loaderElem.remove();
      }
    }, 1000);
  }, []);

  return (
    <Grid
      container
      sx={{
        padding: (theme) => theme.spacing(2),
        alignItems: 'stretch',
        width: '100%'
      }}
    >
      <Grid
        component={'aside'}
        className="yarl__no_scroll_padding "
        sx={{
          width: {
            xs: 0,
            md: 106
          },
          height: 'calc(100vh - 32px)',
          position: {
            xs: 'static',
            md: 'fixed'
          },
          top: 16,
          bottom: 16,
          zIndex: (theme) => theme.zIndex.tooltip + 2
        }}
      >
        <Navigation width={navWidth} isOpen={isDesktopNavOpen} />
      </Grid>
      <Grid
        component={'main'}
        sx={{
          width: {
            xs: '100%',
            lg: 'calc(100% - 106px)'
          },
          height: 'calc(100vh - 32px)',
          paddingLeft: '16px',
          marginLeft: {
            xs: 0,
            md: '106px'
          }
        }}
      >
        <Grid
          container
          spacing={2}
          columns={12}
          sx={{
            width: '100%'
          }}
        >
          <Grid
            size={{
              xs: 12,
              lg: isDrawerOpen ? 8 : 12,
              xl: isDrawerOpen ? 9 : 12
            }}
            sx={{
              transition: (theme) =>
                theme.transitions.create(['flex-basis, max-width'], {
                  easing: theme.transitions.easing.easeInOut,
                  duration: theme.transitions.duration.standard
                })
            }}
          >
            <Stack
              sx={{
                position: 'sticky',
                top: 0,
                zIndex: (theme) => theme.zIndex.tooltip + 2,
                backgroundColor: (theme) => theme.palette.background.default,
                height: isSnackbarVisible ? 80 : 'auto'
              }}
            >
              <Snackbar
                open={isNotificationVisible}
                onClose={() => dispatch(closeNotification())}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={3000}
                sx={{
                  zIndex: (theme) => theme.zIndex.tooltip + 1,
                  position: 'static',
                  transform: {
                    xs: 'translate(0)'
                  },
                  my: 2,
                  width: '100%'
                }}
              >
                <Alert
                  onClose={() => dispatch(closeNotification())}
                  severity={profileNotification.status === ActionStatus.Idle ? 'success' : 'error'}
                  icon={<CheckedDarkIcon size={16} />}
                  sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    color: (theme) => theme.palette.common.white,
                    borderRadius: 2,
                    backgroundColor: (theme) => theme.palette.secondary.main,
                    padding: '0 16px',
                    '& svg': {
                      color: (theme) => theme.palette.common.black,
                      width: 16,
                      height: 16
                    }
                  }}
                >
                  <Typography>
                    {profileNotification.i18n_status_label && t(`${profileNotification.i18n_status_label}`, { ns: 'employee_profile' })} {''}
                    {profileNotification.firstName}
                    {"'s"} {profileNotification.i18n_category_label && t(`${profileNotification.i18n_category_label}`, { ns: 'employee_profile' })}
                  </Typography>
                </Alert>
              </Snackbar>
              <GlobalSnackbar />
              <DeploymentSnackbar />
              <PostDraftSnackbar />
            </Stack>
            <SiteAppBar />
            {children}
          </Grid>
          {isDrawerOpen && (
            <Grid
              size={{
                xs: 12,
                lg: isDrawerOpen ? 4 : 0,
                xl: isDrawerOpen ? 3 : 0
              }}
              sx={{
                transition: (theme) =>
                  theme.transitions.create(['flex-basis, max-width'], {
                    easing: theme.transitions.easing.easeInOut,
                    duration: theme.transitions.duration.standard
                  })
              }}
            >
              <MainDrawer />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
