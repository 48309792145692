import { Avatar, Typography, Link, Skeleton } from '@mui/material';
import { Box, Stack, alpha } from '@mui/material';
import { CalendarIcon } from '@mui/x-date-pickers';
import { initials } from '@/utils';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import {
  EmployeeActions,
  retrieveEmployeeBasicInfoRequested,
  retrieveEmployeesBySiteIdRequested,
  retrieveSiteListItemByIdRequested,
  selectEmployeeBasicInfo,
  selectEmployeeBasicInfoStatus
} from '@features/admin-settings';
import { UserIcon, EmailIcon, SmartPhoneIcon, DocsIcon, OrganizationIcon, PictureSizeSuffix, ActionStatus } from '@/shared';
import { DrawerType, EmployeeDrawerTabs, openDrawer } from '@features/drawer';
import { RetrieveEmployeeBasicInfoRequest, RetrieveEmployeesBySiteIdRequest, RetrieveSiteListItemByIdRequest } from '@thrivea/organization-client';
import { selectEmployeeProfileAndCoverReadSasToken } from '@features/employee-profile';
import { pictureUrl } from '@utils/pictureUrl';

export const SingleEmployeeDrawerContent = () => {
  const dispatch = useAppDispatch();
  const employeeBasicInfo = useAppSelector(selectEmployeeBasicInfo);
  const employeeBasicInfoStatus = useAppSelector(selectEmployeeBasicInfoStatus);
  const employeeProfileAndCoverReadSasToken = useAppSelector(selectEmployeeProfileAndCoverReadSasToken);

  const handleSetDrawerSite = (siteId: string) => {
    dispatch(
      retrieveSiteListItemByIdRequested({
        siteId
      } as RetrieveSiteListItemByIdRequest)
    );
    dispatch(
      retrieveEmployeesBySiteIdRequested({
        siteId: siteId,
        pageNumber: 1,
        pageSize: 20
      } as RetrieveEmployeesBySiteIdRequest)
    );
    dispatch(openDrawer(DrawerType.Site));
  };

  const handleEmployeeSwitch = (employeeId: string) => {
    dispatch(
      retrieveEmployeeBasicInfoRequested(
        new RetrieveEmployeeBasicInfoRequest({
          employeeId
        })
      )
    );
  };

  if (employeeBasicInfoStatus === ActionStatus.Pending)
    return (
      <Stack
        gap={1}
        sx={{
          alignItems: 'center',
          position: 'relative',
          top: -88,
          margin: '0 -16px'
        }}
      >
        <Skeleton sx={{ width: '100%', height: 250, transform: 'none' }} />
        <Skeleton sx={{ width: '70%', height: 150, transform: 'none' }} />
        <Skeleton sx={{ width: '70%', height: 200, transform: 'none' }} />
        <Skeleton sx={{ width: '70%', height: 300, transform: 'none' }} />
      </Stack>
    );

  return (
    <Stack
      sx={{
        position: 'relative',
        margin: '0 -16px',
        top: -88
      }}
    >
      <Stack
        direction={'row'}
        sx={{
          alignItems: 'flex-start',
          justifyContent: 'flex-end',
          padding: 1,
          width: '100%',
          minHeight: 220,
          backgroundColor: (theme) => theme.palette.common.white,
          borderRadius: (theme) => theme.spacing(2)
        }}
      >
        <Box
          component="img"
          loading="lazy"
          sx={{ position: 'absolute', top: 0, left: 0, height: 220, width: '100%', objectFit: 'cover' }}
          src={
            employeeBasicInfo.employeeCoverPictureUrl
              ? pictureUrl(employeeBasicInfo.employeeCoverPictureUrl, employeeProfileAndCoverReadSasToken, PictureSizeSuffix.lg)
              : `/images/cover-image-placeholder.png`
          }
          alt={`${employeeBasicInfo.employeeDisplayName} cover image`}
        />
      </Stack>
      <Stack
        gap={1}
        sx={{
          margin: '-96px 0 16px',
          height: '100%',
          justifyContent: 'flex-start',
          alignItems: 'center',
          padding: '0 32px',
          zIndex: 1,
          position: 'relative'
        }}
      >
        <Stack
          spacing={2}
          sx={{
            padding: '24px 20px',
            backgroundColor: (theme) => theme.palette.grey[600],
            width: '100%',
            borderRadius: (theme) => theme.spacing(2),
            alignItems: 'center',
            position: 'relative'
          }}
        >
          <Avatar
            src={
              employeeBasicInfo.employeeProfilePictureUrl !== undefined
                ? pictureUrl(employeeBasicInfo.employeeProfilePictureUrl, employeeProfileAndCoverReadSasToken, PictureSizeSuffix.lg)
                : ''
            }
            alt={employeeBasicInfo.employeeDisplayName}
            sx={{
              width: 150,
              height: 150,
              objectFit: 'cover',
              borderRadius: (theme) => theme.spacing(2)
            }}
          >
            {initials(employeeBasicInfo.employeeDisplayName)}
          </Avatar>
          <Stack
            sx={{
              alignItems: 'center'
            }}
          >
            <Typography fontWeight={600}>{employeeBasicInfo.employeeDisplayName}</Typography>
            <Typography
              sx={{
                color: (theme) => alpha(theme.palette.common.black, 0.5)
              }}
            >
              {employeeBasicInfo.employeeJobTitle}
            </Typography>
          </Stack>
          <EmployeeActions employee={employeeBasicInfo} />
        </Stack>
        <Stack
          spacing={2}
          sx={{
            padding: '24px 16px',
            backgroundColor: (theme) => theme.palette.grey[600],
            width: '100%',
            borderRadius: (theme) => theme.spacing(2)
          }}
        >
          <Stack gap={4}>
            <Typography variant="subtitle1">Basic Info</Typography>
            <Stack gap={2}>
              <Stack direction="row" alignItems={'center'} gap={2}>
                <UserIcon />
                <Typography>{employeeBasicInfo.employeeDisplayName.length !== 0 ? employeeBasicInfo.employeeDisplayName : '-'}</Typography>
              </Stack>
              <Stack direction="row" alignItems={'center'} gap={2}>
                <EmailIcon />
                <Typography>{employeeBasicInfo.emailAddress.length !== 0 ? employeeBasicInfo.emailAddress : '-'}</Typography>
              </Stack>
              <Stack direction="row" alignItems={'center'} gap={2}>
                <SmartPhoneIcon />
                <Typography>{employeeBasicInfo.employeeMobilePhoneNumber.length !== 0 ? employeeBasicInfo.employeeMobilePhoneNumber : '-'}</Typography>
              </Stack>
              <Stack direction="row" alignItems={'center'} gap={2}>
                <DocsIcon />
                <Typography>
                  Works at{' '}
                  <Link
                    onClick={() => handleSetDrawerSite(employeeBasicInfo.worksAtSiteId)}
                    sx={{
                      color: (theme) => theme.palette.customTheme.focusItem,
                      fontWeight: 700,
                      cursor: 'pointer'
                    }}
                  >
                    {employeeBasicInfo.worksAtSiteName.length !== 0 ? employeeBasicInfo.worksAtSiteName : ''}
                  </Link>
                </Typography>
              </Stack>
              <Stack direction="row" alignItems={'center'} gap={2}>
                <OrganizationIcon />
                <Typography>
                  Direct Manager:{' '}
                  <Link
                    onClick={() => handleEmployeeSwitch(employeeBasicInfo.directManagerId)}
                    sx={{
                      color: (theme) => theme.palette.customTheme.focusItem,
                      fontWeight: 700,
                      cursor: 'pointer'
                    }}
                  >
                    {employeeBasicInfo.directManagerDisplayName.length !== 0 ? employeeBasicInfo.directManagerDisplayName : '-'}
                  </Link>
                </Typography>
              </Stack>
              <Stack direction="row" alignItems={'center'} gap={2}>
                <CalendarIcon />
                <Typography>{employeeBasicInfo.joinedAt}</Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <EmployeeDrawerTabs employee={employeeBasicInfo} />
      </Stack>
    </Stack>
  );
};
