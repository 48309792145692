import { Typography, Button, Stack } from '@mui/material';
import { useCallback, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { selectCurrentEmployeeId } from '@app/user';
import { RowCenterStack, NotificationsIcon, ActionStatus } from '@/shared';
import {
  deleteAllNotificationsRequested,
  markAllNotificationsAsReadRequested,
  retrieveNotificationsRequested,
  selectCurrentNotificationPageNumber,
  selectHasMore,
  selectNotificationAllIds,
  selectNotificationStatus,
  selectNotificationTotalCount
} from '@features/notifications';
import { useTranslation } from 'react-i18next';
import { DeleteAllNotificationsRequest, MarkAllAsReadRequest, RetrieveNotificationsRequest } from '@thrivea/notification-client';
import { NotificationItem } from '@features/drawer';

export const NotificationsDrawerContent = () => {
  const { t } = useTranslation(['common']);
  const dispatch = useAppDispatch();

  const currentEmployeeId = useAppSelector(selectCurrentEmployeeId);

  const notificationAllIds = useAppSelector(selectNotificationAllIds);
  const hasMore = useAppSelector(selectHasMore);
  const notificationStatus = useAppSelector(selectNotificationStatus);
  const currentNotificationPageNumber = useAppSelector(selectCurrentNotificationPageNumber);
  const totalCount = useAppSelector(selectNotificationTotalCount);

  const observer = useRef<IntersectionObserver>();
  const bottomElementRef = useRef<HTMLDivElement>(null);

  const handleScroll = useCallback(() => {
    if (hasMore) {
      dispatch(
        retrieveNotificationsRequested(
          new RetrieveNotificationsRequest({
            employeeId: currentEmployeeId,
            pageSize: 10,
            pageNumber: currentNotificationPageNumber + 1
          })
        )
      );
    }
  }, [hasMore, currentNotificationPageNumber]);

  useEffect(() => {
    observer.current = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && notificationStatus === ActionStatus.Idle) {
          handleScroll();
        }
      },
      { threshold: 1 } // Trigger the observer when the target is 100% visible
    );

    // Start observing the target element
    if (bottomElementRef.current && observer.current) {
      observer.current.observe(bottomElementRef.current);
    }

    return () => {
      // Clean up the observer and scroll event listener when the component unmounts
      if (observer.current && bottomElementRef.current) {
        observer.current.disconnect();
      }
    };
  }, [handleScroll]);

  const handleMarkAllAsReadButtonClick = () => {
    dispatch(
      markAllNotificationsAsReadRequested(
        new MarkAllAsReadRequest({
          employeeId: currentEmployeeId
        })
      )
    );
  };

  const handleClearAllButtonClick = () => {
    dispatch(
      deleteAllNotificationsRequested(
        new DeleteAllNotificationsRequest({
          employeeId: currentEmployeeId
        })
      )
    );
  };

  return (
    <Stack>
      <Stack>
        <NotificationsIcon width={64} height={64} />
        {totalCount === 0 && (
          <Stack gap={'8px'}>
            <Typography variant="subtitle1">{t('notifications_title', { ns: 'common' })}</Typography>
            <Typography sx={{ fontWeight: 600 }}>{t('no_new_notifications', { ns: 'common' })}</Typography>
          </Stack>
        )}
        {totalCount > 0 && (
          <Stack>
            <Typography
              variant="subtitle1"
              sx={{
                mb: 2
              }}
            >
              You have {t('notification_with_count', { ns: 'common', count: totalCount })}
            </Typography>
            <RowCenterStack gap={2} sx={{ justifyContent: 'center', py: 2 }}>
              <Button variant="text" sx={{ fontWeight: 700, '&:hover': { backgroundColor: 'transparent' } }} onClick={handleMarkAllAsReadButtonClick}>
                {t('mark_as_read', { ns: 'common' })}
              </Button>
              <Button variant="text" sx={{ fontWeight: 700, '&:hover': { backgroundColor: 'transparent' } }} onClick={handleClearAllButtonClick}>
                {t('clear_all', { ns: 'common' })}
              </Button>
            </RowCenterStack>
            {notificationAllIds.map((notificationId) => (
              <NotificationItem key={notificationId} notificationId={notificationId} />
            ))}
            <div ref={bottomElementRef} style={{ width: '100%', height: '50px' }}></div>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
