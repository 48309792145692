import { RetrieveEmployeeBasicInfoRequest } from '@thrivea/organization-client';
import Quill from 'quill';
import { store } from '@app/store';
import { retrieveEmployeeBasicInfoRequested } from '@features/admin-settings';

const Embed = Quill.import('blots/embed') as any;

export class EmployeeBlot extends Embed {
  static create(data) {
    const node = super.create();
    node.setAttribute('data-id', data.id);
    node.setAttribute('data-name', data.name);
    node.setAttribute('class', 'mention');
    node.textContent = `@${data.name}`;

    node.addEventListener('click', () => {
      store.dispatch(
        retrieveEmployeeBasicInfoRequested(
          new RetrieveEmployeeBasicInfoRequest({
            employeeId: data.id
          })
        )
      );
    });
    return node;
  }

  static value(node) {
    return {
      id: node.getAttribute('data-id'),
      name: node.getAttribute('data-name')
    };
  }
}

EmployeeBlot.blotName = 'employee';
EmployeeBlot.tagName = 'span';
